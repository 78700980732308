import React, { Component } from 'react';
import TableRender from '../../../../components/TableRender/TableRender';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import LoginManagementAction from './LoginManagementAction';
import { TABLE_HEADER } from '../../LoginManagementStructure';

class LoginManagementTable extends Component {
  state = {
    openLoginModal: -1,
    TABLE_CONTENT: []
  };

  componentDidMount() {}

  componentDidUpdate() {
    // this.props.loginManagementStore.TABLE_CONTENT
  }

  handleOpenLoginModal = data => {
    this.setState({ openLoginModal: data !== '' ? data : '' });
    this.props.loginManagementStore.loginUserId = data !== '' ? data : '';
  };

  handleCloseLoginModal = () => {
    this.setState({ openLoginModal: -1 });
  };

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardHeader
            // action={
            // 	<Button>ddddd</Button>
            // }
            title={
              <Button
                color="secondary"
                // disabled={!formState.isValid}
                // fullWidth
                type="submit"
                variant="contained"
                onClick={() => this.handleOpenLoginModal()}>
                เพิ่ม
              </Button>
            }
          />
          <Divider />
          <CardContent>
            <TableRender
              TABLE_HEADER={TABLE_HEADER}
              TABLE_CONTENT={this.props.loginManagementStore.TABLE_CONTENT}
            />
          </CardContent>
          <Divider />
        </Card>
        <LoginManagementAction
          open={this.state.openLoginModal !== -1 ? true : false}
          close={this.handleCloseLoginModal}
        />
      </React.Fragment>
    );
  }
}

export default inject('loginManagementStore')(observer(LoginManagementTable));
