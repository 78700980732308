import sendRequest from './sendRequestAPI';
import { HTTP } from '../constants/enum';

class ServicesOption {
  getAllGroup(requestData) {
    return sendRequest('/option/getGroup', HTTP.POST, requestData);
  }

  getAllShop(requestData) {
    return sendRequest('/option/getShop', HTTP.POST, requestData);
  }

  getShopByGroup(requestData) {
    return sendRequest('/option/getShop', HTTP.POST, requestData);
  }

  getBank(requestData) {
    return sendRequest('/option/getBank', HTTP.POST, requestData);
  }

  getActiveStatus(requestData) {
    return sendRequest('/option/getStatus', HTTP.POST, requestData);
  }

  getApprovalStatus(requestData) {
    return sendRequest('/option/getStatus', HTTP.POST, requestData);
  }

  getSaleStatus(requestData) {
    return sendRequest('/option/getStatus', HTTP.POST, requestData);
  }

  getItemCategory(requestData) {
    return sendRequest('/option/getItemCategory', HTTP.POST, requestData);
  }

  getItemProduct(requestData) {
    return sendRequest('/option/getItemProduct', HTTP.POST, requestData);
  }

  getProductByQRCode(requestData) {
    return sendRequest('/option/getProductByQRCode', HTTP.POST, requestData);
  }

  getProductByBarcode(requestData) {
    return sendRequest('/option/getProductByBarcode', HTTP.POST, requestData);
  }

  getAreaByZipcode(requestData) {
    return sendRequest('/option/getAreaByZipcode', HTTP.POST, requestData);
  }

  getSubDistricts(requestData) {
    return sendRequest('/option/getSubDistricts', HTTP.POST, requestData);
  }

  getDistricts(requestData) {
    return sendRequest('/option/getDistricts', HTTP.POST, requestData);
  }

  getProvinces(requestData) {
    return sendRequest('/option/getProvinces', HTTP.POST, requestData);
  }
  getUserType(requestData) {
    return sendRequest(`/option/get_utility?type=${requestData}`, HTTP.GET);
  }
}

export default new ServicesOption();
