import { observable, computed, action, decorate } from "mobx"

class campaignStore {

	productPointList = []

	TABLE_CONTENT_SET_POINT = []
	
	TABLE_CONTENT = []

	campaignKeyArray = []

	historySearch = {}

	openConfirmDeleteCampaign = ''

	historySearchNonActive = {}

	optionProduct = []

	chooseProductLog = []

	seeDetailOnly = false

}
decorate(campaignStore, {
	productPointList:  observable,
	TABLE_CONTENT: observable,
	TABLE_CONTENT_SET_POINT: observable,
	campaignKeyArray: observable,
	historySearch: observable,
	openConfirmDeleteCampaign: observable,
	historySearchNonActive: observable,
	optionProduct: observable,
	chooseProductLog: observable,
	seeDetailOnly: observable,
})

export default new campaignStore()

