import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    //height: '100%'
    marginBottom: '7px'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    color: '#3F51B5'
  },
  avatar: {
    backgroundColor: '#3F51B5',
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const TotalUsersNew = props => {
  const { totalPC, year, totalPcOfYear, className, ...rest } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={6} sm={6} xl={6} xs={12}>
          <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2">
                    {`PC / ${year}`}
                  </Typography>
                  <Typography variant="h3">{totalPcOfYear}</Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.avatar}>
                    <PeopleIcon className={classes.icon} />
                  </Avatar>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} sm={6} xl={6} xs={12}>
          <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2">
                    {`Total PC`}
                  </Typography>
                  <Typography variant="h3">{totalPC}</Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.avatar}>
                    <PeopleIcon className={classes.icon} />
                  </Avatar>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

TotalUsersNew.propTypes = {
  className: PropTypes.string
};

export default TotalUsersNew;
