import sendRequest from './sendRequestAPI';
import { HTTP } from '../constants/enum';

class ServicesDashboard {
  getPCDashboard(requestData) {
    return sendRequest('/pc/get-dashboard', HTTP.POST, requestData);
  }

  getAds(requestData) {
    return sendRequest('/report/getAds', HTTP.POST, requestData);
  }

  getBudget(requestData) {
    return sendRequest('/home/getBudget', HTTP.POST, requestData);
  }
  getEarnIncentive(requestData) {
    return sendRequest('/home/getEarnIncentive', HTTP.POST, requestData);
  }

  getNewPC(requestData) {
    return sendRequest('/home/getNewPC', HTTP.POST, requestData);
  }
}

export default new ServicesDashboard();
