import React, { Component } from 'react';
import TableRender from '../../../../components/TableRender/TableRender'
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Button
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import ApproveAction from '../ApproveAction';

const TABLE_HEADER = [
	{
		key: 'rowNo',
		label: 'ลำดับ',
	},
	{
		key: 'pcNo',
		label: 'รหัสพีซี',
	},
	{
		key: 'pcName',
		label: 'ชื่อพีซี',
	},
	{
		key: 'groupName',
		label: 'ทีม',
	},
	{
		key: 'shopName',
		label: 'ชื่อร้าน',
	},
	{
		key: 'locationName',
		label: 'ห้าง',
	},
	{
		key: 'tel',
		label: 'เบอร์โทร',
	},
	{
		key: 'approvalStatus',
		label: 'สถานะ',
	},
	{
		key: 'action',
		label: 'จัดการอนุมัติ',
	},
]

class ApprovalTable extends Component {
	
	state = {
		openPCAccountModal: false,
		openPCUpdateModal: false,
		ListPC: [],
		TABLE_CONTENT: [],
	}

	handleOpenPCAccountModal = () => {
		this.setState({ openPCAccountModal: true })
	}

	handleClosePCAccountModal = () => {
		this.setState({ openPCAccountModal: false })
	}

	handleCloseUpdatePCModal = () => {
		this.props.approvalPCStore.pcNo = '';
	}

	render() {
		return (
			<React.Fragment>
				<Card>
					{/* <CardHeader
					// action={
					// 	<Button>ddddd</Button>
					// }
					// title={
					// 	<Button
					// 		color="secondary"
					// 		// disabled={!formState.isValid}
					// 		// fullWidth
					// 		size="large"
					// 		type="submit"
					// 		variant="contained"
					// 		onClick={() => this.handleOpenPCAccountModal()}
					// 	>
					// 		เพิ่มพีซี
					// 	</Button>
					// }
					/> */}
					<Divider />
					<CardContent >
						<TableRender TABLE_HEADER={TABLE_HEADER} TABLE_CONTENT={this.props.approvalPCStore.TABLE_CONTENT} />
					</CardContent>
					<Divider />
					<ApproveAction open={this.props.approvalPCStore.pcNo !== '' ? true : false} close={this.handleCloseUpdatePCModal}/>
				</Card>
			</React.Fragment>
		);
	}
}

export default inject('approvalPCStore')(observer(ApprovalTable))