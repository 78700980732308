import React, { Component } from 'react';
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { Grid, Button, Card, CardHeader, CardContent, Divider, colors } from '@material-ui/core';
import FilterRender from '../../components/FilterRender/FilterRender'
import TableRender from '../../components/TableRender/TableRender'
import { withRouter } from 'react-router-dom';
import { CSVLink } from "react-csv";
import ServicesOption from '../../services/servicesOption'
import ServicesPCManagement from '../../services/servicesPCManagement'
import CustomerReportModal from './CustomerReportModal'
import { getExistData } from '../../constants/function/checkDataValue'
import { INPUT_RENDER, TABLE_HEADER } from './CustomReportStructure'
import { OPTION_RENDER } from '../../components/OptionRender/OptionRender'
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert'
import DialogAlert from '../../components/DialogAlert/DialogAlert'

const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});

class CustomerReport extends Component {
	state = {
		filterList: [],
		TABLE_CONTENT: [],
		idModal: '',
		alertMessage: {
			content: '',
			open: false,
			status: ''
		},
	}

	componentDidMount() {
		//todo น่าจะต้องส่งค่าว่างๆ ไปค้นหาแล้วเอาข้อมูลในตารางมาโชว์ แบบ service เริ่มต้น
		this.setState({ filterList: INPUT_RENDER }, () => {
			let filterList = this.state.filterList
			filterList[2].option = OPTION_RENDER('group', false)
			// filterList[6].option = OPTION_RENDER('activeStatus', false)
			// filterList[7].option = OPTION_RENDER('approvalStatus', false)
			this.setState({ filterList: filterList })
		})
		this.searchSubmit()
	}

	shopByGroupOption = (value) => {
		ServicesOption.getShopByGroup(value).then(res => {
			let filterList = [...this.state.filterList]
			switch (res.status) {
				case 200:
					let groupFilter = filterList[3].option
					res.data.result.map((val, i) => {
						groupFilter.push({ label: val.shopName, value: val.shopNo })
					})
					this.setState({ filterList: filterList })
					break;
				default:
					filterList[3].option = []
					this.setState({ filterList: filterList })
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })

			}
		})
	}

	getListPC = (data) => {
		ServicesPCManagement.getListPC(data).then(res => {
			switch (res.status) {
				case 200:
					let TABLE_CONTENT = []
					res.data.result.map((value, index) => {
						TABLE_CONTENT.push(
							{
								rowNo: index + 1,
								pcNo: value.pcNo,
								pcName: value.firstName,
								groupName: value.groupName,
								shopName: value.shopName,
								locationName: value.locationName,
								tel: getExistData(value.tel).toString(),
								approvalStatus: value.approvalStatusName,
								action:
									<Button color="secondary"
										// disabled={!formState.isValid}
										// fullWidth
										type="submit"
										variant="contained"
										color="secondary"
										onClick={() => this.handleOpenModal(value.pcNo)}

									>
										เพิ่มเติม
									</Button>
								,
							}
						)
					})
					this.setState({ TABLE_CONTENT: TABLE_CONTENT })
					break;
				default:
					this.setState({ TABLE_CONTENT: [] })
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}
		})
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]

		switch (name) {
			case 'groupNo':
				// selectedOption[1].selected = ''
				filterList[3].option = [];
				this.shopByGroupOption(
					{
						groupNo: value,
						userLogin: JSON.parse(localStorage.getItem('userData')).userId,
						roleId: JSON.parse(localStorage.getItem('userData')).roleId,
					}
				)
				break;
		}

		filterList.map((val, i) => {
			if (val.name === name) {

				val.value = value
			}
		})
		this.setState({ filterList: filterList })
	}

	searchSubmit = () => {
		this.setState({ TABLE_CONTENT: [] })
		let object = {}
		this.state.filterList.map((val, i) => {
			object[`${val.name}`] = val.value
		})
		object.userLogin = JSON.parse(localStorage.getItem('userData')).userId
		object.approvalStatus = ['1']
		object.workSection = JSON.parse(localStorage.getItem('userData')).workSection
		object.roleId = JSON.parse(localStorage.getItem('userData')).roleId

		this.getListPC(object)
	}

	handleOpenModal = (idModal) => {
		this.setState({ idModal: idModal })
	}

	handleCloseModal = () => {
		this.setState({ idModal: '' })
	}

	prepareExportContent = (content) => {
		if (content.length > 0) {
			return content.filter((val, i) => {
				return val.name !== 'action' && val
			})
		} else {
			return []
		}
	}

	prepareExportHeader = (content) => {
		if (content.length > 0) {
			return content.filter((val, i) => {
				return val.key !== 'action' && val
			})
		} else {
			return []
		}
	}

	closeAlert = () => {
		if (this.state.alertMessage.status === 'success') {
			this.searchSubmit()
		}
		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
			rowNumSelected: [],
			rowNumSelectedData: [],
		})
	}

	render() {
		const { classes } = this.props;
		const { filterList, TABLE_CONTENT, alertMessage } = this.state;

		return (
			<React.Fragment>
				<div className={classes.root}>
					<Card>
						<CardHeader
							title={
								"ข้อมูลตัวพีซี"
							}
						/>
						<Divider />
						<CardContent >
							<Grid
								container
								spacing={4}
							>
								<FilterRender
									filterList={filterList}
									handleChangeFilter={this.handleChangeFilter}
								/>
								<Grid
									item
									lg={12}
									sm={12}
									xl={12}
									xs={12}
									style={{ textAlignLast: 'right' }}
								>
									<Button
										color="primary"
										type="submit"
										variant="contained"
										onClick={() => this.searchSubmit()}
									>
										ค้นหา
							</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					&nbsp;
					<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<Card>
								<CardHeader
									action={
										TABLE_CONTENT.length > 0 ? <CSVLink data={this.prepareExportContent(TABLE_CONTENT)} headers={this.prepareExportHeader(TABLE_HEADER)} filename={"customerReport.csv"}>
											<Button
												style={{ backgroundColor: colors.green[600], color: 'white' }}

												variant="contained"
											//onClick={() => this.handleOpenAddSales()}
											>
												ส่งออกไฟล์ EXCEL
										</Button>
										</CSVLink> :
											<Button
												style={{ backgroundColor: colors.green[600], color: 'white' }}

												// disabled={!formState.isValid}
												// fullWidth
												variant="contained"
												diabled
											//onClick={() => this.handleOpenAddSales()}
											>
												ส่งออกไฟล์ EXCEL
											</Button>
									}
								/>
								<Divider />
								<CardContent >
									<TableRender TABLE_HEADER={TABLE_HEADER} TABLE_CONTENT={TABLE_CONTENT} />
								</CardContent>
								<Divider />
							</Card>
						</Grid>
					</Grid>
				</div>
				<DialogAlert data={alertMessage} close={this.closeAlert} />
				<CustomerReportModal open={this.state.idModal} close={this.handleCloseModal} />
			</React.Fragment>
		);
	}
}

export default compose(
	withStyles(useStyles),
	inject('optionStore')
)(observer(withRouter(CustomerReport)))