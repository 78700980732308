import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { inject, observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Profile, SidebarNav } from './components';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import EditIcon from '@material-ui/icons/Edit';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ServicesReport from '../../../../services/servicesReport';
import { callDialogAlert } from '../../../../components/DialogAlert/DialogAlert';

const useStyles = theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(9)
  }
});

// const useStyles = makeStyles(theme => ({
// 	drawer: {
// 		width: 240,
// 		[theme.breakpoints.up('lg')]: {
// 			marginTop: 64,
// 			height: 'calc(100% - 64px)'
// 		}
// 	},
// 	root: {
// 		backgroundColor: theme.palette.white,
// 		display: 'flex',
// 		flexDirection: 'column',
// 		height: '100%',
// 		padding: theme.spacing(2)
// 	},
// 	divider: {
// 		margin: theme.spacing(2, 0)
// 	},
// 	nav: {
// 		marginBottom: theme.spacing(9)
// 	}
// }));

class Sidebar extends Component {
  state = {
    badgePC: 0,
    badgeSale: 0
  };

  componentDidMount() {
    this.getBadgePC();
    // this.getBadgeSale();
  }

  componentDidUpdate() {
  }

  getBadgePC = () => {
    ServicesReport.getBadgePC(
      JSON.parse(localStorage.getItem('userData')).workSection
    ).then(res => {
      switch (res.status) {
        case 200:
          if (!!res.data.result) {
            this.setState({ badgePC: res.data.result.badge }, () => {
              this.forceUpdate();
            });
          }
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  getBadgeSale = () => {
    ServicesReport.getBadgeSale(
      JSON.parse(localStorage.getItem('userData')).workSection
    ).then(res => {
      switch (res.status) {
        case 200:
          if (!!res.data.result) {
            this.setState({ badgeSale: res.data.result.badge }, () => {
              this.forceUpdate();
            });
          }

          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  privatePage = userPersonal => {
    const badgeSale = this.state.badgeSale;
    switch (userPersonal.roleName) {
      case 'ADMIN':
            return [
              {
                title: 'หน้าหลัก',
                href: '/dashboardAdmin',
                icon: <DashboardIcon />
              },
              {
                title: 'รายการพีซี',
                href: '',
                icon: <EditIcon />,
                qtyAllData: this.state.badgePC >= 0 && this.state.badgePC,
                subMenu: [
                  {
                    title: 'ข้อมูล',
                    href: '/pc',
                    icon: <GroupAddIcon />,
                    qtyData: this.state.badgePC >= 0 && this.state.badgePC
                  }
                ]
              },
              {
                title: 'รายการขาย',
                href: '',
                icon: <MonetizationOnIcon />,
                qtyAllData: badgeSale >= 0 && badgeSale,
                subMenu: [
                  // {
                  //   title: 'รออนุมัติขาย',
                  //   href: '/approvalSaleList',
                  //   icon: <CheckBoxIcon />,
                  //   qtyData: badgeSale >= 0 && badgeSale
                  // },
                  {
                    title: 'สรุปยอดโอน',
                    href: '/transferSummeryTrade',
                    icon: <AssignmentIcon />
                  },
                  {
                    title: 'รออนุมัติส่งบัญชี',
                    href: '/pendingApprovalToAccount',
                    icon: <CheckBoxIcon />
                  }
                ]
              },
              {
                title: 'การตลาด',
                href: '',
                icon: <ShoppingBasketIcon />,
                subMenu: [
                  {
                    title: 'แคมเปญ',
                    href: '/campaignManagement',
                    icon: <CollectionsBookmarkIcon />
                  },
                  // {
                  //   title: 'สินค้าแนะนำ',
                  //   href: '/productManagement',
                  //   icon: <ShopTwoIcon />
                  // }
                ]
              },
              {
                title: 'รายงาน',
                href: '/salesListReport',
                icon: <FindInPageIcon />,
                subMenu: [
                  // {
                  // 	title: 'การขายตามสินค้า',
                  // 	href: '/salesListReport',
                  // 	icon: <FindInPageIcon />
                  // },
                  {
                    title: 'บัญชี/การเงิน',
                    href: '/incentiveReport',
                    icon: <FindInPageIcon />
                  },
                  // {
                  // 	title: 'รายงานส่งการเงิน',
                  // 	href: '/financialReport',
                  // 	icon: <FindInPageIcon />
                  // },
                  // {
                  //   title: 'ยอดขาย',
                  //   href: '/salesReport',
                  //   icon: <FindInPageIcon />
                  // },
                  // {
                  //   title: 'สรุปค่าใช้จ่าย',
                  //   href: '/IncentiveByCampaignReport',
                  //   icon: <FindInPageIcon />
                  // }
                ]
              },
              {
                title: 'ตั้งค่า',
                href: '',
                icon: <SettingsIcon />,
                subMenu: [
                  {
                    title: 'หน้าล็อกอิน',
                    href: '/loginManagement',
                    icon: <PostAddIcon />
                  },
                  {
                    title: 'สาขา',
                    href: '/branch',
                    icon: <PostAddIcon />
                  }
                ]
              }
            ]
      case 'PC':
        switch (userPersonal.roleId) {
          case 0:
            return [
              {
                title: 'หน้าหลัก',
                href: '/dashboard',
                icon: <DashboardIcon />
              },
              // {
              // 	title: 'บันทึกการขาย',
              // 	href: '/addSalesList',
              // 	icon: <AddToPhotosIcon />
              // },
              {
                title: 'ประวัติการขาย',
                href: '/salesHistory',
                icon: <ShoppingBasketIcon />
              },
              // {
              // 	title: 'รายการขาย',
              // 	href: '/salesList',
              // 	icon: <ReceiptIcon />
              // },
              {
                title: 'ติดต่อเรา',
                href: '/contactUs',
                icon: <ContactSupportIcon />
              }
            ];
          case 2:
            return [
              {
                title: 'หน้าหลัก',
                href: '/dashboard',
                icon: <DashboardIcon />
              },
              {
                title: 'ประวัติการขาย',
                href: '/salesHistory',
                icon: <ShoppingBasketIcon />
              },
              {
                title: 'รายการขาย',
                href: '/salesList',
                icon: <ReceiptIcon />
              },
              {
                title: 'ติดต่อเรา',
                href: '/contactUs',
                icon: <ContactSupportIcon />
              }
            ];
          case 3:
            return [
              {
                title: 'หน้าหลัก',
                href: '/dashboard',
                icon: <DashboardIcon />
              },
              // {
              // 	title: 'บันทึกการขาย',
              // 	href: '/addSalesList',
              // 	icon: <AddToPhotosIcon />
              // },
              {
                title: 'ประวัติการขาย',
                href: '/salesHistory',
                icon: <ShoppingBasketIcon />
              },
              // {
              // 	title: 'รายการขาย',
              // 	href: '/salesList',
              // 	icon: <ReceiptIcon />
              // },
              {
                title: 'ติดต่อเรา',
                href: '/contactUs',
                icon: <ContactSupportIcon />
              }
            ];
          case 4:
            return [
              {
                title: 'หน้าหลัก',
                href: '/dashboard',
                icon: <DashboardIcon />
              },
              // {
              // 	title: 'บันทึกการขาย',
              // 	href: '/addSalesList',
              // 	icon: <AddToPhotosIcon />
              // },
              {
                title: 'ประวัติการขาย',
                href: '/salesHistory',
                icon: <ShoppingBasketIcon />
              },
              // {
              // 	title: 'รายการขาย',
              // 	href: '/salesList',
              // 	icon: <ReceiptIcon />
              // },
              {
                title: 'ติดต่อเรา',
                href: '/contactUs',
                icon: <ContactSupportIcon />
              }
            ];
        }

      default:
    }
  };

  render() {
    const {
      classes,
      open,
      variant,
      onClose,
      className,
      userPersonal,
      ...rest
    } = this.props;
    if (this.props.pcManagementStore.triggerApproved) {
      this.getBadgePC();
      setTimeout(() => {
        this.props.pcManagementStore.triggerApproved = false;
        this.forceUpdate();
      }, 100);
    }

    if (this.props.approvalSaleListStore.triggerApproved) {
      // this.getBadgeSale();
      setTimeout(() => {
        this.props.approvalSaleListStore.triggerApproved = false;
        this.forceUpdate();
      }, 100);
    }

    return (
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawer }}
        onClose={onClose}
        open={open}
        variant={variant}>
        <div {...rest} className={clsx(classes.root, className)}>
          <Profile userPersonal={userPersonal} />
          <Divider className={classes.divider} />
          <SidebarNav
            className={classes.nav}
            pages={this.privatePage(userPersonal)}
          />
        </div>
      </Drawer>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('approvalSaleListStore', 'pcManagementStore')
)(observer(Sidebar));

// const Sidebar = props => {
// 	const { open, variant, onClose, className, userPersonal, ...rest } = props;

// 	const classes = useStyles();

// 	const privatePage = (userPersonal) => {
// 		switch (userPersonal.roleName) {
// 			case 'ADMIN':
// 				switch (userPersonal.roleId) {
// 					case 0:
// 						return [
// 							{
// 								title: 'หน้าหลัก',
// 								href: '/dashboardAdmin',
// 								icon: <DashboardIcon />
// 							},
// 							{
// 								title: 'รายการพีซี',
// 								href: '',
// 								icon: <EditIcon />,
// 								subMenu: [
// 									{
// 										title: 'จัดการพีซี',
// 										href: '/pcManagement',
// 										icon: <GroupAddIcon />
// 									},
// 									// {
// 									// 	title: 'อนุมัติพีซี',
// 									// 	href: '/approvalPC',
// 									// 	icon: <CheckBoxIcon />
// 									// },
// 									{
// 										title: 'ข้อมูลพีซี',
// 										href: '/customerReport',
// 										icon: <PeopleAltIcon />
// 									},
// 								]
// 							},
// 							{
// 								title: 'รายการขาย',
// 								href: '',
// 								icon: <MonetizationOnIcon />,
// 								subMenu: [
// 									{
// 										title: 'อนุมัติบันทึกการขาย',
// 										href: '/approvalSaleList',
// 										icon: <CheckBoxIcon />
// 									},
// 									{
// 										title: 'สรุปยอดโอนแผนก Product',
// 										href: '/transferSummeryProduct',
// 										icon: <AssignmentIcon />
// 									},
// 									{
// 										title: 'สรุปยอดโอนแผนก Trade',
// 										href: '/transferSummeryTrade',
// 										icon: <AssignmentIcon />
// 									},
// 									{
// 										title: 'รออนุมัติส่งบัญชี',
// 										href: '/pendingApprovalToAccount',
// 										icon: <CheckBoxIcon />
// 									},
// 								]
// 							},
// 							{
// 								title: 'การตลาด',
// 								href: '',
// 								icon: <ShoppingBasketIcon />,
// 								subMenu: [
// 									{
// 										title: 'แคมเปญ',
// 										href: '/campaignManagement',
// 										icon: <CollectionsBookmarkIcon />
// 									},
// 									{
// 										title: 'สินค้าแนะนำ',
// 										href: '/productManagement',
// 										icon: <ShopTwoIcon />
// 									},
// 								]
// 							},
// 							{
// 								title: 'รายงาน',
// 								href: '/salesListReport',
// 								icon: <FindInPageIcon />,
// 								subMenu: [
// 									// {
// 									// 	title: 'การขายตามสินค้า',
// 									// 	href: '/salesListReport',
// 									// 	icon: <FindInPageIcon />
// 									// },
// 									{
// 										title: 'รายงานบัญชี/การเงิน',
// 										href: '/incentiveReport',
// 										icon: <FindInPageIcon />
// 									},
// 									// {
// 									// 	title: 'รายงานส่งการเงิน',
// 									// 	href: '/financialReport',
// 									// 	icon: <FindInPageIcon />
// 									// },
// 									{
// 										title: 'รายงานยอดขาย',
// 										href: '/salesReport',
// 										icon: <FindInPageIcon />
// 									},
// 								]
// 							},
// 							{
// 								title: 'ตั้งค่า',
// 								href: '',
// 								icon: <SettingsIcon />,
// 								subMenu: [
// 									{
// 										title: 'หน้าล็อกอิน',
// 										href: '/loginManagement',
// 										icon: <PostAddIcon />
// 									},
// 								]
// 							},
// 						]
// 					case 1:
// 						return [
// 							{
// 								title: 'หน้าหลัก',
// 								href: '/dashboardAdmin',
// 								icon: <DashboardIcon />
// 							},
// 							{
// 								title: 'รายการพีซี',
// 								href: '',
// 								icon: <EditIcon />,
// 								subMenu: [
// 									{
// 										title: 'จัดการพีซี',
// 										href: '/pcManagement',
// 										icon: <GroupAddIcon />
// 									},
// 									// {
// 									// 	title: 'อนุมัติพีซี',
// 									// 	href: '/approvalPC',
// 									// 	icon: <CheckBoxIcon />
// 									// },
// 									{
// 										title: 'ข้อมูลพีซี',
// 										href: '/customerReport',
// 										icon: <PeopleAltIcon />
// 									},
// 								]
// 							},
// 							{
// 								title: 'รายการขาย',
// 								href: '',
// 								icon: <MonetizationOnIcon />,
// 								subMenu: [
// 									{
// 										title: 'สรุปยอดโอน',
// 										href: '/transferSummeryProduct',
// 										icon: <AssignmentIcon />
// 									},
// 									{
// 										title: 'รออนุมัติส่งบัญชี',
// 										href: '/pendingApprovalToAccount',
// 										icon: <CheckBoxIcon />
// 									},
// 									// {
// 									// 	title: 'สรุปยอดโอนแผนก Trade',
// 									// 	href: '/transferSummeryTrade',
// 									// 	icon: <AssignmentIcon />
// 									// },
// 								]
// 							},
// 							{
// 								title: 'การตลาด',
// 								href: '',
// 								icon: <ShoppingBasketIcon />,
// 								subMenu: [
// 									{
// 										title: 'แคมเปญ',
// 										href: '/campaignManagement',
// 										icon: <CollectionsBookmarkIcon />
// 									},
// 									{
// 										title: 'สินค้าแนะนำ',
// 										href: '/productManagement',
// 										icon: <ShopTwoIcon />
// 									},
// 								]
// 							},
// 							{
// 								title: 'รายงาน',
// 								href: '/salesListReport',
// 								icon: <FindInPageIcon />,
// 								subMenu: [
// 									// {
// 									// 	title: 'การขายตามสินค้า',
// 									// 	href: '/salesListReport',
// 									// 	icon: <FindInPageIcon />
// 									// },
// 									{
// 										title: 'รายงานบัญชี/การเงิน',
// 										href: '/incentiveReport',
// 										icon: <FindInPageIcon />
// 									},
// 									// {
// 									// 	title: 'รายงานส่งการเงิน',
// 									// 	href: '/financialReport',
// 									// 	icon: <FindInPageIcon />
// 									// },
// 									{
// 										title: 'รายงานยอดขาย',
// 										href: '/salesReport',
// 										icon: <FindInPageIcon />
// 									},
// 								]
// 							},
// 						]
// 					case 2:
// 						return [
// 							{
// 								title: 'หน้าหลัก',
// 								href: '/dashboardAdmin',
// 								icon: <DashboardIcon />
// 							},
// 							{
// 								title: 'รายการพีซี',
// 								href: '',
// 								icon: <EditIcon />,
// 								subMenu: [
// 									{
// 										title: 'จัดการพีซี',
// 										href: '/pcManagement',
// 										icon: <GroupAddIcon />
// 									},
// 									// {
// 									// 	title: 'อนุมัติพีซี',
// 									// 	href: '/approvalPC',
// 									// 	icon: <CheckBoxIcon />
// 									// },
// 									{
// 										title: 'ข้อมูลพีซี',
// 										href: '/customerReport',
// 										icon: <PeopleAltIcon />
// 									},
// 								]
// 							},
// 							{
// 								title: 'รายการขาย',
// 								href: '',
// 								icon: <MonetizationOnIcon />,
// 								subMenu: [
// 									{
// 										title: 'อนุมัติบันทึกการขาย',
// 										href: '/approvalSaleList',
// 										icon: <CheckBoxIcon />,
// 										qtyData: 100
// 									},
// 									{
// 										title: 'สรุปยอดโอน',
// 										href: '/transferSummeryTrade',
// 										icon: <AssignmentIcon />
// 									},
// 									{
// 										title: 'รออนุมัติส่งบัญชี',
// 										href: '/pendingApprovalToAccount',
// 										icon: <CheckBoxIcon />
// 									},

// 								]
// 							},
// 							{
// 								title: 'การตลาด',
// 								href: '',
// 								icon: <ShoppingBasketIcon />,
// 								subMenu: [
// 									{
// 										title: 'แคมเปญ',
// 										href: '/campaignManagement',
// 										icon: <CollectionsBookmarkIcon />
// 									},
// 									{
// 										title: 'สินค้าแนะนำ',
// 										href: '/productManagement',
// 										icon: <ShopTwoIcon />
// 									},
// 								]
// 							},
// 							{
// 								title: 'รายงาน',
// 								href: '/salesListReport',
// 								icon: <FindInPageIcon />,
// 								subMenu: [
// 									// {
// 									// 	title: 'การขายตามสินค้า',
// 									// 	href: '/salesListReport',
// 									// 	icon: <FindInPageIcon />
// 									// },
// 									{
// 										title: 'รายงานบัญชี/การเงิน',
// 										href: '/incentiveReport',
// 										icon: <FindInPageIcon />
// 									},
// 									// {
// 									// 	title: 'รายงานส่งการเงิน',
// 									// 	href: '/financialReport',
// 									// 	icon: <FindInPageIcon />
// 									// },
// 									{
// 										title: 'รายงานยอดขาย',
// 										href: '/salesReport',
// 										icon: <FindInPageIcon />
// 									},
// 								]
// 							},
// 							{
// 								title: 'ตั้งค่า',
// 								href: '',
// 								icon: <SettingsIcon />,
// 								subMenu: [
// 									{
// 										title: 'หน้าล็อกอิน',
// 										href: '/loginManagement',
// 										icon: <PostAddIcon />
// 									},
// 								]
// 							},
// 						]

// 				}
// 				break;
// 			case 'PC':
// 				switch (userPersonal.roleId) {
// 					case 0:
// 						return [
// 							{
// 								title: 'หน้าหลัก',
// 								href: '/dashboard',
// 								icon: <DashboardIcon />
// 							},
// 							{
// 								title: 'บันทึกการขาย',
// 								href: '/addSalesList',
// 								icon: <AddToPhotosIcon />
// 							},
// 							{
// 								title: 'ประวัติการขาย',
// 								href: '/salesHistory',
// 								icon: <ShoppingBasketIcon />
// 							},
// 							{
// 								title: 'รายการขาย',
// 								href: '/salesList',
// 								icon: <ReceiptIcon />
// 							},
// 							{
// 								title: 'สอบถามข้อมูล',
// 								href: '/contactUs',
// 								icon: <ContactSupportIcon />
// 							},
// 						]
// 					case 2:
// 						return [
// 							{
// 								title: 'หน้าหลัก',
// 								href: '/dashboard',
// 								icon: <DashboardIcon />
// 							},
// 							{
// 								title: 'ประวัติการขาย',
// 								href: '/salesHistory',
// 								icon: <ShoppingBasketIcon />
// 							},
// 							{
// 								title: 'รายการขาย',
// 								href: '/salesList',
// 								icon: <ReceiptIcon />
// 							},
// 							{
// 								title: 'สอบถามข้อมูล',
// 								href: '/contactUs',
// 								icon: <ContactSupportIcon />
// 							},
// 						]
// 					case 3:
// 						return [
// 							{
// 								title: 'หน้าหลัก',
// 								href: '/dashboard',
// 								icon: <DashboardIcon />
// 							},
// 							// {
// 							// 	title: 'บันทึกการขาย',
// 							// 	href: '/addSalesList',
// 							// 	icon: <AddToPhotosIcon />
// 							// },
// 							{
// 								title: 'ประวัติการขาย',
// 								href: '/salesHistory',
// 								icon: <ShoppingBasketIcon />
// 							},
// 							{
// 								title: 'รายการขาย',
// 								href: '/salesList',
// 								icon: <ReceiptIcon />
// 							},
// 							{
// 								title: 'สอบถามข้อมูล',
// 								href: '/contactUs',
// 								icon: <ContactSupportIcon />
// 							},
// 						]
// 					case 4:
// 						return [
// 							{
// 								title: 'หน้าหลัก',
// 								href: '/dashboard',
// 								icon: <DashboardIcon />
// 							},
// 							{
// 								title: 'บันทึกการขาย',
// 								href: '/addSalesList',
// 								icon: <AddToPhotosIcon />
// 							},
// 							{
// 								title: 'ประวัติการขาย',
// 								href: '/salesHistory',
// 								icon: <ShoppingBasketIcon />
// 							},
// 							{
// 								title: 'รายการขาย',
// 								href: '/salesList',
// 								icon: <ReceiptIcon />
// 							},
// 							{
// 								title: 'สอบถามข้อมูล',
// 								href: '/contactUs',
// 								icon: <ContactSupportIcon />
// 							},
// 						]
// 				}

// 			default:
// 		}
// 	}

// 	return (
// 		<Drawer
// 			anchor="left"
// 			classes={{ paper: classes.drawer }}
// 			onClose={onClose}
// 			open={open}
// 			variant={variant}
// 		>
// 			<div
// 				{...rest}
// 				className={clsx(classes.root, className)}
// 			>
// 				<Profile userPersonal={userPersonal} />
// 				<Divider className={classes.divider} />
// 				<SidebarNav
// 					className={classes.nav}
// 					pages={privatePage(userPersonal)}
// 				/>
// 			</div>
// 		</Drawer>
// 	);
// };

// Sidebar.propTypes = {
// 	className: PropTypes.string,
// 	onClose: PropTypes.func,
// 	open: PropTypes.bool.isRequired,
// 	variant: PropTypes.string.isRequired
// };

// export default Sidebar;
