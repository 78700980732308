import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesIncentiveReport {

	getReportSummaryIncentive(requestData) {
		return sendRequest("/report/get-summary", HTTP.POST, requestData)
	}

	exportReportAccount(requestData) {
		return sendRequest("/report/export-account", HTTP.POST, requestData)
	}

	exportReportFinance(requestData) {
		return sendRequest("/report/export-finance", HTTP.POST, requestData)
	}

	
}

export default new ServicesIncentiveReport()