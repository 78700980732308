export const TABLE_HEADER = [
	{
		key: 'rowNo',
		label: 'ลำดับ',
	},
	{
		key: 'vendorId',
		label: 'รหัสร้าน',
	},
	{
		key: 'bankCode',
		label: 'รหัสธนาคาร',
	},
	{
		key: 'accountNumber',
		label: 'เลขบัญชี',
	},
	{
		key: 'vendorName',
		label: 'ชื่อร้าน',
	},
	{
		key: 'amount',
		label: 'จำนวนเงิน',
	},
	{
		key: 'beneRef',
		label: 'beneRef',
	},
	{
		key: 'WHT',
		label: 'WHT',
	},
	{
		key: 'advice',
		label: 'advice',
	},
	{
		key: 'SMS',
		label: 'SMS',
	},
	{
		key: 'paymentDetail',
		label: 'paymentDetail',
	},
	{
		key: 'invoice',
		label: 'invoice',
	},
	{
		key: 'branchCode',
		label: 'branchCode',
	},
]

export const INPUT_RENDER = [
	{
		name: 'vendorId',
		displayName: 'รหัสร้าน',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'bankCode',
		displayName: 'รหัสธนาคาร',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'accountNumber',
		displayName: 'เลขบัญชี',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'vendorName',
		displayName: 'ชื่อร้าน',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'amount',
		displayName: 'จำนวนเงิน',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'beneRef',
		displayName: 'beneRef',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'WHT',
		displayName: 'WHT',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'advice',
		displayName: 'advice',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'SMS',
		displayName: 'SMS',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'paymentDetail',
		displayName: 'paymentDetail',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'invoice',
		displayName: 'invoice',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'branchCode',
		displayName: 'branchCode',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
]

