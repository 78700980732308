import React, { Component } from 'react';
import {
	Dialog, DialogContent, Typography, DialogActions, Button, Grid
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FilterRender from '../../../../components/FilterRender/FilterRender'
import validate from 'validate.js';
import ServicesLogin from '../../../../services/servicesLogin'
import DialogAlert from '../../../../components/DialogAlert/DialogAlert'


const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	button: {
		color: theme.palette.success,
	},
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const schema = {
	oldPassword: {
		presence: { allowEmpty: false, message: '^กรุณากรอกรหัสผ่านปัจจุบัน' },
		length: {
			maximum: 18
		}
	},
	newPassword: {
		presence: { allowEmpty: false, message: '^กรุณากรอกรหัสผ่านใหม่' },
		length: {
			minimum: 6,
			tooShort: "^กรอกรหัสผ่านอย่างน้อย 6 ตัวอักษร",
			maximum: 18
		}
	},
	confirmNewPassword: {
		presence: { allowEmpty: false, message: '^กรุณากรอกยืนยันรหัสผ่านใหม่' },
		equality: {
			attribute: "newPassword",
			message: "^กรุณากรอกรหัสผ่านให้สอดคล้องกับช่องรหัสผ่านใหม่",
			// comparator: function (v1, v2) {
			// 	return JSON.stringify(v1) === JSON.stringify(v2);
			// }
		},
		length: {
			minimum: 6,
			tooShort: "^กรอกรหัสผ่านอย่างน้อย 6 ตัวอักษร",
			maximum: 18
		}
	}
};


class ChangePasswordModal extends Component {
	state = {
		filterList: [
			{
				name: 'oldPassword',
				displayName: 'รหัสผ่านปัจจุบัน',
				showDisplayName: true,
				type: 'password',
				value: '',
				option: [],
				required: true,
				revealPassword: false,
				styleXs: {
					label: 4,
					input: 8,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
			{
				name: 'newPassword',
				displayName: 'รหัสผ่านใหม่',
				showDisplayName: true,
				type: 'password',
				revealPassword: false,
				value: '',
				option: [],
				required: true,
				styleXs: {
					label: 4,
					input: 8,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
			{
				name: 'confirmNewPassword',
				displayName: 'ยืนยันรหัสผ่านใหม่',
				showDisplayName: true,
				type: 'password',
				revealPassword: false,
				value: '',
				option: [],
				required: true,
				styleXs: {
					label: 4,
					input: 8,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
		],
		formCheck: {
			isValid: false,
			touched: {},
			values: {},
			errors: {}
		},
		alertMessage: {
			content: '',
			open: false,
			status: ''
		},
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]
		let formCheck = this.state.formCheck

		filterList.map((val, i) => {
			if (val.name === name) {
				if (value === null) {
					// for uploadContent filter
					value = []
				}
				val.value = value
				formCheck.touched[`${val.name}`] = true
				formCheck.values[`${val.name}`] = value
			}
		})

		if (formCheck.touched[name]) {
			const errors = validate(formCheck.values, schema);
			formCheck = {
				...formCheck,
				isValid: errors ? false : true,
				errors: errors || {}
			}
		}


		this.setState({ filterList: filterList, formCheck: formCheck })
	}

	hasError = field => {
		return this.state.formCheck.touched[field] && this.state.formCheck.errors[field] ? true : false;
	}

	helperTextError = field => {
		return this.state.formCheck.touched[field] && this.state.formCheck.errors[field] ? this.state.formCheck.errors[field][0] : null;
	}


	handleSubmit = () => {
		let filterList = [...this.state.filterList]
		let formCheck = this.state.formCheck

		if (formCheck.isValid) {
			this.openAlert('processing', '')

			let object = {}
			this.state.filterList.map((val, i) => {
				if (val.name !== 'confirmNewPassword') {
					object[`${val.name}`] = val.value
				}
			})

			object.pcNo = JSON.parse(localStorage.getItem('userData')).pcNo
			object.userLogin = JSON.parse(localStorage.getItem('userData')).pcNo
			object.roleId = JSON.parse(localStorage.getItem('userData')).roleId

			ServicesLogin.changePassword(object).then(res => {
				switch (res.status) {
					case 200:
						//todo กลับไปหน้าหลัก campaign
						// reload ตารางหน้าหลัก campaign
						this.openAlert('success', 'บันทึกสำเร็จ')
						break;
					default:
						this.openAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่')
				}
			})

		} else {
			filterList.map((val, i) => {
				if (val.value === '' || val.value.length === 0) {
					formCheck.touched[`${val.name}`] = true
				}
				if (formCheck.touched[val.name]) {
					const errors = validate(formCheck.values, schema);
					formCheck = {
						...formCheck,
						isValid: errors ? false : true,
						errors: errors || {}
					}
				}
			})

			this.setState({ formCheck: formCheck })
		}
	}

	handleCloseModal = () => {
		this.props.close()

		let filterList = this.state.filterList

		filterList.map((val, i) => {
				val.value = ''
				val.revealPassword = false
		})

		this.setState({
			filterList,
			formCheck: {
				isValid: false,
				touched: {},
				values: {},
				errors: {}
			},
		})
	}

	handleHiddenPassword = (fieldName) => {
		let filterList = [...this.state.filterList]
		
		filterList.map((val, i) => {
			if (val.name === fieldName) {
				val.revealPassword = !val.revealPassword
			}
		})
		this.setState({ filterList })
	}

	openAlert = (status, message) => {
		this.setState({
			alertMessage: {
				content: message,
				open: true,
				status: status
			},
		})
	}

	closeAlert = () => {
		this.handleCloseModal()
		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
		})
	}

	render() {
		const { filterList, formCheck, alertMessage } = this.state;

		return (
			<Dialog aria-labelledby="customized-dialog-title" open={this.props.open} fullWidth={true}>
				<DialogTitle id="customized-dialog-title" onClose={() => this.handleCloseModal()}>
					เปลี่ยนรหัสผ่าน
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={4}>
						<Grid item lg={12} sm={12} xl={12} xs={12}>
							<FilterRender
								filterList={filterList}
								handleChangeFilter={this.handleChangeFilter}
								hasError={this.hasError}
								helperTextError={this.helperTextError}
								handleHiddenPassword={this.handleHiddenPassword}
							/>
						</Grid>
					</Grid>
				&nbsp;
				</DialogContent>
				<DialogActions>
					<Button color="primary" variant="contained" onClick={() => this.handleSubmit()} >
						บันทึก
			  		</Button>
					<Button color="default" variant="contained" onClick={() => this.handleCloseModal()}>
						ยกเลิก
			  		</Button>
				</DialogActions>
				<DialogAlert data={alertMessage} close={this.closeAlert} />
			</Dialog>
		);
	}
}

export default ChangePasswordModal;