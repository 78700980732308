export const INPUT_RENDER = [
  {
    name: 'docNo',
    displayName: 'เลขเอกสาร',
    placeholder: [],
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 6,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 4,
      input: 8
    }
  }
];

export const INPUT_RENDER_MODAL = [
  {
    name: 'docNo',
    displayName: 'เลขเอกสาร',
    placeholder: [],
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    disabled: true,
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 2,
      input: 10
    }
  },
  {
    name: 'createDate',
    displayName: 'วันที่สร้าง',
    placeholder: [],
    showDisplayName: true,
    type: 'text',
    value: '',
    disabled: true,
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 2,
      input: 10
    }
  },
  // {
  //   name: 'totalQty',
  //   displayName: 'จำนวนที่ขายทั้งหมด',
  //   placeholder: [],
  //   showDisplayName: true,
  //   type: 'text',
  //   value: '',
  //   disabled: true,
  //   option: [],
  //   required: false,
  //   widthCol: 12,
  //   styleXs: {
  //     label: 12,
  //     input: 12
  //   },
  //   styleSm: {
  //     label: 2,
  //     input: 10
  //   }
  // },
  {
    name: 'totalAmount',
    displayName: 'ยอดรวม',
    placeholder: [],
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    disabled: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 2,
      input: 10
    }
  }
];

export const TABLE_HEADER = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'docNo',
    label: 'เลขเอกสาร'
  },
  {
    key: 'createDate',
    label: 'วันที่สร้าง'
  },
  // {
  //   key: 'campaignName',
  //   label: 'แคมเปญ'
  // },
  {
    key: 'totalAmount',
    label: 'ยอดรวม'
  },
  {
    key: 'action',
    label: ''
  }
];

export const TABLE_HEADER_MODAl = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'docNo',
    label: 'เลขเอกสาร'
  },
  {
    key: 'pcName',
    label: 'ชื่อพีซี'
  },
  {
    key: 'qty',
    label: 'จำนวนที่ขาย'
  },
  {
    key: 'amount',
    label: 'จำนวนเงิน'
  },
  {
    key: 'createDate',
    label: 'วันที่สร้าง'
  }
];
