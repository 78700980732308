import React, { Component } from 'react';
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { Grid, Button, Card, CardHeader, CardContent, Divider, colors } from '@material-ui/core';
import FilterRender from '../../components/FilterRender/FilterRender'
import TableRender from '../../components/TableRender/TableRender'
import { withRouter } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { INPUT_RENDER, TABLE_HEADER } from './FinancialReportStructure'



const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});

class FinancialReport extends Component {
	state = {
		filterList: [],
		TABLE_CONTENT: [],
	}

	componentDidMount() {
		this.setState({ filterList: INPUT_RENDER })
		// this.searchSubmit()
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]

		filterList.map((val, i) => {
			if (val.name === name) {
				val.value = value
			}
		})
		this.setState({ filterList: filterList })
	}

	searchSubmit = () => {
		let object = {}

		this.state.filterList.map((val, i) => {
			object[`${val.name}`] = val.value
		})

		object.reportType = "salesByItem"
		object.userLogin = JSON.parse(localStorage.getItem('userData')).userId

		// this.getSalesByItemReport(object)
	}


	render() {
		const { classes } = this.props;
		const { filterList, TABLE_CONTENT } = this.state;

		return (
			<React.Fragment>
				<div className={classes.root}>
					<Card>
						<CardHeader
							title={
								"รายงานการเงิน"
							}
						/>
						<Divider />
						<CardContent >
							<Grid
								container
								spacing={4}
							>
								<FilterRender
									filterList={filterList}
									handleChangeFilter={this.handleChangeFilter}
								//hasError={this.hasError}
								//helperTextError={this.helperTextError}
								/>
								<Grid
									item
									lg={12}
									sm={12}
									xl={12}
									xs={12}
									style={{ textAlignLast: 'right' }}
								>
									<Button
										color="primary"
										// disabled={!formState.isValid}
										// fullWidth
										type="submit"
										variant="contained"
										onClick={() => this.searchSubmit()}
									>
										ค้นหา
						</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
						&nbsp;
						<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<Card>
								<CardHeader
									action={
										TABLE_CONTENT.length > 0 ? <CSVLink data={TABLE_CONTENT} headers={TABLE_HEADER} filename={"salesListReport.csv"}>
											<Button
												style={{ backgroundColor: colors.green[600], color: 'white' }}

												// disabled={!formState.isValid}
												// fullWidth
												variant="contained"
											//onClick={() => this.handleOpenAddSales()}
											>
												ส่งออกไฟล์ EXCEL
									</Button>
										</CSVLink> :
											<Button
												style={{ backgroundColor: colors.green[600], color: 'white' }}

												// disabled={!formState.isValid}
												// fullWidth
												variant="contained"
												diabled
											//onClick={() => this.handleOpenAddSales()}
											>
												ส่งออกไฟล์ EXCEL
										</Button>
									}
								/>
								<Divider />
								<CardContent >
									<TableRender TABLE_HEADER={TABLE_HEADER} TABLE_CONTENT={TABLE_CONTENT} />
								</CardContent>
								<Divider />
							</Card>
						</Grid>
					</Grid>
				</div>
			</React.Fragment>
		);
	}
}

export default compose(
	withStyles(useStyles),
	inject('loginStore')
)(observer(withRouter(FinancialReport)))