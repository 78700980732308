import React, { Component } from 'react';
import FilterRender from '../../../../components/FilterRender/FilterRender'
import {
	Grid,
	TextField,
	Button,
	Card,
	Divider,
	CardContent,
	CardHeader
} from '@material-ui/core';
import ServicesOption from '../../../../services/servicesOption'
import ServicesPCManagement from '../../../../services/servicesPCManagement'
import { inject, observer } from 'mobx-react';

class ApprovalFilter extends Component {
	state = {
		filterList: [
			{
				name: 'pcNo',
				displayName: 'รหัสพีซี',
				showDisplayName: true,
				type: 'text',
				value: '',
				option: [],
				required: false,
				widthCol: 6,
				styleXs: {
					label: 12,
					input: 12,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
			{
				name: 'pcName',
				displayName: 'ชื่อพีซี',
				showDisplayName: true,
				type: 'text',
				value: '',
				option: [],
				required: false,
				widthCol: 6,
				styleXs: {
					label: 12,
					input: 12,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
			{
				name: 'groupNo',
				displayName: 'ทีม',
				showDisplayName: true,
				type: 'option',
				value: '',
				option: [],
				required: false,
				widthCol: 6,
				styleXs: {
					label: 12,
					input: 12,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
			{
				name: 'shopNo',
				displayName: 'ชื่อร้าน',
				showDisplayName: true,
				type: 'option',
				value: '',
				option: [],
				required: false,
				widthCol: 6,
				styleXs: {
					label: 12,
					input: 12,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
			{
				name: 'locationName',
				displayName: 'ห้าง',
				showDisplayName: true,
				type: 'text',
				value: '',
				option: [],
				required: false,
				widthCol: 6,
				styleXs: {
					label: 12,
					input: 12,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
			{
				name: 'tel',
				displayName: 'เบอร์โทร',
				showDisplayName: true,
				type: 'text',
				value: '',
				option: [],
				required: false,
				widthCol: 6,
				styleXs: {
					label: 12,
					input: 12,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			}
		]
	}

	componentDidMount() {
		//todo น่าจะต้องส่งค่าว่างๆ ไปค้นหาแล้วเอาข้อมูลในตารางมาโชว์ แบบ service เริ่มต้น
		this.groupOption()
		// this.activeStatusOption()
		// this.approvalStatusOption()
		this.searchSubmit()
	}

	shopByGroupOption = (value) => {
		ServicesOption.getShopByGroup(value).then(res => {
			let filterList = [...this.state.filterList]

			switch (res.status) {
				case 200:
					let groupFilter = filterList[3].option

					res.data.result.map((val, i) => {
						groupFilter.push({ label: val.shopName, value: val.shopNo })
					})

					this.setState({ filterList: filterList })

					break;
				default:
					filterList[3].option = []
					this.setState({ filterList: filterList })

			}
		})
	}

	groupOption = () => {
		ServicesOption.getAllGroup(
			{
				groupId: '',
				userLogin: JSON.parse(localStorage.getItem('userData')).userId,
				roleId: JSON.parse(localStorage.getItem('userData')).roleId,
				workSection: JSON.parse(localStorage.getItem('userData')).workSection
			}
		).then(res => {
			let filterList = [...this.state.filterList]
			let groupOption = []

			switch (res.status) {
				case 200:
					res.data.result.map((val, i) => {
						groupOption.push({ label: val.groupName, value: val.groupNo })
					})

					filterList[2].option = [...groupOption]
					this.setState({ filterList: filterList })

					this.props.optionStore.groupOption = groupOption

					break;
				default:
					filterList[2].option = []
					this.setState({ filterList: filterList })
					this.props.optionStore.groupOption = []

			}
		})
	}

	getListPC = (data) => {
		ServicesPCManagement.getListPC(data).then(res => {
			switch (res.status) {
				case 200:
					let TABLE_CONTENT = []

					// TABLE_HEADER.map((val, i) => {
					res.data.result.map((value, index) => {
						TABLE_CONTENT.push(
							{
								rowNo: index + 1,
								pcNo: value.pcNo,
								pcName: value.firstName,
								groupName: value.groupName,
								shopName: value.shopName,
								locationName: value.locationName,
								tel: value.tel,
								approvalStatus: value.approvalStatusName,
								action:
									<Button color="secondary"
										// disabled={!formState.isValid}
										// fullWidth
										type="submit"
										variant="contained"
										onClick={() => this.handleOpenUpdatePCModal(value.pcNo)}
									>
										จัดการ
									</Button>
								,
							}
						)
					})

					this.props.approvalPCStore.TABLE_CONTENT = TABLE_CONTENT
					break;
				default: this.props.approvalPCStore.TABLE_CONTENT = []
			}
		})
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]

		switch (name) {
			case 'groupNo':
				// selectedOption[1].selected = ''
				filterList[3].option = [];
				this.shopByGroupOption(
					{
						groupNo: value,
						userLogin: JSON.parse(localStorage.getItem('userData')).userId,
						roleId: JSON.parse(localStorage.getItem('userData')).roleId,
					}
				)
				break;
		}
		filterList.map((val, i) => {
			if (val.name === name) {
				if (value === null) {
					// for uploadContent filter
					value = []
				}
				val.value = value
			}
		})
		this.setState({ filterList: filterList })
	}

	searchSubmit = () => {
		let object = {}

		this.state.filterList.map((val, i) => {
			if (val.type === 'multiOption') {
				if (val.value.length > 0) {
					object[`${val.name}`] = []
					val.value.map(valOpt => {
						object[`${val.name}`].push(valOpt.value)
					})
				} else if (val.value === '' || val.value.length === 0) {
					object[`${val.name}`] = []
					val.option.map(valOpt => {
						object[`${val.name}`].push(valOpt.value)
					})
				}

			} else {
				object[`${val.name}`] = val.value
			}
		})

		object.approvalStatus = ['3']
		object.userLogin = JSON.parse(localStorage.getItem('userData')).userId
		object.workSection = JSON.parse(localStorage.getItem('userData')).workSection
		object.roleId = JSON.parse(localStorage.getItem('userData')).roleId

		this.getListPC(object)

		this.props.approvalPCStore.historySearch = object
	}

	handleOpenUpdatePCModal = (idModal) => {
		this.props.approvalPCStore.pcNo = idModal
	}

	render() {
		const { filterList } = this.state;


		return (
			<React.Fragment>

				<Card>
					<CardHeader
						// action={
						// 	<Button>ddddd</Button>
						// }
						title={
							"อนุมัติพีซี"
						}
					/>
					<Divider />
					<CardContent >
						<Grid
							container
							spacing={2}
						>
							<FilterRender
								filterList={filterList}
								handleChangeFilter={this.handleChangeFilter}
							//hasError={this.hasError}
							//helperTextError={this.helperTextError}
							/>
							<Grid
								item
								lg={12}
								sm={12}
								xl={12}
								xs={12}
								style={{ textAlignLast: 'right' }}
							>
								<Button
									color="primary"
									// disabled={!formState.isValid}
									// fullWidth
									type="submit"
									variant="contained"
									onClick={() => this.searchSubmit()}
								>
									ค้นหา
						</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</React.Fragment>
		);
	}
}

export default inject('optionStore', 'approvalPCStore')(observer(ApprovalFilter))
