import { OPTION_RENDER } from '../../components/OptionRender/OptionRender'


export const INPUT_RENDER = [
	{
		name: 'itemNumber',
		displayName: 'Item Number',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'productName',
		displayName: 'Product Name',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'dateFrom',
		displayName: 'ขายตั้งแต่วันที่',
		showDisplayName: true,
		type: 'date',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'dateTo',
		displayName: 'ถึงวันที่',
		showDisplayName: true,
		type: 'date',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
]

export const TABLE_HEADER = [
	{
		key: 'rowNo',
		label: 'ลำดับ',
	},
	{
		key: 'itemNumber',
		label: 'Item Number',
	},
	{
		key: 'productName',
		label: 'Product Name',
	},
	{
		key: 'qty',
		label: 'QTY',
	},
]