export function utcTimeToFullDate(date) {
  return (
    date.getFullYear() +
    '/' +
    parseInt(date.getMonth() + 1) +
    '/' +
    date.getDate()
  );
}

export function utcTimeToMonthAndYear(date) {
  return parseInt(date.getMonth() + 1) >= 10
    ? parseInt(date.getMonth() + 1)
    : '0' + parseInt(date.getMonth() + 1) + '/' + date.getFullYear();
}

export function currencyFormat(date) {
  return date !== '' && date !== null
    ? date.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    : 0;
}

export function getCurrentYearThaiFormat() {
  return parseInt(new Date().getFullYear()) + 543;
}

export function getCurrentYearFormat() {
  return parseInt(new Date().getFullYear());
}

export function getThaiYearOption(number) {
  let currentYearLabel = parseInt(new Date().getFullYear()) + 543;
  let currentYearValue = parseInt(new Date().getFullYear());

  let listOption = [];
  for (let i = 0; i < number; i++) {
    listOption.push({
      label: 'ปี ' + (currentYearLabel - i).toString(),
      value: currentYearValue - i
    });
  }

  return listOption;
}

export function getThaiMonthsOption() {
  let thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
  ];

  let listMonthsOption = [];
  for (let i = 0; i < thaiMonths.length; i++) {
    listMonthsOption.push({
      label: thaiMonths[i],
      value: i + 1
    });
  }

  return listMonthsOption;
}
