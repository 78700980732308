import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';

import {
  Budget,
  BudgetBar,
  UsersBar,
  TotalUsers,
  UsersNewBar,
  TotalUsersNew
} from './components';
import {
  currencyFormat,
  getThaiYearOption,
  getCurrentYearFormat
} from '../../constants/function/formatConverter';
import servicesSalesHistory from '../../services/servicesSalesHistory';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';
import servicesDashboard from 'services/servicesDashboard';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

class DashboardAdmin extends Component {
  state = {
    optionYear: [],
    selectedYear: '',
    selectedCurrentYear: '',
    selectedNewPC: '',
    optionCurrentYear: [],
    thaiYear: '',
    pcNewThaiYear: '',
    // optionCurrentYear: [],
    budget: [],
    incentiveOfMonth: [],
    pcOfMonth: [],
    totalBudget: 0,
    pcOfYear: 0,
    totalPcOfYear: 0,
    totalPC: 0,
    newPC: [],
    totalApproved: 0,
    totalAll: 0
  };

  componentDidMount() {
    this.getDataBudgetChart();
    this.getEarnIncentive();
    this.getNewPC();
  }

  ////// Budget ////////
  getDataBudgetChart = () => {
    let year = getThaiYearOption(3);
    this.setState(
      { optionYear: year, selectedYear: getCurrentYearFormat() },
      () => {
        servicesDashboard
          .getBudget({
            userLogin: JSON.parse(localStorage.getItem('userData')).userId,
            year: this.state.selectedYear
          })
          .then(res => {
            switch (res.status) {
              case 200:
                this.setState({
                  budget: res.data.result.chart,
                  totalBudget: res.data.result.totalBudget
                });
                break;
              default:
                this.setState({ budget: [], totalBudget: {} });
                this.setState({
                  alertMessage: callDialogAlert(
                    'error',
                    'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
                  )
                });
            }
          });
      }
    );
  };

  handleChangeSelect = value => {
    //let budget = [];
    this.setState({ selectedYear: value.value });
    //todo call services

    servicesDashboard
      .getBudget({
        userLogin: JSON.parse(localStorage.getItem('userData')).userId,
        year: value.value
      })
      .then(res => {
        switch (res.status) {
          case 200:
            this.setState({
              budget: res.data.result.chart,
              totalBudget: res.data.result.totalBudget
            });
            break;
          default:
            this.setState({ budget: [], totalBudget: {} });
        }
      });
  };
  ////// End Budget ////////

  ////// EarnIncentive ////////
  getEarnIncentive = () => {
    let year = getThaiYearOption(3);
    this.setState(
      {
        optionCurrentYear: year,
        selectedCurrentYear: getCurrentYearFormat(),
        thaiYear: parseInt(new Date().getFullYear()) + 543
      },
      () => {
        servicesDashboard
          .getEarnIncentive({
            userLogin: JSON.parse(localStorage.getItem('userData')).userId,
            year: this.state.selectedYear
          })
          .then(res => {
            switch (res.status) {
              case 200:
                this.setState({
                  totalApproved: res.data.result.totalApproved,
                  totalAll: res.data.result.totalAll,
                  approved: res.data.result.approved,
                  pcAll: res.data.result.pcAll
                });
                break;
              default:
                this.setState({
                  incentiveOfMonth: [],
                  pcOfMonth: [],
                  pcOfYear: {}
                });
                this.setState({
                  alertMessage: callDialogAlert(
                    'error',
                    'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
                  )
                });
            }
          });
      }
    );
  };

  handleChangeSelectUserCurrent = value => {
    this.setState({
      selectedCurrentYear: value.value,
      thaiYear: value.label
    });
    //todo call services

    servicesDashboard
      .getEarnIncentive({
        userLogin: JSON.parse(localStorage.getItem('userData')).userId,
        year: value.value
      })
      .then(res => {
        switch (res.status) {
          case 200:
            this.setState({
              totalApproved: res.data.result.totalApproved,
              totalAll: res.data.result.totalAll,
              approved: res.data.result.approved,
              pcAll: res.data.result.pcAll
            });
            break;
          default:
            this.setState({
              approved: [],
              pcAll: [],
              totalAll: {},
              totalApproved: {}
            });
            this.setState({
              alertMessage: callDialogAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              )
            });
        }
      });
  };
  ////// End EarnIncentive ////////

  ////// NewPC ////////
  getNewPC = () => {
    let year = getThaiYearOption(3);
    this.setState(
      {
        optionCurrentYear: year,
        selectedNewPC: getCurrentYearFormat(),
        pcNewThaiYear: parseInt(new Date().getFullYear()) + 543
      },
      () => {
        servicesDashboard
          .getNewPC({
            userLogin: JSON.parse(localStorage.getItem('userData')).userId,
            year: this.state.selectedNewPC
          })
          .then(res => {
            switch (res.status) {
              case 200:
                this.setState({
                  totalPC: res.data.result.totalPC,
                  totalPcOfYear: res.data.result.pcOfYear,
                  newPC: res.data.result.newPC
                });
                break;
              default:
                this.setState({
                  newPC: [],
                  totalPcOfYear: {},
                  totalPC: {}
                });
                this.setState({
                  alertMessage: callDialogAlert(
                    'error',
                    'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
                  )
                });
            }
          });
      }
    );
  };

  handleChangeSelectNewPC = value => {
    this.setState({
      selectedNewPC: value.value,
      pcNewThaiYear: value.label
    });
    //todo call services

    servicesDashboard
      .getNewPC({
        userLogin: JSON.parse(localStorage.getItem('userData')).userId,
        year: value.value
      })
      .then(res => {
        switch (res.status) {
          case 200:
            this.setState({
              totalPC: res.data.result.totalPC,
              totalPcOfYear: res.data.result.pcOfYear,
              newPC: res.data.result.newPC
            });
            break;
          default:
            this.setState({
              newPC: [],
              totalPC: {}
            });
            this.setState({
              alertMessage: callDialogAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              )
            });
        }
      });
  };
  ////// End EarnIncentive ////////
  render() {
    const { classes } = this.props;
    const {
      optionYear,
      selectedYear,
      budget,
      totalBudget,
      pcOfYear,
      incentiveOfMonth,
      pcOfMonth,
      totalPC,
      newPC,
      totalPcOfYear,
      optionCurrentYear,
      selectedNewPC,
      selectedCurrentYear,
      thaiYear,
      pcNewThaiYear,
      totalAll,
      totalApproved,
      approved,
      pcAll
    } = this.state;

    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Budget totalBudget={totalBudget} />
            <BudgetBar
              optionYear={optionYear}
              selectedYear={selectedYear}
              budget={budget}
              handleChangeSelect={this.handleChangeSelect}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <TotalUsers totalAll={totalAll} totalApproved={totalApproved} year={thaiYear}/>
            <UsersBar
              optionYear={optionYear}
              selectedYear={selectedCurrentYear}
              approved={approved}
              pcAll={pcAll}
              handleChangeSelect={this.handleChangeSelectUserCurrent}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <TotalUsersNew
              totalPC={totalPC}
              year={pcNewThaiYear}
              totalPcOfYear={totalPcOfYear}
            />
            <UsersNewBar
              optionYear={optionYear}
              selectedYear={selectedNewPC}
              newPC={newPC}
              handleChangeSelect={this.handleChangeSelectNewPC}
            />
          </Grid>
          {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalUsers />
          </Grid> */}
          {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit />
          </Grid> */}
          {/* <Grid item lg={8} md={12} xl={9} xs={12}>
            <LatestSales />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <UsersByDevice />
          </Grid> */}
        </Grid>
      </div>
    );
  }
}

export default withStyles(useStyles)(DashboardAdmin);
