import styled from 'styled-components';
import Select from 'react-select';

export const SelectStyle = styled(Select)`

	.css-yk16xz-control {
		border-color: ${props => (props.error ? 'red' : 'unset')},
		overflow-y: auto;

	}
	
	`;
