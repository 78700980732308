export const INPUT_RENDER = [
	{
		name: 'date',
		displayName: 'วันที่',
		showDisplayName: true,
		type: 'date',
		value: '',
		option: [],
		required: true,
		widthCol: 12,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'invoiceNo',
		displayName: 'Invoice No',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: true,
		widthCol: 12,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'invoiceImage',
		displayName: 'รูปใบเสร็จ invoice',
		showDisplayName: true,
		type: 'upload',
		maxFile: 1,
		value: [],
		option: [],
		required: true,
		canUpload: true,
		widthCol: 12,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
]

export const INPUT_RENDER2 = [
	{
		name: 'barcode',
		displayName: 'Barcode',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: true,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'itemNumber',
		displayName: 'สินค้า',
		showDisplayName: true,
		type: 'textarea',
		value: '',
		option: [],
		required: true,
		disabled: true,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'qty',
		displayName: 'จำนวน',
		showDisplayName: true,
		type: 'number',
		value: '1',
		option: [],
		required: true,
		disabled: true,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},

]

export const TABLE_HEADER = [
	{
		key: 'rowNo',
		label: 'ลำดับ',
	},
	{
		key: 'barcode',
		label: 'barcode',
	},
	{
		key: 'qty',
		label: 'จำนวน',
	},
	{
		key: 'action',
		label: 'จัดการ',
	},
]