import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
	Grid, Button,
	Card,
	CardHeader,
	CardContent,
	Divider,
	colors,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TablePagination,
} from '@material-ui/core';
import FilterRender from '../../components/FilterRender/FilterRender'
import { utcTimeToFullDate } from '../../constants/function/formatConverter'
import ServicesTranferSummery from '../../services/servicesTranferSummery'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { currencyFormat } from '../../constants/function/formatConverter'
import { getExistData } from '../../constants/function/checkDataValue'
import ConfirmTransferProduct from './ConfirmTransferProduct';
import moment from 'moment';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert'
import DialogAlert from '../../components/DialogAlert/DialogAlert'

const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});

class TransferSummeryProduct extends Component {
	state = {
		filterList: [
			{
				name: 'dateFrom',
				displayName: 'วันที่เริ่ม',
				showDisplayName: true,
				type: 'dateBetween',
				value: utcTimeToFullDate(new Date()),
				option: [],
				error: '',
				required: false,
				widthCol: 6,
				styleXs: {
					label: 12,
					input: 12,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
			{
				name: 'dateTo',
				displayName: 'ถึงวันที่',
				showDisplayName: true,
				type: 'dateBetween',
				value: utcTimeToFullDate(new Date()),
				error: '',
				option: [],
				required: false,
				widthCol: 6,
				styleXs: {
					label: 12,
					input: 12,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
		],
		TABLE_CONTENT: [],
		ROW_CONTENT: [],
		rowNumSelected: [],
		rowNumSelectedData: [],
		rowCount: 0,
		page: 0,
		rowsPerPage: 10,
		FOOTER_CONTENT: {},
		alertMessage: {
			content: '',
			open: false,
			status: ''
		},
		openConfirm: false,
	}

	componentDidMount() {
		this.searchSubmit()
	}

	getWaitSelect = (data) => {
		ServicesTranferSummery.getWaitSelect(data).then(res => {
			switch (res.status) {
				case 200:
					let TABLE_CONTENT = []
					let ROW_CONTENT = []
					let res2 = res.data.result.data
					let rowCount = 0
					res2.map((val, i) => {
						rowCount = ++i;
						TABLE_CONTENT.push(
							{
								checkbox: val,
								rowNo: val.rowNo,
								groupNo: val.groupName,
								pcName: val.pcName,
								shopNo: val.shopName,
								qty: val.qty,
								amount: val.amount,
							}
						)
						ROW_CONTENT.push(val)
					})
					this.setState({ TABLE_CONTENT: TABLE_CONTENT, ROW_CONTENT: ROW_CONTENT, rowCount: rowCount, FOOTER_CONTENT: res.data.result.footer })
					break;
				default:
				this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}
		})
	}

	sendForApproval = (data) => {
		ServicesTranferSummery.sendForApproval(data).then(res => {
			switch (res.status) {
				case 200:
				this.setState({ alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ') })
					break;
				default:
				this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}
		})
	}

	handleCheckbox = (selected, value) => {
		let rowNumSelected = this.state.rowNumSelected
		let rowNumSelectedData = this.state.rowNumSelectedData
		if (rowNumSelected.includes(selected)) {
			//เอาออก
			// rowNumSelected.remove(selected)
			rowNumSelected = rowNumSelected.filter((val, i) => {
				return selected !== val
			})
			rowNumSelectedData = rowNumSelectedData.filter((val, i) => {
				return val.rowNo !== selected
			})
		} else {
			//เอาเข้า
			rowNumSelected.push(selected)
			rowNumSelectedData.push(value)
		}
		this.setState({ rowNumSelected: rowNumSelected, rowNumSelectedData: rowNumSelectedData })
	}

	handleCheckboxAll = (e) => {
		let ROW_CONTENT = this.state.ROW_CONTENT
		let rowNumSelected = this.state.rowNumSelected
		let rowNumSelectedData = this.state.rowNumSelectedData

		if (e.target.checked) {
			ROW_CONTENT.map((val, i) => {
				if (!rowNumSelected.includes(val.rowNo)) {
					rowNumSelected.push(val.rowNo)
					rowNumSelectedData.push(val)
				}
			})
		} else {
			// TABLE_CONTENT.map((val, i) => {
			rowNumSelected = []
			rowNumSelectedData = []
			// })
		}
		this.setState({ rowNumSelected, rowNumSelectedData })
	}

	checkValidDateBetween = (name, value) => {
		let filterList = [...this.state.filterList]
		let isValid = true
		switch (name) {
			case 'dateFrom':
				if (filterList[1].value === '') {
					filterList[1].error = 'กรุณากรอกวันที่ยกเลิก'
				} else {
					if (moment(value).isAfter(filterList[1].value)) {
						filterList[0].error = 'วันที่เริ่มต้นต้องน้อยกว่าหรือเท่ากับวันที่ยกเลิก'
						filterList[1].error = ''
						isValid = false
					} else {
						filterList[0].error = ''
						filterList[1].error = ''
					}
				}
				break;
			case 'dateTo':
				if (filterList[0].value === '') {
					filterList[0].error = 'กรุณากรอกวันที่เริ่มต้น'
				} else {
					if (moment(value).isBefore(filterList[0].value)) {
						filterList[1].error = 'วันที่ยกเลิกต้องมากกว่าหรือเท่ากับวันที่เริ่มต้น'
						filterList[0].error = ''
						isValid = false
					} else {
						filterList[1].error = ''
						filterList[0].error = ''
					}
				}
				break;
		}

		this.setState({ filterList: filterList })
		return isValid
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]

		filterList.map((val, i) => {
			if (val.name === name) {
				switch (name) {
					case 'dateFrom':
						let resDateFormat = utcTimeToFullDate(value)
						let isValid = this.checkValidDateBetween(name, resDateFormat)
						if (isValid) {
							val.value = resDateFormat
						} else {
							val.value = ''
						}
						break;
					case 'dateTo':
						let resDateFormat2 = utcTimeToFullDate(value)
						let isValid2 = this.checkValidDateBetween(name, resDateFormat2)
						if (isValid2) {
							val.value = resDateFormat2
						} else {
							val.value = ''
						}
						break;
					default: val.value = value
				}
			}
		})
		this.setState({ filterList: filterList })
	}

	searchSubmit = () => {
		let object = {}
		let countHasDateBetween = 0;

		this.state.filterList.map((val, i) => {
			object[`${val.name}`] = val.value
			if (val.value !== '') {
				++countHasDateBetween;
			}
		})

		if (countHasDateBetween !== 1) {
			object.userLogin = JSON.parse(localStorage.getItem('userData')).userId
			object.roleId = JSON.parse(localStorage.getItem('userData')).roleId
			object.campaignId = ''
			this.getWaitSelect(object)
		} else {
			this.setState({ alertMessage: callDialogAlert('warning', 'กรุณากรอกวันที่ให้ครบถ้วน') })
		}
	}

	renderTableContent = (content) => {
		let td = Object.keys(content)

		return td.map((val, i) => {
			return (
				val === 'checkbox' ?
					<TableCell className="pointer" style={{ textAlign: 'center' }} key={i}>
						<React.Fragment> <Checkbox
							// indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={this.state.rowNumSelected.includes(content[val].rowNo)}
							onChange={() => this.handleCheckbox(content[val].rowNo, content[val])}
							inputProps={{ 'aria-label': 'labelId' }}
						/>
						</React.Fragment>
					</TableCell>

					:
					<TableCell className="pointer" style={{ textAlign: 'center' }} key={i}>
						{content[val]}
					</TableCell>
			)
		})
	}

	handlePageChange = (event, page) => {
		this.setState({ page: page })
	}

	handleRowsPerPageChange = event => {
		this.setState({ rowsPerPage: event.target.value })
	}

	handleSubmit = () => {
		let object = {}
		let rowNumSelectedData = this.state.rowNumSelectedData

		this.state.filterList.map((val, i) => {
			if (val.type === 'dateBetween') {
				if (val.value !== '') {
					object[`${val.name}`] = val.value
				} else {
					object[`${val.name}`] = utcTimeToFullDate(new Date())
				}
			} else {
				object[`${val.name}`] = val.value
			}
		})

		object.userLogin = JSON.parse(localStorage.getItem('userData')).userId
		object.roleId = JSON.parse(localStorage.getItem('userData')).roleId
		object.campaignId = ''
		object.data = []

		rowNumSelectedData.map((val, i) => {
			object.data.push({
				"pcNo": val.pcNo,
				"pcName": val.pcName,
				"groupNo": val.groupNo,
				"groupName": val.groupName,
				"shopNo": val.shopNo,
				"shopName": val.shopName,
				"qty": val.qty,
				"amount": val.amount,
			})
		})

		this.setState({ alertMessage: callDialogAlert('processing', '') })
		this.sendForApproval(object)
	}

	closeAlert = () => {
		if (this.state.alertMessage.status === 'success') {
			this.searchSubmit()
		}
		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
			rowNumSelected: [],
			rowNumSelectedData: [],
		})
	}

	handleOpenConfirm = () => {
		let isValid = true;
		this.state.filterList.map((val, i) => {
			if (val.type === 'date') {
				if (val.value !== '') {
					val.value = val.value
				} else {
					val.value = utcTimeToFullDate(new Date())
				}
				isValid = true
			}
		})

		if (isValid && this.state.rowNumSelectedData.length > 0) {
			this.setState({ openConfirm: true })
		} else {
			this.setState({ alertMessage: callDialogAlert('warning', 'กรุณากรอกข้อมูลให้ครบถ้วน') })
		}
	}

	handleCloseConfirm = () => {
		// this.searchSubmit()
		this.setState({ openConfirm: false })
	}

	render() {
		const { classes } = this.props;
		const { filterList, TABLE_CONTENT, rowsPerPage, page, alertMessage } = this.state;

		let TABLE_HEADER = [
			{
				key: 'checkbox',
				label: <Checkbox
					indeterminate={this.state.rowNumSelected.length > 0 && this.state.rowNumSelected.length < this.state.rowCount}
					checked={this.state.rowCount > 0 && this.state.rowNumSelected.length === this.state.rowCount}
					onChange={(e) => this.handleCheckboxAll(e)}
					inputProps={{ 'aria-label': 'select all desserts' }}
				/>,
			},
			{
				key: 'rowNo',
				label: 'ลำดับ',
			},
			{
				key: 'groupNo',
				label: 'กลุ่มลูกค้า',
			},
			{
				key: 'pcName',
				label: 'ชื่อ-นามสกุล',
			},
			{
				key: 'shopName',
				label: 'ชื่อร้าน',
			},
			{
				key: 'qty',
				label: 'จำนวนที่ขาย',
			},
			{
				key: 'amount',
				label: 'จำนวนเงิน',
			},
		]
		return (
			<React.Fragment>
				<div className={classes.root}>
					<Card>
						<CardHeader
							// action={
							// 	<Button>ddddd</Button>
							// }
							title={
								"สรุปยอดโอน"
							}
						/>
						<Divider />
						<CardContent >
							<Grid
								container
								spacing={4}
							>
								<FilterRender
									filterList={filterList}
									handleChangeFilter={this.handleChangeFilter}
								//hasError={this.hasError}
								//helperTextError={this.helperTextError}
								/>
								<Grid
									item
									lg={12}
									sm={12}
									xl={12}
									xs={12}
									style={{ textAlignLast: 'right' }}
								>
									<Button
										color="primary"
										// disabled={!formState.isValid}
										// fullWidth
										type="submit"
										variant="contained"
										onClick={() => this.searchSubmit()}
									>
										ค้นหา
									</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<CardContent>
								<Divider />
								<CardContent >
									<React.Fragment>
										<PerfectScrollbar>
											<div >
												<Table>
													<TableHead>
														<TableRow>
															{
																TABLE_HEADER.map((val, i) => {
																	return (
																		<TableCell style={{ textAlign: 'center' }} key={i}>{val.label}</TableCell>
																	)
																})
															}
														</TableRow>
													</TableHead>
													<TableBody>
														{
															TABLE_CONTENT.length > 0 && TABLE_CONTENT.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, i) => {
																return (
																	<TableRow hover key={i}>
																		{this.renderTableContent(val)}
																	</TableRow>
																)
															})
														}
														<TableRow>
															<TableCell colSpan={5} style={{ textAlign: 'right' }}>รวม</TableCell>
															<TableCell style={{ textAlign: 'center' }}>{currencyFormat(getExistData(this.state.FOOTER_CONTENT.totalQty))}</TableCell>
															<TableCell style={{ textAlign: 'center' }}>{currencyFormat(getExistData(this.state.FOOTER_CONTENT.totalAmount))}</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</div>
										</PerfectScrollbar>
										<div>
											<TablePagination
												component="div"
												count={TABLE_CONTENT.length}
												onChangePage={this.handlePageChange}
												onChangeRowsPerPage={this.handleRowsPerPageChange}
												page={page}
												rowsPerPage={rowsPerPage}
												rowsPerPageOptions={[5, 10, 25, 50, 100]}
											/>
										</div>
									</React.Fragment>
								</CardContent>
								<Divider />
							</CardContent>
						</Grid>

						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
							style={{ textAlign: 'center' }}
						>
							<Button style={{ backgroundColor: colors.green[600], color: 'white' }} variant="contained" onClick={() => this.handleOpenConfirm()} >ส่งรายงาน</Button>
						</Grid>
						<ConfirmTransferProduct handleSubmit={this.handleSubmit} open={this.state.openConfirm} close={this.handleCloseConfirm} />
						{/* <DialogAlert data={alertMessage} close={this.closeAlert} /> */}
						<DialogAlert data={alertMessage} close={this.closeAlert} />
					</Grid>
				</div>
			</React.Fragment>
		);
	}
}


export default withStyles(useStyles)(TransferSummeryProduct)