import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesApprovePC {

	approvalPC(requestData) {
		return sendRequest("/pc/approvalPC", HTTP.POST, requestData)
	}

}

export default new ServicesApprovePC()