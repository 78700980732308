import React, { Component } from 'react';
import FilterRender from '../../../../components/FilterRender/FilterRender'
import {
	Grid,
	Button,
	Card,
	Divider,
	CardContent,
	CardHeader
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { utcTimeToFullDate } from '../../../../constants/function/formatConverter'
import ApprovalSaleListAction from './ApprovalSaleListAction'
import ServicesApprovalSaleList from '../../../../services/servicesApprovalSaleList'
import { INPUT_RENDER_PRODUCT, INPUT_RENDER_TRADE, TABLE_HEADER_TRADE } from '../../ApprovalSaleListStructure'
import { OPTION_RENDER } from '../../../../components/OptionRender/OptionRender'




class ApprovalSaleListFilter extends Component {
	state = {
		filterList: [],
		openModal: false,
		statusRow: '',
	}

	componentDidMount() {
		if(JSON.parse(localStorage.getItem('userData')).roleId === 2 || JSON.parse(localStorage.getItem('userData')).roleId === 0) {
			this.setState({ filterList: INPUT_RENDER_TRADE }, () => {
				let filterList = this.state.filterList
				filterList[4].option = OPTION_RENDER('group', false)
				this.setState({ filterList: filterList })
				this.searchInit()
			})
		} else {
			this.setState({ filterList: INPUT_RENDER_PRODUCT }, () => {
				let filterList = this.state.filterList
				filterList[2].option = OPTION_RENDER('group', false)
				this.setState({ filterList: filterList })
				this.searchInit()
			})
		}
	}

	searchInit = () => {
		let object = {}
		this.state.filterList.map((val, i) => {
				object[`${val.name}`] = val.value
		})
		object.userLogin = JSON.parse(localStorage.getItem('userData')).userId
		object.roleId = JSON.parse(localStorage.getItem('userData')).roleId
		this.getApprovalSales(object)
		this.props.approvalSaleListStore.historySearch = object
	}

	renderButtonAction = (status, data) => {
		switch (status) {
			case '1':
			case '3':
			case '4':
				return [
					< Button
						// disabled={!formState.isValid}
						// fullWidth
						type="submit"
						variant="contained"
						color="secondary"
						onClick={() => this.handleOpenModal(data, status)}
					>
						ดูรายละเอียด
				</Button>
				]
				break;
			case '2':
				return [
					< Button
						color="primary"
						// disabled={!formState.isValid}
						// fullWidth
						type="submit"
						variant="contained"
						onClick={() => this.handleOpenModal(data, status)}
					>
						จัดการ
					</Button>
				]
				break;
		}
	}

	getApprovalSales = (data) => {
		ServicesApprovalSaleList.getApprovalSales(data).then(res => {
			switch (res.status) {
				case 200:
					let TABLE_CONTENT = []
					res.data.result.map((value, index) => {
						TABLE_CONTENT.push(
							{
								rowNo: index + 1,
								invoiceNo: value.invoiceNo,
								createDate: value.createDate,
								pcNo: value.pcNo,
								pcName: value.pcName,
								groupNo: value.groupName,
								action: this.renderButtonAction(value.saleStatus, value.transactionId),
							}
						)
					})

					this.props.approvalSaleListStore.TABLE_CONTENT = TABLE_CONTENT
					break;
				default: this.props.approvalSaleListStore.TABLE_CONTENT = []
			}
		})
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]

		filterList.map((val, i) => {
			if (val.name === name) {
				if (value === null) {
					// for uploadContent filter
					value = []
				}
				val.value = value
			}
		})
		this.setState({ filterList: filterList })
	}

	searchSubmit = () => {
		let object = {}

		this.state.filterList.map((val, i) => {
			if (val.type === 'date') {
				if (val.value !== '') {
					object[`${val.name}`] = utcTimeToFullDate(val.value)
				} else {
					object[`${val.name}`] = utcTimeToFullDate(new Date())
				}
			} else {
				object[`${val.name}`] = val.value
			}
		})

		object.userLogin = JSON.parse(localStorage.getItem('userData')).userId
		object.roleId = JSON.parse(localStorage.getItem('userData')).roleId
		
		this.getApprovalSales(object)
		this.props.approvalSaleListStore.historySearch = object
	}

	handleOpenModal = (idModal, status) => {
		this.setState({ openModal: true, statusRow: status })
		this.props.approvalSaleListStore.idModal = idModal
	}

	handleCloseModal = () => {
		this.setState({ openModal: false, statusRow: '' })
		this.props.approvalSaleListStore.idModal = ''
	}


	render() {
		const { filterList, openModal } = this.state;


		return (
			<React.Fragment>
				<Card>
					<CardHeader
						title={
							"รออนุมัติขาย"
						}
						
					/>
					<Divider />
					<CardContent >
						<Grid
							container
							spacing={2}
						>
							<FilterRender
								filterList={filterList}
								handleChangeFilter={this.handleChangeFilter}
							/>
							<Grid
								item
								lg={12}
								sm={12}
								xl={12}
								xs={12}
								style={{ textAlignLast: 'right' }}
							>
								<Button
									color="primary"
									type="submit"
									variant="contained"
									onClick={() => this.searchSubmit()}
								>
									ค้นหา
						</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				<ApprovalSaleListAction statusRow={this.state.statusRow} searchSubmit={this.searchSubmit} open={openModal} close={this.handleCloseModal} />
			</React.Fragment>
		);
	}
}

export default inject('optionStore', 'approvalSaleListStore')(observer(ApprovalSaleListFilter))
