import sendRequest from './sendRequestAPI';
import { HTTP } from '../constants/enum';

class ServicesTranferSummery {
  getWaitSelect(requestData) {
    return sendRequest('/sale/get-wait-select', HTTP.POST, requestData);
  }

  sendForApproval(requestData) {
    return sendRequest('/sale/request-approval', HTTP.POST, requestData);
  }

  revokeApprovalSales(requestData) {
    return sendRequest(
      '/incentive/revokeApprovalSales',
      HTTP.POST,
      requestData
    );
  }

  importFileIncentive(requestData) {
    return sendRequest(
      '/incentive/importFileIncentive',
      HTTP.POST,
      requestData
    );
  }
}

export default new ServicesTranferSummery();
