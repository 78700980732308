import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    ref: 'มกราคม',
    amount: 30,
    customer: {
      name: 'Ekaterina Tankova'
    },
    receivedMoney: 15550,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'กุมภาพันธ์',
    amount: 25,
    customer: {
      name: 'Cao Yu'
    },
    receivedMoney: 15550,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'มีนาคม',
    amount: 10,
    customer: {
      name: 'Alexa Richardson'
    },
    receivedMoney: 5000,
    status: 'refunded'
  },
  {
    id: uuid(),
    ref: 'เมษายน',
    amount: 96,
    customer: {
      name: 'Anje Keizer'
    },
    receivedMoney: 200000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'พฤษภาคม',
    amount: 32,
    customer: {
      name: 'Clarke Gillebert'
    },
    receivedMoney: 800000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'มิถุนายน',
    amount: 16,
    customer: {
      name: 'Adam Denisov'
    },
    receivedMoney: 700,
    status: 'delivered'
  }
];
