import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ablemensellerlink from '../../assets/ablemensellerlink.png'
import ServicesContactUs from '../../services/servicesContactUs'
import { isExistData } from 'constants/function/checkDataValue';
import { getImageAPI } from '../../services/servicesImage'

const useStyles = theme => ({
	root: {
		padding: theme.spacing(4),
	}
});

class ContactUs extends Component {

	state = {
		channel: '',
		qrCodeImage: '',
		url: ''
	}

	componentDidMount() {
		let obj = {
			"userLogin": JSON.parse(localStorage.getItem('userData')).userId,
			"workSection": JSON.parse(localStorage.getItem('userData')).workSection,
			"roleId": JSON.parse(localStorage.getItem('userData')).roleId,
			"groupNo": JSON.parse(localStorage.getItem('userData')).groupNo,

		}
		ServicesContactUs.contactUs(obj).then(res => {
			switch (res.status) {
				case 200:
					if (isExistData(res.data.result)) {
						let resData = res.data.result
						this.setState({ qrCodeImage: getImageAPI(resData[0].qrCodeImage), channel: resData[0].channel, url: resData[0].url})
					}
					break;
				default:
			}
		})
	}
	state = {}
	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root} >
				<Grid container spacing={4} >
					<Grid item lg={12} sm={12} xl={12} xs={12} style={{ textAlign: 'center' }}>
						<Typography variant="h2">
							แอดไลน์มาพูดคุยกับเรา
						</Typography>
					</Grid>
					<Grid item lg={12} sm={12} xl={12} xs={12} style={{ textAlign: 'center' }}>
						<a href={this.state.url} target="_blank">
							<img src={this.state.qrCodeImage} alt="ablemensellerlink_QRCode" />
						</a>
						<Typography variant="h3">
							{this.state.channel}
						</Typography>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withStyles(useStyles)(ContactUs);