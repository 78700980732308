import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesPCManagement {

	getListPC(requestData) {
		return sendRequest("/pc/getListPC", HTTP.POST, requestData)
	}

	generatePC(requestData) {
		return sendRequest("/pc/generatePC", HTTP.POST, requestData)
	}

}

export default new ServicesPCManagement()