import React, { Component } from 'react';
import FilterRender from '../../components/FilterRender/FilterRender'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Grid, Button, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import validate from 'validate.js';
import { INPUT_RENDER } from './ProductManagementStructure'
import ServicesProductManagement from '../../services/servicesProductManagement';
import { isExistData } from 'constants/function/checkDataValue';
import DialogAlert from '../../components/DialogAlert/DialogAlert'
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert'
import { OPTION_RENDER } from '../../components/OptionRender/OptionRender'


const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});

let schema = {
	// productImage1: {
	// 	presence: { allowEmpty: false, message: '^กรุณาแนบไฟล์ *สินค้า 1' },
	// 	length: {
	// 		maximum: 1,
	// 		message: '^เลือกได้เพียง 1 รูปเท่านั้น'
	// 	}
	// },
	// productDesc1: {
	// 	presence: { allowEmpty: false, message: '^กรุณากรอกคำอธิบาย 1' },
	// 	length: {
	// 		maximum: 255,
	// 	}
	// },
};


class ProductManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterList: [],
			formCheck: {
				isValid: false,
				touched: {},
				values: {},
				errors: {}
			},
			productList: [],
			alertMessage: {
				content: '',
				open: false,
				status: ''
			},
			selectedCustomer: [],

		};
	}

	componentDidMount() {
		this.setState({ filterList: INPUT_RENDER }, () => {
			let filterList = [...this.state.filterList]
			filterList[6].option = OPTION_RENDER('group', false)
			this.setState({ filterList: filterList }, () => {
				this.getMockSuggestProduct()
			})
		})
	}

	getMockSuggestProduct = () => {
		// this.setState({ alertMessage: callDialogAlert('loading', '') })

		ServicesProductManagement.getMockSuggestProduct(
			{
				userLogin: JSON.parse(localStorage.getItem('userData')).userId,
				roleId: JSON.parse(localStorage.getItem('userData')).roleId,
			}
		).then(res => {
			let filterList = this.state.filterList
			switch (res.status) {
				case 200:
					if (isExistData(res.data.result)) {
						let resDataSuggest = res.data.result.suggest
						resDataSuggest.map((val, index) => {
							let resKeySuggest = Object.keys(val)
							resKeySuggest.map((key) => {
								filterList.map((val2) => {
									if (val2.name === key + 'Box' + (index + 1)) {
										if (isExistData(val[key])) {
											if (key === 'productImage') {
												val2.value = []
												val2.value.push({ showImg: val[key], name: val[key].substring((val[key].lastIndexOf('/') + 1)) })
											} else {
												val2.value = val[key]
											}
										}
									}
								})
							})
						})

						filterList[6].value = []
						filterList[6].option.map(val2 => {
							if (isExistData(res.data.result.groupNo)) {
								res.data.result.groupNo.map((val) => {
									if (val2.value === val) {
										filterList[6].value.push(val2)
									}
								})
							}
						})
					}
					// this.setState({ alertMessage: callDialogAlert('', '') })
					this.setState({ filterList: filterList })
					break;
				default:
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })

			}
		})
	}

	updateSuggestProduct = (data) => {
		ServicesProductManagement.updateSuggestProduct(data).then(res => {
			switch (res.status) {
				case 200:
					this.setState({ alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ') })
					break;
				default:
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}
		})
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]
		let formCheck = this.state.formCheck

		filterList.map((val, i) => {
			if (val.name === name) {
				if (value === null) {
					// for uploadContent filter
					value = []
				}
				val.value = value
				formCheck.touched[`${val.name}`] = true
				formCheck.values[`${val.name}`] = value
			}
		})

		if (formCheck.touched[name]) {
			const errors = validate(formCheck.values, schema);
			formCheck = {
				...formCheck,
				isValid: errors ? false : true,
				errors: errors || {}
			}
		}


		this.setState({ filterList: filterList, formCheck: formCheck })
	}

	hasError = field => {
		return this.state.formCheck.touched[field] && this.state.formCheck.errors[field] ? true : false;
	}

	helperTextError = field => {
		return this.state.formCheck.touched[field] && this.state.formCheck.errors[field] ? this.state.formCheck.errors[field][0] : null;
	}

	handleRemoveAll = (fieldUploadName) => {
		this.setState({ [fieldUploadName]: [] })

		let filterList = [...this.state.filterList]
		let formCheck = this.state.formCheck

		filterList.map((val, i) => {
			if (val.name === fieldUploadName) {
				val.value = []
				formCheck.touched[`${val.name}`] = true
				formCheck.values[`${val.name}`] = []
			}
		})

		if (formCheck.touched[fieldUploadName]) {
			const errors = validate(formCheck.values, schema);
			formCheck = {
				...formCheck,
				isValid: errors ? false : true,
				errors: errors || {}
			}
		}


		this.setState({ filterList: filterList, formCheck: formCheck })

	}

	handleDisplayImg = (src) => {
		let modal = document.getElementById("showImg");
		let modalImg = document.getElementById("imgContent");
		modal.style.display = "block";
		modalImg.src = src;
	}

	handleCloseDisplayImg = () => {
		let modal = document.getElementById("showImg");
		modal.style.display = "none";
	}

	handleSubmit = () => {
		const data = new FormData();
		data.append(`userLogin`, JSON.parse(localStorage.getItem('userData')).userId)
		data.append(`roleId`, JSON.parse(localStorage.getItem('userData')).roleId)
		this.state.filterList.map((val, i) => {
			if (val.type === 'multiOption') {
				if (val.value.length > 0) {
					val.value.map(valOpt => {
						data.append(`${val.name}`, valOpt.value)
					})
				} else {
					data.append(`${val.name}`, '')
				}
			} else if (val.type === 'upload') {
				if (isExistData(val.value) && !val.value[0].showImg) {
					data.append(`${val.name}`, val.value[0])
				}
				else if (!isExistData(val.value)) {
					data.append(`${val.name}`, '')
				}
			} else {
				data.append(`${val.name}`, val.value)
			}
		})

		this.updateSuggestProduct(data)
	}

	closeAlert = () => {
		if (this.state.alertMessage.status === 'success') {
			this.getMockSuggestProduct()
		}
		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
		})
	}


	render() {
		const { classes } = this.props;
		const { filterList, alertMessage } = this.state;

		return (
			<React.Fragment>
				<div className={classes.root}>
					<Card>
						<CardHeader
							title={
								"สินค้าแนะนำ"
							}
						/>
						<Divider />
						<CardContent >
							<Grid
								container
								spacing={4}
							>
								<FilterRender
									filterList={filterList}
									handleChangeFilter={this.handleChangeFilter}
									hasError={this.hasError}
									helperTextError={this.helperTextError}
									handleDisplayImg={this.handleDisplayImg}
									handleRemoveAll={this.handleRemoveAll}
								/>
								<Grid
									item
									lg={12}
									sm={12}
									xl={12}
									xs={12}
									style={{ textAlignLast: 'right' }}
								>
									<Button
										color="primary"
										// disabled={!formState.isValid}
										// fullWidth
										type="submit"
										variant="contained"
										onClick={() => this.handleSubmit()}
									>
										บันทึก
							</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<div id="showImg" class="modal" style={{ zIndex: '99' }}>
						<span class="close" onClick={() => this.handleCloseDisplayImg()} >&times;</span>
						<img class="modal-content" id="imgContent" />
					</div>
				</div>
				<DialogAlert data={alertMessage} close={this.closeAlert} />
			</React.Fragment>
		);
	}
}

export default compose(
	withStyles(useStyles),
	inject('campaignStore')
)(observer(withRouter(ProductManagement)))