import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesApprovalSaleList {

	getApprovalSales(requestData) {
		return sendRequest("/incentive/getApprovalSales", HTTP.POST, requestData)
	}

	getApprovalSalesDetail(requestData) {
		return sendRequest("/incentive/getApprovalSalesDetail", HTTP.POST, requestData)
	}

	saveApprovalSales(requestData) {
		return sendRequest("/incentive/saveApprovalSales", HTTP.POST, requestData)
	}

	getApprovalSalesReport(requestData) {
		let resKey = Object.keys(requestData)
		let sendParams = ''
		resKey.map((key, i) => {
			if((!!requestData[key] && requestData[key]!== '') && (key !== 'userLogin' && key !== 'roleId')) {
				if(sendParams === '') {
					sendParams += `?${key}=${requestData[key]}`
				} else {
					sendParams += `&${key}=${requestData[key]}`
				}
			}
		})
		return sendRequest("/report/exportApprovalSales" + sendParams , HTTP.GET)
	}

}

export default new ServicesApprovalSaleList()