/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  Button,
  colors,
  Divider,
  Collapse,
  Badge
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { VERSION } from 'services/sendRequestAPI';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    //paddingLeft: theme.spacing(4),
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const [open, setOpen] = React.useState(-1);

  const handleClick = openClick => {
    if (open === openClick) {
      let open = -1;
      setOpen(open);
    } else {
      let open = openClick;
      setOpen(open);
    }
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <List {...rest} className={classes.root}>
        {pages.map((page, index) => (
          <React.Fragment>
            <ListItem
              className={classes.item}
              disableGutters
              key={page.title}
              onClick={page.subMenu ? () => handleClick(index) : ''}>
              {page.subMenu ? (
                <Button
                  activeClassName={classes.active}
                  className={classes.button}>
                  <div className={classes.icon}>{page.icon}</div>
                  {page.qtyAllData ? (
                    <Badge
                      style={{ paddingRight: '15px' }}
                      badgeContent={page.qtyAllData}
                      color="error">
                      <span>{page.title}</span>
                    </Badge>
                  ) : (
                    <span>{page.title}</span>
                  )}
                </Button>
              ) : (
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  to={page.href}>
                  <div className={classes.icon}>{page.icon}</div>
                  {page.qtyAllData ? (
                    <Badge
                      style={{ paddingRight: '15px' }}
                      badgeContent={page.qtyAllData}
                      color="error">
                      <span>{page.title}</span>
                    </Badge>
                  ) : (
                    <span>{page.title}</span>
                  )}
                </Button>
              )}

              {page.subMenu ? (
                open !== -1 && open === index ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : (
                ''
              )}
            </ListItem>
            {page.subMenu && (
              <Collapse in={open === index} timeout="auto">
                <List component="div" disablePadding>
                  {page.subMenu.map(sub => (
                    <ListItem
                      className={classes.nested}>
                      {/* className={classes:  item}> */}
                      {/* <ListItemIcon>
													{sub.icon}
												</ListItemIcon>
												<ListItemText primary={sub.title} /> */}

                      <Button
                        activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={sub.href}>
                        <div className={classes.icon}>{sub.icon}</div>
                        {sub.qtyData ? (
                          <Badge
                            style={{ paddingRight: '15px' }}
                            badgeContent={sub.qtyData}
                            color="error">
                            <span>{sub.title}</span>
                          </Badge>
                        ) : (
                          <span>{sub.title}</span>
                        )}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
        <div style={{ paddingTop: '10px' }}>
          <Divider className={classes.divider} />
          <List>
            <ListItem className={classes.item} disableGutters>
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={
                  JSON.parse(localStorage.getItem('userData')).roleName === 'PC'
                    ? '/login'
                    : '/admin'
                }>
                <div className={classes.icon}>
                  <ExitToAppIcon />
                </div>
                ออกจากระบบ
              </Button>
            </ListItem>
          </List>
        </div>
        <div style={{ paddingLeft: '10px', fontWeight: '500' }}>
          <span className="nav-text">{ `${VERSION}` }</span>
        </div>
      </List>
    </React.Fragment>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
