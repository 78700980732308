import React, { Component } from 'react';
import 'antd/dist/antd.css';
import { Layout,Row,Col,Input} from 'antd';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  colors,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,

} from '@material-ui/core';

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
      }
    
    //   componentDidMount() {
    //     fetch("http://dplus-system.com:1011/api/v1/option/getBank",{
    //         method: "POST",
    //   headers: {
    //     "Content-type": "application/json; charset=UTF-8"
    //   }
    //     })
    //     .then(response => response.json())
    //     .then(data => {

    //       this.setState({ data });
    //     });
          
    //   }

    //   componentDidMount() {
        // fetch("https://jsonplaceholder.typicode.com/users/")
        //   .then(response => response.json())
        //   .then(data => {

        //     this.setState({ data });
        //   });
    //     fetch("http://dplus-system.com:1011/api/v1/option/getBank", {
    //       method: "POST",
    //       headers: {
    //         "Content-type": "application/json; charset=UTF-8"
    //       }
    //     })
    //       .then(response => response.json())
    //       .then(data => {

    //         this.setState({ data });
            
    //       });
    //   }

    componentDidMount() {
        fetch("https://incentive.dplus-groups.com:2096/api/v1/option/getBank",{
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
        })
          .then(res => res.json())
          .then(
            (results) => {
              this.setState({
                isLoaded: true,
                items: results.result
              });

            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
      }
    
    render() {
        const { error, isLoaded, items } = this.state;
      return (
          <div>
              <ul>
          {items.map(item => (
            <li key={item.bankNo}>
              {item.bankNo} {item.bankName}
            </li>
          ))}
        </ul>
          </div>
        
      );
    
    }
}

export default Register;