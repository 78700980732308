export function isUndefined(date) {
	return !!date ? true : false
}

//return data or ''
export function getExistData(data) {
	if (!!data) {
		if (Array.isArray(data)) {
			if (data.length > 0) {
				return data
			} else {
				return []
			}
		} else if(typeof data === 'object') {
			if(isEmpty(data)) {
				return {}
			} else {
				return data
			}
		}
		else {
			if (data !== '' && data !== null) {
				return data
			} else {
				return ''
			}
		}
	} else {
		return ''
	}

}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function isExistData(data) {
	if (!!data) {
		if (Array.isArray(data)) {
			if (data.length > 0) {
				return true
			} else {
				return false
			}
		} else if(typeof data === 'object') {
			if(isEmpty(data)) {
				return false
			} else {
				return true
			}
		}
		else {
			if (data !== '' && data !== null) {
				return true
			} else {
				return false
			}
		}
	} else {
		return false
	}

}
