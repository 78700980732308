import sendRequest from './sendRequestAPI';
import { HTTP } from '../constants/enum';

class ServicesShop {
  getShop(requestData) {
    return sendRequest('/shop/get_shop', HTTP.POST, requestData);
  }
  addShop(requestData) {
    return sendRequest('/shop/add', HTTP.POST, requestData);
  }
  editShop(requestData) {
    return sendRequest('/shop/edit', HTTP.POST, requestData);
  }
}

export default new ServicesShop();
