import moment from 'moment';
import { OPTION_RENDER } from '../../components/OptionRender/OptionRender';

export const INPUT_RENDER = [
  {
    name: 'shopName',
    displayName: 'สาขา',
    placeholder: [],
    showDisplayName: true,
    type: 'text',
    value: '',
    required: false,
    widthCol: 6,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
  // {
  //   name: 'branchName',
  //   displayName: 'สาขา',
  //   showDisplayName: true,
  //   type: 'text',
  //   value: '',
  //   required: false,
  //   widthCol: 6,
  //   styleXs: {
  //     label: 12,
  //     input: 12
  //   },
  //   styleSm: {
  //     label: 3,
  //     input: 9
  //   }
  // }
];

export const TABLE_HEADER = [
  {
    key: 'rowNo',
    label: 'ลำดับ',
    width: '80px'
  },
  {
    key: 'dimensionDesc',
    label: 'กลุ่มผู้ใช้',
    width: '200px',
    textAlign: 'left'
  },

  {
    key: 'shopName',
    label: 'ชื่อสาขา',
    width: '300px',
    textAlign: 'left'
  },
  {
    key: 'locationName',
    label: 'สถานที่',
    width: '300px',
    textAlign: 'left'
  },
  {
    key: 'action',
    label: '',
    width: '200px'
  }
];
export const INPUT_BRANCH = [
  {
    name: 'shopName',
    displayName: 'ชื่อสาขา',
    label: 'ชื่อสาขา',
    showDisplayName: true,
    type: 'text',
    value: '',
    required: true,
    widthCol: 6,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'locationName',
    displayName: 'สถานที่',
    showDisplayName: true,
    type: 'text',
    value: '',
    required: true,
    widthCol: 6,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'status',
    displayName: 'สถานะ',
    showDisplayName: true,
    type: 'switch',
    value: '',
    required: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  }
];
