import React, { Component } from 'react';
import { LoginManagementTable, LoginManagementFilter } from './components';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { inject, observer } from 'mobx-react';
import { Grid } from '@material-ui/core';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

class LoginManagement extends Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <LoginManagementFilter />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <LoginManagementTable />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('alertStore')
)(observer(LoginManagement));
