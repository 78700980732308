import React, { Component } from 'react';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { isExistData } from 'constants/function/checkDataValue';
import palette from 'theme/palette';
import BarChartIcon from '@material-ui/icons/BarChart';

import { options } from './chart';

const useStyles = () => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    color: '#fff',
    fontSize: '16px'
  },
  avatar: {
    fontSize: '18px',
    color: '#fff'
  }
});

class SalesHistoryBar extends Component {
  render() {
    const { historySales, className, classes, ...rest } = this.props;
    const dataQty =
      Array.isArray(historySales) &&
      historySales.map(val => {
        return val.qty;
      });
    const dataReceived =
      Array.isArray(historySales) &&
      historySales.map(val => {
        return val.received;
      });

    let data = {};
    data.labels = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    data.datasets = [];
    data.datasets.push(
      // {
      //   label: 'จำนวนที่ขาย',
      //   backgroundColor: '#d81b60',
      //   data: dataQty
      // },
      {
        label: 'จำนวนเงิน',

        backgroundColor: '#d81b60',
        data: dataReceived
      }
    );

    let dataProd = {};
    dataProd.labels = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    dataProd.datasets = [];
    dataProd.datasets.push({
      label: 'จำนวนที่ขาย',
      backgroundColor: '#43A047',
      data: dataQty
    });
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Card {...rest} className={clsx(classes.root, className)}>
              <CardHeader
                classes={{
                  title: classes.title,
                  avatar: classes.avatar
                }}
                title="กราฟแสดงจำนวนเงินที่ขายได้"
                style={{ backgroundColor: '#d81b60' }}
                avatar={<BarChartIcon />}
              />
              <CardContent>
                <Bar data={data} options={options} height={350} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Card {...rest} className={clsx(classes.root, className)}>
              <CardHeader
                classes={{
                  title: classes.title,
                  avatar: classes.avatar
                }}
                title="กราฟแสดงจำนวนสินค้าที่ขายได้"
                style={{ backgroundColor: '#43A047' }}
                avatar={<BarChartIcon />}
              />
              <CardContent>
                <Bar data={dataProd} options={options} height={350} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(useStyles)(SalesHistoryBar);
