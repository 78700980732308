import { OPTION_RENDER } from '../../components/OptionRender/OptionRender'


export const INPUT_RENDER = [
	{
		name: 'productImageBox1',
		displayName: 'รูปสินค้า 1',
		showDisplayName: true,
		type: 'upload',
		maxFile: 1,
		value: '',
		canUpload: true,
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'productDescBox1',
		displayName: 'คำอธิบายสินค้า 1',
		showDisplayName: true,
		type: 'textarea',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 4,
			input: 8,
		},
	},
	{
		name: 'productImageBox2',
		displayName: 'รูปสินค้า 2',
		showDisplayName: true,
		type: 'upload',
		maxFile: 1,
		value: '',
		canUpload: true,
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'productDescBox2',
		displayName: 'คำอธิบายสินค้า 2',
		showDisplayName: true,
		type: 'textarea',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 4,
			input: 8,
		},
	},
	{
		name: 'productImageBox3',
		displayName: 'รูปสินค้า 3',
		showDisplayName: true,
		type: 'upload',
		maxFile: 1,
		value: '',
		canUpload: true,
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'productDescBox3',
		displayName: 'คำอธิบายสินค้า 3',
		showDisplayName: true,
		type: 'textarea',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 4,
			input: 8,
		},
	},
	{
		name: 'groupNo',
		displayName: 'กลุ่มลูกค้าที่ต้องการแนะนำ',
		showDisplayName: true,
		type: 'multiOption',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 5,
			input: 7,
		},
	},
]