import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import loginStore from './store/loginStore';
import campaignStore from './store/campaignStore';
import salesListstore from './store/salesListstore';
import optionStore from './store/optionStore';
import alertStore from './store/alertStore';
import accountStore from './store/accountStore';
import pcManagementStore from './store/pcManagementStore';
import approvalPCStore from './store/approvalPCStore';
import loginManagementStore from './store/loginManagementStore';
import approvalSaleListStore from './store/approvalSaleListStore';
import pendingApprovalToAccountStore from './store/pendingApprovalToAccountStore';

import { Provider } from 'mobx-react';
// import Store from './store/rootStore'
const browserHistory = createBrowserHistory();
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  render() {
    // <Provider rootStore={Store} loginStore={Store.loginStore}>

    return (
      <Provider
        approvalSaleListStore={approvalSaleListStore}
        pendingApprovalToAccountStore={pendingApprovalToAccountStore}
        loginManagementStore={loginManagementStore}
        loginStore={loginStore}
        accountStore={accountStore}
        salesListstore={salesListstore}
        campaignStore={campaignStore}
        alertStore={alertStore}
        optionStore={optionStore}
        pcManagementStore={pcManagementStore}
        approvalPCStore={approvalPCStore}>
        {/* <ThemeProvider theme={theme}> */}
        <Router history={browserHistory}>
          <Routes />
        </Router>
        {/* </ThemeProvider> */}
      </Provider>
    );
  }
}
