import React, { Component } from 'react';
import {
	Dialog, DialogContent, Typography, DialogActions, Button, Grid
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { inject, observer } from 'mobx-react';
import FilterRender from '../../components/FilterRender/FilterRender'
import ServicesOption from '../../services/servicesOption'
import ServicesPCManagement from '../../services/servicesPCManagement'
import validate from 'validate.js';
import DialogAlert from '../../components/DialogAlert/DialogAlert'
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert'
import { colors } from '@material-ui/core';


const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	button: {
		color: theme.palette.success,
	},
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});


const schema = {
	groupNo: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 64
		}
	},
	shopNo: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 128
		}
	},
	pcQty: {
		presence: { allowEmpty: false, message: '^กรุณากรอกจำนวน' },
		// length: {
		// 	maximum: 128
		// }
	}
};

class PCAccountModal extends Component {

	state = {
		filterList: [
			{
				name: 'groupNo',
				displayName: 'ทีม',
				showDisplayName: true,
				type: 'option',
				value: '',
				option: [],
				required: true,
				styleXs: {
					label: 4,
					input: 8,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
			{
				name: 'shopNo',
				displayName: 'ชื่อร้าน',
				showDisplayName: true,
				type: 'option',
				value: '',
				option: [],
				required: true,
				styleXs: {
					label: 4,
					input: 8,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
			{
				name: 'pcQty',
				displayName: 'จำนวน',
				showDisplayName: true,
				type: 'number',
				value: '',
				option: [],
				required: true,
				styleXs: {
					label: 4,
					input: 8,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
		],
		formCheck: {
			isValid: false,
			touched: {},
			values: {},
			errors: {}
		},
		alertMessage: {
			content: '',
			open: false,
			status: ''
		},
	}

	componentDidUpdate() {
		let filterList = this.state.filterList
		if (filterList[0].option.length === 0) {
			let arr = []
			arr = this.props.optionStore.groupOption.filter((val, i) => {
				if (!(val.value === '10' || val.value === '11')) {
					return val
				}
			})
			filterList[0].option = arr
			this.setState({ filterList: filterList })
		}
	}

	shopByGroupOption = (value) => {
		// this.setState({ alertMessage: callDialogAlert('loading', '') })

		ServicesOption.getShopByGroup(value).then(res => {
			let filterList = [...this.state.filterList]

			switch (res.status) {
				case 200:
					let groupFilter = filterList[1].option

					res.data.result.map((val, i) => {
						groupFilter.push({ label: val.shopName, value: val.shopNo })
					})

					this.setState({ filterList: filterList })
					// this.setState({ alertMessage: callDialogAlert('', '') })

					break;
				default:
					filterList[1].option = []
					this.setState({ filterList: filterList })
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })

			}
		})
	}

	createPC = (object) => {
		this.setState({ alertMessage: callDialogAlert('processing', '') })
		ServicesPCManagement.generatePC(object).then(res => {
			switch (res.status) {
				case 200:
					this.setState({ alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ') })
					break;
				case 401:
					this.setState({ alertMessage: callDialogAlert('warning', res.data.message) })
					break;
				default:
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}
		})
	}

	handleChangeFilter = (fieldName, value) => {
		let filterList = [...this.state.filterList]
		let formCheck = this.state.formCheck
		switch (fieldName) {
			case 'groupNo':
				// selectedOption[1].selected = ''
				filterList[1].option = [];
				this.shopByGroupOption(
					{
						groupNo: value,
						userLogin: JSON.parse(localStorage.getItem('userData')).userId,
						roleId: JSON.parse(localStorage.getItem('userData')).roleId
					})
				break;
		}

		filterList.map((val, i) => {
			if (val.name === fieldName) {
				if (val.name === 'pcQty') {
					if (value === '') {
						formCheck.values[`${val.name}`] = value
						val.value = value
					} else {
						if (value < 1) {
							val.value = 1
							formCheck.values[`${val.name}`] = 1
						} else if (value >= 1) {
							val.value = value
							formCheck.values[`${val.name}`] = value
						}
					}
					formCheck.touched[`${val.name}`] = true
				} else {
					val.value = value
					formCheck.touched[`${val.name}`] = true
					formCheck.values[`${val.name}`] = value
				}
			}
		})

		if (formCheck.touched[fieldName]) {
			const errors = validate(formCheck.values, schema);
			formCheck = {
				...formCheck,
				isValid: errors ? false : true,
				errors: errors || {}
			}
		}

		this.setState({ filterList: filterList, formCheck: formCheck })

	}

	handleSubmit = () => {
		let object = {}
		this.state.filterList.map((val, i) => {
			object[`${val.name}`] = val.value;
		})

		object.userLogin = JSON.parse(localStorage.getItem('userData')).userId;
		object.roleId = JSON.parse(localStorage.getItem('userData')).roleId;

		this.createPC(object)
	}

	hasError = field => {
		return this.state.formCheck.touched[field] && this.state.formCheck.errors[field] ? true : false;
	}

	helperTextError = field => {
		return this.state.formCheck.touched[field] && this.state.formCheck.errors[field] ? this.state.formCheck.errors[field][0] : null;
	}

	handleOpenUpdatePCModal = (idModal) => {
		this.props.pcManagementStore.updatePCModal.idPC = idModal
	}

	handleCloseModal = () => {
		this.props.close()

		let filterList = this.state.filterList

		filterList.map((val, i) => {
			val.value = ''
		})

		this.setState({
			formCheck: {
				isValid: false,
				touched: {},
				values: {},
				errors: {}
			},
			filterList
		})
	}

	closeAlert = () => {
		if (this.state.alertMessage.status === 'success') {
			this.handleCloseModal()
			this.props.searchSubmit()
		}

		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
		})
	}

	render() {
		const { classes } = this.props;

		const { filterList, formCheck, alertMessage } = this.state;
		return (
			<Dialog aria-labelledby="customized-dialog-title" open={this.props.open} fullWidth={true}>
				<DialogTitle id="customized-dialog-title" onClose={() => this.handleCloseModal()}>
					เพิ่มพีซี
        		</DialogTitle>
				<DialogContent dividers style={{ height: '430px' }}>
					<Grid container spacing={4}>
						<Grid item lg={12} sm={12} xl={12} xs={12}>
							<FilterRender
								filterList={filterList}
								handleChangeFilter={this.handleChangeFilter}
								hasError={this.hasError}
								helperTextError={this.helperTextError}
							/>
						</Grid>
					</Grid>
					&nbsp;
				</DialogContent>
				<DialogActions>
					<Button color="primary" variant="contained" onClick={() => this.handleSubmit()} disabled={!formCheck.isValid} >
						สร้างพีซี
          			</Button>
					<Button color="default" variant="contained" onClick={() => this.handleCloseModal()}>
						ยกเลิก
          			</Button>
				</DialogActions>
				<DialogAlert data={alertMessage} close={this.closeAlert} />
			</Dialog>
		);
	}
}


export default inject('optionStore', 'alertStore', 'pcManagementStore')(observer(PCAccountModal))