
export const INPUT_RENDER = [
	{
		name: 'docNo',
		displayName: 'ชุดเอกสาร',
		placeholder: [],
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	}
]


export const TABLE_HEADER = [
	{
		key: 'rowNo',
		label: 'ลำดับ',
	},
	{
		key: 'docNo',
		label: 'ชุดเอกสาร',
	},
	// {
	// 	key: 'epCode',
	// 	label: 'เลข EP',
	// },
	{
		key: 'period',
		label: 'ค่าใช้จ่ายเดือน',
	},
	{
		key: 'totalAmount',
		label: 'ยอดรวม',
	},
	{
		key: 'createDate',
		label: 'วันที่สร้าง',
	},
	{
		key: 'createBy',
		label: 'ผู้สร้าง',
	},
	{
		key: 'action',
		label: '',
	}
]