import { OPTION_RENDER } from '../../components/OptionRender/OptionRender';

export const INPUT_RENDER = [
  {
    name: 'purchaseDate',
    displayName: 'เดือนที่ซื้อ',
    showDisplayName: true,
    type: 'month',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'groupNo',
    displayName: 'ทีมเซลล์',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'shopNo',
    displayName: 'ร้านค้า',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'locationName',
    displayName: 'ห้าง',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'pcNo',
    displayName: 'รหัสพีซี',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'pcName',
    displayName: 'ชื่อ-นามสกุล',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'brandMobile',
    displayName: 'รุ่นมือถือ',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'typeMobile',
    displayName: 'ประเภทสินค้า',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
];

export const TABLE_HEADER = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'purchaseDate',
    label: 'เดือนที่ซื้อ'
  },
  {
    key: 'groupName',
    label: 'ทีมเซลล์'
  },
  {
    key: 'shopName',
    label: 'ร้านค้า'
  },
  {
    key: 'locationName',
    label: 'ห้าง'
  },
  {
    key: 'pcNo',
    label: 'รหัสพีซี'
  },
  {
    key: 'pcName',
    label: 'ชื่อพีซี'
  },
  {
    key: 'qty',
    label: 'จำนวนชิ้น'
  },
  {
    key: 'amount',
    label: 'เงินที่ได้รับแต่ละเดือน'
  },
  {
    key: 'brandMobile',
    label: 'รุ่นมือถือ'
  },
  {
    key: 'typeMobile',
    label: 'ประเภทสินค้า'
  }
];
