import React, { Component } from 'react';
import {
  TextField,
  Grid,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  colors,
  InputAdornment,
  Switch
} from '@material-ui/core';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { getImageAPI } from '../../services/servicesImage';

const useStyles = theme => ({
  Select: {
    marginTop: '16px'
  },
  dropZone: {
    border: `1px dashed #2979ff`, //${theme.palette.divider}
    padding: theme.spacing(1),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.blue[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  }
});

const selectStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: '9999 !important' })
};

class FormRender extends Component {
  state = {};

  handleDateChange = e => {};

  renderInputType = val => {
    const { classes, ...rest } = this.props;

    switch (val.type) {
      case 'text':
        return (
          <>
            {' '}
            <TextField
              //className={classes.textField}
              error={this.props.hasError && this.props.hasError(val.name)}
              fullWidth
              disabled={val.disabled ? true : false}
              size="small"
              // helperText={
              //   this.props.helperTextError &&
              //   this.props.helperTextError(val.name)
              // }
              label={val.displayName}
              name={val.name}
              onChange={e =>
                this.props.handleChangeFilter(val.name, e.target.value)
              }
              type="text"
              value={val.value || ''}
              variant="outlined"
              required={val.required}
            />
            {this.props.helperTextError &&
            this.props.helperTextError(val.name) ? (
              <Typography
                variant="caption"
                style={{ color: 'red', paddingLeft: '15px' }}>
                กรุณาเลือก {val.displayName}
              </Typography>
            ) : (
              ''
            )}
          </>
        );
      case 'switch':
        return (
          <>
            <p>{val.displayName}</p>
            <Switch
              checked={val.value == 1 ? true : false}
              onChange={e =>
                this.props.handleChangeFilter(val.name, e.target.checked)
              }
            />
          </>
        );
      case 'password':
        return (
          <TextField
            //className={classes.textField}
            error={this.props.hasError && this.props.hasError(val.name)}
            fullWidth
            size="small"
            helperText={
              this.props.helperTextError && this.props.helperTextError(val.name)
            }
            label={val.displayName}
            name={val.name}
            onChange={e =>
              this.props.handleChangeFilter(val.name, e.target.value)
            }
            type={val.revealPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {val.revealPassword ? (
                    <VisibilityOffIcon
                      className="pointer"
                      onClick={() => this.props.handleHiddenPassword(val.name)}
                    />
                  ) : (
                    <VisibilityIcon
                      className="pointer"
                      onClick={() => this.props.handleHiddenPassword(val.name)}
                    />
                  )}
                </InputAdornment>
              )
            }}
            value={val.value || ''}
            variant="outlined"
          />
        );
      case 'textarea':
        return val.hidden ? (
          ''
        ) : (
          <TextField
            error={this.props.hasError && this.props.hasError(val.name)}
            fullWidth
            size="small"
            disabled={val.disabled ? true : false}
            helperText={
              this.props.helperTextError && this.props.helperTextError(val.name)
            }
            label={val.displayName}
            name={val.name}
            onChange={e =>
              this.props.handleChangeFilter(val.name, e.target.value)
            }
            type="text"
            multiline
            rows="4"
            // value={formState.values.firstName || ''}
            value={val.value || ''}
            variant="outlined"
            required={val.required}
          />
        );
      case 'number':
        return (
          <TextField
            //className={classes.textField}
            error={this.props.hasError(val.name)}
            fullWidth
            size="small"
            helperText={this.props.helperTextError(val.name)}
            label={val.displayName}
            name={val.name}
            onChange={e =>
              this.props.handleChangeFilter(val.name, e.target.value)
            }
            type="number"
            //value={formState.values.username || ''}
            value={val.value || ''}
            variant="outlined"
            inputProps={{ min: '1', step: '1' }}
          />
        );
      case 'option':
        return (
          <React.Fragment>
            {val.label ? <p>{val.label}</p> : ''}

            <Select
              //defaultValue={options.filter(opt => opt.value === '2563')}
              onChange={e => this.props.handleChangeFilter(val.name, e.value)}
              options={val.option}
              error={this.props.hasError && this.props.hasError(val.name)}
              isDisabled={val.disabled ? true : false}
              value={val.option.filter(option => option.value == val.value)}
              menuPortalTarget={document.querySelector('body')}
              //menuContainerStyle={{ zIndex: 999 }}
              styles={selectStyles}
            />
            {/* {this.props.helperTextError && this.props.helperTextError(val.name) ? <Typography variant="caption" style={{ color: 'red', paddingLeft: '15px' }}>{this.props.helperTextError(val.name)}</Typography> : ''} */}
            {this.props.helperTextError &&
            this.props.helperTextError(val.name) ? (
              <Typography
                variant="caption"
                style={{ color: 'red', paddingLeft: '15px' }}>
                กรุณาเลือก {val.displayName}
              </Typography>
            ) : (
              ''
            )}
          </React.Fragment>
        );
      case 'multiOption':
        return (
          <React.Fragment>
            <Select
              isMulti
              onChange={e => this.props.handleChangeFilter(val.name, e)}
              options={val.option}
              value={val.value}
              withAll={true}
              closeMenuOnSelect={false}
              classNamePrefix="select"
              className="basic-multi-select"
              menuPortalTarget={document.querySelector('body')}
              styles={selectStyles}
            />
          </React.Fragment>
        );
      case 'date':
        return (
          <React.Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                fullWidth
                //className={classes.Select}
                // error={this.hasError('birthdate')}
                // helperText={
                // 	this.hasError('birthdate') ? formState.errors.birthdate[0] : null
                // }
                label={val.displayName}
                value={
                  val.value !== null && val.value !== ''
                    ? new Date(val.value)
                    : new Date()
                }
                onChange={e => this.props.handleChangeFilter(val.name, e)}
                animateYearScrolling
                disabled={val.disabled ? true : false}
                // defaultValue="Normal"
                variant="inline"
                inputVariant="outlined"
                size="small"
                format="dd/MM/yyyy"
                name="birthdate"
                autoOk
                clearable
                //disableFuture
              />
            </MuiPickersUtilsProvider>
          </React.Fragment>
        );
      case 'month':
        return (
          <React.Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                fullWidth
                views={['month', 'year']}
                //className={classes.Select}
                // error={this.hasError('birthdate')}
                // helperText={
                // 	this.hasError('birthdate') ? formState.errors.birthdate[0] : null
                // }
                label={val.displayName}
                value={
                  val.value !== null && val.value !== ''
                    ? new Date(val.value)
                    : ''
                }
                onChange={e => this.props.handleChangeFilter(val.name, e)}
                animateYearScrolling
                disabled={val.disabled ? true : false}
                // defaultValue="Normal"
                variant="inline"
                inputVariant="outlined"
                size="small"
                format="MM/yyyy"
                name="birthdate"
                autoOk
                clearable
                //disableFuture
              />
            </MuiPickersUtilsProvider>
          </React.Fragment>
        );
      case 'upload':
        return (
          <React.Fragment>
            {val.canUpload ? (
              <React.Fragment>
                <Dropzone
                  onDrop={e => this.props.handleChangeFilter(val.name, e)}
                  accept="image/jpeg, image/png, image/png"
                  minSize={1024}
                  maxSize={3072000}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />

                      <Typography
                        className={classes.dropZone}
                        style={{
                          border:
                            this.props.hasError && this.props.hasError(val.name)
                              ? '1px dashed red'
                              : '1px dashed #2979ff'
                        }}
                        variant="h5">
                        <AttachFileIcon fontSize="small" />
                        {val.required ? '*' : ''} แนบไฟล์ {val.displayName}
                      </Typography>
                    </div>
                  )}
                </Dropzone>
                {this.props.helperTextError &&
                this.props.helperTextError(val.name) ? (
                  <Typography
                    variant="caption"
                    style={{ color: 'red', paddingLeft: '15px' }}>
                    {this.props.helperTextError(val.name)}
                  </Typography>
                ) : (
                  ''
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>{val.displayName}</React.Fragment>
            )}

            <List
            //className={classes.list}
            >
              {val.value &&
                val.value.length > 0 &&
                val.value.length <= val.maxFile &&
                val.value.map(file => (
                  <ListItem>
                    <ListItemIcon>
                      {file.showImg ? (
                        <img
                          src={getImageAPI(file.showImg)}
                          onClick={() =>
                            this.props.handleDisplayImg(
                              getImageAPI(file.showImg)
                            )
                          }
                          style={{
                            maxHeight: '70px',
                            maxWidth: '70px',
                            width: '70px',
                            height: '70px',
                            objectFit: 'cover',
                            cursor: 'pointer'
                          }}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(file)}
                          onClick={() =>
                            this.props.handleDisplayImg(
                              window.URL.createObjectURL(file)
                            )
                          }
                          style={{
                            maxHeight: '70px',
                            maxWidth: '70px',
                            width: '70px',
                            height: '70px',
                            objectFit: 'cover',
                            cursor: 'pointer'
                          }}
                        />
                      )}
                    </ListItemIcon>
                    &nbsp;
                    <ListItemText
                      primary={file.name}
                      primaryTypographyProps={{ variant: 'h5' }}
                    />
                    {val.canUpload ? (
                      <DeleteTwoToneIcon
                        className="pointer"
                        onClick={() => this.props.handleRemoveAll(val.name)}
                      />
                    ) : (
                      ''
                    )}
                  </ListItem>
                ))}
            </List>
          </React.Fragment>
        );
    }
  };

  render() {
    const { filterList } = this.props;
    return (
      <Grid container spacing={3}>
        {filterList.map((val, i) => {
          return (
            <Grid
              item
              lg={val.widthCol}
              sm={val.widthCol}
              xl={val.widthCol}
              xs={12}
              key={i}>
              <Grid container spacing={3} key={i}>
                <Grid
                  item
                  lg={val.styleSm.input}
                  sm={val.styleSm.input}
                  xl={val.styleSm.input}
                  xs={val.styleXs.input}>
                  {this.renderInputType(val)}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default withStyles(useStyles)(FormRender);
