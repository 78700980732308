import React, { Component } from 'react';
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { Grid, Button, Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import FilterRender from '../../components/FilterRender/FilterRender'
import TableRender from '../../components/TableRender/TableRender'
import { withRouter } from 'react-router-dom';
import { utcTimeToFullDate } from '../../constants/function/formatConverter'
import ServicesReport from '../../services/servicesReport'
import { INPUT_RENDER, TABLE_HEADER } from './SalesListStructure'
import DialogAlert from '../../components/DialogAlert/DialogAlert'
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert'
import { colors } from '@material-ui/core';

const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});

class SalesList extends Component {
	state = {
		filterList: [],
		TABLE_CONTENT: [],
		alertMessage: {
			content: '',
			open: false,
			status: ''
		},
	}

	componentDidMount() {
		this.setState({ filterList: INPUT_RENDER })
		this.searchSubmit()
	}

	getReportSales = (data) => {
		// this.setState({ alertMessage: callDialogAlert('loading', '') })

		ServicesReport.getReportSales(data).then(res => {
			switch (res.status) {
				case 200:
					let TABLE_CONTENT = []
					res.data.result.map((value, index) => {
						TABLE_CONTENT.push(
							{
								rowNo: value.rowNo,
								qrCode: value.qrCode,
								productName: value.productName,
								purchaseDate: value.purchaseDate,
								saleStatusName: <span style={{ color: this.renderColorBg(value.saleStatusId) }}>{value.saleStatusName}</span>,

							}
						)
					})
					this.setState({ TABLE_CONTENT })
					// this.setState({ alertMessage: callDialogAlert('', '') })

					break;
				default:
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}
		})
	}

	renderColorBg = (status) => {
		switch (status) {
			case '1':
				return colors.green[400]
			case '2':
				return colors.orange[400]
			case '3':
				return colors.red[400]
		}
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]

		filterList.map((val, i) => {
			if (val.name === name) {
				val.value = value
			}
		})
		this.setState({ filterList: filterList })
	}

	searchSubmit = () => {
		let object = {}

		this.state.filterList.map((val, i) => {
			if (val.name === 'purchaseDate') {
				if (val.value !== '') {
					object[`${val.name}`] = utcTimeToFullDate(val.value)
				} else {
					object[`${val.name}`] = utcTimeToFullDate(new Date())
				}
			} else {
				object[`${val.name}`] = val.value
			}
		})

		object.userLogin = JSON.parse(localStorage.getItem('userData')).pcNo
		object.roleId = JSON.parse(localStorage.getItem('userData')).roleId
		
		this.getReportSales(object)
	}

	handleOpenAddSales = () => {
		this.props.history.push('/addSalesList')
	}

	closeAlert = () => {
		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
		})
	}

	render() {
		const { classes } = this.props;
		const { filterList, TABLE_CONTENT, alertMessage } = this.state;

		return (
			<React.Fragment>
				<div className={classes.root}>
				<DialogAlert data={alertMessage} close={this.closeAlert} />
					<Card>
						<CardHeader
							// action={
							// 	<Button>ddddd</Button>
							// }
							title={
								"รายการขาย"
							}
						/>
						<Divider />
						<CardContent >
							<Grid
								container
								spacing={4}
							>
								<FilterRender
									filterList={filterList}
									handleChangeFilter={this.handleChangeFilter}
								//hasError={this.hasError}
								//helperTextError={this.helperTextError}
								/>
								<Grid
									item
									lg={12}
									sm={12}
									xl={12}
									xs={12}
									style={{ textAlignLast: 'right' }}
								>
									<Button
										color="primary"
										// disabled={!formState.isValid}
										// fullWidth
										type="submit"
										variant="contained"
										onClick={() => this.searchSubmit()}
									>
										ค้นหา
							</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					&nbsp;
					<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<Card>
								<CardContent >
									<TableRender TABLE_HEADER={TABLE_HEADER} TABLE_CONTENT={TABLE_CONTENT} />
								</CardContent>
								<Divider />
							</Card>
						</Grid>
					</Grid>
				</div>
			</React.Fragment>
		);
	}
}

export default compose(
	withStyles(useStyles),
	inject('loginStore')
)(observer(withRouter(SalesList)))