import React, { Component } from 'react';
import moment from 'moment';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import validate from 'validate.js';
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import FilterRender from '../../components/FilterRender/FilterRender';
import TableRender from '../../components/TableRender/TableRender';
import { CSVLink } from 'react-csv';
import ServicesShop from '../../services/servicesShop';
import { INPUT_RENDER, TABLE_HEADER, INPUT_BRANCH } from './BranchStructure';
import DialogAlert from '../../components/DialogAlert/DialogAlert';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';
import { colors } from '@material-ui/core';
import { dataMock } from './MockData';
import BranchModal from './Modal/BranchModal';
import { OPTION_RENDER } from '../../components/OptionRender/OptionRender';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

const schema = {
  groupNo: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  shopName: {
    presence: { allowEmpty: false, message: 'is required' }
  },

  locationName: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

class Branch extends Component {
  state = {
    filterList: INPUT_RENDER,
    dataSource: [],
    dataExport: [],
    openEdit: false,
    dataRecord: [],
    title: '',
    shopNo: '',
    ref: '',
    userId: JSON.parse(localStorage.getItem('userData')).userId,
    alertMessage: {
      content: '',
      open: false,
      status: ''
    },
    formCheck: {
      isValid: false,
      touched: {},
      values: {
        groupNo: '',
        shopName: '',
        locationName: ''
      },
      errors: {}
    }
  };

  componentDidMount() {
    this.getShop();
  }

  handleOpenModal = (record, ref) => {
    const { formCheck } = this.state;
    this.setState({ ref });
    if (ref === 'edit') {
      INPUT_BRANCH.forEach((val, i) => {
        switch (val.name) {
          case 'shopName':
            val.value = record.shopName;
            break;
          case 'locationName':
            val.value = record.locationName;
            break;
          case 'status':
            val.value = record.status;
            break;
          default:
            break;
        }
        formCheck.isValid = true;
        formCheck.values[`${val.name}`] = val.value;
        formCheck.touched[`${val.name}`] = true;
      });

      this.setState({
        openEdit: true,
        dataRecord: INPUT_BRANCH,
        title: 'แก้ไขสาขา',
        shopNo: record.shopNo
      });
    } else if (ref === 'add') {
      this.setState({
        openEdit: true,
        dataRecord: [
          {
            name: 'groupNo',
            displayName: 'กลุ่มผู้ใช้',
            label: 'กลุ่มผู้ใช้',
            showDisplayName: true,
            type: 'option',
            value: '',
            option: OPTION_RENDER('group', false),
            required: false,
            widthCol: 12,
            styleXs: {
              label: 12,
              input: 12
            },
            styleSm: {
              label: 12,
              input: 12
            }
          },
          ...INPUT_BRANCH
        ],
        title: 'เพิ่มสาขา'
      });
    }
  };

  handleChangeFilter = (name, value) => {
    const { filterList } = this.state;
    filterList.map((val, i) => {
      if (val.name === name) {
        val.value = value;
      }
    });
    this.setState({ filterList });
  };

  onChange = (name, value) => {
    const { dataRecord } = this.state;
    let formCheck = this.state.formCheck;

    dataRecord.map((val, i) => {
      if (val.name === name) {
        val.value = value;
        formCheck.touched[`${val.name}`] = true;
        formCheck.values[`${val.name}`] = value;
      }
    });

    if (formCheck.touched[name]) {
      const errors = validate(formCheck.values, schema);
      formCheck = {
        ...formCheck,
        isValid: errors ? false : true,
        errors: errors || {}
      };
    }
    this.setState({ dataRecord, formCheck });
  };

  getShop = () => {
    const { filterList } = this.state;
    const obj = {};

    filterList.forEach(val => {
      obj[val.name] = val.value;
    });

    ServicesShop.getShop(obj).then(res => {
      switch (res.status) {
        case 200:

          const data = res.data.result.map((val, i) => {
            return {
              ...val,
              action: (
                <React.Fragment>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: '#faad14', color: '#fff' }}
                    onClick={() => this.handleOpenModal(val, 'edit')}>
                    แก้ไข
                  </Button>
                </React.Fragment>
              )
            };
          });

          this.setState({
            dataSource: data,
            dataExport: res.data.result
          });

          break;
        case 204:
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  onCheckData = () => {
    const { dataRecord } = this.state;
    dataRecord.forEach(val => {
      if (val.value === '') {
        this.setState({
          alertMessage: callDialogAlert('error', 'กรุณากรอกข้อมูลให้ครบถ้วน'),
          openEdit: false
        });
      } else {
        this.onSubmit();
      }
    });
  };

  onSubmit = () => {
    const { dataRecord, shopNo, userId, ref } = this.state;
    let formCheck = this.state.formCheck;

    let obj = {};
    dataRecord.forEach(val => {
      if (val.name === 'status') {
        val.value = val.value ? '1' : '0';
      }
      obj[val.name] = String(val.value);
    });

    if (ref === 'edit') {
      ServicesShop.editShop({ ...obj, shopNo, userId }).then(res => {
        switch (res.status) {
          case 200:
            this.setState(
              {
                openEdit: false,
                alertMessage: callDialogAlert('success', res.data.message)
              },
              () => this.getShop()
            );
            break;
          case 204:
            break;
          default:
            this.setState({
              alertMessage: callDialogAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              )
            });
        }
      });
    } else if (ref === 'add' && formCheck.isValid) {
      ServicesShop.addShop({ ...obj, userId }).then(res => {
        switch (res.status) {
          case 200:
            this.setState(
              {
                openEdit: false,
                alertMessage: callDialogAlert('success', res.data.message)
              },
              () => this.getShop()
            );
            break;
          case 204:
            break;
          default:
            this.setState({
              alertMessage: callDialogAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              )
            });
        }
      });
    } else {
      dataRecord.map((val, i) => {
        if (val.value === '' || val.value.length === 0) {
          formCheck.touched[`${val.name}`] = true;
        }

        if (formCheck.touched[val.name]) {
          const errors = validate(formCheck.values, schema);
          formCheck = {
            ...formCheck,
            isValid: errors ? false : true,
            errors: errors || {}
          };
        }
      });

      this.setState({ formCheck });
    }
  };

  hasError = field => {
    return this.state.formCheck.touched[field] &&
      this.state.formCheck.errors[field]
      ? true
      : false;
  };

  helperTextError = field => {
    return this.state.formCheck.touched[field] &&
      this.state.formCheck.errors[field]
      ? this.state.formCheck.errors[field][0]
      : null;
  };

  render() {
    const { classes } = this.props;
    const {
      filterList,
      dataSource,
      openEdit,
      dataRecord,
      title,
      alertMessage,
      dataExport
    } = this.state;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Card>
            <CardHeader title={'จัดการสาขา'} />
            <Divider />
            <CardContent>
              <Grid container spacing={4}>
                <FilterRender
                  filterList={filterList}
                  handleChangeFilter={this.handleChangeFilter}
                />
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  style={{ textAlignLast: 'right' }}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={() => this.getShop()}>
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          &nbsp;
          <Grid container spacing={4}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Card>
                <CardHeader
                  title={
                    <Button
                      color="secondary"
                      type="submit"
                      variant="contained"
                      onClick={() => this.handleOpenModal([], 'add')}>
                      เพิ่ม
                    </Button>
                  }
                  action={
                    dataSource.length > 0 && (
                      <CSVLink
                        data={dataExport}
                        headers={TABLE_HEADER}
                        filename={'incentiveCampaignReport.csv'}>
                        <Button
                          style={{
                            backgroundColor: colors.green[600],
                            color: 'white'
                          }}
                          variant="contained">
                          ส่งออกไฟล์ EXCEL
                        </Button>
                      </CSVLink>
                    )
                  }
                />
                <Divider />
                <CardContent>
                  <TableRender
                    TABLE_HEADER={TABLE_HEADER}
                    TABLE_CONTENT={dataSource}
                  />
                </CardContent>
                <Divider />
              </Card>
            </Grid>
          </Grid>
          <DialogAlert
            data={alertMessage}
            close={() => {
              INPUT_BRANCH.forEach((val, i) => {
                val.value = '';
              });
              this.setState({
                alertMessage: {
                  content: '',
                  open: false,
                  status: ''
                }
              });
            }}
          />
          <BranchModal
            open={openEdit}
            onClose={() => {
              INPUT_BRANCH.forEach((val, i) => {
                val.value = '';
              });
              this.setState({ openEdit: false, dataRecord: INPUT_BRANCH });
            }}
            record={dataRecord}
            title={title}
            onChange={this.onChange}
            onSubmit={() => this.onSubmit}
            hasError={this.hasError}
            helperTextError={this.helperTextError}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Branch);
