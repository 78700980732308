export const SCHEMA = {
  approvalStatus: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกสถานะการอนุมัติ' },
    length: {
      maximum: 32
    }
  },
  approvalReason: {
    presence: { allowEmpty: false, message: '^กรุณากรอกเหตุผล' },
    length: {
      maximum: 255
    }
  }
};

export const SCHEMA2 = {
  approvalStatus: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกสถานะการอนุมัติ' },
    length: {
      maximum: 32
    }
  }
};

export const TABLE_HEADER = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'pcNo',
    label: 'รหัส',
    textAlign: 'left'
  },
  {
    key: 'fullName',
    label: 'ชื่อ-นามสกุล',
    textAlign: 'left'
  },
  {
    key: 'groupName',
    label: 'ทีม',
    textAlign: 'left'
  },
  {
    key: 'shopName',
    label: 'ชื่อร้าน',
    textAlign: 'left'
  },
  {
    key: 'tel',
    label: 'เบอร์'
  },
  {
    key: 'approvalStatus',
    label: 'สถานะ'
  },
  {
    key: 'createDate',
    label: 'วันที่สมัคร'
  },
  {
    key: 'action',
    label: ''
  }
];

export const INPUT_RENDER = [
  {
    name: 'approvalStatus',
    displayName: 'สถานะการอนุมัติ',
    showDisplayName: true,
    type: 'option',
    value: '',
    option: [],
    required: true,
    disabled: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'approvalReason',
    displayName: 'เหตุผล',
    showDisplayName: true,
    type: 'textarea',
    value: '',
    option: [],
    required: true,
    hidden: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
];

export const INPUT_RENDER_CONTENT = [
  // {
  //   name: 'pcNo',
  //   displayName: 'รหัส PC',
  //   type: 'text',
  //   value: '',
  //   option: [],
  //   errors: 'กรุณากรอกรหัส PC',
  //   required: true,
  //   // disabled: true,
  //   widthCol: 12,
  //   styleXs: {
  //     label: 12,
  //     input: 12
  //   },
  //   styleSm: {
  //     label: 12,
  //     input: 12
  //   }
  // },
  {
    name: 'firstName',
    displayName: 'ชื่อ',
    type: 'text',
    value: '',
    option: [],
    errors: 'กรุณากรอกชื่อ',
    required: true,
    // disabled: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'lastName',
    displayName: 'นามสกุล',
    errors: 'กรุณากรอกนามสกุล',
    type: 'text',
    value: '',
    option: [],
    required: true,
    // disabled: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'personalId',
    displayName: 'เลขบัตรประชาชน',
    errors: 'กรุณากรอกเลขบัตรประชาชน',
    type: 'text',
    value: '',
    option: [],
    required: true,
    // disabled: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'address',
    displayName: 'ที่อยู่ (ตรอก/ซอย)',
    type: 'textarea',
    value: '',
    option: [],
    required: false,
    // disabled: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'zipcode',
    displayName: 'รหัสไปรษณีย์',
    type: 'text',
    value: '',
    option: [],
    required: false,
    // disabled: true,
    widthCol: 6,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'provinceId',
    displayName: 'จังหวัด',
    type: 'option',
    value: '',
    //disabled: true,
    option: [],
    required: false,
    widthCol: 6,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'districtId',
    displayName: 'อำเภอ/เขต',
    type: 'option',
    value: '',
    option: [],
    // disabled: true,
    required: false,
    widthCol: 6,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'subDistrictId',
    displayName: 'ตำบล/แขวง',
    type: 'option',
    value: '',
    option: [],
    required: false,
    // disabled: true,
    widthCol: 6,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },

  {
    name: 'pcNo',
    displayName: 'รหัสพนักงาน',
    type: 'text',
    value: '',
    // disabled: true,
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'birthdate',
    displayName: 'วัน เดือน ปี เกิด',
    type: 'date',
    value: '',
    option: [],
    required: false,
    // disabled: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'tel',
    displayName: 'เบอร์โทร',
    type: 'text',
    value: '',
    option: [],
    required: false,
    // disabled: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'email',
    displayName: 'อีเมล',
    type: 'text',
    value: '',
    option: [],
    required: false,
    // disabled: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'groupNo',
    displayName: 'ทีม',
    type: 'option',
    // disabled: true,
    value: '',
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'shopNo',
    displayName: 'ชื่อร้าน',
    type: 'option',
    // disabled: true,
    value: '',
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'locationName',
    displayName: 'ห้าง',
    type: 'option',
    // disabled: true,
    value: '',
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'bankAccount',
    displayName: 'เลขที่บัญชี',
    type: 'text',
    // disabled: true,
    value: '',
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'bankGroupNo',
    displayName: 'ธนาคาร',
    type: 'option',
    // disabled: true,
    value: '',
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'bankBranchName',
    displayName: 'สาขา',
    type: 'text',
    // disabled: true,
    value: [],
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'bookBankImage',
    displayName: 'หน้าบัญชี',
    errors: 'กรุณาแนบหน้าบัญชี',
    type: 'upload',
    maxFile: 1,
    value: [],
    option: [],
    canUpload: true,
    required: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'idcardImage',
    displayName: 'สำเนาบัตรประชาชน',
    errors: 'กรุณาแนบสำเนาบัตรประชาชน',
    type: 'upload',
    maxFile: 1,
    value: [],
    canUpload: true,
    option: [],
    required: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'profileImage',
    displayName: 'บัตรพนักงาน',
    errors: '',
    type: 'upload',
    maxFile: 1,
    value: [],
    canUpload: true,
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'pcId',
    displayName: 'รหัส',
    hidden: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    disabled: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  }
];
