import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { inject, observer } from 'mobx-react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  Grid,
  Button,
  Typography,
  colors,
  Divider,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import ServicesOption from '../../services/servicesOption';
import ServicesApprovePC from '../../services/servicesApprovePC';

import validate from 'validate.js';
import FilterRender from '../../components/FilterRender/FilterRender';
import DialogAlert from '../../components/DialogAlert/DialogAlert';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';
import FormRender from '../../components/FormRender/FormRender';
import { isExistData } from 'constants/function/checkDataValue';
import ServicesSignup from '../../services/servicesSignup';
import {
  INPUT_RENDER_CONTENT,
  INPUT_RENDER,
  SCHEMA,
  SCHEMA2
} from './PCManagementStructure';
import { OPTION_RENDER } from 'components/OptionRender/OptionRender';
import { isGetAccessor } from 'typescript';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  button: {
    color: theme.palette.success
  }
});

let filterList2New = [];
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class PCUpdateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterList: [],
      filterList2: [],
      dataOption: [],
      approveOriginal: '',
      formState: {
        isValid: false,
        values: {},
        touched: {},
        errors: {},
        items: []
      },
      alertMessage: {
        content: '',
        open: false,
        status: ''
      },
      idModal: ''
    };
  }

  componentDidMount() {
    this.getProvinces();
    this.groupOption();
    filterList2New = INPUT_RENDER_CONTENT;
    this.setState(
      { filterList: INPUT_RENDER, filterList2: INPUT_RENDER_CONTENT },
      () => {
        let filterList2 = this.state.filterList2;
        let filterList = this.state.filterList;

        filterList[0].option = OPTION_RENDER('approvalStatus', false);
        filterList2[16].option = OPTION_RENDER('bank', false);
        this.setState({
          filterList2: filterList2,
          filterList: filterList
        });
      }
    );
  }

  componentDidUpdate() {
    this.getDataInfo();
  }

  getDataInfo = () => {
    const { filterList2 } = this.state;

    if (
      isExistData(this.props.pcManagementStore.updatePCModal.idPC) &&
      isExistData(this.props.statusRow) &&
      !isExistData(this.state.idModal)
    ) {
      this.setState(
        {
          idModal: this.props.pcManagementStore.updatePCModal.idPC
          // filterList2: INPUT_RENDER_CONTENT
        },

        () => {
          ServicesSignup.getPCInfo({
            pcNo: this.props.pcManagementStore.updatePCModal.idPC,
            userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
            roleId: JSON.parse(localStorage.getItem('userData')).roleId
          }).then(res => {
            switch (res.status) {
              case 200:
                let resData = res.data.result[0];

                let resKey = Object.keys(resData);

                let { filterList2, filterList, formState } = this.state;

                filterList2.map((val, i) => {
                  val.value = null;
                  return val;
                });

                resKey.map((key, i) => {
                  filterList2.map((val, i2) => {
                    if (key === val.name && isExistData(resData[key])) {

                      switch (key) {
                        case 'groupNo':
                          this.handleSelectGroup(resData[key]);
                          val.value = resData[key];
                          break;
                        case 'shopNo':
                          val.value = resData[key];
                          this.setLocationName(resData[key]);
                          break;
                        case 'bookBankImage':
                        case 'idcardImage':
                          // case 'profileImage':
                          let data = [];
                          data.push({
                            showImg: resData[key],
                            name: resData[key].substring(
                              resData[key].lastIndexOf('/') + 1
                            )
                          });
                          val.value = data;
                          break;
                        case 'profileImage':
                          let data2 = [];
                          data2.push({
                            showImg: resData[key],
                            name: resData[key].substring(
                              resData[key].lastIndexOf('/') + 1
                            )
                          });
                          val.value = data2;
                          break;
                        case 'zipcode':
                          this.getAreaByZipcode({
                            zipcode: resData[key],
                            userLogin: JSON.parse(
                              localStorage.getItem('userData')
                            ).pcNo,
                            roleId: JSON.parse(localStorage.getItem('userData'))
                              .roleId
                          });
                          val.value = resData[key];
                          break;
                        case 'subDistrictId':
                          val.value = resData[key].toString();
                          break;
                        default:
                          val.value = resData[key];
                      }
                    } else if (
                      key === 'approvalStatus' &&
                      isExistData(resData[key])
                    ) {
                      filterList[0].value = resData[key].toString();
                      formState = {
                        ...formState,
                        isValid: true,
                        values: {
                          ...formState.values,
                          [`${key}`]: resData[key]
                        },
                        touched: { ...formState.touched, [`${key}`]: true }
                      };
                    }
                  });
                });

                if (this.props.statusRow !== '4') {
                  filterList[0].disabled = false;
                } else {
                  filterList[0].disabled = true;
                }

                this.setState({
                  filterList2,
                  filterList,
                  formState,
                  approveOriginal: filterList[0].value
                });
                break;
              default:

                this.setState({
                  alertMessage: callDialogAlert(
                    'error',
                    'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
                  )
                });
            }
          });
        }
      );

      // this.setState({ alertMessage: callDialogAlert('loading', '') })
    }
  };

  setLocationName = data => {
    let filterList2 = this.state.filterList2;
    filterList2[14].value = data;
    this.setState({ filterList2 });
  };

  groupOption = () => {
    ServicesOption.getAllGroup({
      userLogin: JSON.parse(localStorage.getItem('userData')).userId,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId,
      workSection: JSON.parse(localStorage.getItem('userData')).workSection
    }).then(res => {
      let filterList2 = [...this.state.filterList2];
      let groupOption = [];

      switch (res.status) {
        case 200:
          res.data.result.map((val, i) => {
            groupOption.push({ label: val.groupName, value: val.groupNo });
          });

          filterList2[12].option = groupOption;
          this.setState({ filterList2 });
          break;
        default:
          filterList2[12].option = [];
          this.setState({ filterList2 });
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  getShopByGroup = data => {

    let filterList2 = this.state.filterList2;
    ServicesOption.getShopByGroup(data).then(res => {
      switch (res.status) {
        case 200:
          let resShop = res.data.result;
          let optionShop = [];
          let optionStore = [];
          resShop.map((val, i) => {
            optionShop.push({ value: val.shopNo, label: val.shopName });
            optionStore.push({ value: val.shopNo, label: val.locationName });
          });
          filterList2[13].option = optionShop;
          filterList2[14].option = optionStore;
          this.setState({ filterList2: filterList2 });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  getAreaByZipcode(data) {
    let filterList2 = this.state.filterList2;
    // const { optionProvince } = this.state;
    ServicesOption.getAreaByZipcode(data).then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;

          let optionSubDistricts = [];
          let optionProvince = [];
          let optionDistricts = [];

          if (resGroup.length > 0) {
            resGroup.map((val, i) => {
              optionSubDistricts.push({
                value: val.subDistrictId,
                label: val.subDistrictTH
              });
              optionProvince.push({
                value: val.provinceId,
                label: val.provinceNameTH
              });
              optionDistricts.push({
                value: val.districtId,
                label: val.districtNameTH
              });
            });

            filterList2.forEach(val => {
              switch (val.name) {
                case 'provinceId':
                  val.value = resGroup[0].provinceId;
                  break;
                default:
              }
            });

            function removeDuplicates(data, key) {
              return [...new Map(data.map(item => [key(item), item])).values()];
            }

            filterList2[7].option = optionSubDistricts;
            filterList2[6].option = removeDuplicates(
              optionDistricts,
              item => item.value
            );
            filterList2[5].option = removeDuplicates(
              optionProvince,
              item => item.value
            );
          }
          this.setState({
            filterList2,
            dataOption: resGroup
          });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  }

  getProvinces() {
    ServicesOption.getProvinces({
      userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    }).then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;
          let optionProvince = [];
          resGroup.map((val, i) => {
            optionProvince.push({
              value: val.provinceId,
              label: val.provinceNameTH
            });
          });
          this.setState({ optionProvince: optionProvince });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  }

  handleSelectGroup = value => {
    this.getShopByGroup({
      groupNo: value,
      userLogin: JSON.parse(localStorage.getItem('userData')).userId,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    });
  };

  handleChangeFilter = (name, value) => {
    let filterList = [...this.state.filterList];

    switch (name) {
      case 'approvalStatus':
        if (value === '4' || value === '2' || value === '5') {
          filterList[1].hidden = false;
        } else {
          filterList[1].hidden = true;
        }
        filterList[1].value = '';
        break;
    }

    filterList.map((val, i) => {
      if (val.name === name) {
        if (value === null) {
          // for uploadContent filter
          value = [];
        }
        val.value = value;
      }
    });

    let formState = this.state.formState;
    formState = {
      ...formState,
      values: {
        ...formState.values,
        [name]: value
      },
      touched: {
        ...formState.touched,
        [name]: true
      }
    };

    /*check validate empty onChange*/
    if (formState.touched[name]) {
      const errors = validate(
        formState.values,
        filterList[0].value === '4' || filterList[0].value === '2'
          ? SCHEMA
          : SCHEMA2
      );
      formState = {
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      };
    }
    this.setState({ formState, filterList });
  };

  handleChangeProfile = (name, value) => {
    let filterList2 = [...this.state.filterList2];
    let optionSubDistrictsNew = [];
    const { dataOption } = this.state;

    filterList2.map((val, i) => {
      if (val.name === name) {
        if (value === null) {
          value = [];
        }
        switch (name) {
          case 'groupNo':
            this.getShopByGroup({
              groupNo: value,
              userLogin: JSON.parse(localStorage.getItem('userData')).userId,
              roleId: JSON.parse(localStorage.getItem('userData')).roleId
            });
            val.value = value;
            break;
          case 'shopNo':
            this.setLocationName(val.name);
            val.value = value;
            break;

          case 'districtId':
            const data = dataOption.filter(obj => obj.districtId == value);

            data.map((val, i) => {
              optionSubDistrictsNew.push({
                value: val.subDistrictId,
                label: val.subDistrictTH
              });
            });
            filterList2[7].option = optionSubDistrictsNew;
            val.value = value;
            break;
          case 'zipcode':
            this.getAreaByZipcode({
              zipcode: value,
              userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
              roleId: JSON.parse(localStorage.getItem('userData')).roleId
            });
            val.value = value;
            break;
          default:
            val.value = value;
        }
      }
    });

    this.setState({ filterList2 });
  };

  hasError = field => {
    return this.state.formState.touched[field] &&
      this.state.formState.errors[field]
      ? true
      : false;
  };

  helperTextError = field => {
    return this.state.formState.touched[field] &&
      this.state.formState.errors[field]
      ? this.state.formState.errors[field][0]
      : null;
  };

  handleOpenUpdatePCModal = idModal => {
    this.props.pcManagementStore.updatePCModal.idPC = idModal;
  };

  handleCheckData = () => {
    let objStatusApprove = {};
    let objUpdateProfile = {};
    let obj = {};
    const data = new FormData();

    const { filterList, filterList2, approveOriginal } = this.state;
    const approveNew = filterList[0].value;

    filterList.forEach(val => {
      objStatusApprove[val.name] = val.value;
    });
    ///// handleApprovement////
    //  objStatusApprove['approvalStatus'] = approveNew;
    objStatusApprove.pcNo = this.props.pcManagementStore.updatePCModal.idPC;

    objStatusApprove.userLogin = JSON.parse(
      localStorage.getItem('userData')
    ).userId;

    objStatusApprove.roleId = JSON.parse(
      localStorage.getItem('userData')
    ).roleId;

    ///// handleApprovement////

    ///// handleUpdateProfile////
    filterList2.forEach((val, i) => {
      obj[val.name] = val.value;
      // if (
      //   val.name === 'firstName' ||
      //   val.name === 'lastName' ||
      //   val.name === 'personalId'
      // ) {
      //   if (!val.value) {
      //     this.setState({
      //       alertMessage: callDialogAlert('warning', val.errors)
      //     });
      //   }
      // }
      if (
        val.name == 'bookBankImage' ||
        val.name == 'idcardImage' ||
        val.name == 'profileImage'
      ) {
        if (!!val.value) {
          data.append(val.name, val.value[0]);
        }
      } else {
        data.append(val.name, val.value);
      }
    });

    data.append('userLogin', this.props.pcManagementStore.updatePCModal.idPC);

    if (!obj.firstName) {
      this.setState({
        alertMessage: callDialogAlert('warning', 'กรุณากรอกชื่อ')
      });
    } else if (!obj.lastName) {
      this.setState({
        alertMessage: callDialogAlert('warning', 'กรุณานามสกุล')
      });
    } else if (!obj.personalId) {
      this.setState({
        alertMessage: callDialogAlert('warning', 'กรุณาเลขประจำตัวประชาชน')
      });
    } else if (obj.idcardImage.length === 0) {
      this.setState({
        alertMessage: callDialogAlert('warning', 'กรุณาแนบบัตรประชนชน')
      });
    } else if (obj.bookBankImage.length === 0) {
      this.setState({
        alertMessage: callDialogAlert('warning', 'กรุณาแนบหน้าบัญชี')
      });
    } else {
      if (approveNew == approveOriginal) {
        this.handleUpdatePC(data);
      } else {
        this.handleUpdatePC(data);
        this.handleApprovement(objStatusApprove);
      }

    }
  };

  handleUpdatePC = data => {

    this.setState({ alertMessage: callDialogAlert('loading', '') })

    ServicesSignup.onUpdate(data).then(res => {
      switch (res.status) {
        case 200:
          this.setState({
            alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ')
          });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  handleApprovement = object => {

    ServicesApprovePC.approvalPC(object).then(res => {
      switch (res.status) {
        case 200:
          this.setState({
            alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ')
          });
          this.props.pcManagementStore.triggerApproved = true;
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  handleClose = () => {
    this.props.pcManagementStore.updatePCModal.idPC = '';
    let filterList = this.state.filterList;

    filterList.map((val, i) => {
      val.value = '';
      if (val.name === 'approvalReason') {
        val.hidden = true;
      }
    });

    this.setState({
      filterList: filterList,
      formState: {
        isValid: false,
        values: {},
        touched: {},
        errors: {},
        items: []
      },
      idModal: ''
    });
  };

  closeAlert = () => {
    if (this.state.alertMessage.status === 'success') {
      this.handleClose();
      this.props.searchSubmit();
    }
    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      }
    });
  };

  handleRemoveAll = fieldUploadName => {
    const { filterList2 } = this.state;
    filterList2.map(val => {
      if (val.name == fieldUploadName) {
        val.value = [];
      }
    });
    this.setState({ filterList2 });
  };

  handleDisplayImg = src => {
    let modal = document.getElementById('showImg');
    let modalImg = document.getElementById('imgContent');
    modal.style.display = 'block';
    modalImg.src = src;
  };

  handleCloseDisplayImg = () => {
    let modal = document.getElementById('showImg');
    modal.style.display = 'none';
  };

  render() {
    const { filterList, alertMessage, filterList2 } = this.state;

    return (
      <Dialog
        open={this.props.open}
        //TransitionComponent={Transition}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => this.handleClose()}>
          แก้ไขข้อมูล
        </DialogTitle>
        <React.Fragment>
          <DialogContent dividers>
            <FormRender
              filterList={filterList2}
              handleRemoveAll={this.handleRemoveAll}
              handleDisplayImg={this.handleDisplayImg}
              handleChangeFilter={this.handleChangeProfile}
            />
            <Divider />
            &nbsp;
            <Grid container spacing={4}>
              {/* <Grid item lg={12} sm={12} xl={12} xs={12}> */}
              <FilterRender
                filterList={filterList}
                handleChangeFilter={this.handleChangeFilter}
                hasError={this.hasError}
                helperTextError={this.helperTextError}
              />
            </Grid>
            <div style={{ paddingBottom: '40px' }}></div>
          </DialogContent>
          {isExistData(this.props.statusRow) && this.props.statusRow !== '4' && (
            <DialogActions>
              <React.Fragment>
                <Button
                  variant="contained"
                  style={{ backgroundColor: colors.green[600], color: 'white' }}
                  onClick={() => this.handleCheckData()}>
                  บันทึก
                </Button>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => this.handleClose()}>
                  ยกเลิก
                </Button>
              </React.Fragment>
            </DialogActions>
          )}
          <div id="showImg" class="modal" style={{ zIndex: '99' }}>
            <span class="close" onClick={() => this.handleCloseDisplayImg()}>
              &times;
            </span>
            <img class="modal-content" id="imgContent" />
          </div>
        </React.Fragment>
        <DialogAlert data={alertMessage} close={this.closeAlert} />
      </Dialog>
    );
  }
}

export default inject(
  'pcManagementStore',
  'alertStore'
)(observer(PCUpdateModal));
