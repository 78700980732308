import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { withStyles } from '@material-ui/core/styles';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { isExistData } from 'constants/function/checkDataValue';
import palette from 'theme/palette';

import { data2, options } from './chart';

const useStyles = () => ({
	root: {},
	chartContainer: {
		height: 400,
		position: 'relative'
	},
	actions: {
		justifyContent: 'flex-end'
	}
});

class LatestSales extends Component {
	state = {
		chartData: {

		},
		options2: ''
	}

	componentDidUpdate() {
		if (!isExistData(this.state.chartData) && isExistData(this.props.chart)) {
			let data = {}
			data.labels = this.props.chart.labels
			data.datasets = []
			data.datasets.push({
				label: this.props.chart.datasets[0].label,
				backgroundColor: palette.primary.main,
				// data: [18, 5, 19, 27, 29, 19, 20]
				data: this.props.chart.datasets[0].data
			})
			this.setState({ chartData: data })
			if (!!options.scales.yAxes[0]) {
				let maxNumChart = Math.max(...this.props.chart.datasets[0].data)
				let options2 = Object.assign({}, options)
				options2.scales.yAxes[0].ticks.max = maxNumChart + 5
				this.setState({ options2 })
			}
		}
	}

	render() {
		const { className, classes, ...rest } = this.props;
		return (
			<Card
				{...rest}
				className={clsx(classes.root, className)}
			>
				<CardHeader
					// action={
					//   <Button
					//     size="small"
					//     variant="text"
					//   >
					//     Last 7 days <ArrowDropDownIcon />
					//   </Button>
					// }
					title="Latest Sales"
				/>
				<Divider />
				<CardContent>
					<div className={classes.chartContainer}>
						<Bar
							data={isExistData(this.props.chart) ? this.state.chartData : data2}
							options={this.state.options2}
						/>
					</div>
				</CardContent>
				<Divider />
				{/* <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          Overview <ArrowRightIcon />
        </Button>
      </CardActions> */}
			</Card>
		)
	}
}

export default withStyles(useStyles)(LatestSales)
