import React, { Component } from 'react';
import {
	Dialog, DialogContent, Typography, DialogTitle, DialogActions, Button, Grid, DialogContentText, MobileStepper, Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ServicesDashboard from '../../../../services/servicesDashboard'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTheme } from '@material-ui/core/styles';
import { getImageAPI } from '../../../../services/servicesImage'

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	button: {
		color: theme.palette.success,
	},
	dialog: {
		//backgroundImage: "url(https://i.imgur.com/HeGEEbu.jpg)",
		backgroundRepeat: 'round',
		width: '100%',
		height: '100%',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		height: 50,
		// paddingLeft: theme.spacing(4),
		backgroundColor: theme.palette.background.default,
		placeContent: 'center'
	},
	img: {
		// height: 255,
		// maxWidth: 400,
		overflow: 'hidden',
		display: 'block',
		width: '100%',
	},
});

class AdvertisementModal extends Component {
	state = {
		adsList: [],
		activeStep: 0,
	}

	componentDidMount() {
		this.getAds()
	}

	getAds = () => {
		ServicesDashboard.getAds(
			{
				pcNo: JSON.parse(localStorage.getItem('userData')).pcNo,
				userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
				roleId: JSON.parse(localStorage.getItem('userData')).roleId,
			}
		).then(res => {
			if (res.data.success === true) {
				this.setState({ adsList: res.data.result })
			}
		})
	}

	handleNext = (e) => {
		this.setState({ activeStep: this.state.activeStep + 1 })
	};

	handleBack = (e) => {
		this.setState({ activeStep: this.state.activeStep - 1 })
	};

	render() {
		const { classes } = this.props;
		const { adsList, activeStep } = this.state;
		const theme = useTheme;

		return (
			adsList.length > 0 &&
				<Dialog
					open={this.props.open}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					classes={{ paper: classes.dialog }}
				>
					<DialogContent>
						<Paper square elevation={0} className={classes.header}>
							<Typography variant="h3" >
								{adsList[activeStep].adsName}
							</Typography>
						</Paper>
					&nbsp;
					<Paper square elevation={0} style={{ textAlign: 'center' }}>
							<Typography variant="h4">
								{adsList[activeStep].adsDesc}
							</Typography>
						</Paper>
					&nbsp;
					<div>
							<img
								className={classes.img}
								src={getImageAPI(adsList[activeStep].adsImage)}
								alt={adsList[activeStep].adsName}
							/>
						</div>
					</DialogContent>
					<DialogActions>
						<div style={{ width: '100%' }}>
							<MobileStepper
								style={{ background: 'unset' }}
								steps={adsList.length}
								position="static"
								variant="text"
								activeStep={activeStep}
								nextButton={
									<Button size="small" onClick={this.handleNext} disabled={activeStep === adsList.length - 1}>
										Next
            						{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
									</Button>
								}
								backButton={
									<Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
										{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            						Back
          						</Button>
								}
							/>
						</div>
						<Button color="primary" variant="contained" onClick={() => this.props.close()} >
							ปิด
          			</Button>
					</DialogActions>
				</Dialog>
		);
	}
}

export default withStyles(styles)(AdvertisementModal);