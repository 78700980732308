import React, { Component } from 'react';
import moment from 'moment';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import FilterRender from '../../components/FilterRender/FilterRender';
import TableRender from '../../components/TableRender/TableRender';
import { CSVLink } from 'react-csv';
import ServicesReport from '../../services/servicesReport';
import {
  INPUT_RENDER,
  TABLE_HEADER
} from './IncentiveByCampaignReportStructure';
import DialogAlert from '../../components/DialogAlert/DialogAlert';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';
import { colors } from '@material-ui/core';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

class IncentiveByCampaignReport extends Component {
  state = {
    filterList: INPUT_RENDER,
    dataSource: []
  };

  componentDidMount() {
    this.getIncentiveByCampaign();
  }

  handleChangeFilter = (name, value) => {
    let filterList = [...this.state.filterList];

    filterList.map((val, i) => {
      if (val.name === name) {
        val.value = value;
      }
    });
    this.setState({ filterList });
  };

  getIncentiveByCampaign = () => {
    const { filterList } = this.state;
    const obj = {};

    filterList.forEach(val => {
      if (val.name === 'startDate' || val.name === 'endDate') {
        obj[val.name] = moment(val.value).format('YYYY-MM-DD');
      } else {
        obj[val.name] = val.value;
      }
    });



    ServicesReport.getIncentiveByCampaign(obj).then(res => {

      switch (res.status) {
        case 200:
          this.setState({ dataSource: res.data.result });
          break;
        case 204:
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  // searchSubmit = () => {
  //   const { filterList } = this.state;

  //   getIncentiveByCampaign;


  // };
  render() {
    const { classes } = this.props;
    const { filterList, dataSource } = this.state;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Card>
            <CardHeader title={'รายงานสรุปค่าใช้จ่ายตามแคมเปญ'} />
            <Divider />
            <CardContent>
              <Grid container spacing={4}>
                <FilterRender
                  filterList={filterList}
                  handleChangeFilter={this.handleChangeFilter}
                />
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  style={{ textAlignLast: 'right' }}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={() => this.getIncentiveByCampaign()}>
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          &nbsp;
          <Grid container spacing={4}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Card>
                <CardHeader
                  action={
                    dataSource.length > 0 && (
                      <CSVLink
                        data={dataSource}
                        headers={TABLE_HEADER}
                        filename={'incentiveCampaignReport.csv'}>
                        <Button
                          style={{
                            backgroundColor: colors.green[600],
                            color: 'white'
                          }}
                          variant="contained">
                          ส่งออกไฟล์ EXCEL
                        </Button>
                      </CSVLink>
                    )
                  }
                />
                <Divider />
                <CardContent>
                  <TableRender
                    TABLE_HEADER={TABLE_HEADER}
                    TABLE_CONTENT={dataSource}
                  />
                </CardContent>
                <Divider />
              </Card>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(IncentiveByCampaignReport);
