import React, { Component } from 'react';
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { Grid, Button, Card, CardHeader, CardContent, Divider, colors } from '@material-ui/core';
import FilterRender from '../../components/FilterRender/FilterRender'
import TableRender from '../../components/TableRender/TableRender'
import { withRouter } from 'react-router-dom';
import { INPUT_RENDER, TABLE_HEADER } from './IncentiveReportStructure'
import ServicesIncentiveReport from '../../services/servicesIncentiveReport'
import { isExistData } from 'constants/function/checkDataValue';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/Email';
// import IncentiveReportPreview from './incentiveReportPreview'
import { getImageAPI } from '../../services/servicesImage'
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert'
import DialogAlert from '../../components/DialogAlert/DialogAlert'
import { ButtonRender } from '../../components/ButtonRender/ButtonRender'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { GET } from 'services/sendRequestAPI';

const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});

class IncentiveReport extends Component {
	state = {
		filterList: [],
		TABLE_CONTENT: [],
		header: {},
		detail: {},
		docNo: null,
		onClickBtn: false,
		openSendSMSModal: false,
		alertMessage: {
			content: '',
			open: false,
			status: ''
		},
	}

	componentDidMount() {
		// this.searchSubmit()
		this.setState({ filterList: INPUT_RENDER })
		this.searchSubmit()
	}

	getReportSummaryIncentive = (data) => {
		this.setState({ alertMessage: callDialogAlert('loading', '') })
		let TABLE_CONTENT = []
		ServicesIncentiveReport.getReportSummaryIncentive(data).then(res => {
			switch (res.status) {
				case 200:
					this.setState({ alertMessage: callDialogAlert('', '') })
					res.data.result.map((value) => {
						TABLE_CONTENT.push(
							{
								rowNo: value.rowNo,
								docNo: value.docNo,
								epCode: value.epCode,
								period: value.period,
								totalAmount: value.totalAmount,
								createDate: value.createDate,
								createBy: value.createBy,
								action: <div style={{ float: 'center' }}>
										<ButtonRender
											type="pdf"
											text="ส่งบัญชี"
											icon={<PictureAsPdfIcon />}
											onClick={() => this.previewReport(value.docNo)}
										/>
									&nbsp;
									<ButtonRender
											type="excel"
											text="ส่งการเงิน"
											icon={<DescriptionIcon />}
											onClick={(e) => this.exportReportFinance(e, { userLogin: JSON.parse(localStorage.getItem('userData')).userId, docNo: value.docNo, roleId: JSON.parse(localStorage.getItem('userData')).roleId, })}
										/>
										&nbsp;
									<ButtonRender
											type="sms"
											text={`ส่ง SMS (${value.sms || 0})`}
											icon={<EmailIcon />}
											onClick={() => this.handleSMSModal(value)}
										/>
									</div>
									
								,
							}
						)
					})

					this.setState({ TABLE_CONTENT })
					break;
				case 204:
					break;
				default:
					this.setState({ TABLE_CONTENT: [] })
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}
		})

	

	}

	exportReportFinance = (e, data) => {
		ServicesIncentiveReport.exportReportFinance(data).then(res => {
			switch (res.status) {
				case 200:
					if (isExistData(res.data.result.export)) {
						let url = getImageAPI(res.data.result.export.pathFile)
						window.open(url).focus();
						// window.open(`dplus-system.com:1011/api/v1${res.data.result.export.pathFile}`);
					}
					break;
				case 204:
					break;
				default:
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })

			}
		})
	}

	
	handleSMSModal = (data) => {
		this.setState({ 
			openSendSMSModal: true, 
			dataRow: data, 
			docNo: data.docNo 
		})
	}

	actionSendSMS = async () => {
		try {
				const data = this.state.dataRow
				const { docNo } = data
				
				this.setState({ openSendSMSModal: false, alertMessage: callDialogAlert('loading', '') })
				if(!docNo){
				  this.setState({ alertMessage: callDialogAlert('error', 'ไม่พบเลขเอกสาร') })
				  return
				}

				const params = { docNo }
				const res = await GET('/sale/send-sms-incentive', params)
				if(!res.data.success){
					this.setState({ alertMessage: callDialogAlert('error', 'พบปัญหาการส่ง SMS') })
					return
				}

				this.setState({ alertMessage: callDialogAlert('success', 'ส่งข้อความสำเร็จ') })

		} catch (error) {
			this.setState({ alertMessage: callDialogAlert('error', error) })
	  	}

	}

	previewReport = (transferNo) => {
		this.props.history.push('/incentiveReportPreview' + '?report=' + window.btoa(JSON.stringify(transferNo)))
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]
		filterList.map((val, i) => {
			if (val.name === name) {
				val.value = value
			}
		})
		this.setState({ filterList: filterList })
	}

	searchSubmit = () => {
		let object = {}
		this.state.filterList.map((val, i) => {
			object[`${val.name}`] = val.value
		})
		object.userLogin = JSON.parse(localStorage.getItem('userData')).userId
		object.roleId = JSON.parse(localStorage.getItem('userData')).roleId
		this.getReportSummaryIncentive(object)
	}

	closeAlert = () => {
		if (this.state.alertMessage.status === 'success') {
			this.searchSubmit()
		}
		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
			rowNumSelected: [],
			rowNumSelectedData: [],
		})
	}



	render() {

		const { classes } = this.props;
		const { filterList, TABLE_CONTENT, alertMessage, openSendSMSModal, docNo } = this.state;

		return (
			<React.Fragment>

			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={openSendSMSModal}
				>
					<DialogTitle>{ docNo }</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								ยืนยันการส่งข้อความค่าคอม
							</DialogContentText>
						</DialogContent>
					<DialogActions>
					<Button color="secondary" onClick={()=> this.actionSendSMS()} >ส่ง SMS</Button>
					<Button onClick={()=>this.setState({ openSendSMSModal: false })}>ยกเลิก</Button>
					
					</DialogActions>
				</Dialog>


				<div className={classes.root}>
					<Card>
						<CardHeader
							title={
								"รายงานบัญชี/การเงิน"
							}
						/>
						<Divider />
						<CardContent >
							<Grid
								container
								spacing={4}
							>
								<FilterRender
									filterList={filterList}
									handleChangeFilter={this.handleChangeFilter}
								//hasError={this.hasError}
								//helperTextError={this.helperTextError}
								/>
								<Grid
									item
									lg={12}
									sm={12}
									xl={12}
									xs={12}
									style={{ textAlignLast: 'right' }}
								>
									<Button
										color="primary"
										// disabled={!formState.isValid}
										// fullWidth
										type="submit"
										variant="contained"
										onClick={() => this.searchSubmit()}
									>
										ค้นหา
							</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					&nbsp;
					<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<Card>
								<CardContent >
									<TableRender TABLE_HEADER={TABLE_HEADER} TABLE_CONTENT={TABLE_CONTENT} />
								</CardContent>
								<Divider />
							</Card>
						</Grid>
					</Grid>
				</div>
				<DialogAlert data={alertMessage} close={this.closeAlert} />
			</React.Fragment>
		);
	}
}

export default compose(
	withStyles(useStyles),
	inject('loginStore')
)(observer(withRouter(IncentiveReport)))
