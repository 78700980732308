import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { inject, observer } from 'mobx-react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormRender from '../../../components/FormRender/FormRender';
import { INPUT_BRANCH } from '../BranchStructure';
import {
  Button,
  Typography,
  colors,
  DialogContent,
  DialogActions
} from '@material-ui/core';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  button: {
    color: theme.palette.success
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class BranchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterList: [],
      dataOption: [],
      approveOriginal: '',
      formCheck: {
        isValid: false,
        values: {},
        touched: {},
        errors: {}
      },
      alertMessage: {
        content: '',
        open: false,
        status: ''
      },
      idModal: ''
    };
  }

  render() {
    const {
      open,
      onClose,
      record,
      onChange,
      title,
      onSubmit,
      helperTextError,
      hasError
    } = this.props;
    return (
      <Dialog open={open} fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          {title}
        </DialogTitle>
        <React.Fragment>
          <DialogContent dividers>
            <FormRender
              filterList={record}
              hasError={hasError}
              helperTextError={helperTextError}
              //   handleRemoveAll={this.handleRemoveAll}
              //   handleDisplayImg={this.handleDisplayImg}
              handleChangeFilter={onChange}
            />
          </DialogContent>

          <DialogActions>
            <React.Fragment>
              <Button
                variant="contained"
                style={{ backgroundColor: colors.green[600], color: 'white' }}
                onClick={onSubmit()}>
                บันทึก
              </Button>
              <Button color="default" variant="contained" onClick={onClose}>
                ยกเลิก
              </Button>
            </React.Fragment>
          </DialogActions>
        </React.Fragment>
        {/* <DialogAlert data={alertMessage} close={this.closeAlert} /> */}
      </Dialog>
    );
  }
}

export default observer(BranchModal);
