import React, { Component } from 'react';
import { PendingApprovalToAccountFilter, PendingApprovalToAccountTable } from './components'
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});

class PendingApprovalToAccount extends Component {
	state = {}
	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<div className={classes.root}>
					<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<PendingApprovalToAccountFilter />
						</Grid>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<PendingApprovalToAccountTable />
						</Grid>
					</Grid>
				</div>
			</React.Fragment>
		);
	}
}

export default
	withStyles(useStyles)(PendingApprovalToAccount)