import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesContactUs {

	contactUs(requestData) {
		return sendRequest("/pc/contactUs", HTTP.POST, requestData)
	}

}

export default new ServicesContactUs()