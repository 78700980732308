import React, { Component } from 'react';
import ServicesOption from '../../services/servicesOption';
import ServicesSignup from '../../services/servicesSignup';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import compose from 'recompose/compose';
import validate from 'validate.js';
import { withStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import 'date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select';
import {
  Grid,
  Button,
  TextField,
  Link,
  colors,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Typography,
  IconButton
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { inject, observer } from 'mobx-react';
import AlertRender from '../../components/alertRender/AlertRender';
import DialogAlert from '../../components/DialogAlert/DialogAlert';
import {
  decryptURL,
  encryptURL
} from '../../constants/function/encryptURLConverter';
import ServicesLoginManagement from '../../services/servicesLoginManagement';
import ServiceLogin from '../../services/servicesLogin';
import {
  isExistData,
  getExistData
} from '../../constants/function/checkDataValue';
import { getImageAPI } from '../../services/servicesImage';

let schema = {
  firstName: {
    presence: { allowEmpty: false, message: '^กรุณากรอกชื่อ' },
    length: {
      maximum: 255
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: '^กรุณากรอกนามสกุล' },
    length: {
      maximum: 255
    }
  },
  groupNo: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกทีม' },
    length: {
      maximum: 255
    }
  },
  shopNo: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกร้านค้า' },
    length: {
      maximum: 255
    }
  },
  address: {
    presence: {
      allowEmpty: false,
      message: '^กรุณากรอกที่อยู่ ที่อยู่ตามบัตรประชาชน (ตรอก ซอย)'
    },
    length: {
      maximum: 500
    }
  },
  zipcode: {
    presence: { allowEmpty: false, message: '^กรุณากรอกรหัสไปรษณีย์' },
    length: {
      maximum: 10
    },
    format: {
      pattern: '[0-9]+',
      flags: 'i',
      message: '^กรอกตัวเลขเท่านั้น'
    }
  },
  subDistrictId: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกตำบอล/แขวง' }
  },
  pcNo: {
    presence: { allowEmpty: false, message: '^กรุณากรอกรหัสพนักงาน' },
    length: {
      maximum: 500
    }
  },
  bankAccount: {
    presence: { allowEmpty: false, message: '^กรุณากรอกเลขที่บัญชี' },
    length: {
      maximum: 32
    },
    format: {
      pattern: '[0-9]+',
      flags: 'i',
      message: '^กรอกตัวเลขเท่านั้น'
    }
  },
  tel: {
    presence: { allowEmpty: false, message: '^กรุณากรอกเบอร์โทรศัพท์' },
    length: {
      maximum: 10,
      message: '^ห้ามกรอกเบอร์โทรศัพท์เกิน 10 ตัวเลข'
    },
    format: {
      pattern: '[0-9]+',
      flags: 'i',
      message: '^กรอกตัวเลขเท่านั้น'
    }
  },
  bankGroupNo: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกธนาคาร' },
    length: {
      maximum: 200
    }
  },
  bankBranchName: {
    presence: { allowEmpty: false, message: '^กรุณากรอกสาขาธนาคาร' },
    length: {
      maximum: 200
    }
  },
  bookBankImage: {
    presence: { allowEmpty: false, message: '^กรุณาแนบไฟล์ *หน้าบัญชี' },
    length: {
      maximum: 1,
      message: '^เลือกได้เพียง 1 รูปเท่านั้น'
    }
  },
  idcardImage: {
    presence: { allowEmpty: false, message: '^กรุณาแนบไฟล์ *สำเนาบัตรประชาชน' },
    length: {
      maximum: 1,
      message: '^เลือกได้เพียง 1 รูปเท่านั้น'
    }
  },
  profileImage: {
    length: {
      maximum: 1,
      message: '^เลือกได้เพียง 1 รูปเท่านั้น'
    }
  },
  email: {
  	email: {
  		email: true,
  		message: '^กรุณากรอกรูปแบบอีเมลให้ถูกต้อง'
  	},
  }
};

const useStyles = theme => ({
  Select: {
    marginTop: '16px'
  },
  dropZone: {
    border: `1px dashed #2979ff`, //${theme.palette.divider}
    padding: theme.spacing(1),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.blue[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  list: {
    maxHeight: 320
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingBottom: 50,
    flexBasis: 700,
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
});

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {
        isValid: false,
        values: {
          firstName: '',
          lastName: '',
          personalId: '',
          email: '',
          tel: '',
          pcNo: '',
          zipcode: '',
          subDistrictId: '',
          districtId: '',
          provinceId: '',
          birthdate: '',
          address: '',
          groupNo: '',
          shopNo: '',
          storeId: '',
          bankAccount: '',
          bankBranchName: '',
          bankGroupNo: '',
          bookBankImage: [],
          idcardImage: [],
          profileImage: []
        },
        touched: {},
        errors: {},
        items: []
      },
      optionBank: [],
      optionShop: [],
      optionStore: [],
      optionGroup: [],
      selectedOptionGroup: null,
      optionProvince: [],
      optionDistricts: [],
      optionSubDistricts: [],
      selectedDate: null,
      bookBankImage: [],
      idcardImage: [],
      profileImage: [],
      storeId: null,
      alertMessage: {
        content: '',
        open: false,
        status: ''
      },
      isWithParamPath: false,
      pcNo: '',
      workSection: ''
    };
  }

  getBank() {
    ServicesOption.getBank().then(res => {
      switch (res.status) {
        case 200:
          let resBank = res.data.result;
          let optionBank = [...this.state.optionBank];
          resBank.map((val, i) => {
            optionBank.push({ value: val.bankNo, label: val.bankName });
          });
          this.setState({ optionBank: optionBank });
          break;
        default:
      }
    });
  }

  getGroup() {
    ServicesOption.getAllGroup({ workSection: this.state.workSection }).then(
      res => {
        switch (res.status) {
          case 200:
            let resGroup = res.data.result;
            let optionGroup = [];
            resGroup.map((val, i) => {
              optionGroup.push({ value: val.groupNo, label: val.groupName });
            });
            this.setState({ optionGroup: optionGroup });
            break;
          default:
        }
      }
    );
  }

  getShopByGroup = data => {
    ServicesOption.getShopByGroup(data).then(res => {
      switch (res.status) {
        case 200:
          let resShop = res.data.result;
          let optionShop = [];
          let optionStore = [];
          resShop.map((val, i) => {
            optionShop.push({ value: val.shopNo, label: val.shopName });
            optionStore.push({ value: val.shopNo, label: val.locationName });
          });

          this.setState({ optionShop: optionShop, optionStore: optionStore });
          break;
        default:
      }
    });
  };

  getProvinces() {
    ServicesOption.getProvinces().then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;
          let optionProvince = [];
          resGroup.map((val, i) => {
            optionProvince.push({
              value: val.provinceId,
              label: val.provinceNameTH
            });
          });
          this.setState({ optionProvince: optionProvince });
          break;
        default:
      }
    });
  }

  getDistricts(data) {
    ServicesOption.getDistricts(data).then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;
          let optionDistricts = [];
          resGroup.map((val, i) => {
            optionDistricts.push({
              value: val.districtId,
              label: val.districtNameTH
            });
          });
          this.setState({ optionDistricts: optionDistricts });
          break;
        default:
      }
    });
  }

  getAreaByZipcode(data) {
    ServicesOption.getAreaByZipcode(data).then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;
          let optionSubDistricts = [];
          let formState = this.state.formState;
          if (resGroup.length > 0) {
            resGroup.map((val, i) => {
              optionSubDistricts.push({
                value: val.subDistrictId,
                label: val.subDistrictTH
              });
            });

            formState = {
              ...formState,
              values: {
                ...formState.values,
                provinceId: resGroup[0].provinceId,
                districtId: resGroup[0].districtId
              }
            };
            this.getDistricts({ provinceId: resGroup[0].provinceId });
          }
          this.setState({
            optionSubDistricts: optionSubDistricts,
            formState: formState
          });
          break;
        default:
      }
    });
  }

  getSchema = () => {
    let listSchema = Object.keys(schema);

    const urlParams = new URLSearchParams(window.location.search);
    // let objectParams = decryptURL(urlParams)
    const g = urlParams.get('g');
    if (urlParams && g) {
      ServiceLogin.checkingUser({ uniqid: g }).then(res => {
        switch (res.status) {
          case 200:
            if (!!res.data.result && res.data.result.length > 0) {
              let resData = res.data.result[0];

              this.getShopByGroup({ groupNo: resData.groupNo });
              this.setState(
                {
                  selectedOptionGroup: resData.groupNo.toString(),
                  isWithParamPath: true,
                  workSection: resData.workSection
                },
                () => {
                  this.checkFormIsValidOnChange(
                    'groupNo',
                    this.state.selectedOptionGroup
                  );
                  this.getGroup();
                }
              );
            } else {
              this.setState(
                { selectedOptionGroup: '', isWithParamPath: false },
                () => {
                  this.checkFormIsValidOnChange('groupNo', '');
                }
              );
            }

            break;
          default:
            // this.props.loginManagementStore.TABLE_CONTENT = []
            this.setState(
              { selectedOptionGroup: '', isWithParamPath: false },
              () => {
                this.checkFormIsValidOnChange('groupNo', '');
              }
            );
        }
      });
    } else {
      if (localStorage.getItem('userData')) {
        listSchema.map((val, i) => {
          switch (val) {
            case 'groupNo':
            case 'shopNo':
            case 'pcNo':
              delete schema[`${val}`];
              break;
          }
        });
      } else {
        listSchema.map((val, i) => {
          if (val === 'pcNo') {
            // columns.splice(i, 1)
            delete schema.pcNo;
          }
        });
      }
    }
  };

  componentDidMount() {
    this.getSchema();
    this.getProvinces();
    this.getBank();
    //this.getShop();

    if (localStorage.getItem('userData')) {
      this.setState({
        pcNo: JSON.parse(localStorage.getItem('userData')).pcNo
      });
      if (
        ['2','3','5'].includes(
          JSON.parse(localStorage.getItem('userData')).approvalStatus
        )
      ) {
        ServicesSignup.getPCInfo({
          pcNo: JSON.parse(localStorage.getItem('userData')).pcNo
        }).then(res => {
          switch (res.status) {
            case 200:
              let formState = this.state.formState;
              let resData = res.data.result[0];
              let resKey = Object.keys(resData);

              resKey.map((key, i) => {
                let formStateValues = Object.keys(formState.values);
                formStateValues.map((key2, i2) => {
                  if (key === key2 && isExistData(resData[key])) {
                    switch (key) {
                      case 'groupNo':
                        this.handleSelectGroup(resData[key]);
                        formState = {
                          ...formState,
                          values: {
                            ...formState.values,
                            [`${key}`]: resData[key]
                          }
                        };
                        break;
                      case 'bookBankImage':
                      case 'idcardImage':
                      case 'profileImage':
                        let data = [];
                        data.push({
                          showImg: resData[key],
                          name: resData[key].substring(
                            resData[key].lastIndexOf('/') + 1
                          )
                        });
                        // this.setState({ [`${key}`]: data })
                        formState = {
                          ...formState,
                          values: { ...formState.values, [`${key}`]: data }
                        };

                        break;
                      case 'zipcode':
                        this.getAreaByZipcode({
                          zipcode: resData[key],
                          userLogin: JSON.parse(
                            localStorage.getItem('userData')
                          ).pcNo
                        });
                        formState = {
                          ...formState,
                          values: {
                            ...formState.values,
                            [`${key}`]: resData[key].toString()
                          }
                        };
                        break;
                      default:
                        formState = {
                          ...formState,
                          values: {
                            ...formState.values,
                            [`${key}`]: resData[key]
                          }
                        };
                    }
                  }
                });
              });

              this.setState({ formState });

              break;
            default:
              console.log('ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่');
          }
        });
      }
    }
  }

  checkFormIsValidOnChange = (fieldName, value) => {
    let formState = this.state.formState;
    formState = {
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value
      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    };

    /*check validate empty onChange*/
    if (formState.touched[fieldName]) {
      const errors = validate(formState.values, schema);
      formState = {
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      };
    }
    this.setState({ formState: formState });
  };

  checkFormIsValidOnSubmit = () => {
    let formState = this.state.formState;

    let resKey = Object.keys(formState.values);
    resKey.map((key, i) => {
      if (key !== 'email') {
        if (!isExistData(formState.values[key])) {
          formState.touched[`${key}`] = true;
        }
      }

      if (formState.touched[key]) {
        let errors = validate(formState.values, schema);

        formState = {
          ...formState,
          isValid: errors ? false : true,
          errors: errors || {}
        };
      }
    });

    this.setState({ formState: formState });
    return formState.isValid;
  };

  isAllNumber = value => {
    let text = '';
    if (value !== '') {
      for (let i = 0; i < value.length; i++) {
        let char = value.charAt(i);
        if (char.match('^[0-9]+$')) {
          text += char;
        }
      }
      return text;
    }
  };

  handleChange = (fieldName, value) => {
    console.log({fieldName, value});
    switch (fieldName) {
      case 'zipcode':
        if (value.length > 4) {
          this.getAreaByZipcode({ zipcode: value });
        } else if (value.length < 4) {
          this.setState({ optionSubDistricts: [] });
        }
        this.checkFormIsValidOnChange(fieldName, value);
        break;
      case 'groupNo':
        this.getShopByGroup({ groupNo: value });
        this.checkFormIsValidOnChange(fieldName, value);
        break;
      case 'bookBankImage':
      case 'idcardImage':
      case 'profileImage':
        this.checkFormIsValidOnChange(fieldName, value);
        break;
      default:
        this.checkFormIsValidOnChange(fieldName, value);
    }
  };

  handleSelectGroup = value => {
    this.setState({ optionShop: [] });
    this.getShopByGroup({ groupNo: value });
  };

  hasError = field => {
    return this.state.formState.touched[field] &&
      this.state.formState.errors[field]
      ? true
      : false;
  };

  handleSubmit = event => {
    event.preventDefault();
    const formState = this.state.formState;

    const data = new FormData();
    let isValidData = this.checkFormIsValidOnSubmit();

    if (localStorage.getItem('userData')) {
      //login for register
      if (isValidData) {
        if (this.state.isWithParamPath) {
          this.openAlert('processing', '');

          let listFormState = Object.keys(formState.values);
          console.log({formState});
          listFormState.map((val, i) => {
            if (Array.isArray(formState.values[val])) {
              if (
                formState.values[val].length > 0 &&
                !formState.values[val][0].showImg
              ) {
                data.append([`${val}`], formState.values[val][0]);
              } else {
                data.append([`${val}`], '');
              }
            } else {
              data.append([`${val}`], formState.values[val]);
            }
          });
          // data.append('pcNo', JSON.parse(localStorage.getItem('userData')).pcNo)
        } else {
          this.openAlert('processing', '');

          let listFormState = Object.keys(formState.values);
         

          listFormState.map((val, i) => {
            if (val !== 'groupNo' || val !== 'shopNo') {
              if (Array.isArray(formState.values[val])) {
                if (
                  formState.values[val].length > 0 &&
                  !formState.values[val][0].showImg
                ) {
                  data.append([`${val}`], formState.values[val][0]);
                } else {
                  data.append([`${val}`], '');
                }
              } else {
                data.append([`${val}`], formState.values[val]);
              }
            }
          });
        }

        // todo update
        ServicesSignup.updatePCRegister(data).then(res => {
          switch (res.status) {
            case 200:
              this.openAlert('success', res.data.message);
              break;
            default:
              this.openAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              );
          }
        });
      } else {
        this.openAlert('warning', 'กรุณากรอกข้อมูลให้ครบ');
      }
    } else {
      //click register on login page
      if (isValidData) {
        if (this.state.isWithParamPath) {
          // with query params
          this.openAlert('processing', '');

          let listFormState = Object.keys(formState.values);

          listFormState.map((val, i) => {
            if (Array.isArray(formState.values[val])) {
              data.append([`${val}`], formState.values[val][0]);
            } else {
              data.append([`${val}`], formState.values[val]);
            }
          });
        } else {
          this.openAlert('processing', '');

          let listFormState = Object.keys(formState.values);

          listFormState.map((val, i) => {
            if (val !== 'pcNo') {
              if (Array.isArray(formState.values[val])) {
                data.append([`${val}`], formState.values[val][0]);
              } else {
                data.append([`${val}`], formState.values[val]);
              }
            }
          });
        }

        ServicesSignup.registerPC(data).then(res => {
          switch (res.status) {
            case 200:
              this.openAlert(
                'success',
                'ลงทะเบียนสำเร็จ โปรดรอเจ้าหน้าที่ตรวจสอบข้อมูลของท่าน'
              );
              break;
            default:
              this.openAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              );
          }
        });
      } else {
        this.openAlert('warning', 'กรุณากรอกข้อมูลให้ครบ');
      }
    }
  };

  handleRemoveAll = fieldUploadName => {
    const formState = this.state.formState;
    let data = formState.values;
    const payload = {
      pcNo:data['pcNo'],
      typeFile: fieldUploadName,
      pathFile:data[fieldUploadName][0]?.showImg
    }
    console.log({data,fieldUploadName,payload});
      ServicesSignup.deleteFile(payload).then(res => {
          switch (res.status) {
            case 200:
             // this.openAlert('success', res.data.message);
              this.checkFormIsValidOnChange(fieldUploadName, []);
              break;
            default:
              this.openAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              );
          }
        });
    

  };

  handleDisplayImg = src => {
    let modal = document.getElementById('showImg');
    let modalImg = document.getElementById('imgContent');
    modal.style.display = 'block';
    modalImg.src = src;
  };

  handleCloseDisplayImg = () => {
    let modal = document.getElementById('showImg');
    modal.style.display = 'none';
  };

  handleBack = () => {
    this.props.loginStore.pcGroupLogin = {
      name: '',
      value: 0
    };

    const urlParams = new URLSearchParams(window.location.search);
    // let objectParams = decryptURL(urlParams)
    const g = urlParams.get('g');
    if (urlParams && g) {
      this.props.history.push('/login?g=' + urlParams.get('g'));
    } else {
      this.setState({ pcGroup: '' });
      this.props.loginStore.pcGroupLogin = '';
      this.props.history.push('/login');
    }
  };

  openAlert = (status, message) => {
    this.setState({
      alertMessage: {
        content: message,
        open: true,
        status: status
      }
    });
  };

  closeAlert = () => {
    if (this.state.alertMessage.status === 'success') {
      this.handleBack();
    }

    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      }
    });
  };

  renderPcNoInput = () => {
    const { classes } = this.props;
    const { formState } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    // let objectParams = decryptURL(urlParams)
    const g = urlParams.get('g');
    if (urlParams && g) {
      if (localStorage.getItem('userData')) {
        return (
          <Grid item lg={12} xs={12}>
            <TextField
              fullWidth
              className={classes.textField}
              label="รหัสพนักงาน"
              value={
                this.state.pcNo !== ''
                  ? JSON.parse(localStorage.getItem('userData')).pcNo
                  : this.state.pcNo
              }
              disabled
              name="pcNo"
              type="text"
              variant="outlined"
              size="small"
            />
          </Grid>
        );
      } else {
        return (
          <Grid item lg={12} xs={12}>
            <TextField
              error={this.hasError('pcNo')}
              helperText={
                this.hasError('pcNo') ? formState.errors.pcNo[0] : null
              }
              fullWidth
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              className={classes.textField}
              label="*รหัสพนักงาน"
              value={formState.values.pcNo || ''}
              name="pcNo"
              type="text"
              variant="outlined"
              size="small"
            />
          </Grid>
        );
      }
    } else {
      return '';
    }
  };

  dateForm = selectedDate => {
    return (
      selectedDate.getFullYear() +
      '/' +
      parseInt(selectedDate.getMonth() + 1) +
      '/' +
      selectedDate.getDate()
    );
  };

  render() {
    const { classes, ...rest } = this.props;
    const {
      formState,
      optionBank,
      optionShop,
      optionStore,
      optionGroup,
      selectedOptionGroup,
      alertMessage,
      isWithParamPath,
      optionSubDistricts,
      optionProvince,
      optionDistricts
    } = this.state;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.content}>
            <Typography gutterBottom variant="h4">
              <IconButton onClick={() => this.handleBack()}>
                <ArrowBackIcon />
              </IconButton>
              กลับหน้าเข้าสู่ระบบ
            </Typography>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={event => this.handleSubmit(event)}
                spacing={1}>
                <Typography
                  className={classes.title}
                  variant="h2"
                  style={{ textAlign: 'center' }}>
                  {localStorage.getItem('userData')
                    ? JSON.parse(localStorage.getItem('userData'))
                        .approvalStatus === '3'
                      ? 'แก้ไขข้อมูล'
                      : 'ลงทะเบียนสมาชิก'
                    : 'ลงทะเบียนสมาชิก'}
                </Typography>
                &nbsp;
                <Grid container spacing={2}>
                  <Grid item lg={6} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      className={classes.textField}
                      error={this.hasError('firstName')}
                      helperText={
                        this.hasError('firstName')
                          ? formState.errors.firstName[0]
                          : null
                      }
                      label="*ชื่อ"
                      name="firstName"
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      type="text"
                      value={formState.values.firstName || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      className={classes.textField}
                      error={this.hasError('lastName')}
                      helperText={
                        this.hasError('lastName')
                          ? formState.errors.lastName[0]
                          : null
                      }
                      label="*นามสกุล"
                      name="lastName"
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      type="text"
                      value={formState.values.lastName || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      fullWidth
                      className={classes.textField}
                      error={this.hasError('personalId')}
                      helperText={
                        this.hasError('personalId')
                          ? formState.errors.personalId[0]
                          : null
                      }
                      label="*เลขบัตรประจำตัวประชาชน"
                      name="personalId"
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      type="text"
                      value={formState.values.personalId || ''}
                      variant="outlined"
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      className={classes.textField}
                      error={this.hasError('address')}
                      helperText={
                        this.hasError('address')
                          ? formState.errors.address[0]
                          : null
                      }
                      fullWidth
                      label="*ที่อยู่ตามบัตรประชาชน (ตรอก ซอย)"
                      name="address"
                      placeholder="ที่อยู่ตามบัตรประชาชน (ตรอก ซอย)"
                      id="address"
                      variant="outlined"
                      size="small"
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      type="text"
                      multiline
                      rows="4"
                      value={formState.values.address || ''}
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <TextField
                      className={classes.textField}
                      error={this.hasError('zipcode')}
                      helperText={
                        this.hasError('zipcode')
                          ? formState.errors.zipcode[0]
                          : null
                      }
                      fullWidth
                      label="*รหัสไปรษณีย์"
                      name="zipcode"
                      id="zipcode"
                      variant="outlined"
                      size="small"
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      type="text"
                      value={formState.values.zipcode || ''}
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <Select
                      className={classes.Select}
                      error={this.hasError('subDistrictId')}
                      helperText={
                        this.hasError('subDistrictId')
                          ? formState.errors.subDistrictId[0]
                          : null
                      }
                      value={
                        formState.values.subDistrictId !== null
                          ? optionSubDistricts.filter(
                              option =>
                                option.value ===
                                formState.values.subDistrictId.toString()
                            )
                          : optionSubDistricts.filter(
                              option =>
                                option.value === formState.values.subDistrictId
                            )
                      }
                      options={optionSubDistricts}
                      onChange={e =>
                        this.handleChange('subDistrictId', e.value)
                      }
                      placeholder="*กรุณาเลือกตำบล/แขวง"
                      name="subDistrictId"
                      id="subDistrictId"
                    />
                    {this.hasError('subDistrictId') ? (
                      <Typography
                        variant="caption"
                        style={{ color: 'red', paddingLeft: '15px' }}>
                        กรุณาเลือกตำบล/เขต
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <Select
                      className={classes.Select}
                      value={optionDistricts.filter(
                        option => option.value === formState.values.districtId
                      )}
                      options={optionDistricts}
                      placeholder="กรุณาเลือกอำเภอ/เขต"
                      name="districtId"
                      id="districtId"
                      isDisabled
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <Select
                      className={classes.Select}
                      value={optionProvince.filter(
                        option => option.value === formState.values.provinceId
                      )}
                      options={optionProvince}
                      placeholder="กรุณาเลือกจังหวัด"
                      name="provinceId"
                      id="provinceId"
                      isDisabled
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      className={classes.textField}
                      error={this.hasError('tel')}
                      helperText={
                        this.hasError('tel') ? formState.errors.tel[0] : null
                      }
                      fullWidth
                      label="*เบอร์โทรศัพท์"
                      name="tel"
                      id="tel"
                      type="tel"
                      variant="outlined"
                      size="small"
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      value={formState.values.tel || ''}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        fullWidth
                        className={classes.Select}
                        error={this.hasError('birthdate')}
                        helperText={
                          this.hasError('birthdate')
                            ? formState.errors.birthdate[0]
                            : null
                        }
                        label="วัน เดือน ปี เกิด"
                        value={
                          isExistData(formState.values.birthdate)
                            ? new Date(formState.values.birthdate)
                            : null
                        }
                        onChange={e =>
                          this.handleChange('birthdate', this.dateForm(e))
                        }
                        animateYearScrolling
                        defaultValue="Normal"
                        variant="inline"
                        inputVariant="outlined"
                        size="small"
                        format="dd/MM/yyyy"
                        name="birthdate"
                        autoOk
                        clearable
                        disableFuture
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {this.renderPcNoInput()}
                  {localStorage.getItem('userData') ? (
                    ''
                  ) : (
                    <React.Fragment>
                      <Grid item lg={12} xs={12}>
                        <Select
                          className={classes.Select}
                          error={this.hasError('groupNo')}
                          helperText={
                            this.hasError('groupNo')
                              ? formState.errors.groupNo[0]
                              : null
                          }
                          value={
                            formState.values.groupNo !== null &&
                            formState.values.groupNo !== ''
                              ? optionGroup.filter(
                                  option =>
                                    option.value === formState.values.groupNo
                                )
                              : optionGroup.filter(
                                  option =>
                                    option.value ===
                                    this.state.selectedOptionGroup
                                )
                          }
                          options={optionGroup}
                          onChange={e => this.handleChange('groupNo', e.value)}
                          placeholder="กรุณาเลือกทีม"
                          isDisabled={isWithParamPath}
                          name="groupNo"
                          id="groupNo"
                        />
                        {this.hasError('groupNo') ? (
                          <Typography
                            variant="caption"
                            style={{ color: 'red', paddingLeft: '15px' }}>
                            กรุณาเลือกทีม
                          </Typography>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <Select
                          className={classes.Select}
                          error={this.hasError('shopNo')}
                          helperText={
                            this.hasError('shopNo')
                              ? formState.errors.shopNo[0]
                              : null
                          }
                          options={optionShop}
                          value={optionShop.filter(
                            option => option.value === formState.values.shopNo
                          )}
                          onChange={e => this.handleChange('shopNo', e.value)}
                          placeholder="*กรุณาเลือกร้านค้า"
                          name="shopNo"
                          id="shopNo"
                        />
                        {this.hasError('shopNo') ? (
                          <Typography
                            variant="caption"
                            style={{ color: 'red', paddingLeft: '15px' }}>
                            กรุณาเลือกร้านค้า
                          </Typography>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid item lg={12} xs={12}>
                        <Select
                          className={classes.Select}
                          error={this.hasError('storeId')}
                          helperText={
                            this.hasError('storeId')
                              ? formState.errors.storeId[0]
                              : null
                          }
                          options={optionStore}
                          value={optionStore.filter(
                            option => option.value === formState.values.shopNo
                          )}
                          // onChange={(e) => this.handleChange('storeId', e.value)}
                          placeholder="ห้าง"
                          name="storeId"
                          id="storeId"
                          isDisabled
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                  <Grid item lg={12} xs={12}>
                    <TextField
                      fullWidth
                      error={this.hasError('email')}
                      helperText={
                        this.hasError('email')
                          ? formState.errors.email[0]
                          : null
                      }
                      className={classes.textField}
                      label="อีเมล์"
                      name="email"
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      type="text"
                      value={formState.values.email || ''}
                      variant="outlined"
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      fullWidth
                      className={classes.textField}
                      error={this.hasError('bankAccount')}
                      helperText={
                        this.hasError('bankAccount')
                          ? formState.errors.bankAccount[0]
                          : null
                      }
                      label="*เลขที่บัญชี"
                      name="bankAccount"
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      type="text"
                      value={formState.values.bankAccount || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Select
                      className={classes.Select}
                      error={this.hasError('bankGroupNo')}
                      helperText={
                        this.hasError('bankGroupNo')
                          ? formState.errors.bankGroupNo[0]
                          : null
                      }
                      options={optionBank}
                      value={optionBank.filter(
                        option => option.value === formState.values.bankGroupNo
                      )}
                      onChange={e => this.handleChange('bankGroupNo', e.value)}
                      placeholder="*กรุณาเลือกธนาคาร"
                      name="bankGroupNo"
                      id="bankGroupNo"
                    />
                    {this.hasError('bankGroupNo') ? (
                      <Typography
                        variant="caption"
                        style={{ color: 'red', paddingLeft: '15px' }}>
                        กรุณาเลือกธนาคาร
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      fullWidth
                      error={this.hasError('bankBranchName')}
                      helperText={
                        this.hasError('bankBranchName')
                          ? formState.errors.bankBranchName[0]
                          : null
                      }
                      className={classes.textField}
                      // error={this.hasError('bankBranchName')}
                      label="*สาขาธนาคาร"
                      name="bankBranchName"
                      onChange={e =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                      type="text"
                      value={formState.values.bankBranchName || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Dropzone
                      onDrop={e => this.handleChange('bookBankImage', e)}
                      accept="image/jpeg, image/png, image/png"
                      minSize={1024}
                      maxSize={3072000}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input
                            {...getInputProps()}
                            className={classes.Select}
                          />
                          <Typography
                            className={classes.dropZone}
                            variant="h5"
                            style={{
                              border: this.hasError('bookBankImage')
                                ? '1px dashed red'
                                : '1px dashed #2979ff'
                            }}>
                            <AttachFileIcon fontSize="small" /> แนบไฟล์
                            *หน้าบัญชี
                          </Typography>
                        </div>
                      )}
                    </Dropzone>
                    {this.hasError('bookBankImage') ? (
                      <Typography
                        variant="caption"
                        style={{ color: 'red', paddingLeft: '15px' }}>
                        {formState.errors.bookBankImage[0]}
                      </Typography>
                    ) : (
                      ''
                    )}
                    <React.Fragment>
                      <List className={classes.list}>
                        {formState.values.bookBankImage.length === 1 &&
                          formState.values.bookBankImage.map(file => (
                            <ListItem>
                              <ListItemIcon>
                                {file.showImg ? (
                                  <img
                                    src={getImageAPI(file.showImg)}
                                    onClick={() =>
                                      this.handleDisplayImg(
                                        getImageAPI(file.showImg)
                                      )
                                    }
                                    style={{
                                      maxHeight: '70px',
                                      maxWidth: '70px',
                                      width: '70px',
                                      height: '70px',
                                      objectFit: 'cover',
                                      cursor: 'pointer'
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={URL.createObjectURL(file)}
                                    onClick={() =>
                                      this.handleDisplayImg(
                                        window.URL.createObjectURL(file)
                                      )
                                    }
                                    style={{
                                      maxHeight: '70px',
                                      maxWidth: '70px',
                                      width: '70px',
                                      height: '70px',
                                      objectFit: 'cover',
                                      cursor: 'pointer'
                                    }}
                                  />
                                )}
                              </ListItemIcon>
                              &nbsp;
                              <div
                                style={{
                                  width: '100%',
                                  flex: '1',
                                  whiteSpace: 'nowrap !important',
                                  overflow: 'hidden !important',
                                  textOverflow: 'ellipsis !important'
                                }}>
                                <ListItemText
                                  primary={file.name}
                                  primaryTypographyProps={{ variant: 'h5' }}
                                />
                              </div>
                              <div style={{ textAlign: 'end' }}>
                                <DeleteTwoToneIcon
                                  className="pointer"
                                  onClick={() =>
                                    this.handleRemoveAll('bookBankImage')
                                  }
                                />
                              </div>
                            </ListItem>
                          ))}
                      </List>
                    </React.Fragment>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Dropzone
                      onDrop={e => this.handleChange('idcardImage', e)}
                      accept="image/jpeg, image/png, image/png"
                      minSize={1024}
                      maxSize={3072000}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <Typography
                            className={classes.dropZone}
                            variant="h5"
                            style={{
                              border: this.hasError('idcardImage')
                                ? '1px dashed red'
                                : '1px dashed #2979ff'
                            }}>
                            <AttachFileIcon fontSize="small" /> แนบไฟล์
                            *สำเนาบัตรประชาชน
                          </Typography>
                        </div>
                      )}
                    </Dropzone>
                    {this.hasError('idcardImage') ? (
                      <Typography
                        variant="caption"
                        style={{ color: 'red', paddingLeft: '15px' }}>
                        {formState.errors.idcardImage[0]}
                      </Typography>
                    ) : (
                      ''
                    )}

                    <React.Fragment>
                      <List className={classes.list}>
                        {formState.values.idcardImage.length === 1 &&
                          formState.values.idcardImage.map(file => (
                            <ListItem>
                              <ListItemIcon>
                                {file.showImg ? (
                                  <img
                                    src={getImageAPI(file.showImg)}
                                    onClick={() =>
                                      this.handleDisplayImg(
                                        getImageAPI(file.showImg)
                                      )
                                    }
                                    style={{
                                      maxHeight: '70px',
                                      maxWidth: '70px',
                                      width: '70px',
                                      height: '70px',
                                      objectFit: 'cover',
                                      cursor: 'pointer'
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={URL.createObjectURL(file)}
                                    onClick={() =>
                                      this.handleDisplayImg(
                                        window.URL.createObjectURL(file)
                                      )
                                    }
                                    style={{
                                      maxHeight: '70px',
                                      maxWidth: '70px',
                                      width: '70px',
                                      height: '70px',
                                      objectFit: 'cover',
                                      cursor: 'pointer'
                                    }}
                                  />
                                )}
                              </ListItemIcon>
                              &nbsp;
                              <div
                                style={{
                                  width: '100%',
                                  flex: '1',
                                  whiteSpace: 'nowrap !important',
                                  overflow: 'hidden !important',
                                  textOverflow: 'ellipsis !important'
                                }}>
                                <ListItemText
                                  primary={file.name}
                                  primaryTypographyProps={{ variant: 'h5' }}
                                />
                              </div>
                              <div style={{ textAlign: 'end' }}>
                                <DeleteTwoToneIcon
                                  className="pointer"
                                  onClick={() =>
                                    this.handleRemoveAll('idcardImage')
                                  }
                                />
                              </div>
                            </ListItem>
                          ))}
                      </List>
                    </React.Fragment>
                  </Grid>
                  { <Grid item lg={12} xs={12}>
                    <Dropzone
                      onDrop={e => this.handleChange('profileImage', e)}
                      accept="image/jpeg, image/png, image/png"
                      minSize={1024}
                      maxSize={3072000}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />

                          <Typography className={classes.dropZone} variant="h5">
                            <AttachFileIcon fontSize="small" /> แนบไฟล์บัตรพนักงาน
                          </Typography>
                        </div>
                      )}
                    </Dropzone>
                    {this.hasError('profileImage') ? (
                      <Typography
                        variant="caption"
                        style={{ color: 'red', paddingLeft: '15px' }}>
                        {formState.errors.profileImage[0]}
                      </Typography>
                    ) : (
                      ''
                    )}

                     <React.Fragment>
                      <List className={classes.list}>
                        {formState.values.profileImage.length === 1 &&
                          formState.values.profileImage.map(file => (
                            <ListItem>
                              <ListItemIcon>
                                {file.showImg ? (
                                  <img
                                    src={getImageAPI(file.showImg)}
                                    onClick={() =>
                                      this.handleDisplayImg(
                                        getImageAPI(file.showImg)
                                      )
                                    }
                                    style={{
                                      maxHeight: '70px',
                                      maxWidth: '70px',
                                      width: '70px',
                                      height: '70px',
                                      objectFit: 'cover',
                                      cursor: 'pointer'
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={URL.createObjectURL(file)}
                                    onClick={() =>
                                      this.handleDisplayImg(
                                        window.URL.createObjectURL(file)
                                      )
                                    }
                                    style={{
                                      maxHeight: '70px',
                                      maxWidth: '70px',
                                      width: '70px',
                                      height: '70px',
                                      objectFit: 'cover',
                                      cursor: 'pointer'
                                    }}
                                  />
                                )}
                              </ListItemIcon>
                              &nbsp;
                              <div
                                style={{
                                  width: '100%',
                                  flex: '1',
                                  whiteSpace: 'nowrap !important',
                                  overflow: 'hidden !important',
                                  textOverflow: 'ellipsis !important'
                                }}>
                                <ListItemText
                                  primary={file.name}
                                  primaryTypographyProps={{ variant: 'h5' }}
                                />
                              </div>
                              <div style={{ textAlign: 'end' }}>
                                <DeleteTwoToneIcon
                                  className="pointer"
                                  onClick={() =>
                                    this.handleRemoveAll('profileImage')
                                  }
                                />
                              </div>
                            </ListItem>
                          ))}
                      </List>
                    </React.Fragment> 
                  </Grid> }
                </Grid>
                <Button
                  className={classes.signUpButton}
                  color="primary"
                  // disabled={localStorage.getItem('userData') ? false : !formState.isValid}
                  fullWidth
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor: colors.green[600],
                    color: 'white'
                  }}>
                  {localStorage.getItem('userData')
                    ? JSON.parse(localStorage.getItem('userData'))
                        .approvalStatus === '3'
                      ? 'บันทึกข้อมูล'
                      : 'ลงทะเบียน'
                    : 'ลงทะเบียน'}
                </Button>
              </form>
            </div>
          </div>
          {/* show img modal */}
          <div id="showImg" class="modal" style={{ zIndex: '99' }}>
            <span class="close" onClick={() => this.handleCloseDisplayImg()}>
              &times;
            </span>
            <img class="modal-content" id="imgContent" />
          </div>
          <DialogAlert data={alertMessage} close={this.closeAlert} />
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('alertStore', 'loginStore')
)(observer(withRouter(SignUp)));
