import React, { Component } from 'react';
import {
	Dialog, DialogContent, Typography, DialogActions, Button, Grid
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { inject, observer } from 'mobx-react';
import FilterRender from '../../components/FilterRender/FilterRender'
import ServicesOption from '../../services/servicesOption'
import validate from 'validate.js';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert'
import DialogAlert from '../../components/DialogAlert/DialogAlert'
import ServicesCampaign from '../../services/servicesCampaign'

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	button: {
		color: theme.palette.success,
	},
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const schema = {
	itemNumber: {
		presence: { allowEmpty: false, message: '^กรุณาเลือกสินค้า' },
		length: {
			maximum: 255
		}
	},
	point: {
		presence: { allowEmpty: false, message: '^กรุณากรอกอินเซนทีฟ' },
	}
};

class DefaultIncentiveSetting extends Component {
	state = {
		filterList: [],
		formCheck: {
			isValid: false,
			touched: {},
			values: {},
			errors: {}
		},
		openModal: false,
		optionProductOrigin: [],
		schema: {},
		alertMessage: {
			content: '',
			open: false,
			status: ''
		},
	}

	componentDidMount() {
		this.getItemCategory()
	}

	componentDidUpdate() {
		if (this.state.openModal === false) {
			this.setState({ openModal: true })
			this.getItemCategory()
		}
	}

	getItemCategory = () => {
		ServicesOption.getItemCategory(
			{
				userLogin: JSON.parse(localStorage.getItem('userData')).userId,
				roleId: JSON.parse(localStorage.getItem('userData')).roleId,
				// workSection: JSON.parse(localStorage.getItem('userData')).workSection
			}
		).then(res => {
			switch (res.status) {
				case 200:
					let resData = res.data.result
					let filterList = []
					let schema = {}
					resData.map((value, index) => {
						let resKey = Object.keys(value)
						resKey.map((key, i) => {
							if (key === 'point' || key === 'itemG3Code') {
								filterList.push(
									{
										name: key + (index + 1),
										displayName: key === 'point' ? 'จำนวนเงิน(บาท)' : 'กลุ่มสินค้า',
										showDisplayName: false,
										type: key === 'point' ? 'number' : 'text',
										oldValue: key === 'point' ? value[key] === 0 ? '0' : value[key] : value[key],
										value: key === 'point' ? value[key] === 0 ? '0' : value[key] : value[key],
										option: [],
										required: false,
										disabled: key === 'point' ? false : true,
										widthCol: 6,
										styleXs: {
											label: 1,
											input: 11,
										},
										styleSm: {
											label: 1,
											input: 11,
										},
									},
								)

								if (key === 'point') {
									schema[`${key}${index + 1}`] = {
										presence: { allowEmpty: false, message: '^กรุณากรอกจำนวนเงิน' },
									}
								}
							}
						})
					})
					this.setState({ filterList, schema })
					break;
				default:
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}
		})
	}

	updateItemInitialPoint = (data) => {
		ServicesCampaign.updateItemInitialPoint(data).then(res => {
			switch (res.status) {
				case 200:
					this.setState({ alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ') })
					break;
				default:
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}
		})
	}

	handleChangeFilter = (fieldName, value) => {
		let filterList = [...this.state.filterList]
		let formCheck = this.state.formCheck

		filterList.map((val, i) => {
			if (val.name === fieldName) {
				if (val.name.substring(0, (val.name.lastIndexOf('t') + 1)) === 'point') {
					if (value === '') {
						formCheck.values[`${val.name}`] = value
						val.value = value
					} else {
						if (value < 0) {
							val.value = 1
							formCheck.values[`${val.name}`] = 1
						} else if (value >= 0) {
							val.value = value
							formCheck.values[`${val.name}`] = value
						}
					}
				}
				else {
					val.value = value
					formCheck.values[`${val.name}`] = value
				}
				formCheck.touched[`${val.name}`] = true
			}
		})

		if (formCheck.touched[fieldName]) {
			const errors = validate(formCheck.values, this.state.schema);

			formCheck = {
				...formCheck,
				isValid: errors ? false : true,
				errors: errors || {}
			}
		}

		this.setState({ filterList: filterList, formCheck: formCheck })

	}

	checkValidation = () => {
		let filterList = this.state.filterList
		let formCheck = this.state.formCheck

		filterList.map((val, i) => {
			formCheck.touched[`${val.name}`] = true
			formCheck.values[`${val.name}`] = val.value

			if (formCheck.touched[val.name]) {
				const errors = validate(formCheck.values, this.state.schema);
				formCheck = {
					...formCheck,
					isValid: errors ? false : true,
					errors: errors || {}
				}
			}
		})

		this.setState({ formCheck: formCheck })
		return formCheck.isValid
	}

	handleSubmit = () => {
		let filterList = this.state.filterList
		let formCheck = this.state.formCheck
		let isValid = this.checkValidation()

		if (isValid) {

			let countEvenNumber = 0
			let data = []
			let objectRow = {}
			let indexMain = 0

			filterList.map((val, i) => {
				if (i % 2 == 0) {
					++indexMain;
				}

				if (val.name.substring(0, (val.name.lastIndexOf('t') + 1)) === 'point') {
					let index = parseInt(val.name.substring((val.name.lastIndexOf('t')) + 1))
					if (index === indexMain) {
						objectRow.point = parseInt(val.value)
						++countEvenNumber
					}
				} else if (val.name.substring(0, (val.name.lastIndexOf('e') + 1)) === 'itemG3Code') {
					let index = parseInt(val.name.substring((val.name.lastIndexOf('e')) + 1))
					if (index === indexMain) {
						objectRow.itemG3Code = val.value
						++countEvenNumber
					}
				}

				if (countEvenNumber === 2) {
					data.push(objectRow)
					objectRow = {}
					countEvenNumber = 0
				}
			})

			let objectFinal = {
				userLogin: JSON.parse(localStorage.getItem('userData')).userId,
				roleId: JSON.parse(localStorage.getItem('userData')).roleId,
				data: data
			}

			this.updateItemInitialPoint(objectFinal)

		} else {


			// alert('no success')
		}
	}

	hasError = field => {
		return this.state.formCheck.touched[field] && this.state.formCheck.errors[field] ? true : false;
	}

	helperTextError = field => {
		return this.state.formCheck.touched[field] && this.state.formCheck.errors[field] ? this.state.formCheck.errors[field][0] : null;
	}

	handleCloseModal = () => {
		this.props.close()
		let filterList = this.state.filterList
		filterList.map((val, i) => {
			val.value = ''
		})
		this.setState({
			filterList, formCheck: {
				isValid: false,
				touched: {},
				values: {},
				errors: {}
			},
			openModal: false,
		})
	}

	closeAlert = () => {
		if (this.state.alertMessage.status === 'success') {
			this.handleCloseModal()
		}
		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
		})
	}

	render() {
		const { filterList, alertMessage } = this.state;
		return (
			<Dialog aria-labelledby="customized-dialog-title" open={this.props.open} fullWidth={true}>
				<DialogTitle id="customized-dialog-title" onClose={() => this.handleCloseModal()}>
					ตั้งค่าเริ่มต้นอินเซนทีฟ
        		</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<FilterRender
							filterList={filterList}
							handleChangeFilter={this.handleChangeFilter}
							hasError={this.hasError}
							helperTextError={this.helperTextError}
						/>
					</Grid>
					&nbsp;
				</DialogContent>
				<DialogActions>
					<Button color="primary" variant="contained" onClick={() => this.handleSubmit()} >
						บันทึก
          			</Button>
					<Button color="default" variant="contained" onClick={() => this.handleCloseModal()} >
						ยกเลิก
          			</Button>
				</DialogActions>
				<DialogAlert data={alertMessage} close={this.closeAlert} />
			</Dialog>
		);
	}
}

export default inject('campaignStore')(observer(DefaultIncentiveSetting))





