import React, { Component } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Avatar,
  Paper,
  MobileStepper,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia
} from '@material-ui/core';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import {
  TotalSalesCard,
  TotalReceiveIncome,
  LatestSales,
  AdvertisementModal
} from './components';
import ServicesDashboard from '../../services/servicesDashboard';
import { getImageAPI } from '../../services/servicesImage';
import {
  currencyFormat,
  getThaiYearOption,
  getCurrentYearFormat
} from '../../constants/function/formatConverter';
import { getExistData } from '../../constants/function/checkDataValue';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';
import DialogAlert from '../../components/DialogAlert/DialogAlert';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import WhereToVoteIcon from '@material-ui/icons/WhereToVote';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTheme } from '@material-ui/core/styles';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  avatar1: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
});
class Dashboard extends Component {
  state = {
    userDashboard: {},
    adsList: [],
    activeStep: 0
  };

  componentDidMount() {
    ServicesDashboard.getPCDashboard({
      pcNo: JSON.parse(localStorage.getItem('userData')).pcNo,
      userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    }).then(res => {
      switch (res.status) {
        case 200:
          this.setState({ userDashboard: res.data.result[0] });
          break;
        default:
          this.setState({ userDashboard: {} });
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
    this.getAds();
  }

  getAds = () => {
    ServicesDashboard.getAds({
      pcNo: JSON.parse(localStorage.getItem('userData')).pcNo,
      userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    }).then(res => {
      if (res.data.success === true) {
        this.setState({ adsList: res.data.result });
      }
    });
  };

  handleNext = e => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = e => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  render() {
    const { classes } = this.props;
    const { userDashboard, adsList, activeStep } = this.state;
    const theme = useTheme;

    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography variant="h2">
              สวัสดีคุณ {userDashboard.pcName}
            </Typography>
          </Grid>

          {adsList.length > 0 && (
            <>
              <Card
                sx={{ maxWidth: 345 }}
                style={{ width: '70%', height: '100%', margin: 'auto' }}>
                <CardContent>
                  <img
                    width={'100%'}
                    // height={'250px'}
                    className={classes.img}
                    src={getImageAPI(adsList[activeStep].adsImage)}
                    alt={adsList[activeStep].adsName}
                  />
                  <Typography gutterBottom variant="h3" component="div">
                    {adsList[activeStep].adsName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {adsList[activeStep].adsDesc}
                  </Typography>
                </CardContent>
                <CardActions style={{ display: 'block' }}>
                  <MobileStepper
                    style={{ background: 'unset' }}
                    steps={adsList.length}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={this.handleNext}
                        disabled={activeStep === adsList.length - 1}>
                        Next
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={this.handleBack}
                        disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        Back
                      </Button>
                    }
                  />
                </CardActions>
              </Card>
              {/* <Paper
                square
                elevation={0}
                className={classes.header}
                style={{ width: '50%', margin: 'auto', textAlign: 'center' }}>
                <Typography variant="h3">
                  {adsList[activeStep].adsName}
                </Typography>
                <Typography variant="h3">
                  <img
                    width={'100%'}
                    className={classes.img}
                    src={getImageAPI(adsList[activeStep].adsImage)}
                    alt={adsList[activeStep].adsName}
                  />
                </Typography>
                <div style={{ width: '100%' }}>
                  <MobileStepper
                    style={{ background: 'unset' }}
                    steps={adsList.length}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={this.handleNext}
                        disabled={activeStep === adsList.length - 1}>
                        Next
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={this.handleBack}
                        disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        Back
                      </Button>
                    }
                  />
                </div>
              </Paper> */}
            </>
          )}
          {/* <React.Fragment>
            <Paper square elevation={0} className={classes.header}>
              <Typography variant="h3">
                {adsList[activeStep].adsName}
              </Typography>
            </Paper>
            &nbsp;
            <Paper square elevation={0} style={{ textAlign: 'center' }}>
              <Typography variant="h4">
                {adsList[activeStep].adsDesc}
              </Typography>
            </Paper>
            &nbsp;
            <div>
              <img
                className={classes.img}
                src={getImageAPI(adsList[activeStep].adsImage)}
                alt={adsList[activeStep].adsName}
              />
            </div>
          </React.Fragment> */}
        </Grid>
        {/* <AdvertisementModal
          open={this.props.loginStore.loginSuccess}
          close={this.handleCloseAdv}
        /> */}
      </div>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('loginStore')
)(observer(Dashboard));
