import React, { Component } from 'react';
import {
	colors, Dialog, DialogContent, Typography, DialogActions, Button, DialogTitle
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
	button: {
		marginRight: theme.spacing(1),
		color: theme.palette.text.secondary
	}
});


class ConfirmTransferProduct extends Component {
	state = {}

	handleCloseModal = () => {
		this.props.close()
	}

	handleAction = () => {
		this.props.handleSubmit()
		this.props.close()
	}

	render() {
		const { classes } = this.props;

		return (
			<Dialog open={this.props.open} maxWidth="md" >
				<DialogTitle onClose={() => this.handleCloseModal()}>

					<Typography variant="h4">ยืนยันส่งอนุมัติ</Typography>

				</DialogTitle>
				<DialogContent dividers>
					&nbsp;
					<Typography variant="h5">ต้องการส่งอนุมัตินี้ใช่หรือไม่</Typography>
					&nbsp;
					&nbsp;
				</DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: colors.green[600], color: 'white' }} variant="contained" onClick={() => this.handleAction()}>
						ส่งอนุมัติ
          			</Button>
					<Button color="default" variant="contained" onClick={() => this.handleCloseModal()}>
						ยกเลิก
          			</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(useStyles)(ConfirmTransferProduct);