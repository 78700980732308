import React, { Component } from 'react';
import {
  Grid,
  Button,
  Card,
  Divider,
  CardContent,
  CardHeader
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import FilterRender from '../../../../components/FilterRender/FilterRender';
import PendingApprovalToAccountAction from './PendingApprovalToAccountAction';
import {
  currencyFormat
} from '../../../../constants/function/formatConverter';
import ServicesPendingApprovalToAccount from '../../../../services/servicesPendingApprovalToAccount';
import { INPUT_RENDER } from '../../PendingApprovalToAccountStructure';
import DialogAlert from '../../../../components/DialogAlert/DialogAlert';
import { callDialogAlert } from '../../../../components/DialogAlert/DialogAlert';
import { ButtonRender } from '../../../../components/ButtonRender/ButtonRender';
import ConfirmRevokeReportForAppove from './ConfirmRevokeReportForAppove';

class PendingApprovalToAccountFilter extends Component {
  state = {
    filterList: [],
    openModal: false,
    statusRow: '',
    openConfirmRevoke: false,
    docNo: '',
    alertMessage: {
      content: '',
      open: false,
      status: ''
    }


  };

  componentDidMount() {
    this.setState({ filterList: INPUT_RENDER }, () => {
      this.searchSubmit();
    });
  }

   getWaitApproval = async (obj) => {

    this.setState({ alertMessage: callDialogAlert('loading') })

    let TABLE_CONTENT = [];
    const res = await ServicesPendingApprovalToAccount.getWaitApproval(obj)
    if(res.status === 200){
      res.data.result.map((value, index) => {
        TABLE_CONTENT.push({
          rowNo: index + 1,
          docNo: value.docNo,
          createDate: value.createDate,
          campaignName: value.campaignName,
          totalAmount: currencyFormat(value.totalAmount),

          action: (
            <React.Fragment>
              {this.renderButtonAction(
                value.docNo
              )}
              &nbsp;
              {/* <ButtonRender
                type="warning"
                text="ตีกลับสรุปยอดโอน"
                onClick={() =>
                  this.setState({
                    openConfirmRevoke: true,
                    docNo: value.docNo
                  })
                }
              /> */}
            </React.Fragment>
          )
        });
      });
    }

          
    this.props.pendingApprovalToAccountStore.TABLE_CONTENT = TABLE_CONTENT;
   
    this.setState({ alertMessage: {...this.state.alertMessage.open = false} })

  };

  renderButtonAction = (docNo) => {


    return [
      <Button
        color="primary"
        type="submit"
        variant="contained"
        onClick={() => this.handleOpenModal(docNo)}>
        อนุมัติ
      </Button>
    ];
    
    // switch (status) {
    //   case '1':
    //   case '2':
    //   case '4':
    //     return [
    //       <Button
    //         // disabled={!formState.isValid}
    //         // fullWidth
    //         type="submit"
    //         variant="contained"
    //         color="secondary"
    //         onClick={() => this.handleOpenModal(data, status)}>
    //         ดูรายละเอียด
    //       </Button>
    //     ];
    //     break;
    //   case '3':
    //     return [
    //       <Button
    //         color="primary"
    //         // disabled={!formState.isValid}
    //         // fullWidth
    //         type="submit"
    //         variant="contained"
    //         onClick={() => this.handleOpenModal(data, status)}>
    //         อนุมัติ
    //       </Button>
    //     ];
    //     break;
    // }
  };

  handleChangeFilter = (name, value) => {
    let filterList = [...this.state.filterList];

    filterList.map((val, i) => {
      if (val.name === name) {
        if (value === null) {
          // for uploadContent filter
          value = [];
        }
        val.value = value;
      }
    });
    this.setState({ filterList: filterList });
  };

  searchSubmit = () => {
    let object = {};

    this.state.filterList.map((val, i) => {
      object[`${val.name}`] = val.value;
    });

    // object.approvalStatus = ['3']
    object.userLogin = JSON.parse(localStorage.getItem('userData')).userId;
    object.roleId = JSON.parse(localStorage.getItem('userData')).roleId;
    object.transferStatus = '';
    this.getWaitApproval(object);

    this.props.pendingApprovalToAccountStore.historySearch = object;
  };

  handleOpenModal = (docNo) => {

    this.props.pendingApprovalToAccountStore.docNo = docNo
    this.props.pendingApprovalToAccountStore.idModal = docNo;

    this.setState({ openModal: true, docNo });

  };

  handleRevokeReportForApprove = () => {
    const { docNo } = this.state;
    let object = {
      userLogin: JSON.parse(localStorage.getItem('userData')).userId,
      roleId: 1,
      docNo
    };
    ServicesPendingApprovalToAccount.revokeReportForApprove(object).then(
      res => {
        switch (res.status) {
          case 200:
            this.setState({
              alertMessage: callDialogAlert('success', 'ดำเนินการสำเร็จ')
            });
            break;
          default:
            this.setState({
              alertMessage: callDialogAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              )
            });
        }
      }
    );
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, statusRow: '' });
    this.props.pendingApprovalToAccountStore.idModal = '';
  };

  closeAlert = () => {
    if (this.state.alertMessage.status === 'success') {
      this.handleCloseModal();
      this.searchSubmit();
    }

    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      }
    });
  };

  render() {
    const { filterList, openModal, alertMessage, docNo } = this.state;

    return (
      <React.Fragment>
        <Card>
          <CardHeader
            // action={
            // 	<Button>ddddd</Button>
            // }
            title={'รออนุมัติส่งบัญชี'}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <FilterRender
                filterList={filterList}
                handleChangeFilter={this.handleChangeFilter}
                //hasError={this.hasError}
                //helperTextError={this.helperTextError}
              />
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
                style={{ textAlignLast: 'right' }}>
                <Button
                  color="primary"
                  // disabled={!formState.isValid}
                  // fullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => this.searchSubmit()}>
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DialogAlert data={alertMessage} close={this.closeAlert} />

        <PendingApprovalToAccountAction
          docNo={docNo}
          statusRow={this.state.statusRow}
          searchSubmit={this.searchSubmit}
          open={openModal}
          close={this.handleCloseModal}
        />


        <ConfirmRevokeReportForAppove
          handleSubmit={this.handleRevokeReportForApprove}
          close={() => this.setState({ openConfirmRevoke: false })}
          open={this.state.openConfirmRevoke}
        />
      </React.Fragment>
    );
  }
}

export default inject(
  'pendingApprovalToAccountStore',
  'approvalPCStore'
)(observer(PendingApprovalToAccountFilter));
