import React from 'react';
import { Button, Typography, colors } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

const Dialog2 = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  paper: {
    margin: 0,
    padding: theme.spacing(2),
    width: 400
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))(Dialog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function callDialogAlert(status, message) {
  let obj = {
    content: message,
    open: status === '' ? false : true,
    status: status
  };
  return obj;
}

export default function DialogAlert(props) {
  const handleClose = () => {
    props.close();
  };

  const { children, classes, onClose, data, ...other } = props;

  const renderIcon = status => {
    switch (status) {
      case 'processing':
        return <CircularProgress />;
      case 'loading':
        return <CircularProgress />;
      case 'loading_success':
        return <CheckCircleIcon style={{ color: colors.green[600] }} />;
      case 'success':
        return <CheckCircleIcon style={{ color: colors.green[600] }} />;
      case 'warning':
        return <WarningIcon style={{ color: colors.orange[600] }} />;
      case 'error':
        return <CancelIcon style={{ color: colors.red[600] }} />;
      case 'info':
        return <InfoIcon style={{ color: colors.blue[600] }} />;
    }
  };

  const renderTextStatus = (status, content) => {
    switch (status) {
      case 'processing':
        return 'กำลังดำเนินการ...';
      case 'loading':
        return 'กำลังโหลดข้อมูล...';
      case 'loading_success':
        return 'โหลดข้อมูลสำเร็จ';
      default:
        return content;
    }
  };

  return (
    <Dialog2
      open={props.data.open}
      // className={classes.root}
      // open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      {props.data.header ? (
        <DialogTitle id="alert-dialog-slide-title">
          {props.data.header}
        </DialogTitle>
      ) : (
        ''
      )}
      <DialogContent style={{ alignSelf: 'center' }}>
        <DialogContentText id="alert-dialog-slide-description">
          {/* {props.message.detail} */}
          <div style={{ display: 'flex' }}>
            {renderIcon(props.data.status)}
            &nbsp;&nbsp;
            <span style={{ placeSelf: 'center', fontSize: '18px' }}>
              {renderTextStatus(props.data.status, props.data.content)}
            </span>
          </div>
        </DialogContentText>
      </DialogContent>

      {props.data.status !== 'processing' &&
      props.data.status !== 'loading' &&
      props.data.status !== 'warning' ? (
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            ปิด
          </Button>
        </DialogActions>
      ) : (
        ''
      )}
    </Dialog2>
  );
}
