import { observable, computed, action, decorate } from "mobx"

class accountStore {

	profileImage = []

	profileImageForTrigger = ''
	triggerSaved = 1
	

}
decorate(accountStore, {
	profileImage:  observable,
	profileImageForTrigger:  observable,
	triggerSaved:  observable,
})

export default new accountStore()

