import React, { Component } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import validate from 'validate.js';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  Snackbar
} from '@material-ui/core';
import compose from 'recompose/compose';
import ServiceLogin from '../../services/servicesLogin';
import Alert from '@material-ui/lab/Alert';
import { inject, observer } from 'mobx-react';
import { decryptURL } from '../../constants/function/encryptURLConverter';
import _COM7 from '../../assets/_COM7.jpg';
import _JAYMART from '../../assets/_JAYMART.jpg';
import uniqid from 'uniqid';
import ServicesLoginManagement from '../../services/servicesLoginManagement';
import { getImageAPI } from '../../services/servicesImage';

const schema = {
  username: {
    presence: { allowEmpty: false, message: '^กรุณากรอกชื่อผู้ใช้' },
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: '^กรุณากรอกรหัสผ่าน' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
});

class SignInPC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {
        isValid: false,
        values: {},
        touched: {},
        errors: {}
      },
      pcGroup: '',
      checkingUser: []
    };
  }

  componentDidMount() {
    localStorage.removeItem('userData');
    localStorage.setItem('logged', false);

    const urlParams = new URLSearchParams(window.location.search);
    const g = urlParams.get('g');
    if (urlParams && g) {
      ServiceLogin.checkingUser({ uniqid: g }).then(res => {
        switch (res.status) {
          case 200:
            if (!!res.data.result && res.data.result.length > 0) {
              let resData = res.data.result[0];
              let checkingUser = res.data.result;
              this.setState({
                pcGroup: resData.pageName,
                checkingUser: checkingUser
              });
              this.props.loginStore.pcGroupLogin = {
                name: urlParams.get('g'),
                value: resData.groupNo
              };
            } else {
              this.setState({ pcGroup: '', checkingUser: [] });
              this.props.loginStore.pcGroupLogin = { name: '', value: 0 };
            }

            break;
          default:
            // this.props.loginManagementStore.TABLE_CONTENT = []
            this.setState({ pcGroup: '', checkingUser: [] });
            this.props.loginStore.pcGroupLogin = { name: '', value: 0 };
        }
      });
    } else {
      if (localStorage.getItem('uniqueIdDevice')) {
        ServiceLogin.checkingUser({
          uniqid: localStorage.getItem('uniqueIdDevice')
        }).then(res => {
          switch (res.status) {
            case 200:
              if (!!res.data.result && res.data.result.length > 0) {
                let checkingUserMock = res.data.result;
                this.setState({
                  checkingUser: res.data.result,
                  pcGroup: checkingUserMock[0].pageName
                });
                this.props.loginStore.pcGroupLogin = {
                  name: checkingUserMock[0].decode,
                  value: checkingUserMock[0].groupNo
                };
              } else {
                this.setState({ pcGroup: '', checkingUser: [] });
                this.props.loginStore.pcGroupLogin = {
                  name: '',
                  value: 0
                };
              }

              break;
            default:
              this.setState({ pcGroup: '', checkingUser: [] });
              this.props.loginStore.pcGroupLogin = {
                name: '',
                value: 0
              };
          }
        });
      }
    }
  }

  handleChange = event => {
    event.persist();
    let formState = this.state.formState;
    formState = {
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    };

    /*check validate empty onChange*/
    if (formState.touched[event.target.name]) {
      const errors = validate(formState.values, schema);
      formState = {
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      };
    }

    this.setState({ formState: formState });
  };

  handleSignIn = event => {
    this.setState({ loginMessage: '' });
    event.preventDefault();
    let values = this.state.formState.values;

    if (!localStorage.getItem('uniqueIdDevice')) {
      values.uniqid = '';
    } else {
      values.uniqid = localStorage.getItem('uniqueIdDevice');
    }

    ServiceLogin.loginPC(values).then(res => {
      switch (res.status) {
        case 200:
          localStorage.setItem('userData', JSON.stringify(res.data.result));
          localStorage.setItem('logged', true);
          this.props.loginStore.userData = res.data.result;
          this.props.history.push('/');
          this.props.loginStore.loginSuccess = true;
          localStorage.setItem('uniqueIdDevice', res.data.result.uniqid);
          break;
        default:
          this.setState({ loginMessage: res.data.message });
      }
    });
  };

  hasError = field => {
    return this.state.formState.touched[field] &&
      this.state.formState.errors[field]
      ? true
      : false;
  };

  goToSignUp = () => {
    if (this.state.pcGroup !== '') {
      this.props.history.push(
        '/sign-up?g=' + this.props.loginStore.pcGroupLogin.name
      );
    } else {
      this.props.history.push('/sign-up');
    }
  };

  render() {
    const { classes } = this.props;
    const { formState, pcGroup, checkingUser } = this.state;

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.content} item lg={12} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form
                  className={classes.form}
                  onSubmit={e => this.handleSignIn(e)}>
                  <div style={{ textAlign: 'center' }}>
                    {checkingUser.length > 0 ? (
                      <img
                        src={getImageAPI(checkingUser[0].logoImage)}
                        style={{ width: '85%' }}
                      />
                    ) : (
                      ''
                    )}
                    <Typography className={classes.title} variant="h2">
                      เข้าสู่ระบบ
                    </Typography>
                  </div>

                  {this.state.loginMessage && (
                    <Alert severity="error">{this.state.loginMessage}</Alert>
                  )}
                  <TextField
                    className={classes.textField}
                    error={this.hasError('username')}
                    fullWidth
                    helperText={
                      this.hasError('username')
                        ? formState.errors.username[0]
                        : null
                    }
                    label="ชื่อผู้ใช้"
                    name="username"
                    onChange={e => this.handleChange(e)}
                    type="text"
                    value={formState.values.username || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={this.hasError('password')}
                    fullWidth
                    helperText={
                      this.hasError('password')
                        ? formState.errors.password[0]
                        : null
                    }
                    label="รหัสผ่าน"
                    name="password"
                    onChange={e => this.handleChange(e)}
                    type="password"
                    value={formState.values.password || ''}
                    variant="outlined"
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    type="submit"
                    variant="contained">
                    เข้าสู่ระบบ
                  </Button>

                  {this.state.pcGroup !== '' ? (
                    <Typography color="textSecondary" variant="body1">
                      ยังไม่มีบัญชี ? &nbsp;
                      <Link
                        // component={RouterLink}
                        // to="/sign-up"
                        onClick={() => this.goToSignUp()}
                        variant="h6">
                        ลงทะเบียน
                      </Link>
                    </Typography>
                  ) : (
                    ''
                  )}
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('loginStore')
)(observer(withRouter(SignInPC)));
