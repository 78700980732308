
export const INPUT_RENDER = [
	{
		name: 'productName',
		displayName: 'ชื่อสินค้า',
		showDisplayName: true,
		type: 'text',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	},
	{
		name: 'purchaseDate',
		displayName: 'วันที่ซื้อ',
		showDisplayName: true,
		type: 'date',
		value: '',
		option: [],
		required: false,
		widthCol: 6,
		styleXs: {
			label: 12,
			input: 12,
		},
		styleSm: {
			label: 3,
			input: 9,
		},
	}
]

export const TABLE_HEADER = [
	{
		key: 'rowNo',
		label: 'ลำดับ',
	},
	{
		key: 'qrCode',
		label: 'รหัส Barcode',
	},
	{
		key: 'productName',
		label: 'ชื่อสินค้า',
	},
	{
		key: 'purchaseDate',
		label: 'วันที่ซื้อ',
	},
	{
		key: 'saleStatusName',
		label: 'สถานะ',
	},
]