import React, { Component } from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import {
  Grid,
  IconButton,
  Button,
  Typography,
  colors,

} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import AddProductPointModal from './AddProductPointModal';
import FilterRender from '../../components/FilterRender/FilterRender';
// import TableRender from '../../components/TableRender/TableRender';
import validate from 'validate.js';
import ServicesCampaign from '../../services/servicesCampaign';
import DialogAlert from '../../components/DialogAlert/DialogAlert';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';
import { INPUT_RENDER, TABLE_HEADER } from './SetPointProductCampaignStructure';

const schema = {
  campaignName: {
    presence: { allowEmpty: false, message: '^กรุณากรอกชื่อแคมเปญ' },
    length: {
      maximum: 225
    }
  },
  campaignDesc: {
    presence: { allowEmpty: false, message: '^กรุณากรอกคำอธิบายแคมเปญ' },
    length: {
      maximum: 225
    }
  },
  // campaignImage: {
  //   presence: { allowEmpty: false, message: '^กรุณาแนบไฟล์รูป' },
  //   length: {
  //     maximum: 1,
  //     message: '^เลือกได้เพียง 1 รูปเท่านั้น'
  //   }
  // },
  userType: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกประเภทผู้ใช้งาน' }
  }
};

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

class SetPointProductCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterList: [],
      openSetPointCampaign: false,
      formCheck: {
        isValid: false,
        touched: {},
        values: {},
        errors: {}
      },
      productList: [],
      alertMessage: {
        content: '',
        open: false,
        status: ''
      },
      readOnly: false,
      TABLE_HEADER: [],
      campaignId: ''
    };
  }

  componentDidMount() {
    // หา params
    this.setState({ filterList: INPUT_RENDER, TABLE_HEADER }, () => {
      const urlParams = new URLSearchParams(window.location.search);
      const key = urlParams.get('key');
      const r = urlParams.get('r');
      if (urlParams && key) {
        let readOnly = false;
        if (r) {
          let resR = JSON.parse(window.atob(r));
          if (resR === 1) {
            readOnly = true;
            this.renderHeaderTable(readOnly);
          }
        }
        let campaignId = JSON.parse(window.atob(key));
        this.setState({ campaignId: campaignId });
        //todo call service
        ServicesCampaign.getCampaignLine({
          campaignId: campaignId,
          userLogin: JSON.parse(localStorage.getItem('userData')).userId,
          roleId: JSON.parse(localStorage.getItem('userData')).roleId
        }).then(res => {
          switch (res.status) {
            case 200:
              this.props.campaignStore.productPointList = res.data.result;

              let TABLE_CONTENT = [];
              let filterList = this.state.filterList;
              let formCheck = {
                isValid: false,
                touched: {},
                values: {},
                errors: {}
              };

              // TABLE_HEADER.map((val, i) => {
              this.props.campaignStore.productPointList.map((value, index) => {
                if (index === 0) {
                  let resKey = Object.keys(value);
                  resKey.map((key, i) => {
                    filterList.map((val, i) => {
                      if (val.name === key) {
                        if (value[key] !== '' && value[key] !== null) {
                          formCheck.isValid = true;
                          if (key === 'campaignImage') {
                            val.canUpload = !readOnly;
                            val.value = [];
                            val.value.push({
                              showImg: value[key] || [],
                              name: value[key].substring(
                                value[key].lastIndexOf('/') + 1
                              )
                            });
                          } else {
                            val.value = value[key];
                            val.disabled = readOnly;
                          }
                        } else if (value[key] === '' || value[key] === null) {
                          formCheck.isValid = false;
                          if (key === 'campaignImage') {
                            val.value = [];
                            val.canUpload = !readOnly;
                          } else {
                            val.value = value[key];
                            val.disabled = readOnly;
                          }
                        }
                        formCheck.values[`${key}`] = val.value;
                        formCheck.touched[`${key}`] = true;
                      }
                    });
                  });
                }
                this.props.campaignStore.chooseProductLog.push({
                  index: '',
                  option: value.itemNumber + '---' + value.productName
                });
                TABLE_CONTENT.push({
                  rowNo: index + 1,
                  itemNumber: value.itemNumber,
                  productName: value.productName,
                  point: value.point,
                  action: !readOnly && (
                    <Button
                      color="secondary"
                      // disabled={!formState.isValid}
                      // fullWidth
                      type="submit"
                      variant="contained"
                      onClick={() => this.handleDeleteProductPoint(index)}
                      >
                      ลบ
                    </Button>
                  )
                });
              });

              this.props.campaignStore.TABLE_CONTENT_SET_POINT = TABLE_CONTENT;
              this.setState({
                productList: TABLE_CONTENT,
                filterList: filterList,
                formCheck: formCheck,
                readOnly
              });
              break;
            default:
              this.setState({
                alertMessage: callDialogAlert(
                  'error',
                  'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
                )
              });
          }
        });
      } else {
        this.renderHeaderTable(false);
        this.resetFilter();
      }
    });
  }

  renderHeaderTable = readOnly => {
    let tH = TABLE_HEADER;
    if (readOnly) {
      tH = tH.filter(val => {
        return val.key !== 'action';
      });
    }
    this.setState({ TABLE_HEADER: tH });
  };

  componentDidUpdate() {
    if (
      this.props.campaignStore.TABLE_CONTENT_SET_POINT.length > 0 &&
      this.state.productList.length === 0
    ) {
      this.setState({
        productList: this.props.campaignStore.TABLE_CONTENT_SET_POINT
      });
    } else if (
      this.state.productList.length > 0 &&
      this.state.productList.length !==
        this.props.campaignStore.TABLE_CONTENT_SET_POINT.length
    ) {
      this.setState({
        productList: this.props.campaignStore.TABLE_CONTENT_SET_POINT
      });
    }
  }

  resetFilter = () => {
    this.props.campaignStore.TABLE_CONTENT_SET_POINT = [];
    this.props.campaignStore.chooseProductLog = [];
    this.props.campaignStore.productPointList = [];

    let filterList = this.state.filterList;
    filterList.map((val, i) => {
      if (val.name === 'campaignImage') {
        val.canUpload = true;
        val.value = [];
      } else {
        val.value = '';
        val.disabled = false;
      }
    });
    this.setState({ filterList: filterList });
  };

  handleBack = () => {
    this.resetFilter();
    this.props.history.push('/campaignManagement');
  };

  handleChangeFilter = (name, value) => {
    let filterList = [...this.state.filterList];
    let formCheck = this.state.formCheck;

    filterList.map((val, i) => {
      if (val.name === name) {
        if (value === null) {
          // for uploadContent filter
          value = [];
        }
        val.value = value;
        formCheck.touched[`${val.name}`] = true;
        formCheck.values[`${val.name}`] = value;
      }
    });

    if (formCheck.touched[name]) {
      const errors = validate(formCheck.values, schema);
      formCheck = {
        ...formCheck,
        isValid: errors ? false : true,
        errors: errors || {}
      };
    }

    this.setState({ filterList: filterList, formCheck: formCheck });
  };

  handleCloseSetPointCampaign = () => {
    this.setState({ openSetPointCampaign: false });
  };

  handleOpenSetPointCampaign = () => {
    this.setState({ openSetPointCampaign: true });
  };

  handleSubmit = () => {
    let filterList = [...this.state.filterList];
    let formCheck = this.state.formCheck;

    if (formCheck.isValid) {
      //this.setState({ alertMessage: callDialogAlert('processing', '') });
      const data = new FormData();
      this.state.filterList.map((val, i) => {
        if (val.name === 'campaignImage') {
          if (val.value.length > 0) {
            data.append(`${val.name}`, val.value[0]);
          } else {
            data.append(`${val.name}`, []);
          }
        } else if (val.name === 'monthYear') {
          if (val.value === '') {
            data.append(`${val.name}`, new Date());
          } else {
            data.append(`${val.name}`, val.value);
          }
        } else {
          data.append(`${val.name}`, val.value);
        }
      });

      this.props.campaignStore.productPointList.map((val, i) => {
        data.append(`itemNumber`, val.itemNumber);
        data.append(`point`, val.point);
        if (!!val.itemG3Code) {
          data.append(`itemG3Code`, val.itemG3Code);
        }
      });

      data.append(
        'userLogin',
        JSON.parse(localStorage.getItem('userData')).userId
      );
      data.append(
        'roleId',
        JSON.parse(localStorage.getItem('userData')).roleId
      );

      const urlParams = new URLSearchParams(window.location.search);
      const key = urlParams.get('key');
      if (urlParams && key) {
        // update
        data.append('action', 'update');
        data.append('campaignId', this.state.campaignId);
      } else {
        data.append('action', 'save');
      }


      ServicesCampaign.actionCampaign(data).then(res => {
        switch (res.status) {
          case 200:
            this.setState({
              alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ')
            });
            break;
          default:
            this.setState({
              alertMessage: callDialogAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              )
            });
        }
      });
    } else {
      filterList.map((val, i) => {
        if (val.value === '' || val.value.length === 0) {
          formCheck.touched[`${val.name}`] = true;
        }

        if (formCheck.touched[val.name]) {
          const errors = validate(formCheck.values, schema);
          formCheck = {
            ...formCheck,
            isValid: errors ? false : true,
            errors: errors || {}
          };
        }
      });

      this.setState({ formCheck: formCheck });
    }
  };

  handleOpenCampaignModal = key => {
    this.props.campaignStore.campaignKeyArray = key;
    let newKey = window.btoa(JSON.stringify(key.campaignId));
    this.props.history.push('/setPointProductCampaign?key=' + newKey);
  };

  handleOpenConfirmDeleteCampaign = id => {
    this.props.campaignStore.openConfirmDeleteCampaign = id;
  };

  handleCloseConfirmDeleteCampaign = () => {
    this.searchSubmit();
    this.props.campaignStore.openConfirmDeleteCampaign = '';
  };

  hasError = field => {
    return this.state.formCheck.touched[field] &&
      this.state.formCheck.errors[field]
      ? true
      : false;
  };

  helperTextError = field => {
    return this.state.formCheck.touched[field] &&
      this.state.formCheck.errors[field]
      ? this.state.formCheck.errors[field][0]
      : null;
  };

  handleRemoveAll = fieldUploadName => {
    this.setState({ [fieldUploadName]: [] });

    let filterList = [...this.state.filterList];
    let formCheck = this.state.formCheck;

    filterList.map((val, i) => {
      if (val.name === fieldUploadName) {
        val.value = [];
        formCheck.touched[`${val.name}`] = true;
        formCheck.values[`${val.name}`] = [];
      }
    });

    if (formCheck.touched[fieldUploadName]) {
      const errors = validate(formCheck.values, schema);
      formCheck = {
        ...formCheck,
        isValid: errors ? false : true,
        errors: errors || {}
      };
    }

    this.setState({ filterList: filterList, formCheck: formCheck });
  };

  handleDisplayImg = src => {
    let modal = document.getElementById('showImg');
    let modalImg = document.getElementById('imgContent');
    modal.style.display = 'block';
    modalImg.src = src;
  };

  handleCloseDisplayImg = () => {
    let modal = document.getElementById('showImg');
    modal.style.display = 'none';
  };

  handleDeleteProductPoint = index => {
    this.props.campaignStore.chooseProductLog = this.props.campaignStore.chooseProductLog.filter(
      (val, i) => {
        if (val.index === '') {
          this.props.campaignStore.optionProduct.splice(0, 0, {
            value:
              this.props.campaignStore.productPointList[index].itemNumber +
              '---' +
              this.props.campaignStore.productPointList[index].productName,
            label: this.props.campaignStore.productPointList[index].productName
          });
        } else if (
          val.option ===
          this.props.campaignStore.productPointList[index].itemNumber +
            '---' +
            this.props.campaignStore.productPointList[index].productName
        ) {
          this.props.campaignStore.optionProduct.splice(val.index, 0, {
            value:
              this.props.campaignStore.productPointList[index].itemNumber +
              '---' +
              this.props.campaignStore.productPointList[index].productName,
            label: this.props.campaignStore.productPointList[index].productName
          });
        }
        return (
          val.option !==
          this.props.campaignStore.productPointList[index].itemNumber +
            '---' +
            this.props.campaignStore.productPointList[index].productName
        );
      }
    );

    this.props.campaignStore.productPointList.splice(index, 1);

    let TABLE_CONTENT = [];

    this.props.campaignStore.productPointList.map((value, index) => {
      TABLE_CONTENT.push({
        rowNo: index + 1,
        itemNumber: value.itemNumber,
        productName: value.productName,
        point: value.point,
        action: (
          <Button
            color="secondary"
            type="submit"
            variant="contained"
            onClick={() => this.handleDeleteProductPoint(index)}
            >
            ลบ
          </Button>
        )
      });
    });

    this.props.campaignStore.TABLE_CONTENT_SET_POINT = TABLE_CONTENT;
    this.setState({
      productList: this.props.campaignStore.TABLE_CONTENT_SET_POINT
    });
  };

  closeAlert = () => {
    if (this.state.alertMessage.status === 'success') {
      this.handleBack();
    }

    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { filterList, productList, alertMessage } = this.state;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Typography gutterBottom variant="h4">
              <IconButton onClick={() => this.handleBack()}>
                <ArrowBackIcon />
              </IconButton>
              กลับหน้าแคมเปญ
            </Typography>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Grid container spacing={2}>
                {/* <Grid item lg={12} sm={12} xl={12} xs={12}> */}
                <FilterRender
                  filterList={filterList}
                  handleChangeFilter={this.handleChangeFilter}
                  hasError={this.hasError}
                  helperTextError={this.helperTextError}
                  handleDisplayImg={this.handleDisplayImg}
                  handleRemoveAll={this.handleRemoveAll}
                />
                {/* </Grid> */}
              </Grid>
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              {/* <Card> */}
              {/* {!this.state.readOnly ? (
                  <React.Fragment>
                    <CardHeader
                      // action={
                      // 	<Button>ddddd</Button>
                      // }
                      title={
                        <Button
                          color="secondary"
                          // disabled={!formState.isValid}
                          // fullWidth
                          type="submit"
                          variant="contained"
                          onClick={() => this.handleOpenSetPointCampaign()}>
                          เพิ่มสินค้า
                        </Button>
                      }
                    />
                    <Divider />
                  </React.Fragment>
                ) : (
                  ''
                )}
                <CardContent>
                  <TableRender
                    TABLE_HEADER={this.state.TABLE_HEADER}
                    TABLE_CONTENT={productList}
                  />
                </CardContent>
                <Divider />
              </Card>
              &nbsp;
              {!this.state.readOnly ? (
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  style={{ textAlign: 'center' }}>
                  <Button
                    color="default"
                    variant="contained"
                    onClick={() => this.handleBack()}>
                    ยกเลิก
                  </Button>{' '}
                  &nbsp;
                  <Button
                    style={{
                      backgroundColor: colors.green[600],
                      color: 'white'
                    }}
                    variant="contained"
                    onClick={() => this.handleSubmit()}>
                    บันทึก
                  </Button>
                </Grid>
              ) : (
                ''
              )} */}

              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
                style={{ textAlign: 'center' }}>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => this.handleBack()}>
                  ยกเลิก
                </Button>{' '}
                &nbsp;
                <Button
                  style={{
                    backgroundColor: colors.green[600],
                    color: 'white'
                  }}
                  variant="contained"
                  onClick={() => this.handleSubmit()}>
                  บันทึก
                </Button>
              </Grid>
              <AddProductPointModal
                open={this.state.openSetPointCampaign}
                close={this.handleCloseSetPointCampaign}
                handleDeleteProductPoint={this.handleDeleteProductPoint}
              />
            </Grid>
          </Grid>
          <DialogAlert data={alertMessage} close={this.closeAlert} />

          <div id="showImg" class="modal" style={{ zIndex: '99' }}>
            <span class="close" onClick={() => this.handleCloseDisplayImg()}>
              &times;
            </span>
            <img class="modal-content" id="imgContent" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('campaignStore', 'alertStore')
)(observer(withRouter(SetPointProductCampaign)));
