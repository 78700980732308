import React, { Component } from 'react';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
  IconButton,
  Typography,
  Button,
  colors
} from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import ServicesIncentiveReport from 'services/servicesIncentiveReport';
import { isExistData, getExistData } from 'constants/function/checkDataValue';
import { currencyFormat } from 'constants/function/formatConverter';
import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getImageAPI } from '../../services/servicesImage';
import DialogAlert from '../../components/DialogAlert/DialogAlert';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';

class IncentiveReportPreview extends Component {
  state = {
    header: {},
    detail: {},
    epCode: '',
    alertMessage: {
      content: '',
      open: false,
      status: ''
    }
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('report');
    if (urlParams && key) {
      let keyReport = JSON.parse(window.atob(key));
      ServicesIncentiveReport.exportReportAccount({
        userLogin: JSON.parse(localStorage.getItem('userData')).userId,
        docNo: keyReport,
        roleId: JSON.parse(localStorage.getItem('userData')).roleId
      }).then(res => {
        switch (res.status) {
          case 200:
            if (isExistData(res.data.result)) {
              const resultData = res.data.result;
              this.setState({
                header: resultData.header,
                detail: resultData.detail,
                epCode: resultData.detail.data[0].epCode
              });
            }
            break;
          case 204:
            break;
          default:
            this.setState({
              alertMessage: callDialogAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              )
            });
        }
      });
    } else {
      this.props.history.push('/incentiveReport');
    }
  }

  downloadPdfButton = () => {
    return (
      <Button
        color="secondary"
        style={{ backgroundColor: colors.deepOrange[400], color: 'white' }}
        variant="contained">
        <PictureAsPdfIcon />
        &nbsp;พิมพ์รายงานส่งบัญชี
      </Button>
    );
  };

  handleBack = () => {
    this.props.history.push('/incentiveReport');
  };

  closeAlert = () => {
    if (this.state.alertMessage.status === 'success') {
      this.searchSubmit();
    }
    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      },
      rowNumSelected: [],
      rowNumSelectedData: []
    });
  };

  render() {
    const { alertMessage } = this.state;

    return (
      <div style={{ padding: '5%' }}>
        <DialogAlert data={alertMessage} close={this.closeAlert} />
        <Typography gutterBottom variant="h4">
          <IconButton onClick={() => this.handleBack()}>
            <ArrowBackIcon />
          </IconButton>
          กลับหน้ารายงานบัญชี/การเงิน
        </Typography>
        &nbsp;
        <div style={{ paddingBottom: '5%' }}>
          <ReactToPrint
            trigger={() => this.downloadPdfButton()}
            content={() => this.componentRef}
          />
        </div>
        {/* <div style={{ display: 'none' }} > */}
        <div style={{ border: '1px solid black' }}>
          <PrintForm
            ref={el => (this.componentRef = el)}
            header={this.state.header}
            detail={this.state.detail}
            epCode={this.state.epCode}
          />
        </div>
        {/* </div> */}
      </div>
    );
  }
}

export default withRouter(IncentiveReportPreview);

export class PrintForm extends Component {
  render() {
    const alignCenter = {
      textAlignLast: 'center',
      paddingLeft: '5px',
      paddingRight: '5px'
    };
    const alignLeft = {
      textAlignLast: 'left',
      paddingLeft: '5px',
      paddingRight: '5px'
    };
    const alignRight = {
      textAlignLast: 'right',
      paddingLeft: '5px',
      paddingRight: '5px'
    };

    const header = {
      // position: 'fixed',
      top: 0
    };

    const footer = {
      // position: 'fixed',
      bottom: 0
    };

    return (
      this.props.header !== null &&
      this.props.detail !== null && (
        <div style={{ padding: '0 20px' }}>
          <table style={{ width: '100%' }}>
            <tr style={{ textAlign: 'center' }}>
              <th colSpan="9">{this.props.header.subject}</th>
            </tr>
            <tr style={{ textAlign: 'center' }}>
              <th colSpan="9">{this.props.header.departmentName}</th>
              &nbsp; &nbsp;
            </tr>
            <tr>
              <td colSpan="4">
                <span style={{ fontWeight: 'bold' }}>วันที่</span> &nbsp;
                {this.props.header.documentDate}
              </td>
              {/* <td colSpan="3" style={{ textAlign: 'left' }}>{this.props.header.documentDate}</td> */}
              <td></td>
              <td colSpan="5" style={{ textAlign: 'right' }}>
                <span style={{ fontWeight: 'bold' }}>เลขอ้างอิง</span> &nbsp;
                {this.props.header.transferNo}
              </td>
              {/* <td colSpan="1" >{this.props.header.transferNo}</td> */}
            </tr>
            <tr>
              <td colSpan="4">
                <span style={{ fontWeight: 'bold' }}>ค่าใช้จ่ายเดือน</span>{' '}
                &nbsp;{this.props.header.roundMonth}
              </td>
              <td></td>
              <td colSpan="5" style={{ textAlign: 'right' }}>
                <span style={{ fontWeight: 'bold' }}>เลข EP</span> &nbsp;
                {this.props.epCode}
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan="9">
                <table id="excel-export-table" style={{ width: '100%' }}>
                  <thead>
                    <tr style={{ textAlignLast: 'center', width: '100%' }}>
                      <th>ลำดับ</th>
                      {/* <th>ชุดเอกสาร</th> */}
                      <th>จ่ายให้</th>
                      <th>สาขา/ร้าน</th>
                      <th>เลขปชช</th>
                      <th>ที่อยู่ตามบัตร ปชช</th>
                      <th>จำนวนเงิน</th>
                      <th>ภาษีหัก ณ ที่จ่าย</th>
                      <th>จำนวนจ่ายสุทธิ</th>
                    </tr>
                  </thead>
                  <tbody style={header}>
                    {!!this.props.detail.data &&
                      this.props.detail.data.map((val, i) => {
                        return (
                          <tr>
                            <td style={alignCenter}>{val.rowNo}</td>
                            {/* <td style={alignCenter}>{val.epCode}</td> */}
                            <td style={alignLeft}>{val.pcName}</td>
                            <td style={alignLeft}>{val.shopName}</td>
                            <td style={alignLeft}>{val.bankNo}</td>
                            <td style={alignLeft}>{val.address}</td>
                            <td style={alignRight}>{val.amount}</td>
                            <td style={alignRight}>{val.wht}</td>
                            <td style={alignRight}>{val.netAmount}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr style={footer}>
                      <td colSpan="4"></td>
                      <td style={alignLeft}>รวม&nbsp;</td>
                      <td style={alignRight}>
                        {!!this.props.detail.footer &&
                          currencyFormat(
                            getExistData(this.props.detail.footer.totalAmount)
                          )}
                      </td>
                      <td style={alignRight}>
                        {!!this.props.detail.footer &&
                          currencyFormat(
                            getExistData(this.props.detail.footer.totalWht)
                          )}
                      </td>
                      <td style={alignRight}>
                        {!!this.props.detail.footer &&
                          currencyFormat(
                            getExistData(
                              this.props.detail.footer.totalNetAmount
                            )
                          )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan="4" style={{ width: '50%' }}>
                <table style={{ width: '100%', textAlignLast: 'center' }}>
                  <tr>
                    <td>ผู้จัดทำ</td>
                  </tr>
                  <tr>
                    <td>ชื่อ {this.props.header.createName}</td>
                  </tr>
                  <tr>
                    <td>วันที่จัดทำ {this.props.header.createDate}</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                </table>
              </td>
              <td colSpan="4" style={{ padding: '5px 0' }}>
                <table style={{ width: '100%', textAlignLast: 'center' }}>
                  <tr>
                    <td>ผู้อนุมัติ</td>
                  </tr>
                  <tr>
                    <td>ชื่อ {this.props.header.approverName}</td>
                  </tr>
                  <tr>
                    <td>วันที่อนุมัติ {this.props.header.approverDate}</td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        style={{ width: '30%', marginBottom: '10px' }}
                        src={getImageAPI(this.props.header.signatureImage)}
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      )
    );
  }
}
