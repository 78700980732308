import React, { Component } from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose'
import { inject, observer } from 'mobx-react';
import ServicesSignup from '../../../../services/servicesSignup'
import Dropzone from 'react-dropzone'
import {
	Card,
	CardActions,
	CardContent,
	Avatar,
	Typography,
	Divider,
	Button,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText
} from '@material-ui/core';
import profileIcon from '../../../../assets/avatar-human-male-profile-user-icon-518358.png'
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import { getImageAPI } from '../../../../services/servicesImage'


const useStyles = makeStyles(theme => ({
	root: {},
	details: {
		display: 'flex'
	},
	avatar: {
		marginLeft: 'auto',
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0
	},
	progress: {
		marginTop: theme.spacing(2)
	},
	uploadButton: {
		marginRight: theme.spacing(2)
	}
}));

class AccountProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			profileImage: [],
			fullName: null
		};
		this.profileImageOnDrop = (profileImage) => {
			this.setState({ profileImage: profileImage })
			this.props.accountStore.profileImage = profileImage
		};
	}

	componentDidMount() {
		if (localStorage.getItem('userData')) {
			if (JSON.parse(localStorage.getItem('userData')).approvalStatus === '1') {
				ServicesSignup.getPCInfo(
					{
						pcNo: JSON.parse(localStorage.getItem('userData')).pcNo,
						userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
						roleId: JSON.parse(localStorage.getItem('userData')).roleId,
					}
				).then(res => {
					switch (res.status) {
						case 200:
							let resUser = res.data.result
							let userData = JSON.parse(localStorage.getItem('userData'))

							resUser.map((val, i) => {
								let profileImage2 = []
								if (val.profileImage !== '' && val.profileImage !== null) {
									profileImage2.push({ profileImageShow: val.profileImage })
									userData = { ...userData, profileImage: val.profileImage }
								}
								this.setState({ isLoaded: true, firstName: val.firstName, lastName: val.lastName, bookBankImage: val.bookBankImage, profileImage: profileImage2 })
							})
							localStorage.setItem('userData', JSON.stringify(userData));
					}
				})
			}
		}
	}

	
	handleRemoveAll = () => {
		this.setState({ profileImage: [] })
		this.props.accountStore.profileImage = []
	}

	handleDisplayImg = (src) => {
		let modal = document.getElementById("showImg");
		let modalImg = document.getElementById("imgContent");
		modal.style.display = "block";
		modalImg.src = src;
	}

	handleCloseDisplayImg = () => {
		let modal = document.getElementById("showImg");
		modal.style.display = "none";
	}


	render() {
		const { classes, ...rest } = this.props;
		const { className } = this.state;
		return (
			<Card
				{...rest}
				className={clsx(classes.root, className)}
			>
				<CardContent>
					<div className={classes.details} style={{ textAlign: 'center' }}>
						<Typography
							gutterBottom
							variant="h2"
						>
							{this.state.firstName} {this.state.lastName}
						</Typography>
						<List className={classes.list}>
							{
								this.state.profileImage.length === 1 ?

									this.state.profileImage.map(file => (
										<ListItem style={{ placeContent: 'center' }}>
											<ListItemIcon>
												{
													file.profileImageShow ?
														<img src={getImageAPI(file.profileImageShow)} onClick={() => this.handleDisplayImg(getImageAPI(file.profileImageShow))} style={{ maxHeight: '70px', maxWidth: '70px', width: '70px', height: '70px', objectFit: 'cover', cursor: 'pointer' }} />
														: <img src={URL.createObjectURL(file)} onClick={() => this.handleDisplayImg(window.URL.createObjectURL(file))} style={{ maxHeight: '70px', maxWidth: '70px', width: '70px', height: '70px', objectFit: 'cover', cursor: 'pointer' }} />
												}
											</ListItemIcon>
										</ListItem>
									))

									: <img src={profileIcon} style={{ maxHeight: '70px', maxWidth: '70px', width: '70px', height: '70px', objectFit: 'cover' }} />
							}
						</List>

					</div>
				</CardContent>
				<Divider />
				<CardActions style={{ justifyContent: 'center' }}>
					<Dropzone
						onDrop={this.profileImageOnDrop}
						accept='image/jpeg, image/png, image/png'
						minSize={1024}
						maxSize={3072000}
					>
						{({ getRootProps, getInputProps }) => (
							<div
								{...getRootProps({ className: 'dropzone', })}
							>
								<input {...getInputProps()} />

								<Button
									className={classes.uploadButton}
									color="primary"
									variant="text"
								>
									Upload picture
                				</Button>
							</div>
						)}
					</Dropzone>
					<Button variant="text" onClick={() => this.handleRemoveAll()} >Remove picture</Button>
				</CardActions>
				<div id="showImg" class="modal" style={{ zIndex: '99' }}>
					<span class="close" onClick={() => this.handleCloseDisplayImg()} >&times;</span>
					<img class="modal-content" id="imgContent" />
				</div>
			</Card>
		)
	}
}
export default
	compose(
		withStyles(useStyles), inject('alertStore', 'accountStore')
	)(observer(AccountProfile))
