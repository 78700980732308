import React, { Component } from 'react';
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { Grid, Button, Card, CardContent, CardHeader, colors, Divider, } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import FilterRender from '../../components/FilterRender/FilterRender.js'
import TableRender from '../../components/TableRender/TableRender'
import validate from 'validate.js';
import ServicesOption from '../../services/servicesOption'
import ServicesAddSalesList from '../../services/servicesAddSalesList'
import DialogAlert from '../../components/DialogAlert/DialogAlert'
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert'
import { utcTimeToFullDate } from '../../constants/function/formatConverter'
import { INPUT_RENDER, INPUT_RENDER2, TABLE_HEADER } from './AddSalesListStructure'
import { isExistData } from 'constants/function/checkDataValue';
import AdvertisementModal from '../../views/Dashboard/components/AdvertisementModal/AdvertisementModal'

const schema = {
	date: {
		presence: { allowEmpty: false, message: '^กรุณากรอกชื่อแคมเปญ' },
		length: {
			maximum: 225
		}
	},
	invoiceNo: {
		presence: { allowEmpty: false, message: '^กรุณากรอก invoiceNo' },
		length: {
			maximum: 225
		}
	},
	invoiceImage: {
		presence: { allowEmpty: false, message: '^กรุณาแนบไฟล์รูปใบเสร็จ invoice' },
		length: {
			maximum: 1,
			message: '^เลือกได้เพียง 1 รูปเท่านั้น'
		}
	},
};

const schema2 = {
	barcode: {
		presence: { allowEmpty: false, message: '^กรุณากรอก Barcode' },
		length: {
			maximum: 225
		}
	},
	qty: {
		presence: { allowEmpty: false, message: '^กรุณากรอกจำนวน' },
	},
};

const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});

class AddSalesList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			filterList: [],
			filterList2: [],
			openSetPointCampaign: false,
			formCheck: {
				isValid: false,
				touched: {},
				values: {},
				errors: {}
			},
			formCheck2: {
				isValid: false,
				touched: {},
				values: {
					qty: '1',
				},
				errors: {}
			},
			productList: [],
			alertMessage: {
				content: '',
				open: false,
				status: ''
			},
			notFoundBarcode: '',
			openAds: false,
		};
	}

	componentDidMount() {
		this.setState({ filterList: INPUT_RENDER, filterList2: INPUT_RENDER2 })
	}

	getProductByBarcode = (data) => {
		let filterList2 = [...this.state.filterList2]
		ServicesOption.getProductByBarcode(data).then(res => {
			switch (res.status) {
				case 200:
					let resGroup = res.data.result

					resGroup.map((val, i) => {
						filterList2[1].value = val.productName
					})

					this.setState({ filterList2: filterList2, notFoundBarcode: '' })

					break;
				default: filterList2[1].value = res.data.message
					this.setState({ filterList2: filterList2, notFoundBarcode: res.data.message })
			}
		})
	}

	reRenderTablecontent = () => {
		let TABLE_CONTENT = []
		// TABLE_HEADER.map((val, i) => {
		this.props.salesListstore.productPointList.map((value, index) => {
			TABLE_CONTENT.push(
				{
					rowNo: index + 1,
					barcode: value.barcode,
					qty: value.qty,
					action:
						<Button
							type="submit"
							variant="contained"
							onClick={() => this.handleDeleteProduct(index)}
							color="secondary"
						>
							ลบ
									</Button>
					,
				}
			)
		})

		this.setState({ productList: TABLE_CONTENT })

	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]
		let formCheck = this.state.formCheck

		filterList.map((val, i) => {
			if (val.name === name) {
				if (value === null) {
					// for uploadContent filter
					value = []
				}
				val.value = value
				formCheck.touched[`${val.name}`] = true
				formCheck.values[`${val.name}`] = value
			}
		})

		if (formCheck.touched[name]) {
			const errors = validate(formCheck.values, schema);
			formCheck = {
				...formCheck,
				isValid: errors ? false : true,
				errors: errors || {}
			}
		}


		this.setState({ filterList: filterList, formCheck: formCheck })
	}

	handleChangeFilter2 = (name, value) => {
		let filterList = [...this.state.filterList2]
		let formCheck = this.state.formCheck2

		filterList.map((val, i) => {
			if (val.name === name) {

				if (val.name === 'qty') {
					if (value === '') {
						formCheck.values[`${val.name}`] = value
						val.value = value
					} else {
						if (value < 1) {
							val.value = 1
							formCheck.values[`${val.name}`] = 1
						} else if (value >= 1) {
							val.value = value
							formCheck.values[`${val.name}`] = value
						}
					}
					formCheck.touched[`${val.name}`] = true
				} else {
					if (val.name === 'barcode') {
						if (value.length > 5) {
							this.getProductByBarcode(
								{
									barcode: value,
									userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
									roleId: JSON.parse(localStorage.getItem('userData')).roleId,
								}
							)
						} else if (value.length === 0) {
							filterList[1].option = []
						}
					}

					if (value === null) {
						// for uploadContent filter
						value = []
					}
					val.value = value
					formCheck.touched[`${val.name}`] = true
					formCheck.values[`${val.name}`] = value
				}
			}
		})

		if (formCheck.touched[name]) {
			const errors = validate(formCheck.values, schema2);
			formCheck = {
				...formCheck,
				isValid: errors ? false : true,
				errors: errors || {}
			}
		}
		this.setState({ filterList2: filterList, formCheck2: formCheck })
	}

	hasError2 = field => {
		return this.state.formCheck2.touched[field] && this.state.formCheck2.errors[field] ? true : false;
	}

	helperTextError2 = field => {
		return this.state.formCheck2.touched[field] && this.state.formCheck2.errors[field] ? this.state.formCheck2.errors[field][0] : null;
	}

	hasError = field => {
		return this.state.formCheck.touched[field] && this.state.formCheck.errors[field] ? true : false;
	}

	helperTextError = field => {
		return this.state.formCheck.touched[field] && this.state.formCheck.errors[field] ? this.state.formCheck.errors[field][0] : null;
	}

	handleBack = () => {
		this.props.history.push('/salesList')
	}

	checkFormIsValidOnSubmit = () => {
		let formCheck2 = this.state.formCheck2
		this.state.filterList2.map((val, i) => {
			if (val.value === '') {
				formCheck2.touched[`${val.name}`] = true
			}
			if (formCheck2.touched[val.name]) {
				const errors = validate(formCheck2.values, schema2);
				formCheck2 = {
					...formCheck2,
					isValid: errors ? false : true,
					errors: errors || {}
				}
			}
		})
		this.setState({ formCheck2: formCheck2 })
		return formCheck2.isValid
	}

	addProduct = () => {
		let isDuplicate = false
		let object = {}
		this.state.filterList2.map((val, i) => {
			object[`${val.name}`] = val.value
			this.state.productList.map((value, index) => {
				if (val.name === 'barcode') {
					if (value.barcode === val.value) {
						isDuplicate = true
					}
				}
			})
		})
		let isValid = this.checkFormIsValidOnSubmit()
		if (isValid) {
			if (!isDuplicate) {
				if (isExistData(this.state.filterList2[1].value) && this.state.notFoundBarcode !== this.state.filterList2[1].value) {
					this.props.salesListstore.productPointList.push(object)
					this.reRenderTablecontent()
					this.resetAddProduct()
				} else {
					this.setState({ alertMessage: callDialogAlert('warning', 'กรุณากรอก Barcode ให้ถูกต้อง') })
				}
			} else {
				this.setState({ alertMessage: callDialogAlert('warning', 'Barcode ซ้ำ') })
			}
		} else {
			this.setState({ alertMessage: callDialogAlert('warning', 'กรุณากรอกข้อมูลให้ถูกต้อง') })
		}
	}

	handleDeleteProduct = (index) => {
		this.props.salesListstore.productPointList.splice(index, 1)
		this.reRenderTablecontent()
	}

	handleSubmit = () => {
		let formCheck = this.state.formCheck

		let object = {}
		this.state.filterList.map((val, i) => {
			if (val.name === 'date') {
				if (val.value !== '') {
					object[`${val.name}`] = utcTimeToFullDate(val.value)
				} else {
					object[`${val.name}`] = utcTimeToFullDate(new Date())
				}
			} else {
				object[`${val.name}`] = val.value
			}

			formCheck.values[`${val.name}`] = object[`${val.name}`]
			formCheck.touched[`${val.name}`] = true

			if (formCheck.touched[val.name]) {
				const errors = validate(formCheck.values, schema);
				formCheck = {
					...formCheck,
					isValid: errors ? false : true,
					errors: errors || {}
				}
			}
		})


		this.setState({ formCheck })
		if (formCheck.isValid && this.props.salesListstore.productPointList.length > 0) {
			this.setState({ alertMessage: callDialogAlert('processing', '') })

			const data = new FormData();
			data.append('pcNo', JSON.parse(localStorage.getItem('userData')).pcNo)
			data.append('userLogin', JSON.parse(localStorage.getItem('userData')).pcNo)
			data.append('roleId', JSON.parse(localStorage.getItem('userData')).roleId)
			data.append('groupNo', JSON.parse(localStorage.getItem('userData')).groupNo)
			data.append('shopNo', JSON.parse(localStorage.getItem('userData')).shopNo)
			data.append('invoiceNo', object.invoiceNo)
			data.append('invoiceImage', object.invoiceImage[0])
			data.append('purchaseDate', object.date)

			this.props.salesListstore.productPointList.map((val, i) => {
				data.append(`barcode`, val.barcode)
				data.append(`qty`, val.qty)
			})

			ServicesAddSalesList.saveSell(data).then(res => {
				if (res.status == '200') {
					this.setState({ alertMessage: callDialogAlert('info', res.data.result[0].header + ' ' + res.data.result[0].detail) })
					this.resetFilter()
				} else {
					this.setState({ alertMessage: callDialogAlert('error', res.data.message) })
				}
			})

		} else if (this.props.salesListstore.productPointList.length === 0) {
			this.setState({ alertMessage: callDialogAlert('warning', 'กรุณากรอกสินค้า') })
		}
	}

	handleDisplayImg = (src) => {
		let modal = document.getElementById("showImg");
		let modalImg = document.getElementById("imgContent");
		modal.style.display = "block";
		modalImg.src = src;
	}

	handleCloseDisplayImg = () => {
		let modal = document.getElementById("showImg");
		modal.style.display = "none";
	}

	handleRemoveAll = (fieldUploadName) => {

		let filterList = [...this.state.filterList]
		let formCheck = this.state.formCheck

		filterList.map((val, i) => {
			if (val.name === fieldUploadName) {
				val.value = []
				formCheck.touched[`${val.name}`] = true
				formCheck.values[`${val.name}`] = []
			}
		})

		if (formCheck.touched[fieldUploadName]) {
			const errors = validate(formCheck.values, schema);
			formCheck = {
				...formCheck,
				isValid: errors ? false : true,
				errors: errors || {}
			}
		}
		this.setState({ filterList: filterList, formCheck: formCheck })
	}

	resetAddProduct = () => {
		let filterList2 = this.state.filterList2
		filterList2.map((val, i) => {
			val.value = val.name === 'qty' ? '1' : ''
		})

		this.setState({
			filterList2: filterList2,
			formCheck2: {
				isValid: false,
				touched: {},
				values: {
					qty: '1'
				},
				errors: {}
			},
		})
	}

	resetFilter = () => {
		let filterList = this.state.filterList
		let filterList2 = this.state.filterList2

		filterList.map((val, i) => {
			val.value = ''
		})

		filterList2.map((val, i) => {
			val.value = val.name === 'qty' ? '1' : ''
		})

		this.props.salesListstore.productPointList = []

		this.setState({
			filterList: filterList,
			filterList2: filterList2,
			productList: [],
			formCheck: {
				isValid: false,
				touched: {},
				values: {},
				errors: {}
			},
			formCheck2: {
				isValid: false,
				touched: {},
				values: {
					qty: '1'
				},
				errors: {}
			},
		})
	}

	closeAlert = () => {
		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
		})
	}

	handleCloseAdv = () => {
		this.setState({ openAds: false })
	}

	handleOpenAdv = () => {
		this.setState({ openAds: true })
	}

	render() {
		const { classes } = this.props;
		const { filterList, filterList2, productList, alertMessage } = this.state;
		return (
			<React.Fragment>
				<div className={classes.root}>
					<AdvertisementModal open={this.state.openAds} close={this.handleCloseAdv} />
					<Card>
						<CardHeader
							title={
								"บันทึกการขาย"
							}
							action={
								<Button
									color="primary"
									// disabled={!formState.isValid}
									// fullWidth
									type="submit"
									variant="contained"
									onClick={() => this.handleOpenAdv()}
								>
									ดูแคมเปญ
								</Button>
							}
						/>
						<Divider />
						<CardContent >
							<Grid
								container
								spacing={4}
							>
								<Grid
									item
									lg={12}
									sm={12}
									xl={12}
									xs={12}
								>
									<Grid container spacing={2}>
										<Grid item lg={12} sm={12} xl={12} xs={12}>
											<FilterRender
												filterList={filterList}
												handleChangeFilter={this.handleChangeFilter}
												hasError={this.hasError}
												helperTextError={this.helperTextError}
												handleDisplayImg={this.handleDisplayImg}
												handleRemoveAll={this.handleRemoveAll}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					&nbsp;
				<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<Card>
								<CardHeader
									// action={
									// 	<Button>ddddd</Button>
									// }
									title={
										"เพิ่มสินค้า"
									}
								/>
								<Divider />
								<CardContent >
									<Grid
										container
										spacing={4}
									>
										<FilterRender
											filterList={filterList2}
											handleChangeFilter={this.handleChangeFilter2}
											hasError={this.hasError2}
											helperTextError={this.helperTextError2}
										// handleDisplayImg={this.handleDisplayImg}
										// handleRemoveAll={this.handleRemoveAll}
										/>
										<Grid
											item
											lg={6}
											sm={6}
											xl={6}
											xs={12}
											style={{ textAlignLast: 'right' }}
										>
											<Button
												color="primary"
												// disabled={!formState.isValid}
												// fullWidth
												type="submit"
												variant="contained"
												onClick={() => this.addProduct()}
											>
												เพิ่ม
										</Button>
										</Grid>
									</Grid>
									&nbsp;
									<TableRender
										TABLE_HEADER={TABLE_HEADER}
										TABLE_CONTENT={productList}
									/>
								</CardContent>
								<Divider />
							</Card>
							&nbsp;
							<Grid
								item
								lg={12}
								sm={12}
								xl={12}
								xs={12}
								style={{ textAlign: 'center' }}
							>
								<Button color="default" variant="contained" onClick={() => this.resetFilter()}>ล้างข้อมูล</Button> &nbsp;
							<Button style={{ backgroundColor: colors.green[600], color: 'white' }} onClick={() => this.handleSubmit()} >บันทึก</Button>
							</Grid>
						</Grid>
					</Grid>
					<DialogAlert data={alertMessage} close={this.closeAlert} />
					<div id="showImg" class="modal" style={{ zIndex: '99' }}>
						<span class="close" onClick={() => this.handleCloseDisplayImg()} >&times;</span>
						<img class="modal-content" id="imgContent" />
					</div>
				</div>
			</React.Fragment >
		);
	}
}


export default compose(
	withStyles(useStyles),
	inject('salesListstore', 'alertStore')
)(observer(withRouter(AddSalesList)))