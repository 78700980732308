import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesLogin {
	login(requestData) {
		return sendRequest("/auth/login", HTTP.POST, requestData)
	}

	loginPC(requestData) {
		return sendRequest("/auth/loginPC", HTTP.POST, requestData)
	}

	changePassword(requestData) {
		return sendRequest("/pc/changePassword", HTTP.POST, requestData)
	}

	checkingUser(requestData) {
		return sendRequest("/auth/checkingUser", HTTP.POST, requestData)
	}

}

export default new ServicesLogin()