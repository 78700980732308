import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesLoginManagement {
	actionLogin(requestData) {
		return sendRequest("/system/actionLogin", HTTP.POST, requestData)
	}

	onGetAllLogin(requestData) {
		return sendRequest("/system/get-all-login", HTTP.POST, requestData)
	}


	onAddLoginPage(requestData) {
		return sendRequest("/system/add-login-page", HTTP.POST, requestData)
	}

	onEditLoginPage(requestData) {
		return sendRequest("/system/edit-login-page", HTTP.POST, requestData)
	}

	
}

export default new ServicesLoginManagement()