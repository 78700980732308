import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import { VERSION } from '../../../../services/sendRequestAPI';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
    // position: 'fixed',
    // left: 0,
    // bottom: 0,
    // width: '100%'
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{ textAlign: 'center' }}>
      {/* <Typography variant="body1">
				&copy;{' '}
				<Link
					component="a"
					href="https://devias.io/"
					target="_blank"
				>
					Devias IO
        </Link>
        . 2019
      </Typography> */}

      <Typography variant="caption">
        Copyright © 2022 Dplus Intertrade. All rights reserved
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
