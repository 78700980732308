import { observable, computed, action, decorate } from "mobx"

class loginStore {
// class loginStore {

	// rootStore: rootStore;
	// constructor(rootStore: rootStore) {
	// 	this.rootStore = rootStore
	// }

    userData = {
        userName: 'user1',
        userRole: 'บัญชี1',
        token: 'iwhdiahdiawihdwad',
	}

	pcGroupLogin = {
		name: '',
		value: 0,
	}
	
	userAuthen = {}

	registerSuccess = false

	loginSuccess = false

    getUserData() {
        return this.userData;
    }

    setUserData(data) {
        for( let key in data) {
        }
	}
	
	setUserAuthen(data) {
        this.userAuthen = data;
	}
	
	getUserAuthen() {
        return this.userAuthen;
    }
}
decorate(loginStore, {
    userData: observable,
	userAuthen: observable,
	registerSuccess: observable,
	loginSuccess: observable,
	pcGroupLogin: observable,

	// userData: compose(
	// 	observable,
	// 	persist("object")
	//   ),
	// userAuthen: [persist('object'), observable],
	// userData:  observable,
    // userAuthen: observable,
})

export default new loginStore()

