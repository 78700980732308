import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

class TableRender extends Component {
  state = {
    page: 0,
    rowsPerPage: 10
  };

  renderTableContent = content => {
    let td = Object.keys(content);
    let th = this.props.TABLE_HEADER;
    return td.map((val, i) => {
      return th.map((val2, i2) => {
        if (val2.key === val) {
          return (
            <TableCell
              className="pointer"
              style={{ textAlign: val2.textAlign || 'center' }}
              width={val2.width || ''}
              key={i}>
              {content[val]}
            </TableCell>
          );
        }
      });
    });
  };

  handlePageChange = (event, page) => {
    this.setState({ page: page });
  };

  handleRowsPerPageChange = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { TABLE_HEADER, TABLE_CONTENT } = this.props;
    const { rowsPerPage, page } = this.state;
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <div>
            <Table sx={{ minWidth: 650 }} size="small" >
              <TableHead>
                <TableRow>
                  {TABLE_HEADER.map((val, i) => {
                    return (
                      <TableCell
                        style={{ textAlign: 'center',fontWeight: 'bold' }}
                        key={i}
                        width={val.width}>
                        {val.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody >
                {TABLE_CONTENT.length > 0 &&
                  TABLE_CONTENT.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((val, i) => {
                    return (
                      <TableRow hover key={i}>
                        {this.renderTableContent(val)}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
        <div>
          <TablePagination
            component="div"
            count={TABLE_CONTENT.length}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default TableRender;
