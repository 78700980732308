import sendRequest from './sendRequestAPI';
import { HTTP } from '../constants/enum';

class ServicesReport {
  allReport(requestData) {
    return sendRequest('/report/allReport', HTTP.POST, requestData);
  }

  getReportSales(requestData) {
    return sendRequest('/report/getReportSales', HTTP.POST, requestData);
  }

  getBadgePC(requestData) {
    return sendRequest(`/report/getBadge/${requestData}/PC`, HTTP.GET);
  }

  getBadgeSale(requestData) {
    return sendRequest(`/report/getBadge/${requestData}/SALE`, HTTP.GET);
  }
  getIncentiveByCampaign(requestData) {
    return sendRequest(
      '/report/getIncentiveByCampaign',
      HTTP.POST,
      requestData
    );
  }
}

export default new ServicesReport();
