import React, { useState } from 'react';
import styled from 'styled-components';
import {
	Button
} from '@material-ui/core';
import { colors } from '@material-ui/core';

export const ButtonRender = ({ type, text, icon, onClick }) => {
	return (
		renderButtonMain(type, text, icon, onClick)
	)
}

const renderButtonMain = (type, text, icon, onClick) => {
	switch (type) {
		case 'primary':
		case 'secondary':
		case 'default':
			return (
				<Button
					color={type}
					// disabled={!formState.isValid}
					// fullWidth
					variant="contained"
					onClick={onClick}
				>
					{!!icon ? icon: '' }&nbsp;{text}
				</Button>
			)
		default: return (
			<ButtonStyle
				typeName={type}
				variant="contained"
				onClick={onClick}
			>
				{!!icon ? icon: '' }&nbsp;{text}
			</ButtonStyle>
		)
	}
}

/* type of button
	primary,
	secondary,
	success,
	warning --> darkOrange,
	danger,
	excel,
	pdf --> light of darkOrange
*/
function renderColorBg(type) {
	switch (type) {
		case 'success':
		case 'excel':
			return colors.green[400]
		case 'warning':
		case 'pdf':
			return colors.deepOrange[400]
		case 'danger':
			return colors.red[400]
		case 'sms':
			return colors.blue[400]
	}
}

export const ButtonStyle = styled(Button)`
	background-color: ${props => renderColorBg(props.typeName)};
	color: white;
`;
