import axios from 'axios';
import { HTTP } from '../constants/enum'
// import { HTTP } from '../constants/enum';
// import { isExistData } from '../constants/function/checkDataValue';

// const API_SERVER = 'http://localhost:2100/api/v1';
//const API_SERVER = 'https://dplus-system.com:3100/api/v1';
const API_SERVER = 'https://incentive.dplus-groups.com:2096/api/v1';

//const API_SERVER = 'https://04bc-125-26-15-203.ap.ngrok.io/api/v1';

export const SERVER = API_SERVER;
export const VERSION = 'VERSION 1.5.2';

export default function sendRequest(url, method, requestData) {
  let requestObject = {
    // headers: {token : isExistData(JSON.parse(localStorage.getItem('userData'))) ? JSON.parse(localStorage.getItem('userData')).token : '' },
    method: method,
    url: `${API_SERVER}${url}`
  };
  // if (method != HTTP.GET) {
  // 	requestObject.data = requestData
  // }
  if (requestData !== undefined) {
    requestObject.data = requestData;
  }

  return axios(requestObject)
    .then(
      res => {
        const { data, status } = res;
        return { data, status };
      },
      error => {
        const { data, status, statusText } = error.response;
        return { data, status, statusText };
      }
    )
    .catch(error => {
      throw error;
      // return error
    });
}



export const POST = (url, data) => {
  return axios({
    method: HTTP.POST,
    url: `${API_SERVER}${url}`,
    data
  })
    .then(
      res => {
        const { data, status } = res;
        return { data, status };
      },
      error => {
        const { data, status, statusText } = error.response;
        return { data, status, statusText };
      }
    )
    .catch(error => {
      throw error;
    });
}

export const GET = (url, params) => {
  return axios({
    method: HTTP.GET,
    url: `${API_SERVER}${url}`,
    params
  })
    .then(
      res => {
        const { data, status } = res;
        return { data, status };
      },
      error => {
        const { data, status, statusText } = error.response;
        return { data, status, statusText };
      }
    )
    .catch(error => {
      throw error;
    });
}

