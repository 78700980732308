import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import {
	Grid, Button, Typography, colors,
	Divider, DialogContent, DialogActions, RadioGroup, FormControlLabel, Radio, FormLabel
} from '@material-ui/core';
import FilterRender from '../../../../components/FilterRender/FilterRender'
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogAlert from '../../../../components/DialogAlert/DialogAlert'
import { callDialogAlert } from '../../../../components/DialogAlert/DialogAlert'
import TableRender from '../../../../components/TableRender/TableRender'
import ServicesPendingApprovalToAccount from '../../../../services/servicesPendingApprovalToAccount'
import { currencyFormat } from '../../../../constants/function/formatConverter'
import { TABLE_HEADER_MODAl, INPUT_RENDER_MODAL } from '../../PendingApprovalToAccountStructure'
import { isExistData } from 'constants/function/checkDataValue';
import ConfirmPendingApprovalToAccount from './ConfirmPendingApprovalToAccount';
import { async } from 'validate.js';


const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	button: {
		color: theme.palette.success,
	},
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

class PendingApprovalToAccountAction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterList: [],
			formState: {
				isValid: false,
				values: {},
				touched: {},
				errors: {},
				items: [],
			},
			alertMessage: {
				content: '',
				open: false,
				status: ''
			},
			TABLE_CONTENT: [],
			approvalId: 0,
			openConfirm: false,
			docNo: ''
		};
	}

	componentDidMount() {
		this.setState({ filterList: INPUT_RENDER_MODAL })
	}

	componentDidUpdate() {
	}

	componentWillReceiveProps(){
		const docNo = this.props.pendingApprovalToAccountStore.docNo
		this.getDetail({ docNo })
	}



	getDetail = async (obj)  => {

		if(!obj.docNo) return

		this.setState({ alertMessage: callDialogAlert('loading') })
		this.setState({ filterList: INPUT_RENDER_MODAL, TABLE_CONTENT: [] })

		
		const filterList = this.state.filterList
		const header = await ServicesPendingApprovalToAccount.getWaitApproval(obj)
		const resData = header.data.result[0]

		if(!resData) return
		const resKey = Object.keys(resData)
		resKey.map((key) => {
			filterList.map((key2) => {
				if (key === key2.name) {
					key2.value = resData[key]
				}
			})
		})

		const line = await ServicesPendingApprovalToAccount.getWaitApprovalDetail(obj)
		const data = line.data.result.map((value, index) => {
							return {
									rowNo: index + 1,
									docNo: value.docNo,
									pcName: value.pcName,
									qty: value.qty,
									amount: currencyFormat(value.amount),
									createDate: value.createDate
								}
							
						})


		this.setState({ 
			filterList: filterList, 
			TABLE_CONTENT: data,
			alertMessage: {...this.state.alertMessage.open = false}
		})


	}

	toApproval = (data) => {
		ServicesPendingApprovalToAccount.toApproval(data).then(res => {
			this.setState({ alertMessage: callDialogAlert('loading') })
			if(res.status === 200){
				this.setState({ alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ') })
			}else{
				this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}

		})
	}

	handleChangeRadioBox = (event) => {
		this.setState({ approvalId: event.target.value })
	}


	handleClose = () => {
		let filterList = this.state.filterList

		filterList.map((val, i) => {
			val.value = ''
		})

		this.setState({ filterList: filterList, approvalId: 0, TABLE_CONTENT: [] })
		this.props.close();
	};

	handleOpenConfirm = () => {
		if (this.state.approvalId !== 0) {
			this.setState({ openConfirm: true })
		} else {
			this.setState({ alertMessage: callDialogAlert('warning', 'กรุณาเลือกสถานะการอนุมัติ') })
		}
	}

	handleCloseConfirm = () => {
		this.setState({ openConfirm: false })
	}

	closeAlert = () => {
		if (this.state.alertMessage.status === 'success') {
			this.handleClose()
			this.props.searchSubmit()
		}

		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
		})
	}

	handleApprovement = () => {
		this.toApproval({
			userLogin: JSON.parse(localStorage.getItem('userData')).userId,
			roleId: JSON.parse(localStorage.getItem('userData')).roleId,
			docNo: this.props.pendingApprovalToAccountStore.idModal,
			docStatus: this.state.approvalId
		})
	}


	render() {
		const { filterList, alertMessage, TABLE_CONTENT } = this.state;

		return (
			<Dialog open={this.props.open}
				maxWidth={'md'}
				fullWidth
			>
				<DialogTitle id="customized-dialog-title" onClose={() => this.handleClose()}>
					รายละเอียด
        		</DialogTitle>
				<React.Fragment>
					<DialogContent dividers>
						<FilterRender
							filterList={filterList}
						/>
						<TableRender
							TABLE_HEADER={TABLE_HEADER_MODAl}
							TABLE_CONTENT={TABLE_CONTENT}
						/>
						<Divider />
						{
							
								<Grid container spacing={4} style={{ justifyContent: 'center', marginTop: '20px' }}>
									<RadioGroup row name="approval" onChange={this.handleChangeRadioBox} style={{ justifyContent: 'center', textAlign: 'center' }}>
										<FormLabel component="legend">บันทึกรายการตั้งเบิก EP</FormLabel>
										<FormControlLabel
											value="WAIT_SEND_EP"
											control={<Radio />}
											label="อนุมัติ"
										/>
								&nbsp;
								<FormControlLabel
											value="DISAPPROVED"
											control={<Radio />}
											label="ไม่อนุมัติ"

										/>
									</RadioGroup>
								</Grid>
								
						}

						<div style={{ paddingBottom: '40px' }}></div>
					</DialogContent>
					{
						// this.props.statusRow === '3' ?
							<DialogActions>
								<Button
									variant="contained"
									style={{ backgroundColor: colors.green[600], color: 'white' }}
									onClick={() => this.handleOpenConfirm()}>
									ยืนยัน
            					</Button>
								<Button color="default" variant="contained" onClick={() => this.handleClose()}>
									ยกเลิก
          						</Button>
							</DialogActions>
							// : ''
					}
				</React.Fragment>
				<ConfirmPendingApprovalToAccount handleSubmit={this.handleApprovement} open={this.state.openConfirm} close={this.handleCloseConfirm} />
				<DialogAlert data={alertMessage} close={this.closeAlert} />
			</Dialog>
		);
	}
}

export default
	inject('pendingApprovalToAccountStore', 'alertStore')
		(observer(PendingApprovalToAccountAction))