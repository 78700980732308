import React, { Component } from 'react';
import validate, { async } from 'validate.js';
import DialogAlert from '../../../../components/DialogAlert/DialogAlert';
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Grid
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FilterRender from '../../../../components/FilterRender/FilterRender';
import { inject, observer } from 'mobx-react';
import ServicesLoginManagement from '../../../../services/servicesLoginManagement';
import { INPUT_RENDER_MODAL } from '../../LoginManagementStructure';
import { OPTION_RENDER } from '../../../../components/OptionRender/OptionRender';
import { callDialogAlert } from '../../../../components/DialogAlert/DialogAlert';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  button: {
    color: theme.palette.success
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const schema = {
  pageName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  groupNo: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  logoImage: {
    presence: { allowEmpty: false, message: '^กรุณาแนบไฟล์รูปโลโก้' },
    length: {
      maximum: 1,
      message: '^เลือกได้เพียง 1 รูปเท่านั้น'
    }
  }
};

class LoginManagementAction extends Component {
  state = {
    filterList: [],
    formCheck: {
      isValid: false,
      touched: {},
      values: {
        groupNo: '',
        pageName: '',
        logoImage: []
      },
      errors: {}
    },
    alertMessage: {
      content: '',
      open: false,
      status: ''
    },
    idModal: -1,
    titleModal: ''
  };

  componentDidMount() {
    this.setState({ filterList: INPUT_RENDER_MODAL }, () => {
      let filterList = this.state.filterList;
      filterList[2].option = OPTION_RENDER('userType', false);
      filterList[3].option = OPTION_RENDER('group', false);
      this.setState({ filterList });
    });
  }

  componentDidUpdate() {
    
  }

  componentWillReceiveProps(){
   this.getAllLogin()
	}

  getAllLogin() {
    if (
      this.state.idModal === -1 &&
      this.props.loginManagementStore.loginUserId !== -1 &&
      this.props.loginManagementStore.loginUserId !== '' &&
      this.props.loginManagementStore.loginUserId !== undefined
    ) {

      ServicesLoginManagement.onGetAllLogin({
        pageId: this.props.loginManagementStore.loginUserId,
        userLogin: JSON.parse(localStorage.getItem('userData')).userId,
        roleId: JSON.parse(localStorage.getItem('userData')).roleId
      }).then(res => {
        switch (res.status) {
          case 200:
            let formCheck = this.state.formCheck;
            let resData = res.data.result[0];
            let resKey = Object.keys(resData);
            let filterList = this.state.filterList;
            resKey.map((key) => {
              filterList.map((val) => {
                if (val.name === key) {
                  if (resData[key] !== '' && resData[key] !== null) {
                    formCheck.isValid = true;
                    if (key === 'logoImage') {
                      val.value = [];
                      val.value.push({
                        showImg: resData[key],
                        name: resData[key].substring(
                          resData[key].lastIndexOf('/') + 1
                        )
                      });
                    }else {
                      val.value = resData[key];
                    }
                  } else if (resData[key] === '' || resData[key] === null) {
                    formCheck.isValid = false;
                    if (key === 'logoImage') {
                      val.value = [];
                    } else {
                      val.value = resData[key];
                    }
                  }
                  formCheck.values[key] = val.value;
                  formCheck.touched[key] = true;
                }
              });
            });
            this.setState({
              formCheck,
              filterList,
              alertMessage: callDialogAlert('', '')
            });
            break;
          case 204:
            // todo ไม่พบข้อมูล
            break;
          default:
            this.setState({
              alertMessage: callDialogAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              )
            });
        }
      });

      this.setState({
        idModal: this.props.loginManagementStore.loginUserId,
        titleModal: 'แก้ไข'
      });
    } else if (
      this.state.idModal === -1 &&
      (this.props.loginManagementStore.loginUserId === '' ||
        this.props.loginManagementStore.loginUserId === undefined)
    ) {
      let filterList = this.state.filterList;

      this.setState({ idModal: '', titleModal: 'เพิ่ม', filterList });
    }
  }

  handleChangeFilter = (name, value) => {
    let filterList = [...this.state.filterList];
    let formCheck = this.state.formCheck;

    filterList.map((val, i) => {
      if (val.name === name) {
        if (value === null) {
          value = [];
        }

        val.value = value;
        formCheck.touched[`${val.name}`] = true;
        formCheck.values[`${val.name}`] = value;
      }
    });

    if (formCheck.touched[name]) {
      const errors = validate(formCheck.values, schema);
      formCheck = {
        ...formCheck,
        isValid: errors ? false : true,
        errors: errors || {}
      };
    }

    this.setState({ filterList: filterList, formCheck: formCheck });
  };

  handleSubmit = async () => {
   
    this.setState({ alertMessage: callDialogAlert('processing', '') });
    const userLogin = JSON.parse(localStorage.getItem('userData')).userId
    const roleId = JSON.parse(localStorage.getItem('userData')).roleId
    let filterList = [...this.state.filterList];
    // let formCheck = this.state.formCheck;

    let data = new FormData();
    data.append('userLogin', userLogin);
    data.append('roleId', roleId);

    if(filterList){
        filterList.map((val) => {
          if (val.name === 'logoImage') {
            // if (!val.value[0]?.showImg) {
              data.append(val.name, val.value[0]);
            // }
          } else {
            data.append(`${val.name}`, val.value);
          }
        });
   
        const page = filterList.find( e => e.name === 'pageId')
     
        if(page.value){
          const res = await ServicesLoginManagement.onEditLoginPage(data)
          const { success, message } = res.data
          const alertType = success ? 'success' : 'error'
          this.setState({ alertMessage: callDialogAlert(alertType, message)});

        }else{
          const res = await ServicesLoginManagement.onAddLoginPage(data)
          const { success, message } = res.data
          const alertType = success ? 'success' : 'error'
          this.setState({ alertMessage: callDialogAlert(alertType, message)});
          
        }

        this.getAllLogin()
    }
  

    // if (formCheck.isValid) {
    //   if (this.state.idModal === '') {
    //     //insert
    //     this.setState({ alertMessage: callDialogAlert('processing', '') });

    //     // const data = new FormData();
    
    //     this.state.filterList.map((val) => {
    //       if (val.name === 'logoImage') {
    //         if (!val.value[0].showImg) {
    //           data.append(`${val.name}`, val.value[0]);
    //         }
    //       } else {
    //         data.append(`${val.name}`, val.value);
    //       }
    //     });

    //     data.append('action', 'save');
    //     data.append(
    //       'userLogin',
    //       JSON.parse(localStorage.getItem('userData')).userId
    //     );
    //     data.append(
    //       'roleId',
    //       JSON.parse(localStorage.getItem('userData')).roleId
    //     );

    

    //     ServicesLoginManagement.actionLogin(data).then(res => {
    //       switch (res.status) {
    //         case 200:
    //           //todo กลับไปหน้าหลัก campaign
    //           // reload ตารางหน้าหลัก campaign
    //           this.setState({
    //             alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ')
    //           });
    //           break;
    //         default:
    //           // this.openAlert('error', )
    //           this.setState({
    //             alertMessage: callDialogAlert('error', res.data.message)
    //           });
    //       }
    //     });
    //   } else {
    //     //update
    //     alert(2)
    //     this.setState({ alertMessage: callDialogAlert('processing', '') });
    //     const data = new FormData();
    //     this.state.filterList.map((val) => {
    //       if (val.name !== 'groupNo') {
    //         if (val.name === 'logoImage') {
    //           if (!val.value[0].showImg) {
    //             data.append(`${val.name}`, val.value[0]);
    //           }
    //         } else {
    //           data.append(`${val.name}`, val.value);
    //         }
    //       }
    //     });

    //     data.append('action', 'update');
    //     data.append(
    //       'userLogin',
    //       JSON.parse(localStorage.getItem('userData')).userId
    //     );
    //     data.append(
    //       'roleId',
    //       JSON.parse(localStorage.getItem('userData')).roleId
    //     );
    //     data.append('pageId', this.state.idModal);

    //     ServicesLoginManagement.actionLogin(data).then(res => {
    //       switch (res.status) {
    //         case 200:
    //           this.setState({
    //             alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ')
    //           });
    //           break;
    //         default:
    //           this.setState({
    //             alertMessage: callDialogAlert('error', res.data.message)
    //           });
    //       }
    //     });
    //   }
    // } else {
    //   filterList.map((val, i) => {
    //     if (val.value === '' || val.value.length === 0) {
    //       formCheck.touched[`${val.name}`] = true;
    //     }

    //     if (formCheck.touched[val.name]) {
    //       const errors = validate(formCheck.values, schema);
    //       formCheck = {
    //         ...formCheck,
    //         isValid: errors ? false : true,
    //         errors: errors || {}
    //       };
    //     }
    //   });

    //   this.setState({ formCheck: formCheck });
    // }
  };

  hasError = field => {
    return this.state.formCheck.touched[field] &&
      this.state.formCheck.errors[field]
      ? true
      : false;
  };

  helperTextError = field => {
    return this.state.formCheck.touched[field] &&
      this.state.formCheck.errors[field]
      ? this.state.formCheck.errors[field][0]
      : null;
  };

  handleCloseModal = () => {
    this.props.close();
    this.props.loginManagementStore.loginUserId = -1;

    let filterList = this.state.filterList;

    filterList.map((val, i) => {
      val.value = '';
    });

    this.setState({
      formCheck: {
        isValid: false,
        touched: {},
        values: {},
        errors: {}
      },
      filterList,
      idModal: -1,
      titleModal: ''
    });
  };

  handleRemoveAll = fieldUploadName => {
    this.setState({ [fieldUploadName]: [] });

    let filterList = [...this.state.filterList];
    let formCheck = this.state.formCheck;

    filterList.map((val, i) => {
      if (val.name === fieldUploadName) {
        val.value = [];
        formCheck.touched[`${val.name}`] = true;
        formCheck.values[`${val.name}`] = [];
      }
    });

    if (formCheck.touched[fieldUploadName]) {
      const errors = validate(formCheck.values, schema);
      formCheck = {
        ...formCheck,
        isValid: errors ? false : true,
        errors: errors || {}
      };
    }

    this.setState({ filterList: filterList, formCheck: formCheck });
  };

  handleDisplayImg = src => {
    let modal = document.getElementById('showImg');
    let modalImg = document.getElementById('imgContent');
    modal.style.display = 'block';
    modalImg.src = src;
  };

  handleCloseDisplayImg = () => {
    let modal = document.getElementById('showImg');
    modal.style.display = 'none';
  };

  closeAlert = () => {
    if (this.state.alertMessage.status === 'success') {
      this.handleCloseModal();
    }

    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      }
    });
  };

  render() {
    const { filterList, formCheck, alertMessage } = this.state;

    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        fullWidth={true}>
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => this.handleCloseModal()}>
          {this.state.titleModal}
        </DialogTitle>
        <DialogContent dividers style={{ height: '500px' }}>
          <Grid container spacing={4}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <FilterRender
                filterList={filterList}
                handleChangeFilter={this.handleChangeFilter}
                hasError={this.hasError}
                // helperTextError={this.helperTextError}
                handleDisplayImg={this.handleDisplayImg}
                handleRemoveAll={this.handleRemoveAll}
              />
            </Grid>
          </Grid>
          &nbsp;
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => this.handleSubmit(e)}>
            บันทึก
          </Button>
          <Button
            color="default"
            variant="contained"
            onClick={() => this.handleCloseModal()}>
            ยกเลิก
          </Button>
        </DialogActions>
        <DialogAlert data={alertMessage} close={this.closeAlert} />
        <div id="showImg" class="modal" style={{ zIndex: '99' }}>
          <span class="close" onClick={() => this.handleCloseDisplayImg()}>
            &times;
          </span>
          <img class="modal-content" id="imgContent" />
        </div>
      </Dialog>
    );
  }
}

export default inject('loginManagementStore')(observer(LoginManagementAction));
