import React, { Component } from 'react';
import { ApprovalSaleListFilter, ApprovalSaleListTable } from './components'
import compose from 'recompose/compose'
import { inject, observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});

class ApprovalSaleList extends Component {
	state = {  }
	render() { 
		const { classes } = this.props;

		return ( 
			<React.Fragment>
				<div className={classes.root}>
					<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<ApprovalSaleListFilter />
						</Grid>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<ApprovalSaleListTable />
						</Grid>
					</Grid>
				</div>
			</React.Fragment>
		 );
	}
}

export default compose(
	withStyles(useStyles),
	inject('alertStore')
)(observer(ApprovalSaleList))