import React, { Component } from 'react';
import {
  colors,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  DialogTitle
} from '@material-ui/core';
import ServicesCampaign from '../../services/servicesCampaign';
import { withStyles } from '@material-ui/core/styles';
import DialogAlert from '../../components/DialogAlert/DialogAlert';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';

const useStyles = theme => ({
  button: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
});

class ConfirmDeleteCampaign extends Component {
  state = {
    alertMessage: {
      content: '',
      open: false,
      status: ''
    }
  };

  handleCloseModal = () => {
    this.props.close();
  };

  handleSubmit = () => {
    const data = new FormData();
    data.append('action', 'delete');
    data.append(
      'userLogin',
      JSON.parse(localStorage.getItem('userData')).userId
    );
    data.append('roleId', JSON.parse(localStorage.getItem('userData')).roleId);
    data.append('campaignId', this.props.open);

    ServicesCampaign.actionCampaign(data).then(res => {
      switch (res.status) {
        case 200:
          //todo กลับไปหน้าหลัก campaign
          // reload ตารางหน้าหลัก campaign
          this.setState({
            alertMessage: callDialogAlert('success', 'ลบสำเร็จ')
          });

          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  closeAlert = () => {
    if (this.state.alertMessage.status === 'success') {
      this.handleCloseModal();
    }
    this.setState({
      alertMessage: {
        // header: '',
        content: '',
        open: false,
        status: ''
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { alertMessage } = this.state;

    return (
      <React.Fragment>
        <Dialog open={this.props.open} maxWidth="md">
          <DialogTitle onClose={() => this.handleCloseModal()}>
            <Typography variant="h4">
              ยืนยันการยกเลิกการใช้งานแคมเปญ
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            &nbsp;
            <Typography variant="h5">
              ต้องการยกเลิกการใช้งานแคมเปญนี้ใช่หรือไม่
            </Typography>
            &nbsp; &nbsp;
          </DialogContent>
          <DialogActions>
            <Button
              style={{ backgroundColor: colors.red[600], color: 'white' }}
              variant="contained"
              onClick={() => this.handleSubmit()}>
              ยกเลิกการใช้งาน
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={() => this.handleCloseModal()}>
              ยกเลิก
            </Button>
          </DialogActions>
          <DialogAlert data={alertMessage} close={this.closeAlert} />
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(ConfirmDeleteCampaign);
