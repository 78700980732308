import React, { Component } from 'react';

import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import DataTable from './DataTable';
import { SalesHistoryBar } from './components';
import {
  getThaiYearOption,
  getCurrentYearFormat
} from '../../constants/function/formatConverter';
import servicesSalesHistory from '../../services/servicesSalesHistory';
import { SelectStyle } from './styled-components';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

class SalesHistory extends Component {
  state = {
    historySales: [],
    totalHistorySales: {},
    optionYear: [],
    selectedYear: '',
    alertMessage: {
      content: '',
      open: false,
      status: ''
    }
  };
  componentDidMount() {
    let year = getThaiYearOption(3);
    this.setState(
      { optionYear: year, selectedYear: getCurrentYearFormat() },
      () => {
        servicesSalesHistory
          .getHistorySales({
            pcNo: JSON.parse(localStorage.getItem('userData')).pcNo,
            year: this.state.selectedYear
          })
          .then(res => {
            switch (res.status) {
              case 200:
                this.setState({
                  historySales: res.data.result.data,
                  totalHistorySales: res.data.result.footer
                });
                break;
              default:
                this.setState({ historySales: [], totalHistorySales: {} });
                this.setState({
                  alertMessage: callDialogAlert(
                    'error',
                    'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
                  )
                });
            }
          });
      }
    );
  }
  handleChangeSelect = value => {
    let dataQty = [];
    this.setState({ selectedYear: value.value });
    //todo call services

    servicesSalesHistory
      .getHistorySales({
        pcNo: JSON.parse(localStorage.getItem('userData')).pcNo,
        year: value.value
      })
      .then(res => {
        switch (res.status) {
          case 200:
            this.setState({
              historySales: res.data.result.data,
              totalHistorySales: res.data.result.footer,
              dataQty: dataQty
            });
            break;
          default:
            this.setState({ historySales: [], totalHistorySales: {} });
        }
      });
  };
  render() {
    const { classes } = this.props;
    const {
      historySales,
      totalHistorySales,
      optionYear,
      selectedYear
    } = this.state;




    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <h2>{'ประวัติการขาย'}</h2>
            {
              <SelectStyle
                value={optionYear.filter(opt => opt.value === selectedYear)}
                onChange={this.handleChangeSelect}
                options={optionYear}
                label={'4444'}
              />
            }
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <SalesHistoryBar historySales={historySales} />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <DataTable
              historySales={historySales}
              totalHistorySales={totalHistorySales}
            />
            {/* <TotalSalesCard /> */}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('loginStore')
)(observer(SalesHistory));
