import { observable, computed, action, decorate } from "mobx"

class loginManagementStore {

	loginUserId = -1

	TABLE_CONTENT = []
	

}
decorate(loginManagementStore, {
	loginUserId:  observable,
	TABLE_CONTENT:  observable,
})

export default new loginManagementStore()

