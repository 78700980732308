import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ApprovalTable, ApprovalFilter, ApproveAction } from './components';
import AlertRender from '../../components/alertRender/AlertRender'
import compose from 'recompose/compose'
import { inject, observer } from 'mobx-react';


const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});

class ApprovalPC extends Component {
	state = {}
	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<div className={classes.root}>
					<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<ApprovalFilter />
						</Grid>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<ApprovalTable />
						</Grid>
					</Grid>
					<AlertRender
						open={this.props.alertStore.alert.open}
						color={this.props.alertStore.alert.color}
						messages={this.props.alertStore.alert.messages}
						status={this.props.alertStore.alert.status}
						icon={this.props.alertStore.alert.icon} 
					/>
				</div>
			</React.Fragment>
		);
	}
}

export default compose(
	withStyles(useStyles),
	inject('alertStore')
)(observer(ApprovalPC))