import React, { Component } from 'react';
import {
	Grid,
	TextField,
	Button,
	Card,
	Divider,
	CardContent,
	CardHeader
} from '@material-ui/core';
import FilterRender from '../../../../components/FilterRender/FilterRender'
import { inject, observer } from 'mobx-react';
import ServicesCampaign from '../../../../services/servicesCampaign'
class TransferSummeryTradeFilter extends Component {
	state = {
		filterList: [
			{
				name: 'campaignName',
				displayName: 'แคมเปญ',
				showDisplayName: true,
				type: 'option',
				value: '',
				option: [],
				required: false,
				widthCol: 6,
				styleXs: {
					label: 12,
					input: 12,
				},
				styleSm: {
					label: 3,
					input: 9,
				},
			},
		]
	}

	componentDidMount() {
		this.getCampaign()
	}

	getCampaign = () => {
		ServicesCampaign.getCampaign(
			{
				userLogin: JSON.parse(localStorage.getItem('userData')).userId,
				roleId: JSON.parse(localStorage.getItem('userData')).roleId,
			}
		).then(res => {
			let filterList = [...this.state.filterList]
			let campaignOption = []

			switch (res.status) {
				case 200:
					res.data.result.map((val, i) => {
						campaignOption.push({ label: val.campaignName, value: val.campaignId })
					})

					filterList[0].option = campaignOption
					this.setState({ filterList: filterList })
					break;
				default:
					filterList[0].option = []
					this.setState({ filterList: filterList })

			}
		})
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]

		filterList.map((val, i) => {
			if (val.name === name) {
				if (value === null) {
					// for uploadContent filter
					value = []
				}
				val.value = value
			}
		})
		this.setState({ filterList: filterList })
	}

	searchSubmit = () => {
		let object = {}

		this.state.filterList.map((val, i) => {
			object[`${val.name}`] = val.value
		})


		// this.getListPC(object)

		// this.props.approvalPCStore.historySearch = object
	}

	openAlert = (status, message) => {
		this.setState({
			alertMessage: {
				content: message,
				open: true,
				status: status
			},
		})
	}

	closeAlert = () => {
		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
		})
	}

	render() {
		const { filterList } = this.state;


		return (
			<React.Fragment>
				<Card>
					<CardHeader
						// action={
						// 	<Button>ddddd</Button>
						// }
						title={
							"สรุปยอดโอน"
						}
					/>
					<Divider />
					<CardContent >
						<Grid
							container
							spacing={2}
						>
							<FilterRender
								filterList={filterList}
								handleChangeFilter={this.handleChangeFilter}
							//hasError={this.hasError}
							//helperTextError={this.helperTextError}
							/>
							<Grid
								item
								lg={12}
								sm={12}
								xl={12}
								xs={12}
								style={{ textAlignLast: 'right' }}
							>
								<Button
									color="primary"
									// disabled={!formState.isValid}
									// fullWidth
									type="submit"
									variant="contained"
									onClick={() => this.searchSubmit()}
								>
									ค้นหา
						</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</React.Fragment>
		);
	}
}

export default inject('optionStore', 'approvalPCStore')(observer(TransferSummeryTradeFilter))
