import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesCampaign {

	actionCampaign(requestData) {
		return sendRequest("/campaign/actionCampaign", HTTP.POST, requestData)
	}

	getCampaign(requestData) {
		return sendRequest("/campaign/getCampaign", HTTP.POST, requestData)
	}

	getCampaignLine(requestData) {
		return sendRequest("/campaign/getCampaignLine", HTTP.POST, requestData)
	}

	updateItemInitialPoint(requestData) {
		return sendRequest("/campaign/updateItemInitialPoint", HTTP.POST, requestData)
	}

}

export default new ServicesCampaign()