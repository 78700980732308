import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { Grid, Button, Typography, colors, Divider, DialogContent, DialogActions } from '@material-ui/core';
import FilterRender from '../../../../components/FilterRender/FilterRender'
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogAlert from '../../../../components/DialogAlert/DialogAlert'
import TableRender from '../../../../components/TableRender/TableRender'
import ServicesApprovalSaleList from '../../../../services/servicesApprovalSaleList'
import { TABLE_HEADER_MODAL as TABLE_HEADER, INPUT_RENDER_ACTION_MODAL, INPUT_RENDER_CONTENT } from '../../ApprovalSaleListStructure'
import { OPTION_RENDER } from '../../../../components/OptionRender/OptionRender'
import { isExistData } from 'constants/function/checkDataValue';


const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	button: {
		color: theme.palette.success,
	},
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});


const schema = {
	approvalStatus: {
		presence: { allowEmpty: false, message: '^กรุณาเลือกสถานะการอนุมัติ' },
		length: {
			maximum: 32
		}
	},
	// approvalReason: {
	// 	presence: { allowEmpty: false, message: '^กรุณากรอกเหตุผล' },
	// 	length: {
	// 		maximum: 255
	// 	}
	// },
};



class ApprovalSaleListAction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterList: [],
			filterList2: [],
			formState: {
				isValid: false,
				values: {},
				touched: {},
				errors: {},
				items: [],
			},
			alertMessage: {
				content: '',
				open: false,
				status: ''
			},
			TABLE_CONTENT: [],
			idModal: '',
		};
	}

	componentDidMount() {
		this.setState({ filterList: INPUT_RENDER_ACTION_MODAL, filterList2: INPUT_RENDER_CONTENT }, () => {
			let filterList2 = this.state.filterList2
			filterList2[2].option = OPTION_RENDER('group', false)

			let filterList = this.state.filterList
			filterList[0].option = OPTION_RENDER('saleStatus', false)

			this.setState({ filterList2: filterList2, filterList: filterList })

		})
	}

	componentDidUpdate() {
		if (this.state.idModal === '' && this.props.approvalSaleListStore.idModal !== '' && isExistData(this.props.statusRow)) {
			let data = {
				userLogin: JSON.parse(localStorage.getItem('userData')).userId,
				transactionId: this.props.approvalSaleListStore.idModal,
				roleId: JSON.parse(localStorage.getItem('userData')).roleId,
			}
			ServicesApprovalSaleList.getApprovalSalesDetail(data).then(res => {
				switch (res.status) {
					case 200:
						let TABLE_CONTENT = []
						let filterList2 = this.state.filterList2
						let filterList = this.state.filterList
						let resData = res.data.result.header
						let resKey = Object.keys(resData)
						resKey.map((key, i) => {
							filterList2.map((key2, i2) => {
								if (key === key2.name && isExistData(resData[key])) {
									if (key === 'invoiceImage') {
										let data = []
										data.push({ showImg: resData[key], name: resData[key].substring((resData[key].lastIndexOf('/') + 1)) })
										key2.value = data
									} else {
										key2.value = resData[key]
									}

								}
							})

							if (key === 'saleStatus') {
								filterList[0].value = resData[key]
							}
						})
						res.data.result.detail.map((value, index) => {
							TABLE_CONTENT.push(
								{
									rowNo: index + 1,
									barcode: value.barcode,
									qty: value.qty,
								}
							)
						})

						if (this.props.statusRow !== '2') {
							filterList[0].disabled = true;
						} else {
							filterList[0].disabled = false;
						}
						this.setState({ TABLE_CONTENT, filterList2, filterList, idModal: this.props.approvalSaleListStore.idModal })
						break;
					default:
						this.setState({ TABLE_CONTENT: [] })
				}
			})

		}
	}

	saveApprovalSales = (data) => {
		ServicesApprovalSaleList.saveApprovalSales(data).then(res => {
			switch (res.status) {
				case 200:
					this.openAlert('success', 'บันทึกสำเร็จ')
					this.props.approvalSaleListStore.triggerApproved = true;
					break;
				default:
					this.openAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่')
			}
		})
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]

		filterList.map((val, i) => {
			if (val.name === name) {
				if (value === null) {
					// for uploadContent filter
					value = []
				}
				val.value = value
			}
		})
		this.setState({ filterList: filterList })
	}

	handleApprovement = () => {
		let object = {}
		this.state.filterList.map((val, i) => {
			object[`${val.name}`] = val.value
		})

		if (object.saleStatus !== '') {
			this.openAlert('processing', '')

			object.transactionId = this.state.idModal
			object.userLogin = JSON.parse(localStorage.getItem('userData')).userId
			object.roleId = JSON.parse(localStorage.getItem('userData')).roleId
			
			this.saveApprovalSales(object)
		} else {
			this.openAlert('warning', 'กรุณาเลือกสถานะการอนุมัติ')
		}
	}

	handleClose = () => {
		let filterList = this.state.filterList

		filterList.map((val, i) => {
			val.value = ''
		})

		this.setState({ filterList: filterList, idModal: '' })
		this.props.close()
	};


	openAlert = (status, message) => {
		this.setState({
			alertMessage: {
				content: message,
				open: true,
				status: status
			},
		})
	}


	closeAlert = () => {
		if (this.state.alertMessage.status === 'success') {
			this.handleClose()
			this.props.searchSubmit()
		}

		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
		})
	}

	handleDisplayImg = (src) => {
		let modal = document.getElementById("showImg");
		let modalImg = document.getElementById("imgContent");
		modal.style.display = "block";
		modalImg.src = src;
	}

	handleCloseDisplayImg = () => {
		let modal = document.getElementById("showImg");
		modal.style.display = "none";
	}


	render() {
		const { filterList, alertMessage, filterList2, TABLE_CONTENT } = this.state;
		const { classes } = this.props;


		return (
			<Dialog open={this.props.open}
				//TransitionComponent={Transition}
				fullWidth
			>
				<DialogTitle id="customized-dialog-title" onClose={() => this.handleClose()}>
					รายละเอียด
        		</DialogTitle>
				<React.Fragment>
					<DialogContent dividers>
						<FilterRender
							filterList={filterList2}
							handleDisplayImg={this.handleDisplayImg}
						// handleChangeFilter={this.handleChangeFilter}
						// hasError={this.hasError}
						// helperTextError={this.helperTextError}
						/>
						&nbsp;
						<TableRender
							TABLE_HEADER={TABLE_HEADER}
							TABLE_CONTENT={TABLE_CONTENT}
						/>
						<Divider />
						&nbsp;
						<Grid container spacing={4}>
							{/* <Grid item lg={12} sm={12} xl={12} xs={12}> */}
							<FilterRender
								filterList={filterList}
								handleChangeFilter={this.handleChangeFilter}
								hasError={this.hasError}
								helperTextError={this.helperTextError}
							/>
						</Grid>
						<div style={{ paddingBottom: '40px' }}></div>
					</DialogContent>
					{
						this.props.statusRow === '2' ?
							<DialogActions>
								<Button
									variant="contained"
									style={{ backgroundColor: colors.green[600], color: 'white' }}
									onClick={() => this.handleApprovement()}>
									บันทึก
            					</Button>
								<Button color="default" variant="contained" onClick={() => this.handleClose()}>
									ยกเลิก
          						</Button>
							</DialogActions>
							: ''
					}

				</React.Fragment>
				<div id="showImg" class="modal" style={{ zIndex: '99' }}>
					<span class="close" onClick={() => this.handleCloseDisplayImg()} >&times;</span>
					<img class="modal-content" id="imgContent" />
				</div>
				<DialogAlert data={alertMessage} close={this.closeAlert} />
			</Dialog>
		);
	}
}

export default
	inject('approvalSaleListStore', 'alertStore', 'optionStore')
		(observer(ApprovalSaleListAction))