import { observable, computed, action, decorate } from "mobx"

class optionStore {

	groupOption = []
	
	bankOption = []

}
decorate(optionStore, {
	groupOption:  observable,
	bankOption: observable,
})

export default new optionStore()

