import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Button
} from '@material-ui/core';
import { SelectStyle } from './styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import servicesSalesHistory from '../../services/servicesSalesHistory';
import Select from 'react-select';
import {
  currencyFormat,
  getThaiYearOption,
  getCurrentYearFormat
} from '../../constants/function/formatConverter';
import { getExistData } from '../../constants/function/checkDataValue';
import DialogAlert from '../../components/DialogAlert/DialogAlert';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';
import { withStyles } from '@material-ui/core/styles';
import TableChartIcon from '@material-ui/icons/TableChart';

const useStyles = () => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    color: '#fff',
    fontSize: '16px'
  },
  avatar: {
    fontSize: '18px',
    color: '#fff'
  },
  statusNotSuccess: {
    color: '#fff'
  },
  statusSuccess: {
    color: '#43A047'
  }
});

class DataTable extends Component {
  state = {
    historySales: [],
    totalHistorySales: {},
    optionYear: [],
    selectedYear: '',
    alertMessage: {
      content: '',
      open: false,
      status: ''
    }
  };

  closeAlert = () => {
    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      }
    });
  };

  render() {
    const {
      // optionYear,
      // selectedYear,
      alertMessage
    } = this.state;
    const { historySales, totalHistorySales, classes } = this.props;

    return (
      <Card>
        <CardHeader
          classes={{
            title: classes.title,
            avatar: classes.avatar
          }}
          title="ตารางแสดงประวัติการขาย"
          style={{ backgroundColor: '#3F51B5' }}
          avatar={<TableChartIcon />}
        />
        <Divider />
        <CardContent>
          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 600 }}>เดือน</TableCell>
                    <TableCell style={{ fontWeight: 600 }}>
                      จำนวน (ชิ้น)
                    </TableCell>
                    <TableCell style={{ fontWeight: 600 }}>
                      เงินที่คุณได้รับ
                    </TableCell>
                    {/* <TableCell style={{ fontWeight: 600 }}>สถานะ</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getExistData(historySales).map((val, i) => {
                    return (
                      <TableRow
                        hover
                        //key={order.id}
                      >
                        <TableCell
                          className="pointer"
                          style={{ padding: '12px' }}>
                          {/* {order.ref} */}
                          {val.month}
                        </TableCell>
                        <TableCell style={{ padding: '12px' }}>
                          {currencyFormat(val.qty)}
                        </TableCell>
                        <TableCell style={{ padding: '12px' }}>
                          {currencyFormat(val.received)}
                        </TableCell>
                        {/* <TableCell style={{ padding: '12px' }}>
                          <div>
                            {val.transferStatus === 1 ? (
                              <span style={{ color: '#43A047' }}>
                                {'โอนแล้ว'}
                              </span>
                            ) : val.transferStatus === 0 ? (
                              <span style={{ color: '#E53935' }}>
                                {'ยังไม่ได้โอน'}
                              </span>
                            ) : (
                              '-'
                            )}
                          </div>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell style={{ fontWeight: 600 }}>รวม</TableCell>
                    <TableCell style={{ fontWeight: 600 }}>
                      {currencyFormat(getExistData(totalHistorySales.totalQty))}
                    </TableCell>
                    <TableCell style={{ fontWeight: 600 }}>
                      {currencyFormat(
                        getExistData(totalHistorySales.totalReceived)
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <DialogAlert data={alertMessage} close={this.closeAlert} />
      </Card>
    );
  }
}

export default withStyles(useStyles)(DataTable);
