import React, { Component } from 'react';
import {SnackbarContent, Snackbar, CircularProgress, Typography } from '@material-ui/core';

class AlertRender extends Component {
	state = {}
	render() {
		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={this.props.open}
			>
				<React.Fragment>
					<SnackbarContent
						aria-describedby="client-snackbar"
						style={{ backgroundColor: this.props.color , minWidth: '100%' }}
						message={
							<React.Fragment>
								<div style={{ display: 'flex'}}>
								{
									this.props.status !== 'processing' ?
										this.props.icon
										: <CircularProgress style={{ width: '20px', height: '20px', opacity: '0.9', color: '#FFFFFF', alignItems: 'center', verticalAlign: 'text-bottom ' }} />
								}
								<div style={{ fontSize: '16px', alignSelf: 'center' }} >&nbsp;
									{this.props.messages}
								</div>
								</div>
							</React.Fragment>
						}
					>
					</SnackbarContent>
				</React.Fragment>

			</Snackbar>
		);
	}
}

export default AlertRender;