import moment from 'moment';
import { OPTION_RENDER } from '../../components/OptionRender/OptionRender';

export const INPUT_RENDER = [
  {
    name: 'startDate',
    displayName: 'วันที่เริ่มต้น',
    showDisplayName: true,
    type: 'date',
    value: moment(new Date()).startOf('month'),
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'endDate',
    displayName: 'วันที่ยกเลิก',
    showDisplayName: true,
    type: 'date',
    value: moment(new Date()).endOf('month'),
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'campaignName',
    displayName: 'ชื่อแคมเปญ',
    placeholder: [],
    showDisplayName: true,
    type: 'text',
    value: '',
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
];

export const TABLE_HEADER = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'campaignName',
    label: 'ชื่อแคมเปญ'
  },
  {
    key: 'createDate',
    label: 'วันที่สร้าง'
  },
  {
    key: 'totals',
    label: 'ค่าใช้จ่ายรวม'
  },
  {
    key: 'userQty',
    label: 'จำนวนพนักงาน'
  },
  {
    key: 'docNo',
    label: 'เลขชุดเอกสาร'
  },
  {
    key: 'department',
    label: 'แผนก'
  }
];
