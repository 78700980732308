import React, { Component } from 'react';
import TableRender from '../../../../components/TableRender/TableRender'
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Button
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';


const TABLE_HEADER = [
	{
		key: 'rowNo',
		label: 'ลำดับ',
	},
	{
		key: 'groupNo',
		label: 'รหัสกลุ่ม',
	},
	{
		key: 'groupName',
		label: 'ชื่อกลุ่ม',
	},
	{
		key: 'shopName',
		label: 'ชื่อร้าน',
	},
	{
		key: 'qty',
		label: 'จำนวนที่ขาย',
	},
	{
		key: 'amount',
		label: 'จำนวนเงิน',
	},
]


class TransferSummeryTradeTable extends Component {
	state = {}
	render() {
		return (
			<React.Fragment>
				<Card>
					{/* <CardHeader
					// action={
					// 	<Button>ddddd</Button>
					// }
					// title={
					// 	<Button
					// 		color="secondary"
					// 		// disabled={!formState.isValid}
					// 		// fullWidth
					// 		size="large"
					// 		type="submit"
					// 		variant="contained"
					// 		onClick={() => this.handleOpenPCAccountModal()}
					// 	>
					// 		เพิ่มพีซี
					// 	</Button>
					// }
					/> */}
					<Divider />
					<CardContent >
						<TableRender TABLE_HEADER={TABLE_HEADER} TABLE_CONTENT={[]} />
					</CardContent>
					<Divider />
				</Card>
			</React.Fragment>
		);
	}
}

export default TransferSummeryTradeTable;