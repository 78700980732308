// import { OPTION_RENDER } from '../../components/OptionRender/OptionRender';

export const INPUT_RENDER = [
  {
    name: 'pageName',
    displayName: 'ชื่อหน้า',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 6,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  },
  {
    name: 'groupNo',
    displayName: 'กลุ่ม',
    showDisplayName: true,
    type: 'option',
    value: '',
    option: [],
    required: false,
    widthCol: 6,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 12,
      input: 12
    }
  }
];

export const INPUT_RENDER_MODAL = [
  {
    name: 'pageId',
    displayName: 'รหัส',
    placeholder: [],
    showDisplayName: true,
    disabled: true,
    hidden: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    styleXs: {
      label: 4,
      input: 8
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'pageName',
    displayName: 'ชื่อหน้า',
    placeholder: [],
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: true,
    styleXs: {
      label: 4,
      input: 8
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'userType',
    displayName: 'ประเภทผู้ใช้',
    showDisplayName: true,
    type: 'option',
    value: '',
    disabled: false,
    option: [],
    required: true,
    styleXs: {
      label: 4,
      input: 8
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'groupNo',
    displayName: 'กลุ่ม',
    showDisplayName: true,
    type: 'option',
    value: '',
    disabled: false,
    option: [],
    required: true,
    styleXs: {
      label: 4,
      input: 8
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'logoImage',
    displayName: 'โลโก้',
    showDisplayName: true,
    type: 'upload',
    maxFile: 1,
    canUpload: true,
    value: '',
    option: [],
    required: true,
    styleXs: {
      label: 4,
      input: 8
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
];

export const TABLE_HEADER = [
  {
    key: 'rowNo',
    label: 'ลำดับ',
  },
  {
    key: 'pageName',
    label: 'ชื่อหน้า',
    textAlign: 'left'
  },
  // {
  //   key: 'pageName',
  //   label: 'ประเภทผู้ใช้',
  //   textAlign: 'left'
  // },
  {
    key: 'groupNo',
    label: 'กลุ่ม',
    textAlign: 'left'
  },
  {
    key: 'logoImage',
    label: 'โลโก้'
  },
  {
    key: 'url',
    label: 'URL'
  },
  {
    key: 'action',
    label: 'แก้ไข'
  }
];
