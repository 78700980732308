import React from 'react';
import {
  Button,
  Typography,
  colors,
  Select,
  Grid,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme } from '@material-ui/core/styles';
import FilterRender from '../../components/FilterRender/FilterRender';
import { SelectStyle } from '../../components/FilterRender/styled-components';
import { DropzoneArea } from 'material-ui-dropzone';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { createNamespaceImport } from 'typescript';
import { formatTimeStr } from 'antd/lib/statistic/utils';
import { SERVER } from '../../services/sendRequestAPI';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const selectStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: '9999 !important' })
};

export const DialogImport = ({
  open,
  close,
  filterList,
  actionUpload,
  openConfirm,
  closeConfirm,
  openConfirmModal,
  item,
  changeCampaignImport = () => {},
  dataPreview,
  importIncentive,
  monthYear
}) => {
  return (
    <React.Fragment>
      <Dialog
        className="dialogImport"
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <DialogTitle id="customized-dialog-title" onClose={close}>
          <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
            Import ข้อมูลสรุปยอดโอน
            <span style={{ float: 'right', marginRight: '-20px' }}>
              <a href={`${SERVER}/static/import/template_incentive.xlsx`}>
                <Button
                  color="primary"
                  style={{ margin: '0 20px' }}
                  type="submit"
                  variant="contained">
                  Download Template
                </Button>
              </a>
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            spacing={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <p style={{ marginBottom: '0' }}>
                <span style={{ color: 'red' }}>{'* '}</span>
                {filterList[0].displayName}
              </p>
            </Grid>
            <Grid item xs={8}>
              <SelectStyle
                styles={selectStyles}
                options={filterList[0].option}
                onChange={e =>
                  changeCampaignImport(e, 'dataImport')
                }></SelectStyle>
            </Grid>

            <Grid xs={2}>
              <label for="imporXlsx">
                <a
                  autoFocus
                  // onClick={close}
                  className="btn-browse"
                  variant="contained">
                  BROWSE
                </a>
                <input
                  style={{ display: 'none' }}
                  id="imporXlsx"
                  name="imporXlsx"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={e => actionUpload(e)}
                />
              </label>
            </Grid>
            <Grid container style={{ padding: '0 10px', margin: '20px 0' }}>
              <Grid
                item
                xs={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <p style={{ marginBottom: '0' }}>
                  <span style={{ color: 'red' }}>{'* '}</span>
                  {'เดือน และปี '}
                </p>
              </Grid>
              <Grid item xs={8}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ width: '100%' }}
                    views={['year', 'month']}
                    label="เลือกเดือน ปี"
                    format="MM/yyyy"
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                    autoOk
                    onChange={e => changeCampaignImport(e, 'monthYearImport')}
                    value={monthYear}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container style={{ padding: '0 10px', margin: '20px 0' }}>
              <Grid xs={12} style={{ marginBottom: '20px' }}>
                <Chip
                  icon={<LibraryBooksIcon style={{ color: '#FFF' }} />}
                  label="รายละเอียดข้อมูลนำเข้า"
                  style={{
                    backgroundColor: colors.green[600],
                    color: '#FFF',
                    padding: '0 10px'
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                style={{ paddingLeft: '20px', display: 'inline-flex' }}>
                <Typography style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  ชื่อไฟล์:
                </Typography>
                <Typography>{item.fileName}</Typography>
              </Grid>
              <Grid
                xs={12}
                style={{ paddingLeft: '20px', display: 'inline-flex' }}>
                <Typography style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  สถานะ:
                </Typography>
                {!item.status ? (
                  <Typography style={{ color: '#FF0000' }}>
                    {item.statusName}
                  </Typography>
                ) : (
                  <Typography style={{ color: colors.green[600] }}>
                    {item.statusName}
                  </Typography>
                )}
              </Grid>
              <Grid
                xs={12}
                style={{ paddingLeft: '20px', display: 'inline-flex' }}>
                <Typography style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  หมายเหตุ:
                </Typography>
                {!item.status ? (
                  <Typography style={{ color: '#FF0000' }}>
                    {item.remark}
                  </Typography>
                ) : (
                  <Typography style={{ color: colors.green[600] }}>
                    {item.remark}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!item.status || item.campaignName == '' || !monthYear ? (
            <Button className="btn-not-allow" variant="contained">
              <BlockIcon style={{ marginRight: '5px' }} />
              Import
            </Button>
          ) : (
            <Button
              autoFocus
              onClick={openConfirmModal}
              className="btn-allow "
              variant="contained">
              <CheckCircleIcon style={{ marginRight: '5px' }} />
              Import
            </Button>
          )}

          <Button
            onClick={close}
            style={{ backgroundColor: '#FFF', color: '#000' }}
            variant="contained">
            cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        className="dialogConfirmImport"
        onClose={closeConfirm}
        aria-labelledby="customized-dialog-title"
        open={openConfirm}
        maxWidth={'lg'}>
        <DialogTitle onClose={closeConfirm}>
          <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
            ยืนยันการ Import ข้อมูล{' '}
            <span
              style={{
                fontWeight: 'bold',
                float: 'right',
                color: colors.green[600]
              }}>{` จำนวน:${item.amount}รายการ`}</span>
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{ width: '100%', overflow: 'unset' }}>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <Card
                bordered={false}
                style={{
                  overflow: 'auto',
                  height: '50vh',
                  boxShadow:
                    '0 0 0 1px rgb(63 63 68 / 0%), 0 1px 3px 0 rgb(63 63 68 / 0%)'
                }}>
                {Array.isArray(dataPreview) &&
                  dataPreview.map(value => {
                    return (
                      <TableRow>
                        {Array.isArray(value) &&
                          value.map(props => {
                            return (
                              <TableCell style={{ textAlign: 'center' }}>
                                {props}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    );
                  })}
              </Card>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={importIncentive}
            style={{ color: 'white', backgroundColor: colors.green[600] }}
            // color="primary"
            variant="contained">
            ยืนยัน
          </Button>
          <Button
            onClick={closeConfirm}
            style={{ backgroundColor: '#FFF', color: '#000' }}
            variant="contained">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
