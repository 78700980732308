import React, { Component } from 'react';
import ServicesOption from '../../../../services/servicesOption';
import ServicesSignup from '../../../../services/servicesSignup';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import DialogAlert from '../../../../components/DialogAlert/DialogAlert';
import { callDialogAlert } from '../../../../components/DialogAlert/DialogAlert';

import { inject, observer } from 'mobx-react';
import validate from 'validate.js';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  colors,
  Typography,
  ListItemIcon,
  ListItemText,
  List,
  ListItem
} from '@material-ui/core';
import ChangePasswordModal from './changePasswordModal.js';
import { isExistData } from '../../../../constants/function/checkDataValue';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getImageAPI } from '../../../../services/servicesImage';

let schema = {
  // address: {
  // 	presence: { allowEmpty: false, message: 'is required' },
  // 	length: {
  // 		maximum: 500
  // 	}
  // },
  address: {
    presence: { allowEmpty: false, message: '^กรุณากรอกที่อยู่' },
    length: {
      maximum: 500
    }
  },
  zipcode: {
    presence: { allowEmpty: false, message: '^กรุณากรอกรหัสไปรษณีย์' },
    length: {
      maximum: 10
    },
    format: {
      pattern: '[0-9]+',
      flags: 'i',
      message: '^กรอกตัวเลขเท่านั้น'
    }
  },
  subDistrictId: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกตำบอล/แขวง' }
  },
  bankAccount: {
    presence: { allowEmpty: false, message: '^กรุณากรอกเลขที่บัญชี' },
    length: {
      maximum: 32
    },
    format: {
      pattern: '[0-9]+',
      flags: 'i',
      message: '^กรอกตัวเลขเท่านั้น'
    }
  },
  tel: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 10,
      message: '^ห้ามกรอกเบอร์โทรศัพท์เกิน 10 ตัวเลข'
    },
    format: {
      pattern: '[0-9]+',
      flags: 'i',
      message: '^กรอกตัวเลขเท่านั้น'
    }
  },
  bankBranchName: {
    presence: { allowEmpty: false, message: '^กรุณากรอกสาขา' },
    length: {
      maximum: 32
    }
  },
  bookBankImage: {
    presence: { allowEmpty: false, message: '^กรุณาแนบไฟล์ *หน้าบัญชี' },
    length: {
      maximum: 1,
      message: '^เลือกได้เพียง 1 รูปเท่านั้น'
    }
  },
  idcardImage: {
    presence: { allowEmpty: false, message: '^กรุณาแนบไฟล์ *สำเนาบัตรประชาชน' },
    length: {
      maximum: 1,
      message: '^เลือกได้เพียง 1 รูปเท่านั้น'
    }
  },
  profileImage: {
    length: {
      maximum: 1,
      message: '^เลือกได้เพียง 1 รูปเท่านั้น'
    }
  }
  // email: {
  // 	email: { message: '^กรุณากรอกรูปแบบอีเมลให้ถูกต้อง' },
  // }
};

const useStyles = theme => ({
  Select: {
    marginTop: '7px'
  },
  dropZone: {
    border: `1px dashed #2979ff`, //${theme.palette.divider}
    padding: theme.spacing(1),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.blue[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  }
});

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {
        isValid: false,
        values: {
          firstName: '',
          lastName: '',
          personalId: '',
          email: '',
          tel: '',
          pcNo: '',
          zipcode: '',
          subDistrictId: '',
          districtId: '',
          provinceId: '',
          birthdate: '',
          address: '',
          groupNo: '',
          shopNo: '',
          storeId: '',
          bankAccount: '',
          bankBranchName: '',
          bankGroupNo: '',
          bookBankImage: [],
          idcardImage: [],
          profileImage: ''
        },
        touched: {},
        errors: {},
        items: []
      },
      optionSubDistricts: [],
      optionDistricts: [],
      optionProvince: [],
      bookBankImage: [],
      idcardImage: [],
      optionBank: [],
      selectedOptionBank: null,
      optionShop: [],
      selectedOptionShop: null,
      optionStore: [],
      selectedOptionStore: null,
      optionGroup: [],
      selectedOptionGroup: null,
      selectedDate: null,
      idcardImage: [],
      profileImage: [],
      storeId: null,
      alertMessage: {
        content: '',
        open: false,
        status: ''
      },
      openChangePassword: false
    };

    this.handleUpdate = this.handleUpdate.bind(this);
  }

  getBank() {
    ServicesOption.getBank({
      userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    }).then(res => {
      switch (res.status) {
        case 200:
          let resBank = res.data.result;
          let optionBank = [...this.state.optionBank];
          resBank.map((val, i) => {
            optionBank.push({ value: val.bankNo, label: val.bankName });
          });
          this.setState({ optionBank: optionBank });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  }

  getShopByGroup = data => {
    ServicesOption.getShopByGroup(data).then(res => {
      switch (res.status) {
        case 200:
          let resShop = res.data.result;
          let optionShop = [];
          let optionStore = [];
          resShop.map((val, i) => {
            optionShop.push({ value: val.shopNo, label: val.shopName });
            optionStore.push({ value: val.shopNo, label: val.locationName });
          });

          this.setState({ optionShop: optionShop, optionStore: optionStore });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  getProvinces() {
    ServicesOption.getProvinces({
      userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    }).then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;
          let optionProvince = [];
          resGroup.map((val, i) => {
            optionProvince.push({
              value: val.provinceId,
              label: val.provinceNameTH
            });
          });
          this.setState({ optionProvince: optionProvince });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  }

  getDistricts(data) {
    ServicesOption.getDistricts(data).then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;
          let optionDistricts = [];
          resGroup.map((val, i) => {
            optionDistricts.push({
              value: val.districtId,
              label: val.districtNameTH
            });
          });
          this.setState({ optionDistricts: optionDistricts });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  }

  getAreaByZipcode(data) {
    ServicesOption.getAreaByZipcode(data).then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;
          let optionSubDistricts = [];
          let formState = this.state.formState;
          if (resGroup.length > 0) {
            resGroup.map((val, i) => {
              optionSubDistricts.push({
                value: val.subDistrictId,
                label: val.subDistrictTH
              });
            });

            formState = {
              ...formState,
              values: {
                ...formState.values,
                provinceId: resGroup[0].provinceId,
                districtId: resGroup[0].districtId
              }
            };
            this.getDistricts({
              provinceId: resGroup[0].provinceId,
              userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
              roleId: JSON.parse(localStorage.getItem('userData')).roleId
            });
          }
          this.setState({
            optionSubDistricts: optionSubDistricts,
            formState: formState
          });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  }

  componentDidMount() {
    this.getBank();
    this.getProvinces();

    if (localStorage.getItem('userData')) {
      if (JSON.parse(localStorage.getItem('userData')).approvalStatus === '1') {
        this.getPCInfo();
      }
    }
  }

  getPCInfo = () => {
    ServicesSignup.getPCInfo({
      pcNo: JSON.parse(localStorage.getItem('userData')).pcNo,
      userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    }).then(res => {
      switch (res.status) {
        case 200:
          let formState = this.state.formState;
          let resData = res.data.result[0];
          let resKey = Object.keys(resData);

          resKey.map((key, i) => {
            let formStateValues = Object.keys(formState.values);
            formStateValues.map((key2, i2) => {
              if (
                key === key2 &&
                resData[key] !== null &&
                resData[key] !== ''
              ) {
                switch (key) {
                  case 'groupNo':
                    this.handleSelectGroup(resData[key]);
                    formState = {
                      ...formState,
                      values: { ...formState.values, [`${key}`]: resData[key] },
                      touched: { ...formState.touched, [`${key}`]: true }
                    };
                    break;
                  case 'bookBankImage':
                  case 'idcardImage':
                    // case 'profileImage':
                    let data = [];
                    data.push({
                      showImg: resData[key],
                      name: resData[key].substring(
                        resData[key].lastIndexOf('/') + 1
                      )
                    });
                    // this.setState({ [`${key}`]: data })
                    formState = {
                      ...formState,
                      values: { ...formState.values, [`${key}`]: data },
                      touched: { ...formState.touched, [`${key}`]: true }
                    };
                    break;
                  case 'profileImage':
                    this.props.accountStore.profileImageForTrigger =
                      resData[key];
                    let userPersonal = JSON.parse(
                      localStorage.getItem('userData')
                    );
                    userPersonal.profileImage = resData[key];
                    localStorage.setItem(
                      'userData',
                      JSON.stringify(userPersonal)
                    );
                    break;
                  case 'zipcode':
                    this.getAreaByZipcode({
                      zipcode: resData[key],
                      userLogin: JSON.parse(localStorage.getItem('userData'))
                        .pcNo,
                      roleId: JSON.parse(localStorage.getItem('userData'))
                        .roleId
                    });
                    formState = {
                      ...formState,
                      values: {
                        ...formState.values,
                        [`${key}`]: resData[key].toString()
                      },
                      touched: { ...formState.touched, [`${key}`]: true }
                    };

                    break;
                  default:
                    formState = {
                      ...formState,
                      values: { ...formState.values, [`${key}`]: resData[key] },
                      touched: { ...formState.touched, [`${key}`]: true }
                    };
                }
              }
            });
          });
          this.setState({ formState });

          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  checkFormIsValidOnChange = (fieldName, value) => {
    let formState = this.state.formState;
    formState = {
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value
      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    };

    /*check validate empty onChange*/
    if (formState.touched[fieldName]) {
      const errors = validate(formState.values, schema);
      formState = {
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      };
    }
    this.setState({ formState: formState });
  };

  checkFormIsValidOnSubmit = () => {
    let formState = this.state.formState;

    let resKey = Object.keys(formState.values);
    resKey.map((key, i) => {
      if (key !== 'email') {
        if (!isExistData(formState.values[key])) {
          formState.touched[`${key}`] = true;
        }
      }
      if (formState.touched[key]) {
        const errors = validate(formState.values, schema);
        formState = {
          ...formState,
          isValid: errors ? false : true,
          errors: errors || {}
        };
      }
    });

    this.setState({ formState: formState });

    return formState.isValid;
  };

  handleRemoveAll = fieldUploadName => {
    this.checkFormIsValidOnChange(fieldUploadName, []);
  };

  handleDisplayImg = src => {
    let modal = document.getElementById('showImg');
    let modalImg = document.getElementById('imgContent');
    modal.style.display = 'block';
    modalImg.src = src;
  };

  handleCloseDisplayImg = () => {
    let modal = document.getElementById('showImg');
    modal.style.display = 'none';
  };

  isAllNumber = value => {
    let text = '';
    if (value !== '') {
      for (let i = 0; i < value.length; i++) {
        let char = value.charAt(i);
        if (char.match('^[0-9]+$')) {
          text += char;
        }
      }
      return text;
    }
  };

  handleChange = (fieldName, value) => {
    if (fieldName === 'zipcode') {
      if (value.length > 4) {
        this.getAreaByZipcode({
          zipcode: value,
          userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
          roleId: JSON.parse(localStorage.getItem('userData')).roleId
        });
      } else if (value.length < 4) {
        this.setState({ optionSubDistricts: [] });
      }
    } else if (fieldName === 'groupNo') {
      this.getShopByGroup({
        groupNo: value,
        userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
        roleId: JSON.parse(localStorage.getItem('userData')).roleId
      });
    }

    this.checkFormIsValidOnChange(fieldName, value);
  };

  handleSelectGroup = value => {
    this.setState({ optionShop: [] });
    this.getShopByGroup({
      groupNo: value,
      userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    });
  };

  hasError = field => {
    return this.state.formState.touched[field] &&
      this.state.formState.errors[field]
      ? true
      : false;
  };

  handleUpdate = event => {
    event.preventDefault();
    const formState = this.state.formState;
    const data = new FormData();
    let isValidData = this.checkFormIsValidOnSubmit();

    if (isValidData) {
      this.setState({ alertMessage: callDialogAlert('processing', '') });

      let listFormState = Object.keys(formState.values);

      listFormState.map((val, i) => {
        switch (val) {
          case 'personalId':
          case 'email':
          case 'tel':
          case 'pcNo':
          case 'zipcode':
          case 'subDistrictId':
          case 'districtId':
          case 'provinceId':
          case 'address':
          case 'bankAccount':
          case 'bankBranchName':

          case 'bankGroupNo':
          case 'bookBankImage':
          case 'idcardImage':
            if (Array.isArray(formState.values[val])) {
              if (
                formState.values[val].length > 0 &&
                !formState.values[val][0].showImg
              ) {
                data.append([`${val}`], formState.values[val][0]);
              } else {
                data.append([`${val}`], '');
              }
            } else {
              data.append([`${val}`], formState.values[val]);
            }
            break;
          case 'birthdate':
            if (formState.values[val] !== '') {
              data.append([`${val}`], formState.values[val]);
            } else {
              data.append([`${val}`], '');
            }
            break;
        }
      });

      data.append(
        'userLogin',
        JSON.parse(localStorage.getItem('userData')).pcNo
      );
      data.append(
        'roleId',
        JSON.parse(localStorage.getItem('userData')).roleId
      );
      if (
        isExistData(this.props.accountStore.profileImage) &&
        !this.props.accountStore.profileImage[0].showImg
      ) {
        data.append(`profileImage`, this.props.accountStore.profileImage[0]);
      } else if (!isExistData(this.props.accountStore.profileImage)) {
        data.append(`profileImage`, '');
      }

      ServicesSignup.updateProfile(data).then(res => {
        switch (res.status) {
          case 200:
            this.getPCInfo();

            this.setState({
              alertMessage: callDialogAlert('success', 'บันทึกสำเร็จ')
            });

            this.props.accountStore.triggerSaved = 0;
            break;
          default:
            this.setState({
              alertMessage: callDialogAlert(
                'error',
                'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
              )
            });
        }
      });
    } else {
      this.setState({
        alertMessage: callDialogAlert('warning', 'กรุณากรอกข้อมูลให้ครบ')
      });
    }
  };

  closeAlert = () => {
    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      }
    });
  };

  handleOpenChangePassword = () => {
    this.setState({ openChangePassword: true });
  };

  handleCloseChangePassword = () => {
    this.setState({ openChangePassword: false });
  };

  dateForm = selectedDate => {
    return (
      selectedDate.getFullYear() +
      '/' +
      parseInt(selectedDate.getMonth() + 1) +
      '/' +
      selectedDate.getDate()
    );
  };

  render() {
    const { classes } = this.props;
    const {
      openChangePassword,
      formState,
      optionBank,
      selectedOptionBank,
      selectedOptionShop,
      optionShop,
      optionStore,
      selectedOptionStore,
      alertMessage,
      optionSubDistricts,
      optionDistricts,
      optionProvince
    } = this.state;
    return (
      <Card>
        <form
          autoComplete="off"
          onSubmit={event => this.handleUpdate(event)}
          noValidate>
          <ChangePasswordModal
            open={openChangePassword}
            close={this.handleCloseChangePassword}
          />
          <CardHeader
            subheader="The information can be edited"
            title="ข้อมูลของคุณ"
            action={
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.handleOpenChangePassword()}>
                เปลี่ยนรหัสผ่าน
              </Button>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  //helperText="Please specify the first name"
                  label="*ชื่อ"
                  margin="dense"
                  name="firstName"
                  value={formState.values.firstName || ''}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="*นามสกุล"
                  margin="dense"
                  name="lastName"
                  //required
                  value={formState.values.lastName || ''}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="*เลขบัตรประจำตัวประชาชน"
                  margin="dense"
                  name="personalId"
                  required
                  value={formState.values.personalId || ''}
                  variant="outlined"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <TextField
                  className={classes.textField}
                  error={this.hasError('address')}
                  helperText={
                    this.hasError('address')
                      ? formState.errors.address[0]
                      : null
                  }
                  fullWidth
                  label="*ที่อยู่ตามบัตรประชาชน (ตรอก ซอย)"
                  name="address"
                  placeholder="ที่อยู่ตามบัตรประชาชน (ตรอก ซอย)"
                  id="address"
                  variant="outlined"
                  size="small"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  type="text"
                  multiline
                  rows="4"
                  value={formState.values.address || ''}
                />
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <TextField
                  margin="dense"
                  error={this.hasError('zipcode')}
                  helperText={
                    this.hasError('zipcode')
                      ? formState.errors.zipcode[0]
                      : null
                  }
                  fullWidth
                  label="*รหัสไปรษณีย์"
                  name="zipcode"
                  id="zipcode"
                  variant="outlined"
                  size="small"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  type="text"
                  value={formState.values.zipcode || ''}
                />
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Select
                  className={classes.Select}
                  error={this.hasError('subDistrictId')}
                  helperText={
                    this.hasError('subDistrictId')
                      ? formState.errors.subDistrictId[0]
                      : null
                  }
                  value={
                    formState.values.subDistrictId !== null
                      ? optionSubDistricts.filter(
                          option =>
                            option.value ===
                            formState.values.subDistrictId.toString()
                        )
                      : optionSubDistricts.filter(
                          option =>
                            option.value === formState.values.subDistrictId
                        )
                  }
                  options={optionSubDistricts}
                  onChange={e => this.handleChange('subDistrictId', e.value)}
                  placeholder="*กรุณาเลือกตำบล/แขวง"
                  name="subDistrictId"
                  id="subDistrictId"
                />
                {this.hasError('subDistrictId') ? (
                  <Typography
                    variant="caption"
                    style={{ color: 'red', paddingLeft: '15px' }}>
                    กรุณาเลือกตำบล/เขต
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Select
                  className={classes.Select}
                  value={optionDistricts.filter(
                    option => option.value === formState.values.districtId
                  )}
                  options={optionDistricts}
                  placeholder="กรุณาเลือกอำเภอ/เขต"
                  name="districtId"
                  id="districtId"
                  isDisabled
                />
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Select
                  className={classes.Select}
                  value={optionProvince.filter(
                    option => option.value === formState.values.provinceId
                  )}
                  options={optionProvince}
                  placeholder="กรุณาเลือกจังหวัด"
                  name="provinceId"
                  id="provinceId"
                  isDisabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="*เบอร์โทรศัพท์"
                  margin="dense"
                  name="tel"
                  error={this.hasError('tel')}
                  helperText={
                    this.hasError('tel') ? formState.errors.tel[0] : null
                  }
                  type="number"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={formState.values.tel || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* <TextField
									fullWidth
									label="วัน เดือน ปีเกิด"
									margin="dense"
									name="birthdate"
									error={this.hasError('birthdate')}
									helperText={
										this.hasError('birthdate') ? formState.errors.birthdate[0] : null
									}
									onChange={this.handleChange}
									value={formState.values.birthdate || ''}
									onChange={(e) => this.handleChange('birthdate', this.dateForm(e))}
									variant="outlined"
									disabled
								/> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    fullWidth
                    className={classes.Select}
                    error={this.hasError('birthdate')}
                    helperText={
                      this.hasError('birthdate')
                        ? formState.errors.birthdate[0]
                        : null
                    }
                    label="วัน เดือน ปี เกิด"
                    value={
                      formState.values.birthdate !== ''
                        ? new Date(formState.values.birthdate)
                        : null
                    }
                    onChange={e =>
                      this.handleChange('birthdate', this.dateForm(e))
                    }
                    animateYearScrolling
                    //defaultValue="Normal"
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                    format="dd/MM/yyyy"
                    name="birthdate"
                    autoOk
                    clearable
                    disableFuture
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <Select
                  className={classes.Select}
                  options={optionShop}
                  value={optionShop.filter(
                    option => option.value === formState.values.shopNo
                  )}
                  placeholder="กรุณาเลือกร้านค้า"
                  name="shopNo"
                  id="shopNo"
                  isDisabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Select
                  className={classes.Select}
                  options={optionStore}
                  value={optionStore.filter(
                    option => option.value === formState.values.shopNo
                  )}
                  placeholder="ห้าง"
                  name="storeId"
                  id="storeId"
                  isDisabled
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={this.hasError('email')}
                  helperText={
                    this.hasError('email') ? formState.errors.email[0] : null
                  }
                  label="อีเมล์"
                  margin="dense"
                  name="email"
                  type="email"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={formState.values.email || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="*เลขที่บัญชี"
                  margin="dense"
                  name="bankAccount"
                  error={this.hasError('bankAccount')}
                  helperText={
                    this.hasError('bankAccount')
                      ? formState.errors.bankAccount[0]
                      : null
                  }
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={formState.values.bankAccount || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Select
                  className={classes.Select}
                  options={optionBank}
                  onChange={e => this.handleChange('bankGroupNo', e.value)}
                  value={optionBank.filter(
                    option => option.value === formState.values.bankGroupNo
                  )}
                  placeholder="ธนาคาร"
                  menuPortalTarget={document.querySelector('body')}
                  name="bankGroupNo"
                  id="bankGroupNo"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="*สาขา"
                  margin="dense"
                  name="bankBranchName"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  error={this.hasError('bankBranchName')}
                  helperText={
                    this.hasError('bankBranchName')
                      ? formState.errors.bankBranchName[0]
                      : null
                  }
                  value={formState.values.bankBranchName || ''}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Dropzone
                  onDrop={e => this.handleChange('bookBankImage', e)}
                  accept="image/jpeg, image/png, image/png"
                  minSize={1024}
                  maxSize={3072000}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} className={classes.Select} />
                      <Typography
                        className={classes.dropZone}
                        variant="h5"
                        style={{
                          border: this.hasError('bookBankImage')
                            ? '1px dashed red'
                            : '1px dashed #2979ff'
                        }}>
                        <AttachFileIcon fontSize="small" /> แนบไฟล์ *หน้าบัญชี
                      </Typography>
                    </div>
                  )}
                </Dropzone>
                {this.hasError('bookBankImage') ? (
                  <Typography
                    variant="caption"
                    style={{ color: 'red', paddingLeft: '15px' }}>
                    {formState.errors.bookBankImage[0]}
                  </Typography>
                ) : (
                  ''
                )}

                <React.Fragment>
                  <List className={classes.list}>
                    {formState.values.bookBankImage.length === 1 &&
                      formState.values.bookBankImage.map(file => (
                        <ListItem>
                          <ListItemIcon>
                            {file.showImg ? (
                              <img
                                src={getImageAPI(file.showImg)}
                                onClick={() =>
                                  this.handleDisplayImg(
                                    getImageAPI(file.showImg)
                                  )
                                }
                                style={{
                                  maxHeight: '70px',
                                  maxWidth: '70px',
                                  width: '70px',
                                  height: '70px',
                                  objectFit: 'cover',
                                  cursor: 'pointer'
                                }}
                              />
                            ) : (
                              <img
                                src={URL.createObjectURL(file)}
                                onClick={() =>
                                  this.handleDisplayImg(
                                    window.URL.createObjectURL(file)
                                  )
                                }
                                style={{
                                  maxHeight: '70px',
                                  maxWidth: '70px',
                                  width: '70px',
                                  height: '70px',
                                  objectFit: 'cover',
                                  cursor: 'pointer'
                                }}
                              />
                            )}
                          </ListItemIcon>
                          &nbsp;
                          <div
                            style={{
                              width: '100%',
                              flex: '1',
                              whiteSpace: 'nowrap !important',
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis !important'
                            }}>
                            <ListItemText
                              primary={file.name}
                              primaryTypographyProps={{ variant: 'h5' }}
                            />
                          </div>
                          <div style={{ textAlign: 'end' }}>
                            <DeleteTwoToneIcon
                              className="pointer"
                              onClick={() =>
                                this.handleRemoveAll('bookBankImage')
                              }
                            />
                          </div>
                        </ListItem>
                      ))}
                  </List>
                </React.Fragment>
              </Grid>
              <Grid item md={6} xs={12}>
                <Dropzone
                  onDrop={e => this.handleChange('idcardImage', e)}
                  accept="image/jpeg, image/png, image/png"
                  minSize={1024}
                  maxSize={3072000}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <Typography
                        className={classes.dropZone}
                        variant="h5"
                        style={{
                          border: this.hasError('idcardImage')
                            ? '1px dashed red'
                            : '1px dashed #2979ff'
                        }}>
                        <AttachFileIcon fontSize="small" /> แนบไฟล์
                        *สำเนาบัตรประชาชน
                      </Typography>
                    </div>
                  )}
                </Dropzone>
                {this.hasError('idcardImage') ? (
                  <Typography
                    variant="caption"
                    style={{ color: 'red', paddingLeft: '15px' }}>
                    {formState.errors.idcardImage[0]}
                  </Typography>
                ) : (
                  ''
                )}

                <React.Fragment>
                  <List className={classes.list}>
                    {formState.values.idcardImage.length === 1 &&
                      formState.values.idcardImage.map(file => (
                        <ListItem>
                          <ListItemIcon>
                            {file.showImg ? (
                              <img
                                src={getImageAPI(file.showImg)}
                                onClick={() =>
                                  this.handleDisplayImg(
                                    getImageAPI(file.showImg)
                                  )
                                }
                                style={{
                                  maxHeight: '70px',
                                  maxWidth: '70px',
                                  width: '70px',
                                  height: '70px',
                                  objectFit: 'cover',
                                  cursor: 'pointer'
                                }}
                              />
                            ) : (
                              <img
                                src={URL.createObjectURL(file)}
                                onClick={() =>
                                  this.handleDisplayImg(
                                    window.URL.createObjectURL(file)
                                  )
                                }
                                style={{
                                  maxHeight: '70px',
                                  maxWidth: '70px',
                                  width: '70px',
                                  height: '70px',
                                  objectFit: 'cover',
                                  cursor: 'pointer'
                                }}
                              />
                            )}
                          </ListItemIcon>
                          &nbsp;
                          <div
                            style={{
                              width: '100%',
                              flex: '1',
                              whiteSpace: 'nowrap !important',
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis !important'
                            }}>
                            <ListItemText
                              primary={file.name}
                              primaryTypographyProps={{ variant: 'h5' }}
                            />
                          </div>
                          <div style={{ textAlign: 'end' }}>
                            <DeleteTwoToneIcon
                              className="pointer"
                              onClick={() =>
                                this.handleRemoveAll('idcardImage')
                              }
                            />
                          </div>
                        </ListItem>
                      ))}
                  </List>
                </React.Fragment>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <div style={{ width: '100%', textAlign: 'right' }}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                style={{ backgroundColor: colors.green[600], color: 'white' }}>
                บันทึกข้อมูล
              </Button>
            </div>
          </CardActions>
        </form>
        <div id="showImg" class="modal" style={{ zIndex: '99' }}>
          <span class="close" onClick={() => this.handleCloseDisplayImg()}>
            &times;
          </span>
          <img class="modal-content" id="imgContent" />
        </div>
        <DialogAlert data={alertMessage} close={this.closeAlert} />
      </Card>
    );
  }
}
export default compose(
  withStyles(useStyles),
  inject('alertStore', 'accountStore')
)(observer(AccountDetails));
