import { OPTION_RENDER } from '../../components/OptionRender/OptionRender';

export const INPUT_RENDER = [
  {
    name: 'campaignName',
    displayName: 'ชื่อแคมเปญ',
    placeholder: [],
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: true,
    disabled: false,
    widthCol: 11,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 2,
      input: 10
    }
  },
  {
    name: 'campaignDesc',
    displayName: 'คำอธิบาย',
    showDisplayName: true,
    placeholder: [],
    type: 'text',
    value: '',
    option: [],
    required: true,
    disabled: false,
    widthCol: 11,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 2,
      input: 10
    }
  },
  {
    name: 'campaignImage',
    displayName: 'รูป',
    showDisplayName: true,
    type: 'upload',
    maxFile: 1,
    canUpload: true,
    value: [],
    option: [],
    required: false,
    widthCol: 11,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 2,
      input: 10
    }
  },
  {
    name: 'monthYear',
    displayName: 'เลือกเดือนปี',
    showDisplayName: true,
    // type: 'textarea',
    type: 'month',
    value: new Date(),
    option: [],
    required: true,
    disabled: false,
    widthCol: 11,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 2,
      input: 10
    }
  },
  {
    name: 'userType',
    displayName: 'ประเภทผู้ใช้งาน',
    showDisplayName: true,
    type: 'option',
    value: '',
    option: OPTION_RENDER('userType', false),
    required: true,
    widthCol: 11,
    styleXs: {
      label: 4,
      input: 8
    },
    styleSm: {
      label: 2,
      input: 10
    }
  }
];

export const INPUT_RENDER_MODAL_TRADE = [
  {
    name: 'itemNumber',
    displayName: 'ชื่อสินค้า',
    showDisplayName: true,
    type: 'option',
    value: '',
    option: [],
    required: true,
    styleXs: {
      label: 4,
      input: 8
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'point',
    displayName: 'อินเซนทีฟ',
    showDisplayName: true,
    type: 'number',
    value: '',
    option: [],
    required: true,
    styleXs: {
      label: 4,
      input: 8
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
];

export const INPUT_RENDER_MODAL_PRODUCT = [
  {
    name: 'itemG3Code',
    displayName: 'กลุ่มสินค้า',
    showDisplayName: true,
    type: 'option',
    value: '',
    option: [],
    required: true,
    styleXs: {
      label: 4,
      input: 8
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'itemNumber',
    displayName: 'ชื่อสินค้า',
    showDisplayName: true,
    type: 'option',
    value: '',
    option: [],
    required: true,
    styleXs: {
      label: 4,
      input: 8
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'point',
    displayName: 'อินเซนทีฟ',
    showDisplayName: true,
    type: 'number',
    value: '',
    option: [],
    required: true,
    styleXs: {
      label: 4,
      input: 8
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
];

export const TABLE_HEADER = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'itemNumber',
    label: 'รหัสสินค้า'
  },
  {
    key: 'productName',
    label: 'ชื่อสินค้า'
  },
  {
    key: 'point',
    label: 'อินเซนทีฟ'
  },
  {
    key: 'action',
    label: ''
  }
];

export const SCHEMA_TRADE = {
  itemNumber: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกสินค้า' },
    length: {
      maximum: 255
    }
  },
  point: {
    presence: { allowEmpty: false, message: '^กรุณากรอกอินเซนทีฟ' }
  }
};

export const SCHEMA_PRODUCT = {
  itemG3Code: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกกลุ่มสินค้า' },
    length: {
      maximum: 255
    }
  },
  itemNumber: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกสินค้า' },
    length: {
      maximum: 255
    }
  },
  point: {
    presence: { allowEmpty: false, message: '^กรุณากรอกอินเซนทีฟ' }
  }
};
