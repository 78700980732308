import React, { Component } from 'react';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';

import { RouteWithLayout } from './components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  MinimalPC as MinimalPCLayout
} from './layouts';

import { inject, observer } from 'mobx-react';
import { ThemeProvider } from '@material-ui/styles';
// import theme from './theme';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Register as RegisterView,
  SalesHistory as SalesHistoryView,
  PCManagement as PCManagementView,
  SignInPC as SignInPCView,
  ContactUs as ContactUsView,
  ApprovalPC as ApprovalPCView,
  CampaignManagement as CampaignManagementView,
  SetPointProductCampaign as SetPointProductCampaignView,
  SalesList as SalesListView,
  AddSalesList as AddSalesListView,
  ReportMain as ReportMainView,
  SalesListReport as SalesListReportView,
  ProductManagement as ProductManagementView,
  IncentiveReport as IncentiveReportView,
  FinancialReport as FinancialReportView,
  SalesReport as SalesReportView,
  CustomerReport as CustomerReportView,
  LoginManagement as LoginManagementView,
  ApprovalSaleList as ApprovalSaleListView,
  TransferSummeryProduct as TransferSummeryProductView,
  TransferSummeryTrade as TransferSummeryTradeView,
  PendingApprovalToAccount as PendingApprovalToAccountView,
  DashboardAdmin as DashboardAdminView,
  IncentiveByCampaignReport as IncentiveByCampaignReportView,
  Branch as BranchView
} from './views';

import IncentiveReportPreview from './views/IncentiveReport/incentiveReportPreview';

import { createMuiTheme } from '@material-ui/core';

import palette from './theme/palette';
import palette2 from './theme/palette2';
import palette3 from './theme/palette3';
import typography from './theme/typography';
import overrides from './theme/overrides';
import ReactGA from 'react-ga';

class Routes extends Component {
  state = {
    setPalete: '',
    roleId: '',
    userData: {}
  };

  componentDidMount() {
    //initialize with google analytics
    ReactGA.initialize('UA-173234435-2');
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (JSON.parse(localStorage.getItem('userData'))) {
      if (
        JSON.parse(localStorage.getItem('userData')).roleId == '1' ||
        JSON.parse(localStorage.getItem('userData')).roleId == '3' 
      ) {
        this.setState({ setPalete: palette2 });
      } else {
        this.setState({ setPalete: palette3 });
      }
      this.setState({
        roleId: JSON.parse(localStorage.getItem('userData')).roleId
      });
    } else {
      this.setState({ setPalete: palette, roleId: '' });
    }
  }

  componentDidUpdate() {
    if (JSON.parse(localStorage.getItem('userData'))) {
      if (
        this.state.roleId !==
          JSON.parse(localStorage.getItem('userData')).userId &&
        this.state.roleId === ''
      ) {
        if (
          JSON.parse(localStorage.getItem('userData')).roleId == '1' ||
          JSON.parse(localStorage.getItem('userData')).roleId == '3'
        ) {
          this.setState({ setPalete: palette2 });
        } else {
          this.setState({ setPalete: palette3 });
        }
        this.setState({
          roleId: JSON.parse(localStorage.getItem('userData')).roleId
        });
      }
    } else if (
      !JSON.parse(localStorage.getItem('userData')) &&
      this.state.roleId !== ''
    ) {
      this.setState({ setPalete: palette, roleId: '' });
    }
  }

  render() {
    let theme = createMuiTheme({
      palette: this.state.setPalete,
      typography,
      overrides,
      zIndex: {
        appBar: 1200,
        drawer: 1100
      }
    });

    return (
      <ThemeProvider theme={theme}>
        <Switch>
          {localStorage.getItem('logged') &&
          JSON.parse(localStorage.getItem('userData')) ? (
            RouterWithRole(
              JSON.parse(localStorage.getItem('userData')),
              this.props.loginStore.pcGroupLogin
            )
          ) : (
            <Redirect exact from="/" to="/login" />
          )}
          <RouteWithLayout
            component={SignInView}
            exact
            layout={MinimalLayout}
            path="/admin"
          />
          <RouteWithLayout
            component={SignUpView}
            exact
            layout={MinimalLayout}
            path="/sign-up"
          />
          <RouteWithLayout
            component={SignInPCView}
            exact
            layout={MinimalPCLayout}
            path="/login"
          />
          <RouteWithLayout
            component={RegisterView}
            exact
            layout={MinimalLayout}
            path="/register"
          />
          <RouteWithLayout
            component={NotFoundView}
            exact
            layout={MinimalLayout}
            path="/not-found"
          />
          ,
          <Redirect to="/not-found" />
        </Switch>
      </ThemeProvider>
    );
  }
}

// export default Routes;
export default inject('loginStore')(observer(withRouter(Routes)));

const RouterWithRole = (userPersonal, pcGroupLogin) => {

  switch (userPersonal.roleName) {
    case 'ADMIN':
      switch (userPersonal.roleId) {
        case 0:
          return [
            <Redirect exact from="/" to="/dashboardAdmin" />,
            <RouterAuten
              component={DashboardAdminView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/dashboardAdmin"
            />,
            <RouterAuten
              component={ReportMainView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/reportMain"
            />,
            <RouterAuten
              component={SalesListReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/salesListReport"
            />,
            <RouterAuten
              component={IncentiveByCampaignReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/incentiveByCampaignReport"
            />,
            <RouterAuten
              component={IncentiveReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/incentiveReport"
            />,
            <RouterAuten
              component={FinancialReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/financialReport"
            />,
            <RouterAuten
              component={SalesReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/salesReport"
            />,
            <RouterAuten
              component={CustomerReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/customerReport"
            />,
            <RouterAuten
              component={SalesHistoryView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/salesHistory"
            />,
            <RouterAuten
              component={PCManagementView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/pc"
            />,
            <RouterAuten
              component={ApprovalPCView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/approvalPC"
            />,
            <RouterAuten
              component={CampaignManagementView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/campaignManagement"
            />,
            <RouterAuten
              component={SetPointProductCampaignView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/setPointProductCampaign"
            />,
            <RouterAuten
              component={ProductManagementView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/productManagement"
            />,
            <RouterAuten
              component={LoginManagementView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/loginManagement"
            />,
            <RouterAuten
              component={TransferSummeryProductView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/transferSummeryProduct"
            />,
            <RouterAuten
              component={TransferSummeryTradeView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/transferSummeryTrade"
            />,
            <RouterAuten
              component={PendingApprovalToAccountView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/pendingApprovalToAccount"
            />,
            <RouterAuten
              component={ApprovalSaleListView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/approvalSaleList"
            />,
            <RouterAuten
              component={IncentiveReportPreview}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path=""
            />
          ];
        case 1:
          return [
            <Redirect exact from="/" to="/dashboardAdmin" />,
            <RouterAuten
              component={DashboardAdminView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/dashboardAdmin"
            />,
            <RouterAuten
              component={ReportMainView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/reportMain"
            />,
            <RouterAuten
              component={SalesListReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/salesListReport"
            />,
            <RouterAuten
              component={IncentiveByCampaignReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/IncentiveByCampaignReport"
            />,
            <RouterAuten
              component={IncentiveReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/incentiveReport"
            />,
            <RouterAuten
              component={FinancialReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/financialReport"
            />,
            <RouterAuten
              component={SalesReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/salesReport"
            />,
            <RouterAuten
              component={CustomerReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/customerReport"
            />,
            <RouterAuten
              component={SalesHistoryView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/salesHistory"
            />,
            <RouterAuten
              component={PCManagementView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/pc"
            />,
            <RouterAuten
              component={ApprovalPCView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/approvalPC"
            />,
            <RouterAuten
              component={CampaignManagementView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/campaignManagement"
            />,
            <RouterAuten
              component={SetPointProductCampaignView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/setPointProductCampaign"
            />,
            <RouterAuten
              component={ProductManagementView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/productManagement"
            />,
            <RouterAuten
              component={TransferSummeryProductView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/transferSummeryProduct"
            />,
            <RouterAuten
              component={PendingApprovalToAccountView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/pendingApprovalToAccount"
            />,
            <RouterAuten
              component={IncentiveReportPreview}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/incentiveReportPreview"
            />,
            <RouterAuten
              component={BranchView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/branch"
            />
          ];
        case 2:
          return [
            <Redirect exact from="/" to="/dashboardAdmin" />,
            <RouterAuten
              component={DashboardAdminView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/dashboardAdmin"
            />,
            <RouterAuten
              component={ReportMainView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/reportMain"
            />,
            <RouterAuten
              component={SalesListReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/salesListReport"
            />,
            <RouterAuten
              component={IncentiveByCampaignReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/IncentiveByCampaignReport"
            />,
            <RouterAuten
              component={IncentiveReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/incentiveReport"
            />,
            <RouterAuten
              component={FinancialReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/financialReport"
            />,
            <RouterAuten
              component={SalesReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/salesReport"
            />,
            <RouterAuten
              component={CustomerReportView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/customerReport"
            />,
            <RouterAuten
              component={SalesHistoryView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/salesHistory"
            />,
            <RouterAuten
              component={PCManagementView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/pc"
            />,
            <RouterAuten
              component={ApprovalPCView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/approvalPC"
            />,
            <RouterAuten
              component={CampaignManagementView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/campaignManagement"
            />,
            <RouterAuten
              component={SetPointProductCampaignView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/setPointProductCampaign"
            />,
            <RouterAuten
              component={ProductManagementView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/productManagement"
            />,
            <RouterAuten
              component={LoginManagementView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/loginManagement"
            />,
            <RouterAuten
              component={TransferSummeryProductView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/transferSummeryProduct"
            />,
            <RouterAuten
              component={TransferSummeryTradeView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/transferSummeryTrade"
            />,
            <RouterAuten
              component={TransferSummeryTradeView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/transferSummeryTrade"
            />,
            <RouterAuten
              component={PendingApprovalToAccountView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/pendingApprovalToAccount"
            />,
            <RouterAuten
              component={ApprovalSaleListView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/approvalSaleList"
            />,
            <RouterAuten
              component={IncentiveReportPreview}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/incentiveReportPreview"
            />,
            <RouterAuten
              component={BranchView}
              exact
              userPersonal={userPersonal}
              layout={MainLayout}
              path="/branch"
            />
          ];
      }
      break;
    case 'PC':
      switch (userPersonal.approvalStatus) {
        case '1':
          switch (userPersonal.roleId) {
            case 2:
              return [
                <Redirect exact from="/" to="/dashboard" />,
                <RouterAuten
                  component={DashboardView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/dashboard"
                />,
                <RouterAuten
                  component={AccountView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/account"
                />,
                // <RouterAuten
                // 	component={SettingsView}
                // 	exact
                // 	userPersonal={userPersonal}
                // 	layout={MainLayout}
                // 	path="/settings"
                // />,
                <RouterAuten
                  component={SalesHistoryView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/salesHistory"
                />,
                <RouterAuten
                  component={ContactUsView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/contactUs"
                />
              ];
            case 3:
              return [
                <Redirect exact from="/" to="/dashboard" />,
                <RouterAuten
                  component={DashboardView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/dashboard"
                />,
                <RouterAuten
                  component={AccountView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/account"
                />,
                // <RouterAuten
                // 	component={SettingsView}
                // 	exact
                // 	userPersonal={userPersonal}
                // 	layout={MainLayout}
                // 	path="/settings"
                // />,
                <RouterAuten
                  component={SalesHistoryView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/salesHistory"
                />,
                <RouterAuten
                  component={SalesListView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/salesList"
                />,
                <RouterAuten
                  component={AddSalesListView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/addSalesList"
                />,
                <RouterAuten
                  component={ContactUsView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/contactUs"
                />
              ];
            case 4:
              return [
                <Redirect exact from="/" to="/dashboard" />,
                <RouterAuten
                  component={DashboardView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/dashboard"
                />,
                <RouterAuten
                  component={AccountView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/account"
                />,
                // <RouterAuten
                // 	component={SettingsView}
                // 	exact
                // 	userPersonal={userPersonal}
                // 	layout={MainLayout}
                // 	path="/settings"
                // />,
                <RouterAuten
                  component={SalesHistoryView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/salesHistory"
                />,
                <RouterAuten
                  component={SalesListView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/salesList"
                />,
                <RouterAuten
                  component={AddSalesListView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/addSalesList"
                />,
                <RouterAuten
                  component={ContactUsView}
                  exact
                  userPersonal={userPersonal}
                  layout={MainLayout}
                  path="/contactUs"
                />
              ];
          }
          break;
        case '2':
          return [
            <Redirect
              exact
              from="/"
              to={
                pcGroupLogin.name !== ''
                  ? '/sign-up?g=' + pcGroupLogin.name
                  : '/sign-up'
              }
            />,
            <RouterAuten
              component={SignUpView}
              exact
              userPersonal={userPersonal}
              layout={MinimalLayout}
              path={
                pcGroupLogin.name !== ''
                  ? '/sign-up?g=' + pcGroupLogin.name
                  : '/sign-up'
              }
            />
          ];
        case '3':
        case '5':
          return [
            <Redirect
              exact
              from="/"
              to={
                pcGroupLogin.name !== ''
                  ? '/sign-up?g=' + pcGroupLogin.name
                  : '/sign-up'
              }
            />,
            <RouterAuten
              component={SignUpView}
              exact
              userPersonal={userPersonal}
              layout={MinimalLayout}
              path={
                pcGroupLogin.name !== ''
                  ? '/sign-up?g=' + pcGroupLogin.name
                  : '/sign-up'
              }
            />
          ];
      }

    default:
      return [
        <RouteWithLayout
          component={SignInView}
          exact
          layout={MinimalLayout}
          path="/login"
        />
      ];
  }
};

const RouterAuten = ({ ...rest }) => {
  let token = rest.userPersonal.token;
  return token ? (
    <RouteWithLayout {...rest} />
  ) : (
    <RouteWithLayout
      component={SignInView}
      exact
      layout={MinimalLayout}
      path="/login"
    />
  );
};
