import { observable, computed, action, decorate } from "mobx"

class approvalPCStore {

	TABLE_CONTENT = []

	pcNo = ''

	historySearch = {}
	

}
decorate(approvalPCStore, {
	TABLE_CONTENT:  observable,
	pcNo: observable,
	historySearch: observable,
})

export default new approvalPCStore()

