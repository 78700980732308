import React, { Component } from 'react';
import TableRender from '../../../../components/TableRender/TableRender'
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Button
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { TABLE_HEADER } from '../../PendingApprovalToAccountStructure'
import ServicesPendingApprovalToAccount from '../../../../services/servicesPendingApprovalToAccount'
import { callDialogAlert } from '../../../../components/DialogAlert/DialogAlert'
import DialogAlert from '../../../../components/DialogAlert/DialogAlert'


class PendingApprovalToAccountTable extends Component {
	state = {
		alertMessage: {
			content: '',
			open: false,
			status: ''
		},
	}

	handleRevokeReportForApprove = () => {
		ServicesPendingApprovalToAccount.revokeReportForApprove().then(res => {
			switch (res.status) {
				case 200:
					this.setState({ alertMessage: callDialogAlert('success', 'ดำเนินการสำเร็จ') })
					break;
				default:
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}
		})
	}

	closeAlert = () => {
		if (this.state.alertMessage.status === 'success') {
			// this.handleBack()
			// this.searchSubmit()
		}

		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
		})
	}

	render() {
		const { alertMessage } = this.state;

		return (
			<React.Fragment>
				<DialogAlert data={alertMessage} close={this.closeAlert} />
				<Card>
					<CardHeader
						// action={
						// 	<Button
						// 		color="secondary"
						// 		// disabled={!formState.isValid}
						// 		// fullWidth
						// 		size="large"
						// 		type="submit"
						// 		variant="contained"
						// 		onClick={() => this.handleRevokeReportForApprove()}
						// 	>
						// 		ตีกลับสรุปยอดโอน
						// 	</Button>
						// }
					// title={
					// 	"สรุปยอดโอน"
					// }
					/>
					<Divider />
					<CardContent >
						<TableRender TABLE_HEADER={TABLE_HEADER} TABLE_CONTENT={this.props.pendingApprovalToAccountStore.TABLE_CONTENT} />
					</CardContent>
					<Divider />
				</Card>
			</React.Fragment>
		);
	}
}

export default inject('pendingApprovalToAccountStore')(observer(PendingApprovalToAccountTable))