import React, { Component } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import validate from 'validate.js';
import { withStyles } from '@material-ui/core/styles';
import {
	Grid,
	Button,
	TextField,
	Link,
	Typography,
} from '@material-ui/core';
import compose from 'recompose/compose'
import ServiceLogin from '../../services/servicesLogin'
import Alert from '@material-ui/lab/Alert';
import { inject, observer } from 'mobx-react';
import adminLogo from '../../assets/adminLogo.jpg'

const schema = {
	username: {
		presence: { allowEmpty: false, message: '^กรุณากรอกชื่อผู้ใช้' },
		length: {
			maximum: 64
		}
	},
	password: {
		presence: { allowEmpty: false, message: '^กรุณากรอกรหัสผ่าน' },
		length: {
			maximum: 128
		}
	}
};

const useStyles = theme => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: '100%'
	},
	grid: {
		height: '100%'
	},
	quoteContainer: {
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	quote: {
		backgroundColor: theme.palette.neutral,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundImage: 'url(/images/auth.jpg)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
	},
	quoteInner: {
		textAlign: 'center',
		flexBasis: '600px'
	},
	quoteText: {
		color: theme.palette.white,
		fontWeight: 300
	},
	name: {
		marginTop: theme.spacing(3),
		color: theme.palette.white
	},
	bio: {
		color: theme.palette.white
	},
	contentContainer: {},
	content: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	contentHeader: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: theme.spacing(5),
		paddingBototm: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	logoImage: {
		marginLeft: theme.spacing(4)
	},
	contentBody: {
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center'
		}
	},
	form: {
		margin: 'auto',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	},
	title: {
		marginTop: theme.spacing(3)
	},
	socialButtons: {
		marginTop: theme.spacing(3)
	},
	socialIcon: {
		marginRight: theme.spacing(1)
	},
	sugestion: {
		marginTop: theme.spacing(2)
	},
	textField: {
		marginTop: theme.spacing(2)
	},
	signInButton: {
		margin: theme.spacing(2, 0)
	}
});

class SignIn extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formState: {
				isValid: false,
				values: {},
				touched: {},
				errors: {}
			},
		}
	}

	componentDidMount() {
		localStorage.removeItem("userData");
		localStorage.setItem('logged', false);

	}

	handleChange = (event) => {
		event.persist();
		let formState = this.state.formState
		formState = {
			...formState,
			values: {
				...formState.values,
				[event.target.name]:
					event.target.type === 'checkbox'
						? event.target.checked
						: event.target.value
			},
			touched: {
				...formState.touched,
				[event.target.name]: true
			}
		}

		/*check validate empty onChange*/
		if (formState.touched[event.target.name]) {
			const errors = validate(formState.values, schema);
			formState = {
				...formState,
				isValid: errors ? false : true,
				errors: errors || {}
			}
		}

		this.setState({ formState: formState })
	}

	handleSignIn = event => {
		this.setState({ loginMessage: '' })
		event.preventDefault();
		let formState = this.state.formState
		ServiceLogin.login(formState.values).then((res) => {
			switch (res.status) {
				case 200:
					localStorage.setItem('userData', JSON.stringify(res.data.result));
					localStorage.setItem('logged', true);
					this.props.loginStore.userData = res.data.result;
					this.props.history.push('/');
					this.setState({ loginMessage: res.data.message })
					break;
				default: this.setState({ loginMessage: res.data.message })
			}
		})
	}

	hasError = field => {
		return this.state.formState.touched[field] && this.state.formState.errors[field] ? true : false;
	}

	render() {
		const { classes } = this.props;
		const { formState } = this.state;

		return (
			<div className={classes.root}>
				<Grid
					className={classes.grid}
					container
				>
					<Grid
						className={classes.content}
						item
						lg={12}
						xs={12}
					>
						<div className={classes.content}>
							<div className={classes.contentBody}>
								<form
									className={classes.form}
									onSubmit={(e) => this.handleSignIn(e)}
								>
									<div style={{ textAlign: 'center' }}>
										<img src={adminLogo} style={{ width: '75%', objectFit: 'cover', height: '150px' }} />
										<Typography
											className={classes.title}
											variant="h2"
										>
											เข้าสู่ระบบ - ผู้ดูแลระบบ
										</Typography>
									</div>
									{this.state.loginMessage && <Alert severity="error">{this.state.loginMessage}</Alert>}
									<TextField
										className={classes.textField}
										error={this.hasError('username')}
										fullWidth
										helperText={this.hasError('username') ? formState.errors.username[0] : null}
										label="ชื่อผู้ใช้"
										name="username"
										onChange={(e) => this.handleChange(e)}
										type="text"
										value={formState.values.username || ''}
										variant="outlined"
									/>
									<TextField
										className={classes.textField}
										error={this.hasError('password')}
										fullWidth
										helperText={
											this.hasError('password') ? formState.errors.password[0] : null
										}
										label="รหัสผ่าน"
										name="password"
										onChange={(e) => this.handleChange(e)}
										type="password"
										value={formState.values.password || ''}
										variant="outlined"
									/>
									<Button
										className={classes.signInButton}
										color="primary"
										disabled={!formState.isValid}
										fullWidth
										type="submit"
										variant="contained"
									>
										เข้าสู่ระบบ
                					</Button>
								</form>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default compose(
	withStyles(useStyles),
	inject('loginStore')
)(observer(withRouter(SignIn)))
