import { observable, computed, action, decorate } from "mobx"

class pendingApprovalToAccountStore {

	TABLE_CONTENT = []
	
	historySearch = {}

	idModal = ''

}
decorate(pendingApprovalToAccountStore, {
	TABLE_CONTENT:  observable,
	idModal:  observable,
	historySearch: observable,
})

export default new pendingApprovalToAccountStore()

