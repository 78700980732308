import React, { Component } from 'react';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { isExistData } from 'constants/function/checkDataValue';
import palette from 'theme/palette';

import { options } from './chart';
import { SelectStyle } from './styled-components';
import BarChartIcon from '@material-ui/icons/BarChart';

const useStyles = () => ({
  root: {},
  title: {
    color: '#fff',
    fontSize: '16px'
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  avatar: {
    fontSize: '18px',
    color: '#fff'
  }
});

class UsersBar extends Component {
  render() {
    const {
      approved,
      pcAll,
      selectedYear,
      handleChangeSelect,
      optionYear,
      className,
      classes,
      ...rest
    } = this.props;

    let data = {};
    data.labels = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    data.datasets = [];
    data.datasets.push(
      {
        label: 'PC ทั้งหมด',
        backgroundColor: '#EB3E78',
        data: pcAll
      },
      {
        label: 'PC ที่ได้ค่าสนับสนุน',
        backgroundColor: '#43A047',
        data: approved
      }
    );
    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader
          classes={{
            title: classes.title,
            avatar: classes.avatar
          }}
          avatar={<BarChartIcon />}
          title="PC ที่ได้รับค่าสนับสนุน"
          style={{ backgroundColor: '#43A047' }}></CardHeader>
        <CardContent>
          {
            <SelectStyle
              value={optionYear.filter(opt => opt.value === selectedYear)}
              onChange={handleChangeSelect}
              options={optionYear}
            />
          }
          <Bar data={data} options={options} height={250} />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(useStyles)(UsersBar);
