import { observable, computed, action, decorate } from "mobx"
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { Component } from 'react';


class alertStore {

	patternAlert = [
        {
            status: 'processing',
            icon: '',
            color: '#FF8800',
        },
        {
            status: 'success',
            icon: <CheckIcon />,
            color: '#41C770',
        },
        {
            status: 'warning',
            icon: <ErrorOutlineIcon/>,
            color: '#FF8800',
        },
        {
            status: 'error',
            icon: <CancelIcon/>,
            color: '#FF5858',
        },
	];
	
	alert = {
        open: false,
        messages: '',
        status: '',
        color: '',
        icon: '',
	}

	// custom alert when done with service
    customAlertMessage = (messages, status) => {
        this.patternAlert.map((val, i) => {
            if (status === val.status) {
                this.alert = {
                    open: true,
                    messages: messages,
                    status: val.status,
                    color: val.color,
                    icon: val.icon,
                }
            }
        })
        if(status !== "processing") {
            this.resetCustomAlertMessage();
        }
    }

    resetCustomAlertMessage = () => {
        setTimeout(() => {
            this.alert = {
                open: false,
                messages: '',
                status: '',
                color: '',
                icon: '',
            }
        }, 5000)
    }
	

}

decorate(alertStore, {
	alert:  observable,
})

export default new alertStore()

