import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesSignup{

	updatePCRegister(requestData) {
		return sendRequest("/pc/updatePC", HTTP.POST, requestData)
	}

	deleteFile(requestData) {
		return sendRequest("/pc/delete-file", HTTP.POST, requestData)
	}

	getPCInfo(requestData){
		return sendRequest("/pc/getPCInfo", HTTP.POST, requestData)
	}

	registerPC(requestData){
		return sendRequest("/pc/registerPC", HTTP.POST, requestData)
	}

	updateProfile(requestData){
		return sendRequest("/pc/updateProfile", HTTP.POST, requestData)
	}

	onUpdate(requestData){
		return sendRequest("/pc/update", HTTP.POST, requestData)
	}
}

export default new ServicesSignup()
