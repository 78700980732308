import React, { Component } from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField
} from '@material-ui/core';
import ServicesOption from '../../services/servicesOption';
import FilterRender from '../../components/FilterRender/FilterRender';
import TableRender from '../../components/TableRender/TableRender';
import PCUpdateModal from './PCUpdateModal';
import PCAccountModal from './PCAccountModal';
import { colors } from '@material-ui/core';
import { TABLE_HEADER } from './PCManagementStructure';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';
import DialogAlert from '../../components/DialogAlert/DialogAlert';
import { CSVLink } from 'react-csv';
import { POST, GET } from 'services/sendRequestAPI';
import { Edit, Email, Refresh } from '@material-ui/icons'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import data from 'views/ProductList/data';
// import { async } from 'validate.js';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});
class PCManagement extends Component {
  constructor(props) {
    super(props);
      this.state = {
        filterList: [],
        optionGroup: [],
        optionStatus: [],
        selectGroup: '',
        openPCAccountModal: false,
        openPCUpdateModal: false,
        openSMSModal: false,
        openResetPasswordModal: false,
        TABLE_CONTENT: [],
        TABLE_CONTENT_EXPORT: [],
        search: '',
        statusRow: '',
        alertMessage: {
          content: '',
          open: false,
          status: ''
        }
    }
  }
  

  async componentDidMount() {
    await this.optionGroup()
    await this.loadFilter()
    await this.onGetAll()
  }

  loadFilter = () => {
    this.setState({
      filterList: [{
          name: 'search',
          displayName: 'ค้นหา',
          placeholder: ' รหัสพีซี, ชื่อพีซี, ชื่อร้าน, สถานะ',
          showDisplayName: true,
          type: 'text',
          value: '',
          shrink: true,
          required: true,
          widthCol: 5,
          styleXs: {
            label: 2,
            input: 10,
          },
          styleSm: {
            label: 2,
            input: 10,
          },
        },
        {
          name: 'groupNo',
          displayName: 'ทีม',
          showDisplayName: true,
          type: 'option',
          value: '',
          option: this.state.optionGroup,
          shrink: true,
          required: false,
          widthCol: 5,
          styleXs: {
            label: 2,
            input: 10
          },
          styleSm: {
            label: 2,
            input: 10
          }
        }
      ]
    })
  }

  optionGroup = async () => {

    const workSection = JSON.parse(localStorage.getItem('userData')).workSection
    const res = await ServicesOption.getAllGroup({ workSection })

    if(res.data.success){
      const option = res.data.result.map(e => {
        return {
          label: e.groupName,
          value: e.groupNo
        }
      })
      this.setState({ optionGroup: [ { label: 'NONE', value: '' } ,...option ] });
    }

  }

  async onGetAll(){
    try {
      this.setState({ alertMessage: callDialogAlert('loading', '') })

      const search = this.state.search || ""
      const groupNo = this.state.selectGroup
      const body = {
        search,
        groupNo
      }
      const res = await POST('/pc/get-all', body)
      if(res.data.success){
        const data = res.data.result.data.map((e, i) => {
          return {
            rowNo: i + 1,
            pcNo: e.pcNo,
            pcName: e.firstName,
            fullName: `${e.firstName} ${e.lastName}`,
            groupName: e.groupName,
            shopName: e.shopName,
            locationName: e.locationName,
            tel: e.tel,
            approvalStatus: (
              <span
                style={{ 
                  color: this.colorBg(e.approvalStatus) 
                }}>
                {
                  e.approvalStatusName
                }
              </span>
            ),
            createDate: e.createDate,
            // action: this.buttonAction(e.approvalStatus, e.pcNo)
            action: this.buttonAction(e)
          }
        })

        this.setState({ 
          TABLE_CONTENT: data, 
          TABLE_CONTENT_EXPORT: data.map(e => { return {...e, approvalStatus: e.approvalStatus.props.children } 
          }) 
        });

      }

      this.setState({ alertMessage: {...this.state.alertMessage.open = false} })
     
    } catch (error) {
      console.log(error)
    }
    
  }

  colorBg = status => {
    switch (status) {
      case '1':
        return colors.green[400];
      case '2':
        return colors.blue[400];
      case '3':
        return colors.orange[400];
      case '4':
        return colors.red[400];
    }
  };

  buttonAction = (obj) => {
    return [
      
      <Edit
        type="submit"
        style={{ margin:'2px', color:'#f5b03f'}}
        titleAccess="แก้ไข"
        onClick={() => this.handleOpenUpdatePCModal(obj)}>
      </Edit>,
      <Refresh
        type="submit"
        style={{ margin:'2px', color:'#5ebc6e'}}
        titleAccess="รีเซ็ตรหัสผ่าน"
        onClick={() => this.handleOpenChangePasswordModal(obj)}>
      </Refresh>,
      <Email
        type="submit"
        style={{ margin:'5px', color:'#407db2'}}
        titleAccess="ส่ง SMS"
        onClick={() => this.handleOpenSendSMSModal(obj)}>
      </Email>
    ]

    // switch (status) {
    //     case '1':
    //     case '3':
    //     return [
    //       <Button
    //         type="submit"
    //         variant="contained"
    //         color="secondary"
    //         onClick={() => this.handleOpenUpdatePCModal(status, value)}>
    //         แก้ไข
    //       </Button>
    //     ];
    //   // case '3':
    //   //   return [
    //   //     <Button
    //   //       color="primary"
    //   //       type="submit"
    //   //       variant="contained"
    //   //       onClick={() => this.handleOpenUpdatePCModal(status, value)}>
    //   //       จัดการ
    //   //     </Button>
    //   //   ];

    //   case '4':
    //     return [
    //       <Button
    //         color="secondary"
    //         type="submit"
    //         variant="contained"
    //         onClick={() => this.handleOpenUpdatePCModal(status, value)}>
    //         เพิ่มเติม
    //       </Button>
    //     ];
    // }
  };


  handleChangeFilter = (name, value) => {

    if(name === 'groupNo'){
        this.setState({ selectGroup: value })
    }

    const filterList = [...this.state.filterList];

    filterList.map((val, i) => {
      if (val.name === name) {
        if (value === null) {
          value = [];
        }
        val.value = value;
      }
    });

    this.setState({ [name]: value })
    this.setState({ filterList });
  }

  handleOpenUpdatePCModal = (data) => {
    this.props.pcManagementStore.updatePCModal.idPC = data.pcNo;
    this.setState({ statusRow: data.approvalStatus });
  }

  
  handleOpenChangePasswordModal = (data) => {
    this.setState({ 
      dataRow: data, 
      openResetPasswordModal: true 
    });

  }

  handleOpenSendSMSModal = (data) => {
    this.setState({ 
      dataRow: data, 
      openSMSModal: true 
    });
  }

  actionSendSMS = async () => {
    try {

        this.setState({ openSMSModal: false, alertMessage: callDialogAlert('loading', '') })
        const msg = this.state.txtSMS
        if(!msg){
          this.setState({ alertMessage: callDialogAlert('error', 'กรุณาพิมพ์ข้อความ') })
          return
        }

        const { tel } = this.state.dataRow
        const params = { tel, msg }
    
        const res = await GET('/service/send-sms', params)
        if(!res.data.success){
          this.setState({ alertMessage: callDialogAlert('error', 'พบปัญหาการส่ง SMS') })
          return
        }

        this.setState({ alertMessage: callDialogAlert('success', 'ส่งข้อความสำเร็จ') })
          
    } catch (error) {
      this.setState({ alertMessage: callDialogAlert('error', error) })
    }
    finally{
      this.setState({ openResetPasswordModal: false })
    }
  }

  actionResetPassword = async () => {
    try {
      this.setState({ openResetPasswordModal: false, alertMessage: callDialogAlert('loading', '') })
      const password = this.state.txtNewPassword
      if(!password){
        this.setState({ alertMessage: callDialogAlert('error', 'กรุณาพิมพ์รหัสผ่านใหม่') })
        return
      }

      this.setState({ openSMSModal: false, alertMessage: callDialogAlert('loading', '') })
      const { pcNo } = this.state.dataRow
      const body = { pcNo, password }
     
      const res = await POST('/pc/reset-password', body)
   
      if(!res.data.success){
        this.setState({ alertMessage: callDialogAlert('error', 'พบปัญหาการเปลี่ยนรหัสผ่าน') })
        return
      }

      this.setState({ alertMessage: callDialogAlert('success', 'เปลี่ยนรหัสผ่านสำเร็จ') })
        
    } catch (error) {
      this.setState({ alertMessage: callDialogAlert('error', error) })
    }
   
  }
  
  onChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      })
  }

  prepareExportContent = content => {
    if (content.length > 0) {
      return content.filter((val, i) => {
        if (val.key == 'approvalStatus') {
          val = val.approvalStatusValue;
        }
        return val.name !== 'action' && val;
      });
    } else {
      return [];
    }
  }

  prepareExportHeader = content => {
    if (content.length > 0) {
      return content.filter((val, i) => {
        return val.key !== 'action' && val;
      });
    } else {
      return [];
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      }
    });
  }

  render() {
    const { classes } = this.props;
    const {
      filterList,
      TABLE_CONTENT,
      alertMessage,
      TABLE_CONTENT_EXPORT,
      openSMSModal,
      openResetPasswordModal
    } = this.state;


    return (

      <React.Fragment>

          <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={openResetPasswordModal}
          >
              <DialogTitle> รีเซ็ตรหัสผ่าน </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        inputRef={e => e && e.focus()}
                        label="พิมพ์รหัสผ่านใหม่"
                        name="txtNewPassword"
                        style={{ width: '100%' }}
                        onChange={(e)=> this.onChange(e)}
                    />
                </DialogContent>
              <DialogActions>
              <Button color="secondary" onClick={()=> this.actionResetPassword()} >รีเซ็ต</Button>
              <Button onClick={()=>this.setState({ openResetPasswordModal: false })}>ยกเลิก</Button>
             
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={openSMSModal}
          >
              <DialogTitle> ส่งข้อความ </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        inputRef={e => e && e.focus()}
                        label="พิมพ์ข้อความ"
                        name="txtSMS"
                        multiline
                        style={{ width: '100%' }}
                        onChange={(e)=> this.onChange(e)}
                    />
                </DialogContent>
              <DialogActions>
              <Button color="secondary" onClick={()=> this.actionSendSMS()} >ส่ง SMS</Button>
              <Button onClick={()=>this.setState({ openSMSModal: false })}>ยกเลิก</Button>
             
            </DialogActions>
          </Dialog>

        <div className={classes.root}>
          <Card>
            <CardHeader
              title={'ข้อมูล'}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={4}>
                <FilterRender
                  filterList={ filterList }
                  handleChangeFilter={this.handleChangeFilter}
                />
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  style={{ textAlignLast: 'right' }}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    loading={false}
                    onClick={() => this.onGetAll()}>
                    ค้นหา
                   
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          &nbsp;
          <Grid container spacing={4}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Card>
                {(JSON.parse(localStorage.getItem('userData')) &&
                  JSON.parse(localStorage.getItem('userData')).roleId === 1) ||
                JSON.parse(localStorage.getItem('userData')).roleId === 0 ? (
                  <React.Fragment>
                    <CardHeader
                      title={
                        <Button
                          color="secondary"
                          type="submit"
                          variant="contained"
                          onClick={() => this.handleOpenPCAccountModal()}>
                          เพิ่มพีซี
                        </Button>
                      }
                      action={
                        TABLE_CONTENT.length > 0 ? (
                          <CSVLink
                            data={this.prepareExportContent(
                              TABLE_CONTENT_EXPORT
                            )}
                            headers={this.prepareExportHeader(TABLE_HEADER)}
                            filename={'customerReport.csv'}>
                            <Button
                              style={{
                                backgroundColor: colors.green[600],
                                color: 'white'
                              }}
                              variant="contained"
                            >
                              ส่งออกไฟล์ EXCEL
                            </Button>
                          </CSVLink>
                        ) : (
                          <Button
                            style={{
                              backgroundColor: colors.green[600],
                              color: 'white'
                            }}
                            variant="contained"
                            diabled
                          >
                            ส่งออกไฟล์ EXCEL
                          </Button>
                        )
                      }
                    />
                    <Divider />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <CardHeader
                      action={
                        TABLE_CONTENT.length > 0 ? (
                          <CSVLink
                            data={this.prepareExportContent(
                              TABLE_CONTENT_EXPORT
                            )}
                            headers={this.prepareExportHeader(TABLE_HEADER)}
                            filename={'customerReport.csv'}>
                            <Button
                              style={{
                                backgroundColor: colors.green[600],
                                color: 'white'
                              }}
                              variant="contained"
                            >
                              ส่งออกไฟล์ EXCEL
                            </Button>
                          </CSVLink>
                        ) : (
                          <Button
                            style={{
                              backgroundColor: colors.green[600],
                              color: 'white'
                            }}
                            variant="contained"
                            diabled
                          >
                            ส่งออกไฟล์ EXCEL
                          </Button>
                        )
                      }
                    />
                    <Divider />
                  </React.Fragment>
                )}
                <CardContent>
                  <TableRender
                    TABLE_HEADER={TABLE_HEADER}
                    TABLE_CONTENT={TABLE_CONTENT}
                  />
                </CardContent>
                <Divider />
                <PCAccountModal
                  searchSubmit={this.onGetAll}
                  open={this.state.openPCAccountModal}
                  close={this.handleClosePCAccountModal}
                />
                <PCUpdateModal
                  statusRow={this.state.statusRow}
                  searchSubmit={this.onGetAll}
                  open={
                    this.props.pcManagementStore.updatePCModal.idPC !== ''
                      ? true
                      : false
                  }
                  close={this.handleCloseUpdatePCModal}
                />
              </Card>
            </Grid>
          </Grid>
        </div>
        <DialogAlert data={alertMessage} close={this.closeAlert} />
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('alertStore', 'optionStore', 'pcManagementStore')
)(observer(PCManagement));
