import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesSalesReport {

	getReportSummarySales(requestData) {
		return sendRequest("/report/getReportSummarySales", HTTP.POST, requestData)
	}
}

export default new ServicesSalesReport()