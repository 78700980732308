import React, { Component } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Grid
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { inject, observer } from 'mobx-react';
import FilterRender from '../../components/FilterRender/FilterRender';
import ServicesOption from '../../services/servicesOption';
import validate from 'validate.js';
import {
  INPUT_RENDER_MODAL_PRODUCT,
  INPUT_RENDER_MODAL_TRADE,
  SCHEMA_PRODUCT,
  SCHEMA_TRADE
} from './SetPointProductCampaignStructure';
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert';
import DialogAlert from '../../components/DialogAlert/DialogAlert';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  button: {
    color: theme.palette.success
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const schema = {
  itemNumber: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกสินค้า' },
    length: {
      maximum: 255
    }
  },
  point: {
    presence: { allowEmpty: false, message: '^กรุณากรอกอินเซนทีฟ' }
  }
};

class AddProductPointModal extends Component {
  state = {
    filterList: [],
    formCheck: {
      isValid: false,
      touched: {},
      values: {},
      errors: {}
    },
    openModal: false,
    optionProductOrigin: [],
    schema: {},
    alertMessage: {
      content: '',
      open: false,
      status: ''
    }
  };

  componentDidMount() {
    // if (JSON.parse(localStorage.getItem('userData')).roleId === 2 || JSON.parse(localStorage.getItem('userData')).roleId === 0) {
    // 	this.setState({ filterList: INPUT_RENDER_MODAL_TRADE, schema: SCHEMA_TRADE }, () => {
    // 		let data = {
    // 			userLogin: JSON.parse(localStorage.getItem('userData')).userId,
    // 			roleId: JSON.parse(localStorage.getItem('userData')).roleId,
    // 			workSection: JSON.parse(localStorage.getItem('userData')).workSection
    // 		}
    // 		this.getItemProduct(0, data)
    // 	})
    // } else {
    this.setState(
      { filterList: INPUT_RENDER_MODAL_PRODUCT, schema: SCHEMA_PRODUCT },
      () => {
        // this.getItemProduct(1)
        this.getItemCategory();
      }
    );
    // }
  }

  componentDidUpdate() {
    let filterList = this.state.filterList;
    if (
      !this.state.openModal &&
      this.props.open &&
      this.props.campaignStore.optionProduct.length !==
        filterList[0].option.length
    ) {
      // if (JSON.parse(localStorage.getItem('userData')).roleId === 2 || JSON.parse(localStorage.getItem('userData')).roleId === 0) {
      // 	filterList[0].option = this.props.campaignStore.optionProduct
      // }
      // else {
      // 	filterList[1].option = this.props.campaignStore.optionProduct

      // }
      this.setState({ filterList, openModal: this.props.open });
    }
  }

  getItemCategory = () => {
    ServicesOption.getItemCategory({
      userLogin: JSON.parse(localStorage.getItem('userData')).userId,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
      // workSection: JSON.parse(localStorage.getItem('userData')).workSection
    }).then(res => {

      let filterList = this.state.filterList;
      switch (res.status) {
        case 200:
          let resBank = res.data.result;
          let optionBank = [];
          resBank.map((val, i) => {
            optionBank.push({
              value: val.itemG3Code,
              label: val.itemCategoryName
            });
          });

          // this.props.campaignStore.optionProduct = optionBank
          // filterList[0].option = this.props.campaignStore.optionProduct
          filterList[0].option = optionBank;

          this.setState({ filterList });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  getItemProduct = data => {
    ServicesOption.getItemProduct(data).then(res => {
      let filterList = this.state.filterList;
      switch (res.status) {
        case 200:
          let resBank = res.data.result;
          let option = [];
          resBank.map((val, i) => {
            option.push({
              value: val.itemNumber + '---' + val.productName,
              label: val.productName
            });
          });

          // if (JSON.parse(localStorage.getItem('userData')).roleId === 2 || JSON.parse(localStorage.getItem('userData')).roleId === 0) {
          // 	this.props.campaignStore.optionProduct = option
          // 	filterList[keyRole].option = this.props.campaignStore.optionProduct
          // } else {
          if (this.props.campaignStore.chooseProductLog.length === 0) {
            this.props.campaignStore.optionProduct = option;
            filterList[1].option = this.props.campaignStore.optionProduct;
          } else {
            let optionCopy = option;
            for (let i = 0; i < option.length; i++) {
              for (
                let j = 0;
                j < this.props.campaignStore.chooseProductLog.length;
                j++
              ) {
                if (
                  option[i].value ===
                  this.props.campaignStore.chooseProductLog[j].option
                ) {
                  optionCopy.splice(i, 1);
                }
              }
            }
            this.props.campaignStore.optionProduct = optionCopy;
            filterList[1].option = this.props.campaignStore.optionProduct;
          }
          // }
          this.setState({ filterList });
          break;
        default:
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  handleChangeFilter = (fieldName, value) => {
    let filterList = [...this.state.filterList];
    let formCheck = this.state.formCheck;

    filterList.map((val, i) => {
      if (val.name === fieldName) {
        if (val.name === 'point') {
          if (value === '') {
            formCheck.values[`${val.name}`] = value;
            val.value = value;
          } else {
            if (value < 1) {
              val.value = 1;
              formCheck.values[`${val.name}`] = 1;
            } else if (value >= 1) {
              val.value = value;
              formCheck.values[`${val.name}`] = value;
            }
          }
        } else if (val.name === 'itemG3Code') {
          filterList[1].option = [];
          let data = {
            userLogin: JSON.parse(localStorage.getItem('userData')).userId,
            roleId: JSON.parse(localStorage.getItem('userData')).roleId,
            workSection: JSON.parse(localStorage.getItem('userData'))
              .workSection,
            itemG3Code: value
          };
          val.value = value;
          formCheck.values[`${val.name}`] = value;
          this.getItemProduct(data);
        } else {
          val.value = value;
          formCheck.values[`${val.name}`] = value;
        }
        formCheck.touched[`${val.name}`] = true;
      }
    });

    if (formCheck.touched[fieldName]) {
      const errors = validate(formCheck.values, this.state.schema);

      formCheck = {
        ...formCheck,
        isValid: errors ? false : true,
        errors: errors || {}
      };
    }

    this.setState({ filterList: filterList, formCheck: formCheck });
  };

  handleSubmit = () => {
    let filterList = this.state.filterList;
    let formCheck = this.state.formCheck;

    if (formCheck.isValid) {
      let object = {};

      filterList.map((val, i) => {
        if (val.name === 'itemNumber') {
          let res = val.value.split('---');
          object.itemNumber = res[0];
          object.productName = res[1];

          // if (JSON.parse(localStorage.getItem('userData')).roleId === 2 || JSON.parse(localStorage.getItem('userData')).roleId === 0) {
          // 	filterList[0].option = filterList[0].option.filter((fil, index) => {
          // 		if (fil.value === val.value) {
          // 			this.props.campaignStore.chooseProductLog.push({ index: index, option: fil.value })
          // 		}
          // 		return fil.value !== val.value
          // 	})

          // 	this.props.campaignStore.optionProduct = filterList[0].option
          // } else {
          filterList[1].option = filterList[1].option.filter((fil, index) => {
            if (fil.value === val.value) {
              this.props.campaignStore.chooseProductLog.push({
                index: index,
                option: fil.value
              });
            }
            return fil.value !== val.value;
          });

          this.props.campaignStore.optionProduct = filterList[1].option;
          // }
        } else {
          object[`${val.name}`] = val.value;
        }
      });

      this.props.campaignStore.productPointList.push(object);
      let TABLE_CONTENT = [];

      this.props.campaignStore.productPointList.map((value, index) => {
        TABLE_CONTENT.push({
          rowNo: index + 1,
          itemNumber: value.itemNumber,
          productName: value.productName,
          point: value.point,
          action: (
            <Button
              color="secondary"
              // disabled={!formState.isValid}
              // fullWidth
              type="submit"
              variant="contained"
              onClick={() => this.props.handleDeleteProductPoint(index)}
              //style={{ backgroundColor: colors.red[600], color: 'white' }}
            >
              ลบ
            </Button>
          )
        });
      });

      this.props.campaignStore.TABLE_CONTENT_SET_POINT = TABLE_CONTENT;

      this.handleCloseModal();

      // this.getListPC(object)

      this.setState({ filterList: filterList });
    } else {
      filterList.map((val, i) => {
        if (val.value === '') {
          formCheck.touched[`${val.name}`] = true;
        }

        if (formCheck.touched[val.name]) {
          const errors = validate(formCheck.values, this.state.schema);
          formCheck = {
            ...formCheck,
            isValid: errors ? false : true,
            errors: errors || {}
          };
        }
      });

      this.setState({ formCheck: formCheck });
    }
  };

  hasError = field => {
    return this.state.formCheck.touched[field] &&
      this.state.formCheck.errors[field]
      ? true
      : false;
  };

  helperTextError = field => {
    return this.state.formCheck.touched[field] &&
      this.state.formCheck.errors[field]
      ? this.state.formCheck.errors[field][0]
      : null;
  };

  handleCloseModal = () => {
    this.props.close();
    let filterList = this.state.filterList;
    filterList.map((val, i) => {
      val.value = '';
    });
    this.setState({
      filterList,
      formCheck: {
        isValid: false,
        touched: {},
        values: {},
        errors: {}
      },
      openModal: false
    });
  };

  closeAlert = () => {
    if (this.state.alertMessage.status === 'success') {
      this.searchSubmit();
    }
    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      }
    });
  };

  render() {
    const { filterList, alertMessage } = this.state;
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        fullWidth={true}>
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => this.handleCloseModal()}>
          เพิ่มสินค้า
        </DialogTitle>
        <DialogContent dividers style={{ width: '100%', height: '250px' }}>
          <Grid container spacing={4}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <FilterRender
                filterList={filterList}
                handleChangeFilter={this.handleChangeFilter}
                hasError={this.hasError}
                helperTextError={this.helperTextError}
              />
            </Grid>
          </Grid>
          &nbsp;
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleSubmit()}>
            เพิ่ม
          </Button>
        </DialogActions>
        <DialogAlert data={alertMessage} close={this.closeAlert} />
      </Dialog>
    );
  }
}

export default inject('campaignStore')(observer(AddProductPointModal));
