import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesSalesHistory {

	getSuggestProducts(requestData) {
		return sendRequest("/report/getSuggestProducts", HTTP.POST, requestData)
	}

	getTheBestSellers(requestData) {
		return sendRequest("/report/getTheBestSellers", HTTP.POST, requestData)
	}

	getHistorySales(requestData) {
		return sendRequest("/pc/get-history-sales", HTTP.POST, requestData)
	}
}

export default new ServicesSalesHistory()