import React, { Component } from 'react';
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { Grid, Button, Card, CardHeader, CardContent, Divider, colors } from '@material-ui/core';
import FilterRender from '../../components/FilterRender/FilterRender'
import TableRender from '../../components/TableRender/TableRender'
import { withRouter } from 'react-router-dom';
import { CSVLink } from "react-csv";
import ServicesSalesReport from '../../services/servicesSalesReport'
import { currencyFormat } from '../../constants/function/formatConverter'
import { utcTimeToMonthAndYear } from '../../constants/function/formatConverter'
import { INPUT_RENDER, TABLE_HEADER } from './SalesReportStructure'
import { getExistData } from '../../constants/function/checkDataValue'
import { callDialogAlert } from '../../components/DialogAlert/DialogAlert'
import DialogAlert from '../../components/DialogAlert/DialogAlert'

const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});

class SalesReport extends Component {
	state = {
		filterList: [],
		TABLE_CONTENT: [],
		alertMessage: {
			content: '',
			open: false,
			status: ''
		},
	}

	componentDidMount() {
		this.setState({ filterList: INPUT_RENDER })
		this.searchSubmit()
	}

	getReportSummarySales = (data) => {
		ServicesSalesReport.getReportSummarySales(data).then(res => {
			switch (res.status) {
				case 200:
					let TABLE_CONTENT = []
					res.data.result.map((value, index) => {
						TABLE_CONTENT.push(
							{
								rowNo: value.rowNo,
								purchaseDate: value.purchaseDate,
								groupName: value.groupName,
								shopName: value.shopName,
								locationName: value.locationName,
								pcNo: value.pcNo,
								pcName: value.pcName,
								qty: currencyFormat(getExistData(value.qty)),
								amount: currencyFormat(getExistData(value.amount)),
								brandMobile: value.brandMobile,
								typeMobile: value.typeMobile,
							}
						)
					})
					this.setState({ TABLE_CONTENT })
					break;
				case 204:
					break;
				default:
					this.setState({ alertMessage: callDialogAlert('error', 'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่') })
			}
		})
	}

	handleChangeFilter = (name, value) => {
		let filterList = [...this.state.filterList]

		filterList.map((val, i) => {
			if (val.name === name) {
				val.value = value
			}
		})

		this.setState({ filterList: filterList })
	}

	searchSubmit = () => {
		let object = {}
		this.setState({ TABLE_CONTENT: [] })

		this.state.filterList.map((val, i) => {
			if (val.type === 'month') {
				if (val.value !== '') {
					object[`${val.name}`] = utcTimeToMonthAndYear(val.value)
				} else {
					object[`${val.name}`] = utcTimeToMonthAndYear(new Date())
				}
				// object[`${val.name}`] = val.value
			} else {
				object[`${val.name}`] = val.value
			}
		})

		// object.reportType = "salesByItem"
		object.userLogin = JSON.parse(localStorage.getItem('userData')).userId
		object.roleId = JSON.parse(localStorage.getItem('userData')).roleId

		this.getReportSummarySales(object)
	}

	renderExportButton = () => {
		const { TABLE_CONTENT } = this.state;
		return (
			TABLE_CONTENT.length > 0 &&
			<CSVLink data={TABLE_CONTENT} headers={TABLE_HEADER} filename={"salesListReport.csv"}>
				<Button
					style={{ backgroundColor: colors.green[600], color: 'white' }}

					variant="contained"
				>
					ส่งออกไฟล์ EXCEL
				</Button>
			</CSVLink>
		)
	}

	closeAlert = () => {
		this.setState({
			alertMessage: {
				header: '',
				content: '',
				open: false,
				status: ''
			},
		})
	}


	render() {
		const { classes } = this.props;
		const { filterList, TABLE_CONTENT, alertMessage } = this.state;

		return (
			<React.Fragment>
				<div className={classes.root}>
					<Card>
						<CardHeader
							// action={
							// 	<Button>ddddd</Button>
							// }
							title={
								"รายงานยอดขาย"
							}
						/>
						<Divider />
						<CardContent >
							<Grid
								container
								spacing={4}
							>
								<FilterRender
									filterList={filterList}
									handleChangeFilter={this.handleChangeFilter}
								//hasError={this.hasError}
								//helperTextError={this.helperTextError}
								/>
								<Grid
									item
									lg={12}
									sm={12}
									xl={12}
									xs={12}
									style={{ textAlignLast: 'right' }}
								>
									<Button
										color="primary"
										// disabled={!formState.isValid}
										// fullWidth
										type="submit"
										variant="contained"
										onClick={() => this.searchSubmit()}
									>
										ค้นหา
									</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
						&nbsp;
						<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={12}
							sm={12}
							xl={12}
							xs={12}
						>
							<Card>
								<CardHeader
									action={
										this.renderExportButton()
									}
								/>
								<Divider />
								<CardContent >
									<TableRender TABLE_HEADER={TABLE_HEADER} TABLE_CONTENT={TABLE_CONTENT} />
								</CardContent>
								<Divider />
							</Card>
						</Grid>
					</Grid>
					<DialogAlert data={alertMessage} close={this.closeAlert} />
				</div>
			</React.Fragment>
		);
	}
}

export default compose(
	withStyles(useStyles),
	inject('loginStore')
)(observer(withRouter(SalesReport)))