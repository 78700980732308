import { OPTION_RENDER } from '../../components/OptionRender/OptionRender';

export const INPUT_RENDER = [
  {
    name: 'pcNo',
    displayName: 'รหัสพีซี',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'pcName',
    displayName: 'ชื่อพีซี',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'groupNo',
    displayName: 'ทีม',
    showDisplayName: true,
    type: 'option',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'shopNo',
    displayName: 'ชื่อร้าน',
    showDisplayName: true,
    type: 'option',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'locationName',
    displayName: 'ห้าง',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'tel',
    displayName: 'เบอร์โทร',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
];

export const TABLE_HEADER = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'pcNo',
    label: 'รหัสพีซี'
  },
  {
    key: 'pcName',
    label: 'ชื่อพีซี'
  },
  {
    key: 'groupName',
    label: 'ทีม'
  },
  {
    key: 'shopName',
    label: 'ชื่อร้าน'
  },
  {
    key: 'locationName',
    label: 'ห้าง'
  },
  {
    key: 'tel',
    label: 'เบอร์โทร'
  },
  {
    key: 'approvalStatus',
    label: 'สถานะ'
  },
  {
    key: 'action',
    label: 'เพิ่มเติม'
  }
];
