import { observable, computed, action, decorate } from "mobx"

class approvalSaleListStore {

	TABLE_CONTENT = []
	
	historySearch = {}

	idModal = ''

	triggerApproved = false

}
decorate(approvalSaleListStore, {
	TABLE_CONTENT:  observable,
	idModal:  observable,
	historySearch: observable,
	triggerApproved: observable,
})

export default new approvalSaleListStore()

