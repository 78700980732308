import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesProductManagement {

	getMockSuggestProduct(requestData) {
		return sendRequest("/product/getMockSuggestProduct", HTTP.POST, requestData)
	}

	updateSuggestProduct(requestData) {
		return sendRequest("/product/updateSuggestProduct", HTTP.POST, requestData)
	}

}

export default new ServicesProductManagement()