import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Button
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { SelectStyle } from './styled-components'
import mockData from './data';
import { StatusBullet } from 'components';
import compose from 'recompose/compose'
import { inject, observer } from 'mobx-react';

const options = [
	{ value: '2563', label: 'ปี 2563' },
	{ value: '2562', label: 'ปี 2562' },
	{ value: '2561', label: 'ปี 2561' },
];

const useStyles = makeStyles(theme => ({
	root: {},
	content: {
		padding: 0
	},
	inner: {
		minWidth: 800
	},
	statusContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	status: {
		marginRight: theme.spacing(1)
	},
	actions: {
		justifyContent: 'flex-end'
	}
}));

const statusColors = {
	delivered: 'success',
	pending: 'info',
	refunded: 'danger'
};

const LatestOrders = props => {
	const { className, ...rest } = props;

	const classes = useStyles();

	const [orders] = useState(mockData);

	const handleChangeSelect = (e) => {
		//todo when onChange call service to get data for show on table
		// ถ้าจะแยกหน้าตอนเก็บข้อมูลให้ save value ที่ select มาก่อน แล้วค่อยส่งไปอีกไฟล์
	}

	const handleClickDetailMonth = () => {
		//todo when onChange call service to get data for show on table
		// ถ้าจะแยกหน้าตอนเก็บข้อมูลให้ save value ที่ select มาก่อน แล้วค่อยส่งไปอีกไฟล์
	}

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader
				action={
					<SelectStyle
						defaultValue={options.filter(opt => opt.value === '2563')}
						onChange={handleChangeSelect}
						options={options}
					/>
				}
				title="Latest Orders"
			/>
			<Divider />
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>เดือน</TableCell>
									<TableCell>จำนวน (ชิ้น)</TableCell>
									<TableCell>เงินที่คุณได้รับ</TableCell>
									<TableCell>สถานะ</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{orders.map(order => (
									<TableRow
										hover
										key={order.id}
									>
										<TableCell className="pointer" onClick={handleClickDetailMonth} >{order.ref}</TableCell>
										<TableCell>{order.amount}</TableCell>
										<TableCell>
											{order.receivedMoney.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
										</TableCell>
										<TableCell>
											<div className={classes.statusContainer}>
												<StatusBullet
													className={classes.status}
													color={statusColors[order.status]}
													size="sm"
												/>
												{order.status}
											</div>
										</TableCell>
									</TableRow>
								))}
								<TableRow>
									{/* <TableCell rowSpan={4} /> */}
									<TableCell >รวม</TableCell>
									<TableCell>12</TableCell>
									<TableCell>120,000</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<Divider />
		</Card>
	);
};

LatestOrders.propTypes = {
	className: PropTypes.string
};

// export default LatestOrders;

export default compose(
	inject('loginStore')
)(observer(LatestOrders))