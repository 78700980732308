import ServicesOption from '../../services/servicesOption';
import { isExistData } from 'constants/function/checkDataValue';

export function OPTION_RENDER(optionName, isAll, condition) {
  if (isExistData(JSON.parse(localStorage.getItem('userData')))) {
    switch (optionName) {
      case 'group':
        return groupOption(isAll);
      case 'bank':
        return bankOption(isAll);
      case 'activeStatus':
        return activeStatusOption(isAll);
      case 'userType':
        return getUserTypeOption(isAll);
      case 'approvalStatus':
        return approvalStatusOption(isAll);
      case 'saleStatus':
        return saleStatusOption(isAll);
      case 'provinces':
        return provincesOption(isAll);
    }
  }
}

export function getUserTypeOption(isAll) {
  let option = [];
  ServicesOption.getUserType('USER_TYPE').then(res => {
    switch (res.status) {
      case 200:
        if (isAll) {
          option.push({ label: '---------ทั้งหมด-----------', value: '' });
        }
        res.data.result.map((val, i) => {
          option.push({ label: val.userTypeDesc, value: val.userType });
        });
        break;
      default:
        option = [];
    }
  });
  return option;
}

export function groupOption(isAll) {
  let groupOption = [];
  ServicesOption.getAllGroup({
    userLogin: JSON.parse(localStorage.getItem('userData')).userId,
    roleId: JSON.parse(localStorage.getItem('userData')).roleId,
    workSection: JSON.parse(localStorage.getItem('userData')).workSection
  }).then(res => {
    switch (res.status) {
      case 200:
        if (isAll) {
          groupOption.push({ label: '---------ทั้งหมด-----------', value: '' });
        }
        res.data.result.map((val, i) => {
          groupOption.push({ label: val.groupName, value: val.groupNo });
        });
        break;
      default:
        groupOption = [];
    }
  });
  return groupOption;
}

export function activeStatusOption(isAll) {
  let option = [];
  ServicesOption.getActiveStatus({
    optionType: 'activeStatus',
    userLogin: JSON.parse(localStorage.getItem('userData')).userId,
    roleId: JSON.parse(localStorage.getItem('userData')).roleId
  }).then(res => {
    switch (res.status) {
      case 200:
        if (isAll) {
          option.push({ label: '---------ทั้งหมด-----------', value: '' });
        }
        res.data.result.map((val, i) => {
          option.push({ label: val.optionDescTH, value: val.optionId });
        });
        break;
      default:
        option = [];
    }
  });
  return option;
}

export function approvalStatusOption(isAll) {
  let option = [];
  ServicesOption.getApprovalStatus({
    optionType: 'approvalStatus',
    userLogin: JSON.parse(localStorage.getItem('userData')).userId,
    roleId: JSON.parse(localStorage.getItem('userData')).roleId
  }).then(res => {
    switch (res.status) {
      case 200:
        if (isAll) {
          option.push({ label: '---------ทั้งหมด-----------', value: '' });
        }
        res.data.result.map((val, i) => {
          option.push({ label: val.optionDescTH, value: val.optionId });
        });
        break;
      default:
        option = [];
    }
  });
  return option;
}

export function saleStatusOption(isAll) {
  let option = [];
  ServicesOption.getSaleStatus({
    optionType: 'saleStatus',
    userLogin: JSON.parse(localStorage.getItem('userData')).userId,
    roleId: JSON.parse(localStorage.getItem('userData')).roleId
  }).then(res => {
    switch (res.status) {
      case 200:
        if (isAll) {
          option.push({ label: '---------ทั้งหมด-----------', value: '' });
        }
        res.data.result.map((val, i) => {
          option.push({ label: val.optionDescTH, value: val.optionId });
        });
        break;
      default:
        option = [];
    }
  });
  return option;
}

export function bankOption(isAll) {
  let option = [];
  ServicesOption.getBank({
    userLogin: JSON.parse(localStorage.getItem('userData')).userId,
    roleId: JSON.parse(localStorage.getItem('userData')).roleId
  }).then(res => {
    switch (res.status) {
      case 200:
        if (isAll) {
          option.push({ label: '---------ทั้งหมด-----------', value: '' });
        }
        let resBank = res.data.result;
        resBank.map((val, i) => {
          option.push({ value: val.bankNo, label: val.bankName });
        });
        break;
      default:
        option = [];
    }
  });

  return option;
}

export function provincesOption(isAll) {
  let option = [];
  ServicesOption.getProvinces({
    userLogin: JSON.parse(localStorage.getItem('userData')).userId,
    roleId: JSON.parse(localStorage.getItem('userData')).roleId
  }).then(res => {
    switch (res.status) {
      case 200:
        if (isAll) {
          option.push({ label: '---------ทั้งหมด-----------', value: '' });
        }
        let resGroup = res.data.result;
        resGroup.map((val, i) => {
          option.push({ value: val.provinceId, label: val.provinceNameTH });
        });
        break;
      default:
        option = [];
    }
  });

  return option;
}
