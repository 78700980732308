import { observable, computed, action, decorate } from "mobx"

class pcManagementStore {

	TABLE_CONTENT = []

	updatePCModal = {
		mode: '',
		idPC: ''
	}
	
	historySearch = {}

	triggerApproved = false

}
decorate(pcManagementStore, {
	TABLE_CONTENT:  observable,
	updatePCModal: observable,
	historySearch: observable,
	triggerApproved: observable,
})

export default new pcManagementStore()

