import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesPendingApprovalToAccount {

	getWaitApproval(requestData) {
		return sendRequest("/sale/get-wait-approval", HTTP.POST, requestData)
	}

	getWaitApprovalDetail(requestData) {
		return sendRequest("/sale/get-wait-approval-detail", HTTP.POST, requestData)
	}

	toApproval(requestData) {
		return sendRequest("/sale/approval", HTTP.POST, requestData)
	}

	revokeReportForApprove(requestData) {
		return sendRequest("/report/revokeReportForApprove", HTTP.POST, requestData)
	}

}

export default new ServicesPendingApprovalToAccount()