import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { inject, observer } from 'mobx-react';
import compose from 'recompose/compose';
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Link,
  Typography,
  colors,
  ListItemText,
  ListItemIcon,
  ListItem,
  List
} from '@material-ui/core';
import Select from 'react-select';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import 'date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ServicesOption from '../../services/servicesOption';
import ServicesSignup from '../../services/servicesSignup';
import { isExistData, getExistData } from 'constants/function/checkDataValue';
import { getImageAPI } from '../../services/servicesImage';

const useStyles = theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'white'
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  Select: {
    marginTop: '16px'
  },
  dropZone: {
    border: `1px dashed #2979ff`, //${theme.palette.divider}
    padding: theme.spacing(1),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.blue[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: colors.blue[50],
    opacity: 0.5
  },
  image: {
    width: 50
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },

  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    }
  },
  form: {
    flexBasis: 700,
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class CustomerReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {
        isValid: true,
        values: {
          firstName: '',
          lastName: '',
          email: '',
          tel: '',
          pcNo: '',
          zipcode: '',
          subDistrictId: '',
          districtId: '',
          provinceId: '',
          birthdate: '',
          address: '',
          groupNo: '',
          shopNo: '',
          storeId: '',
          bankAccount: '',
          bankBranchName: '',
          bankGroupNo: '',
          bookBankImage: [],
          idcardImage: [],
          profileImage: []
        },
        touched: {},
        errors: {},
        items: []
      },
      optionBank: [],
      selectedOptionBank: null,
      optionShop: [],
      selectedOptionShop: null,
      optionStore: [],
      selectedOptionStore: null,
      optionGroup: [],
      selectedOptionGroup: null,
      selectedDate: null,
      bookBankImage: [],
      idcardImage: [],
      profileImage: [],
      storeId: null,
      optionProvince: [],
      optionDistricts: [],
      optionSubDistricts: [],
      idModal: ''
    };
  }

  getBank() {
    ServicesOption.getBank({
      userLogin: JSON.parse(localStorage.getItem('userData')).userId,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    }).then(res => {
      switch (res.status) {
        case 200:
          let resBank = res.data.result;
          let optionBank = [...this.state.optionBank];
          resBank.map((val, i) => {
            optionBank.push({ value: val.bankNo, label: val.bankName });
          });
          this.setState({ optionBank: optionBank });
          break;
        default:
      }
    });
  }

  getGroup() {
    ServicesOption.getAllGroup({
      userLogin: JSON.parse(localStorage.getItem('userData')).userId,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId,
      workSection: JSON.parse(localStorage.getItem('userData')).workSection
    }).then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;
          let optionGroup = [...this.state.optionGroup];
          resGroup.map((val, i) => {
            optionGroup.push({ value: val.groupNo, label: val.groupName });
          });
          this.setState({ optionGroup: optionGroup });
          break;
        default:
      }
    });
  }

  getShopByGroup = data => {
    ServicesOption.getShopByGroup(data).then(res => {
      switch (res.status) {
        case 200:
          let resShop = res.data.result;
          let optionShop = [...this.state.optionShop];
          let optionStore = [...this.state.optionStore];
          resShop.map((val, i) => {
            optionShop.push({ value: val.shopNo, label: val.shopName });
            optionStore.push({ value: val.shopNo, label: val.locationName });
          });

          this.setState({ optionShop: optionShop, optionStore: optionStore });
          break;
        default:
      }
    });
  };

  getProvinces() {
    ServicesOption.getProvinces({
      userLogin: JSON.parse(localStorage.getItem('userData')).userId,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    }).then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;
          let optionProvince = [];
          resGroup.map((val, i) => {
            optionProvince.push({
              value: getExistData(val.provinceId).toString(),
              label: val.provinceNameTH
            });
          });
          this.setState({ optionProvince: optionProvince });
          break;
        default:
      }
    });
  }

  getDistricts(data) {
    ServicesOption.getDistricts(data).then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;
          let optionDistricts = [];
          resGroup.map((val, i) => {
            optionDistricts.push({
              value: getExistData(val.districtId).toString(),
              label: val.districtNameTH
            });
          });
          this.setState({ optionDistricts: optionDistricts });
          break;
        default:
      }
    });
  }

  getAreaByZipcode(data) {
    ServicesOption.getAreaByZipcode(data).then(res => {
      switch (res.status) {
        case 200:
          let resGroup = res.data.result;
          let optionSubDistricts = [];
          let formState = this.state.formState;
          if (resGroup.length > 0) {
            resGroup.map((val, i) => {
              optionSubDistricts.push({
                value: getExistData(val.subDistrictId).toString(),
                label: val.subDistrictTH
              });
            });

            formState = {
              ...formState,
              values: {
                ...formState.values,
                provinceId: resGroup[0].provinceId,
                districtId: resGroup[0].districtId
              }
            };
            this.getDistricts({
              provinceId: resGroup[0].provinceId,
              userLogin: JSON.parse(localStorage.getItem('userData')).userId,
              roleId: JSON.parse(localStorage.getItem('userData')).roleId
            });
          }
          this.setState({
            optionSubDistricts: optionSubDistricts,
            formState: formState
          });
          break;
        default:
      }
    });
  }
  componentDidMount() {
    //this.getShop();
    this.getGroup();
    this.getBank();
    this.getProvinces();
  }

  componentDidUpdate() {
    if (this.state.idModal === '' && this.props.open !== '') {
      this.setState({ idModal: this.props.open });
      ServicesSignup.getPCInfo({
        pcNo: this.props.open,
        userLogin: JSON.parse(localStorage.getItem('userData')).pcNo,
        roleId: JSON.parse(localStorage.getItem('userData')).roleId
      }).then(res => {
        switch (res.status) {
          case 200:
            let formState = this.state.formState;
            let resData = res.data.result[0];
            let resKey = Object.keys(resData);

            resKey.map((key, i) => {
              let formStateValues = Object.keys(formState.values);
              formStateValues.map((key2, i2) => {
                if (key === key2 && isExistData(resData[key])) {
                  switch (key) {
                    case 'groupNo':
                      this.handleSelectGroup(resData[key]);
                      formState = {
                        ...formState,
                        values: {
                          ...formState.values,
                          [`${key}`]: resData[key]
                        }
                      };
                      break;
                    case 'bookBankImage':
                    case 'idcardImage':
                    case 'profileImage':
                      let data = [];
                      data.push({
                        showImg: resData[key],
                        name: resData[key].substring(
                          resData[key].lastIndexOf('/') + 1
                        )
                      });
                      // this.setState({ [`${key}`]: data })
                      formState = {
                        ...formState,
                        values: { ...formState.values, [`${key}`]: data }
                      };

                      break;
                    case 'zipcode':
                      this.getAreaByZipcode({
                        zipcode: resData[key],
                        userLogin: JSON.parse(localStorage.getItem('userData'))
                          .pcNo,
                        roleId: JSON.parse(localStorage.getItem('userData'))
                          .roleId
                      });
                      formState = {
                        ...formState,
                        values: {
                          ...formState.values,
                          [`${key}`]: resData[key].toString()
                        }
                      };

                      break;
                    default:
                      formState = {
                        ...formState,
                        values: {
                          ...formState.values,
                          [`${key}`]: resData[key]
                        }
                      };
                  }
                }
              });
            });

            this.setState({ formState });

            break;
          default:
            console.log('ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่');
        }
      });
    }
  }

  handleSelectGroup = value => {
    this.setState({ optionShop: [] });
    this.getShopByGroup({
      groupNo: value,
      userLogin: JSON.parse(localStorage.getItem('userData')).userId,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    });
  };

  handleDisplayImg = src => {
    let modal = document.getElementById('showImg');
    let modalImg = document.getElementById('imgContent');
    modal.style.display = 'block';
    modalImg.src = src;
  };

  handleCloseDisplayImg = () => {
    let modal = document.getElementById('showImg');
    modal.style.display = 'none';
  };

  handleClose = () => {
    this.props.close();
    let formState = this.state.formState;

    let listFormState = Object.keys(formState.values);

    listFormState.map((val, i) => {
      if (Array.isArray(formState.values[val])) {
        formState.values[val] = [];
      } else {
        formState.values[val] = '';
      }
    });

    formState.touched = {};
    formState.errors = {};
    formState.items = [];

    this.setState({
      idModal: '',
      formState: formState
    });
  };

  onDownloadImage = file => {
    var link = document.createElement('a');
    link.href = 'incentive.dplus-groups.com:2096/api/v1' + file.showImg;
    // link.href = file.showImg
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const { classes } = this.props;
    const {
      filterList,
      formState,
      optionBank,
      selectedOptionBank,
      optionShop,
      selectedOptionShop,
      optionStore,
      selectedOptionStore,
      optionGroup,
      selectedOptionGroup,
      optionSubDistricts,
      optionDistricts,
      optionProvince
    } = this.state;

    return (
      <Dialog
        open={
          !!this.props.open &&
          this.props.open !== '' &&
          this.props.open !== null
            ? true
            : false
        }
        //TransitionComponent={Transition}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => this.handleClose()}>
          รายละเอียดพีซี
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form
                  className={classes.form}
                  onSubmit={event => this.handleSubmit(event)}
                  spacing={1}>
                  &nbsp;
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        fullWidth
                        className={classes.textField}
                        label="ชื่อ"
                        name="firstName"
                        type="text"
                        value={formState.values.firstName || ''}
                        variant="outlined"
                        size="small"
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        fullWidth
                        className={classes.textField}
                        label="นามสกุล"
                        name="lastName"
                        type="text"
                        value={formState.values.lastName || ''}
                        variant="outlined"
                        size="small"
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        label="ที่อยู่ตามบัตรประชาชน (ตรอก ซอย)"
                        name="address"
                        placeholder="ที่อยู่ตามบัตรประชาชน (ตรอก ซอย)"
                        id="address"
                        variant="outlined"
                        size="small"
                        type="text"
                        multiline
                        rows="4"
                        disabled
                        value={formState.values.address || ''}
                      />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        label="รหัสไปรษณีย์"
                        name="zipcode"
                        id="zipcode"
                        variant="outlined"
                        size="small"
                        type="text"
                        disabled
                        value={formState.values.zipcode || ''}
                      />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                      <Select
                        className={classes.Select}
                        value={optionSubDistricts.filter(
                          option =>
                            option.value ===
                            getExistData(
                              formState.values.subDistrictId
                            ).toString()
                        )}
                        options={optionSubDistricts}
                        placeholder="กรุณาเลือกตำบล/แขวง"
                        name="subDistrictId"
                        id="subDistrictId"
                        isDisabled
                      />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                      <Select
                        className={classes.Select}
                        value={optionDistricts.filter(
                          option =>
                            option.value ===
                            getExistData(formState.values.districtId).toString()
                        )}
                        options={optionDistricts}
                        //onChange={(e) => this.handleSelectGroup(e.value)}
                        placeholder="กรุณาเลือกอำเภอ/เขต"
                        name="districtId"
                        id="districtId"
                        isDisabled
                      />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                      <Select
                        className={classes.Select}
                        value={optionProvince.filter(
                          option =>
                            option.value ===
                            getExistData(formState.values.provinceId).toString()
                        )}
                        options={optionProvince}
                        // onChange={(e) => this.handleSelectGroup(e.value)}
                        placeholder="กรุณาเลือกจังหวัด"
                        name="provinceId"
                        id="provinceId"
                        isDisabled
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <TextField
                        fullWidth
                        className={classes.textField}
                        label="รหัสพนักงาน"
                        value={formState.values.pcNo || ''}
                        disabled
                        name="pcNo"
                        type="text"
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Select
                        className={classes.Select}
                        value={optionGroup.filter(
                          option =>
                            option.value ===
                            getExistData(formState.values.groupNo).toString()
                        )}
                        options={optionGroup}
                        placeholder="กรุณาเลือกทีม"
                        name="groupNo"
                        id="groupNo"
                        isDisabled
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Select
                        className={classes.Select}
                        options={optionShop}
                        value={optionShop.filter(
                          option =>
                            option.value ===
                            getExistData(formState.values.shopNo).toString()
                        )}
                        placeholder="กรุณาเลือกร้านค้า"
                        name="shopNo"
                        id="shopNo"
                        isDisabled
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Select
                        className={classes.Select}
                        options={optionStore}
                        value={optionStore.filter(
                          option =>
                            option.value ===
                            getExistData(formState.values.shopNo).toString()
                        )}
                        placeholder="ห้าง"
                        name="storeId"
                        id="storeId"
                        isDisabled
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        label="เบอร์โทรศัพท์"
                        name="tel"
                        id="tel"
                        variant="outlined"
                        size="small"
                        required
                        value={formState.values.tel || ''}
                        disabled
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <TextField
                        fullWidth
                        className={classes.textField}
                        label="อีเมล์"
                        name="email"
                        type="text"
                        value={formState.values.email || ''}
                        variant="outlined"
                        size="small"
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <TextField
                        fullWidth
                        className={classes.textField}
                        label="เลขที่บัญชี"
                        name="bankAccount"
                        type="text"
                        value={formState.values.bankAccount || ''}
                        variant="outlined"
                        size="small"
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Select
                        className={classes.Select}
                        options={optionBank}
                        value={optionBank.filter(
                          option =>
                            option.value ===
                            getExistData(
                              formState.values.bankGroupNo
                            ).toString()
                        )}
                        placeholder="กรุณาเลือกธนาคาร"
                        name="bankGroupNo"
                        id="bankGroupNo"
                        isDisabled
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          fullWidth
                          className={classes.Select}
                          label="วัน เดือน ปี เกิด"
                          value={
                            isExistData(formState.values.birthdate)
                              ? new Date(formState.values.birthdate)
                              : null
                          }
                          animateYearScrolling
                          defaultValue="Normal"
                          variant="inline"
                          inputVariant="outlined"
                          size="small"
                          format="dd/MM/yyyy"
                          name="birthdate"
                          autoOk
                          clearable
                          // disableFuture
                          disabled
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <TextField
                        fullWidth
                        className={classes.textField}
                        label="สาขา"
                        name="bankBranchName"
                        type="text"
                        value={formState.values.bankBranchName || ''}
                        variant="outlined"
                        size="small"
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <React.Fragment>
                        <Typography variant="h5">
                          <AttachFileIcon fontSize="small" /> หน้าบัญชี
                        </Typography>
                        <List className={classes.list}>
                          {isExistData(formState.values.bookBankImage) &&
                            formState.values.bookBankImage.length === 1 &&
                            formState.values.bookBankImage.map(file => (
                              <ListItem>
                                <ListItemIcon>
                                  {file.showImg ? (
                                    <img
                                      src={getImageAPI(file.showImg)}
                                      onClick={() =>
                                        this.handleDisplayImg(
                                          getImageAPI(file.showImg)
                                        )
                                      }
                                      style={{
                                        maxHeight: '70px',
                                        maxWidth: '70px',
                                        width: '70px',
                                        height: '70px',
                                        objectFit: 'cover',
                                        cursor: 'pointer'
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      onClick={() =>
                                        this.handleDisplayImg(
                                          window.URL.createObjectURL(file)
                                        )
                                      }
                                      style={{
                                        maxHeight: '70px',
                                        maxWidth: '70px',
                                        width: '70px',
                                        height: '70px',
                                        objectFit: 'cover',
                                        cursor: 'pointer'
                                      }}
                                    />
                                  )}
                                </ListItemIcon>
                                &nbsp;
                                <ListItemText
                                  primary={file.name}
                                  primaryTypographyProps={{ variant: 'h5' }}
                                />
                              </ListItem>
                            ))}
                        </List>
                      </React.Fragment>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <React.Fragment>
                        <Typography variant="h5">
                          <AttachFileIcon fontSize="small" /> สำเนาบัตรประชาชน
                        </Typography>
                        <List className={classes.list}>
                          {isExistData(formState.values.idcardImage) &&
                            formState.values.idcardImage.length === 1 &&
                            formState.values.idcardImage.map(file => (
                              <ListItem>
                                <ListItemIcon>
                                  {file.showImg ? (
                                    <img
                                      src={getImageAPI(file.showImg)}
                                      onClick={() =>
                                        this.handleDisplayImg(
                                          getImageAPI(file.showImg)
                                        )
                                      }
                                      style={{
                                        maxHeight: '70px',
                                        maxWidth: '70px',
                                        width: '70px',
                                        height: '70px',
                                        objectFit: 'cover',
                                        cursor: 'pointer'
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      onClick={() =>
                                        this.handleDisplayImg(
                                          window.URL.createObjectURL(file)
                                        )
                                      }
                                      style={{
                                        maxHeight: '70px',
                                        maxWidth: '70px',
                                        width: '70px',
                                        height: '70px',
                                        objectFit: 'cover',
                                        cursor: 'pointer'
                                      }}
                                    />
                                  )}
                                </ListItemIcon>
                                &nbsp;
                                <ListItemText
                                  primary={file.name}
                                  primaryTypographyProps={{ variant: 'h5' }}
                                />
                                {/* <a href={getImageAPI(file.showImg)} download>
																	<img src={getImageAPI(file.showImg)} alt="W3Schools" width="104" height="142" />
																</a> */}
                                {/* <a href="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png" download target="_blank">
																	<img src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png" alt="W3Schools" width="104" height="142"/>
																</a> */}
                                {/* <Button variant="contained" color="primary" onClick={() => this.onDownloadImage(file)}>Download</Button> */}
                              </ListItem>
                            ))}
                        </List>
                      </React.Fragment>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <React.Fragment>
                        <Typography variant="h5">
                          <AttachFileIcon fontSize="small" /> รูปโปรไฟล์
                        </Typography>
                        <List className={classes.list}>
                          {isExistData(formState.values.profileImage) &&
                            formState.values.profileImage.length === 1 &&
                            formState.values.profileImage.map(file => (
                              <ListItem>
                                <ListItemIcon>
                                  {file.showImg ? (
                                    <img
                                      src={getImageAPI(file.showImg)}
                                      onClick={() =>
                                        this.handleDisplayImg(
                                          getImageAPI(file.showImg)
                                        )
                                      }
                                      style={{
                                        maxHeight: '70px',
                                        maxWidth: '70px',
                                        width: '70px',
                                        height: '70px',
                                        objectFit: 'cover',
                                        cursor: 'pointer'
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      onClick={() =>
                                        this.handleDisplayImg(
                                          window.URL.createObjectURL(file)
                                        )
                                      }
                                      style={{
                                        maxHeight: '70px',
                                        maxWidth: '70px',
                                        width: '70px',
                                        height: '70px',
                                        objectFit: 'cover',
                                        cursor: 'pointer'
                                      }}
                                    />
                                  )}
                                </ListItemIcon>
                                &nbsp;
                                <ListItemText
                                  primary={file.name}
                                  primaryTypographyProps={{ variant: 'h5' }}
                                />
                              </ListItem>
                            ))}
                        </List>
                      </React.Fragment>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
            {/* show img modal */}
            <div id="showImg" class="modal" style={{ zIndex: '99' }}>
              <span class="close" onClick={() => this.handleCloseDisplayImg()}>
                &times;
              </span>
              <img class="modal-content" id="imgContent" />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleClose()}>
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('approvalPCStore', 'optionStore')
)(observer(CustomerReportModal));
