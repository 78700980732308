import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import compose from 'recompose/compose'

import { AccountProfile, AccountDetails } from './components';

const useStyles = theme => ({
	root: {
		padding: theme.spacing(4)
	}
});


class Account extends Component {
	state = {}
	render() {

		const { classes, ...rest } = this.props;
		// this.props.loginStore.userData = { "user": 1 }

		return (
			<div className={classes.root}>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						lg={4}
						md={6}
						xl={4}
						xs={12}
					>
						<AccountProfile />
					</Grid>
					<Grid
						item
						lg={8}
						md={6}
						xl={8}
						xs={12}
					>
						<AccountDetails />
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default compose(
	withStyles(useStyles), inject('loginStore')
)(observer(Account))