import { observable, computed, action, decorate } from "mobx"

class salesListstore {

	productPointList = []

	TABLE_CONTENT_ADD_SALES = []
	
	TABLE_CONTENT = []

	campaignKeyArray = []

}
decorate(salesListstore, {
	productPointList:  observable,
	TABLE_CONTENT: observable,
	TABLE_CONTENT_ADD_SALES: observable,
	campaignKeyArray: observable,
})

export default new salesListstore()

