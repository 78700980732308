import React, { Component } from 'react';
import FilterRender from '../../../../components/FilterRender/FilterRender';
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import ServicesLoginManagement from '../../../../services/servicesLoginManagement';
import servicesOption from 'services/servicesOption';
import { ImgStyle } from './styled-components';
import LoginManagementAction from '../LoginManagementTable/LoginManagementAction';
import { INPUT_RENDER } from '../../LoginManagementStructure';
import { OPTION_RENDER } from '../../../../components/OptionRender/OptionRender';
import { getImageAPI } from 'services/servicesImage';
import copy from 'copy-to-clipboard';
import { callDialogAlert } from '../../../../components/DialogAlert/DialogAlert';
import DialogAlert from '../../../../components/DialogAlert/DialogAlert';

class LoginManagementFilter extends Component {
  state = {
    filterList: [],
    openLoginModal: -1,
    alertMessage: {
      content: '',
      open: false,
      status: ''
    }
  };

  componentDidMount() {
    this.setState({ filterList: INPUT_RENDER }, () => {
      let filterList = this.state.filterList;
      filterList[1].option = OPTION_RENDER('group', false);
      this.setState({ filterList: filterList });
    });
    this.searchSubmit();
  }

  onGetAllLogin = data => {
    // this.setState({ alertMessage: callDialogAlert('loading', '') })
    ServicesLoginManagement.onGetAllLogin(data).then(res => {
      switch (res.status) {
        case 200:
          // this.setState({ alertMessage: callDialogAlert('', '') })
          let TABLE_CONTENT = [];
          res.data.result.map((value, index) => {
            TABLE_CONTENT.push({
              rowNo: index + 1,
              pageName: value.pageName,
              groupNo: value.groupName,
              logoImage:
                !!value.logoImage &&
                value.logoImage !== null &&
                value.logoImage !== '' ? (
                  <ImgStyle src={getImageAPI(value.logoImage)} />
                ) : (
                  ''
                ),
              //url: value.url,
              url: (
                <Button
                  color="secondary"
                  type="submit"
                  variant="contained"
                  onClick={() => this.handleCopyURL(value.url)}>
                  COPY
                </Button>
              ),
              action: (
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  // variant="contained"
                  onClick={() => this.handleOpenLoginModal(value.pageId)}>
                  แก้ไข
                </Button>
              )
            });
          });
          this.props.loginManagementStore.TABLE_CONTENT = TABLE_CONTENT;
          break;
        case 204:
          // todo ไม่พบข้อมูล
          break;
        default:
          this.props.loginManagementStore.TABLE_CONTENT = [];
          this.setState({
            alertMessage: callDialogAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            )
          });
      }
    });
  };

  handleCopyURL = data => {
    copy(data);
  };

  handleOpenLoginModal = data => {
    this.setState({ openLoginModal: data !== '' ? data : '' });
    this.props.loginManagementStore.loginUserId = data !== '' ? data : '';
  };

  handleCloseLoginModal = () => {
    this.setState({ openLoginModal: -1 });
    this.searchSubmit();
  };

  handleChangeFilter = (name, value) => {
    let filterList = [...this.state.filterList];
    filterList.map((val, i) => {
      if (val.name === name) {
        val.value = value;
      }
    });
    this.setState({ filterList: filterList });
  };

  searchSubmit = () => {
    let object = {};

    this.state.filterList.map((val, i) => {
      if (val.name !== 'logoImage') {
        object[`${val.name}`] = val.value;
      }
    });

    object.userLogin = JSON.parse(localStorage.getItem('userData')).userId;
    object.roleId = JSON.parse(localStorage.getItem('userData')).roleId;
    this.onGetAllLogin(object);
  };

  closeAlert = () => {
    if (this.state.alertMessage.status === 'success') {
      this.searchSubmit();
    }
    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      },
      rowNumSelected: [],
      rowNumSelectedData: []
    });
  };

  render() {
    const { filterList, alertMessage } = this.state;

    return (
      <React.Fragment>
        <Card>
          <CardHeader
            // action={

            title={'หน้าล็อกอิน'}
          />
          <Divider />
          <CardContent style={{ position: 'initial' }}>
            <Grid container spacing={2}>
              <FilterRender
                filterList={filterList}
                handleChangeFilter={this.handleChangeFilter}
              />
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
                style={{ textAlignLast: 'right' }}>
                <Button
                  color="primary"
                  // disabled={!formState.isValid}
                  // fullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => this.searchSubmit()}>
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DialogAlert data={alertMessage} close={this.closeAlert} />
        <LoginManagementAction
          open={this.state.openLoginModal !== -1 ? true : false}
          close={this.handleCloseLoginModal}
        />
      </React.Fragment>
    );
  }
}

export default inject(
  'optionStore',
  'loginManagementStore'
)(observer(LoginManagementFilter));
