import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));

const PaymentDate = props => {
  const { className, title, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
		  justify="space-between"
		  style={{ placeContent: 'center', textAlign: 'center' }}
        >
          <Grid item >
            <Typography
              className={classes.title}
              color="inherit"
            //   gutterBottom
			  variant="h5"
			  
            >
             {title}
            </Typography>
          </Grid>
          {/* <Grid item>
            <Avatar className={classes.avatar}>
              <EventIcon className={classes.icon} />
            </Avatar>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

PaymentDate.propTypes = {
  className: PropTypes.string
};

export default PaymentDate;
