import React, { Component } from 'react';
import TableRender from '../../../../components/TableRender/TableRender'
import {
	Card,
	CardContent,
	Divider,
	Button,
	CardHeader,
	colors
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { TABLE_HEADER_TRADE, TABLE_HEADER_PRODUCT } from '../../ApprovalSaleListStructure'
import { isExistData } from 'constants/function/checkDataValue';
import ServicesApprovalSaleList from '../../../../services/servicesApprovalSaleList'
import { getImageAPI } from '../../../../services/servicesImage'

class ApprovalSaleListTable extends Component {
	state = {
		TABLE_HEADER: [],
	}

	componentDidMount() {
		if (JSON.parse(localStorage.getItem('userData')).roleId === 2 || JSON.parse(localStorage.getItem('userData')).roleId === 0) {
			this.setState({ TABLE_HEADER: TABLE_HEADER_TRADE })
		} else {
			this.setState({ TABLE_HEADER: TABLE_HEADER_PRODUCT })
		}
	}

	exportReport = () => {
		ServicesApprovalSaleList.getApprovalSalesReport(this.props.approvalSaleListStore.historySearch).then(res => {
			switch (res.status) {
				case 200:
					if (isExistData(res.data.result.export)) {
						let url = getImageAPI(res.data.result.export.pathFile)
						window.open(url).focus();
						// window.open(`dplus-system.com:1011/api/v1${res.data.result.export.pathFile}`);
					}
					break;
				case 204:
					break;
				default:
			}
		})
	}


	render() {
		return (
			<React.Fragment>
				<Card>
					<CardHeader
						action={
							<Button
								style={{ backgroundColor: colors.green[600], color: 'white' }}
								// disabled={!formState.isValid}
								// fullWidth
								variant="contained"
								onClick={() => this.exportReport()}
							>
								ส่งออกไฟล์ EXCEL
							</Button>
						}

					/>
					<Divider />
					<CardContent >
						<TableRender TABLE_HEADER={this.state.TABLE_HEADER} TABLE_CONTENT={this.props.approvalSaleListStore.TABLE_CONTENT} />
					</CardContent>
					<Divider />
				</Card>
			</React.Fragment>
		);
	}
}

export default inject('approvalSaleListStore')(observer(ApprovalSaleListTable))