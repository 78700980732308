import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Avatar, Typography, Link } from '@material-ui/core';
import profileIcon from '../../../../../../assets/avatar-human-male-profile-user-icon-518358.png';
import { getImageAPI } from '../../../../../../services/servicesImage';
import SettingsIcon from '@material-ui/icons/Settings';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
});

class Profile extends Component {
  state = {
    triggerProfileUpdate: false,
    user: {}
  };

  componentDidMount() {
    const { userPersonal } = this.props;
    let user = {
      name: userPersonal.fullName
        ? userPersonal.fullName
        : `${userPersonal.firstName} ${userPersonal.lastName}`,
      avatar: userPersonal.profileImage
        ? userPersonal.profileImage !== null || userPersonal.profileImage !== ''
          ? getImageAPI(userPersonal.profileImage)
          : profileIcon
        : profileIcon,
      bio: userPersonal.departmentName ? userPersonal.departmentName : '',
      pcNo: userPersonal.pcNo ? userPersonal.pcNo : ''
    };
    this.setState({ user });
  }
  render() {
    const { classes, className, userPersonal, ...rest } = this.props;
    let { user } = this.state;
    if (this.props.accountStore.triggerSaved === 0) {
      if (
        this.props.accountStore.profileImageForTrigger !== '' &&
        this.props.accountStore.profileImageForTrigger !==
          userPersonal.profileImage
      ) {
        let user = {
          name: userPersonal.fullName
            ? userPersonal.fullName
            : `${userPersonal.firstName} ${userPersonal.lastName}`,
          avatar:
            this.props.accountStore.profileImageForTrigger !== null ||
            this.props.accountStore.profileImageForTrigger !== ''
              ? getImageAPI(this.props.accountStore.profileImageForTrigger)
              : profileIcon,
          bio: userPersonal.departmentName ? userPersonal.departmentName : ''
        };
        this.setState({ user, triggerProfileUpdate: false }, () => {
          this.props.accountStore.triggerSaved = 1;
          this.props.accountStore.profileImageForTrigger = '';
        });
      }
    }

    return (
      <div {...rest} className={clsx(classes.root, className)}>
        {userPersonal.roleName === 'ADMIN' ? (
          <React.Fragment>
            <Avatar alt="Person" className={classes.avatar} src={user.avatar} />
            <Typography
              className={classes.name}
              variant="h5"
              style={{ textAlign: 'center' }}>
              {user.bio}
            </Typography>
            <Typography variant="h6">{user.name}</Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div style={{ display: 'flex', position: 'relative' }}>
              <Avatar
                alt="Person"
                className={classes.avatar}
                component={RouterLink}
                src={user.avatar}
                to="/account"
              />

              <div
                style={{ position: 'absolute', bottom: '-4px', right: '-3px' }}>
                <Link component={RouterLink} to="/account">
                  <div
                    style={{
                      borderRadius: '39px',
                      background: 'white',
                      width: '25px',
                      height: '25px'
                    }}>
                    <SettingsIcon color="primary" />
                  </div>
                </Link>
              </div>
            </div>

            <Typography className={classes.name} variant="h4">
              {user.name}
            </Typography>
            <Typography className={classes.name} variant="h6">
              {user.pcNo}
            </Typography>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('accountStore')
)(observer(Profile));
