import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { inject, observer } from 'mobx-react';
import compose from 'recompose/compose';
import Dropzone from 'react-dropzone';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  Slide,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Link,
  Typography,
  colors,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Divider,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import ServicesOption from '../../../../services/servicesOption';
import ServicesApprovePC from '../../../../services/servicesApprovePC';
import validate from 'validate.js';
import FilterRender from '../../../../components/FilterRender/FilterRender';
import ApproveDataAction from './ApproveDataAction';
import ServicesPCManagement from '../../../../services/servicesPCManagement';
import DialogAlert from '../../../../components/DialogAlert/DialogAlert';

const schema = {
  approvalStatus: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกสถานะการอนุมัติ' },
    length: {
      maximum: 32
    }
  },
  approvalReason: {
    presence: { allowEmpty: false, message: '^กรุณากรอกเหตุผล' },
    length: {
      maximum: 255
    }
  }
};

const schema1 = {
  approvalStatus: {
    presence: { allowEmpty: false, message: '^กรุณาเลือกสถานะการอนุมัติ' },
    length: {
      maximum: 32
    }
  }
};

const useStyles = theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'white'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    }
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  form: {
    flexBasis: 700,
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  button: {
    color: theme.palette.success
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class ApproveAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterList: [
        {
          name: 'approvalStatus',
          displayName: 'สถานะการอนุมัติ',
          showDisplayName: true,
          type: 'option',
          value: '',
          option: [],
          required: true,
          widthCol: 12,
          styleXs: {
            label: 12,
            input: 12
          },
          styleSm: {
            label: 3,
            input: 9
          }
        },
        {
          name: 'approvalReason',
          displayName: 'เหตุผล',
          showDisplayName: true,
          type: 'textarea',
          value: '',
          option: [],
          required: true,
          hidden: true,
          widthCol: 12,
          styleXs: {
            label: 12,
            input: 12
          },
          styleSm: {
            label: 3,
            input: 9
          }
        }
      ],
      formState: {
        isValid: false,
        values: {},
        touched: {},
        errors: {},
        items: []
      },
      alertMessage: {
        content: '',
        open: false,
        status: ''
      }
    };
  }

  componentDidMount() {
    this.approvalStatusOption();
  }

  approvalStatusOption = () => {
    ServicesOption.getApprovalStatus({
      optionType: 'approvalStatus',
      userLogin: JSON.parse(localStorage.getItem('userData')).userId,
      roleId: JSON.parse(localStorage.getItem('userData')).roleId
    }).then(res => {
      let filterList = [...this.state.filterList];

      switch (res.status) {
        case 200:
          let option = filterList[0].option;

          // res.data.result.map((val, i) => {
          // 	if (val.optionId !== '3') {
          // 		option.push({ label: val.optionDescTH, value: val.optionId })
          // 	}
          // })

          // filterList[0].option = [...option]
          // this.setState({ filterList: filterList })

          break;
        default:
          filterList[0].option = [];
          this.setState({ filterList: filterList });
      }
    });
  };

  handleChangeFilter = (name, value) => {
    let filterList = [...this.state.filterList];

    switch (name) {
      case 'approvalStatus':
        if (value === '4' || value === '2' || value === '5') {
          filterList[1].hidden = false;
        } else {
          filterList[1].hidden = true;
        }
        filterList[1].value = '';
        break;
    }
    filterList.map((val, i) => {
      if (val.name === name) {
        if (value === null) {
          // for uploadContent filter
          value = [];
        }
        val.value = value;
      }
    });

    let formState = this.state.formState;
    formState = {
      ...formState,
      values: {
        ...formState.values,
        [name]: value
      },
      touched: {
        ...formState.touched,
        [name]: true
      }
    };

    /*check validate empty onChange*/
    if (formState.touched[name]) {
      const errors = validate(
        formState.values,
        filterList[0].value === '4' || filterList[0].value === '2'
          ? schema
          : schema1
      );
      formState = {
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      };
    }
    this.setState({ formState, filterList });
  };

  hasError = field => {
    return this.state.formState.touched[field] &&
      this.state.formState.errors[field]
      ? true
      : false;
  };

  helperTextError = field => {
    return this.state.formState.touched[field] &&
      this.state.formState.errors[field]
      ? this.state.formState.errors[field][0]
      : null;
  };

  handleOpenUpdatePCModal = idModal => {
    this.props.approvalPCStore.pcNo = idModal;
  };

  handleApprovement = () => {
    let object = {};
    let formState = this.state.formState;

    if (formState.isValid) {
      this.openAlert('processing', '');

      this.state.filterList.map((val, i) => {
        object[`${val.name}`] = val.value;
      });

      object.pcNo = this.props.approvalPCStore.pcNo;
      object.userLogin = JSON.parse(localStorage.getItem('userData')).userId;
      object.workSection = JSON.parse(
        localStorage.getItem('userData')
      ).workSection;

      ServicesApprovePC.approvalPC(object).then(res => {
        switch (res.status) {
          case 200:
            this.openAlert('success', 'บันทึกสำเร็จ');
            ServicesPCManagement.getListPC(
              this.props.approvalPCStore.historySearch
            ).then(res => {
              switch (res.status) {
                case 200:
                  let TABLE_CONTENT = [];

                  // TABLE_HEADER.map((val, i) => {
                  res.data.result.map((value, index) => {
                    TABLE_CONTENT.push({
                      rowNo: index + 1,
                      pcNo: value.pcNo,
                      pcName: value.firstName,
                      groupName: value.groupNo,
                      shopName: value.shopNo,
                      locationName: value.locationName,
                      tel: value.tel,
                      approvalStatus: value.approvalStatusName,
                      action: (
                        <Button
                          color="secondary"
                          // disabled={!formState.isValid}
                          // fullWidth
                          type="submit"
                          variant="contained"
                          onClick={() =>
                            this.handleOpenUpdatePCModal(value.pcNo)
                          }>
                          จัดการ
                        </Button>
                      )
                    });
                  });

                  this.props.approvalPCStore.TABLE_CONTENT = TABLE_CONTENT;

                  break;
                default:
                  this.props.approvalPCStore.TABLE_CONTENT = [];
              }
            });

            break;
          default:
            this.openAlert(
              'error',
              'ไม่สามารถทำรายการได้ กรุณาติดต่อเจ้าหน้าที่'
            );
        }
      });
    } else {
      this.openAlert('warning', 'กรุณากรอกข้อมูลให้ครบถ้วน');
    }
  };

  handleClose = () => {
    this.props.approvalPCStore.pcNo = '';
    let filterList = this.state.filterList;

    filterList.map((val, i) => {
      val.value = '';
      if (val.name === 'approvalReason') {
        val.hidden = true;
      }
    });

    this.setState({ filterList: filterList });
  };

  openAlert = (status, message) => {
    this.setState({
      alertMessage: {
        content: message,
        open: true,
        status: status
      }
    });
  };

  closeAlert = () => {
    if (this.state.alertMessage.status === 'success') {
      this.handleClose();
    }

    this.setState({
      alertMessage: {
        header: '',
        content: '',
        open: false,
        status: ''
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { filterList, alertMessage } = this.state;

    return (
      <Dialog
        open={this.props.open}
        //TransitionComponent={Transition}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => this.handleClose()}>
          อนุมัติ PC
        </DialogTitle>
        <React.Fragment>
          <DialogContent dividers>
            <ApproveDataAction />
            <Divider />
            &nbsp;
            <Grid container spacing={4}>
              {/* <Grid item lg={12} sm={12} xl={12} xs={12}> */}
              <FilterRender
                filterList={filterList}
                handleChangeFilter={this.handleChangeFilter}
                hasError={this.hasError}
                helperTextError={this.helperTextError}
              />
            </Grid>
            <div style={{ paddingBottom: '40px' }}></div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={{ backgroundColor: colors.green[600], color: 'white' }}
              onClick={() => this.handleApprovement()}>
              บันทึก
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={() => this.handleClose()}>
              ยกเลิก
            </Button>
          </DialogActions>
        </React.Fragment>
        <DialogAlert data={alertMessage} close={this.closeAlert} />
      </Dialog>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('approvalPCStore', 'alertStore')
)(observer(ApproveAction));
