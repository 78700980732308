import React, { Component } from 'react';
import FilterRender from '../../components/FilterRender/FilterRender';
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  colors,
  Divider
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ServicesCampaign from '../../services/servicesCampaign';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TableRender from '../../components/TableRender/TableRender';
import compose from 'recompose/compose';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ConfirmDeleteCampaign from './ConfirmDeleteCampaign';
import DefaultIncentiveSetting from './DefaultIncentiveSetting';

const TABLE_HEADER = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'campaignName',
    label: 'ชื่อแคมเปญ',
    textAlign: 'left'
  },
  {
    key: 'createDate',
    label: 'วันที่สร้าง'
  },
  {
    key: 'action',
    label: 'จัดการ'
  }
];

const TABLE_HEADER2 = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'campaignName',
    label: 'ชื่อแคมเปญ'
  },
  {
    key: 'createDate',
    label: 'วันที่สร้าง'
  },
  {
    key: 'action',
    label: 'จัดการ'
  }
];

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

class CampaignManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterList: [
        {
          name: 'campaignName',
          displayName: 'ชื่อแคมเปญ',
          placeholder: [],
          showDisplayName: true,
          type: 'text',
          value: '',
          option: [],
          required: false,
          widthCol: 6,
          styleXs: {
            label: 12,
            input: 12
          },
          styleSm: {
            label: 3,
            input: 9
          }
        }
      ],
      campaignList: [],
      campaignList2: [],
      activeTab: 0,
      openConfirmDeleteCampaign: '',
      openSetDefaultIncentive: false
    };
  }

  getCampaign = data => {
    switch (this.state.activeTab) {
      case 0:
        ServicesCampaign.getCampaign(data).then(res => {
          switch (res.status) {
            case 200:
              let TABLE_CONTENT = [];
              res.data.result.map((value, index) => {
                TABLE_CONTENT.push({
                  rowNo: value.rowNo,
                  campaignName: value.campaignName,
                  createDate: value.createDate,
                  action: (
                    <React.Fragment>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            this.handleOpenCampaignModal(value, false)
                          }>
                          แก้ไข
                        </Button>
                        &nbsp;
                        <Button
                          color="secondary"
                          type="submit"
                          variant="contained"
                          style={{
                            backgroundColor: colors.red[600],
                            color: 'white'
                          }}
                          onClick={() =>
                            this.handleOpenConfirmDeleteCampaign(
                              value.campaignId
                            )
                          }>
                          ยกเลิก
                        </Button>
                      </div>
                    </React.Fragment>
                  )
                });
              });

              this.setState({ campaignList: TABLE_CONTENT });
              this.props.campaignStore.TABLE_CONTENT = TABLE_CONTENT;
              break;
            default:
              this.props.campaignStore.TABLE_CONTENT = [];
              this.setState({ campaignList: [] });
          }
        });
        break;
      case 1:
        ServicesCampaign.getCampaign(data).then(res => {
          switch (res.status) {
            case 200:
              let TABLE_CONTENT = [];
              res.data.result.map((value, index) => {
                TABLE_CONTENT.push({
                  rowNo: value.rowNo,
                  campaignName: value.campaignName,
                  createDate: value.createDate,
                  action: (
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      onClick={() => this.handleOpenCampaignModal(value, true)}>
                      ดูรายละเอียด
                    </Button>
                  )
                });
              });

              this.setState({ campaignList2: TABLE_CONTENT });
              break;
            default:
              this.setState({ campaignList2: [] });
          }
        });
        break;
    }
  };

  componentDidMount() {
    this.setState({
      openConfirmDeleteCampaign: this.props.campaignStore
        .openConfirmDeleteCampaign
    });
    this.searchSubmit();
  }

  handleChangeFilter = (name, value) => {
    let filterList = [...this.state.filterList];

    filterList.map((val, i) => {
      if (val.name === name) {
        val.value = value;
      }
    });
    this.setState({ filterList: filterList });
  };

  handleOpenCampaignModal = (key, seeDetailOnly) => {
    this.props.campaignStore.campaignKeyArray = key;
    this.props.campaignStore.seeDetailOnly = seeDetailOnly;
    if (seeDetailOnly) {
      let newKey = window.btoa(JSON.stringify(key.campaignId));
      let r = window.btoa(JSON.stringify(1));
      this.props.history.push(
        '/setPointProductCampaign?key=' + newKey + '&r=' + r
      );
    } else {
      let newKey = window.btoa(JSON.stringify(key.campaignId));

      this.props.history.push('/setPointProductCampaign?key=' + newKey);
    }
  };
  searchSubmit = () => {
    let object = {};

    this.state.filterList.map((val, i) => {
      object[`${val.name}`] = val.value;
    });

    switch (this.state.activeTab) {
      case 0:
        this.getCampaign({
          campaignName: object.campaignName,
          campaignStatus: '1',
          userLogin: JSON.parse(localStorage.getItem('userData')).userId,
          roleId: JSON.parse(localStorage.getItem('userData')).roleId
        });
        this.props.campaignStore.historySearch = {
          campaignName: object.campaignName,
          campaignStatus: '1'
        };
        break;
      case 1:
        this.getCampaign({
          campaignName: object.campaignName,
          campaignStatus: '2',
          userLogin: JSON.parse(localStorage.getItem('userData')).userId,
          roleId: JSON.parse(localStorage.getItem('userData')).roleId
        });
        this.props.campaignStore.historySearch = {
          campaignName: object.campaignName,
          campaignStatus: '2'
        };
        break;
    }
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ activeTab: newValue }, () => {
      this.searchSubmit();
    });
  };

  handleOpenSetPointCampaign = () => {
    this.props.history.push('/setPointProductCampaign');
  };

  handleOpenConfirmDeleteCampaign = id => {
    this.setState({ openConfirmDeleteCampaign: id });
  };

  handleCloseConfirmDeleteCampaign = () => {
    this.searchSubmit();
    this.setState({ openConfirmDeleteCampaign: '' });
  };

  handleOpenSetDefaultIncentiveModal = () => {
    this.setState({ openSetDefaultIncentive: true });
  };

  handleCloseSetDefaultIncentiveModal = () => {
    this.setState({ openSetDefaultIncentive: false });
  };

  render() {
    const { classes } = this.props;
    const {
      filterList,
      campaignList,
      campaignList2,
      activeTab,
      openConfirmDeleteCampaign,
      openSetDefaultIncentive
    } = this.state;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Card>
            <CardHeader
              action={
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={() => this.handleOpenSetDefaultIncentiveModal()}>
                  ตั้งค่าเริ่มต้นอินเซนทีฟ
                </Button>
              }
              title={'แคมเปญ'}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <FilterRender
                        filterList={filterList}
                        handleChangeFilter={this.handleChangeFilter}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      style={{ textAlignLast: 'right' }}>
                      <Button
                        color="primary"
                        // disabled={!formState.isValid}
                        // fullWidth
                        type="submit"
                        variant="contained"
                        onClick={() => this.searchSubmit()}>
                        ค้นหา
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          &nbsp;
          <Grid container spacing={4}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Card>
                <CardHeader
                  title={
                    <Button
                      color="secondary"
                      type="submit"
                      variant="contained"
                      onClick={() => this.handleOpenSetPointCampaign()}>
                      เพิ่ม
                    </Button>
                  }
                />
                <Divider />
                <CardContent>
                  <Tabs value={activeTab} onChange={this.handleChangeTab}>
                    <Tab label="กำลังใช้งาน" />
                    <Tab label="ยกเลิก" />
                  </Tabs>
                  <TabPanel value={activeTab} index={0}>
                    <TableRender
                      TABLE_HEADER={TABLE_HEADER}
                      TABLE_CONTENT={campaignList}
                    />
                  </TabPanel>
                  <TabPanel value={activeTab} index={1}>
                    <TableRender
                      TABLE_HEADER={TABLE_HEADER2}
                      TABLE_CONTENT={campaignList2}
                    />
                  </TabPanel>
                </CardContent>
                <Divider />
              </Card>
            </Grid>
          </Grid>
          <ConfirmDeleteCampaign
            open={openConfirmDeleteCampaign}
            close={this.handleCloseConfirmDeleteCampaign}
          />
          <DefaultIncentiveSetting
            open={openSetDefaultIncentive}
            close={this.handleCloseSetDefaultIncentiveModal}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(useStyles),
  inject('optionStore', 'campaignStore', 'alertStore')
)(observer(withRouter(CampaignManagement)));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}>
      {value === index && children}
    </Typography>
  );
}
