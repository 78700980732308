import sendRequest from './sendRequestAPI'
import { HTTP } from '../constants/enum'

class ServicesAddSalesList {

	saveSell(requestData) {
		return sendRequest("/incentive/saveSell", HTTP.POST, requestData)
	}

}

export default new ServicesAddSalesList()