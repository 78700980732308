import { OPTION_RENDER } from '../../components/OptionRender/OptionRender';

export const INPUT_RENDER_TRADE = [
  {
    name: 'invoiceNo',
    displayName: 'invoiceNo',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'createDate',
    displayName: 'วันที่',
    showDisplayName: true,
    type: 'date',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'pcNo',
    displayName: 'รหัสพีซี',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'pcName',
    displayName: 'ชื่อพีซี',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'groupNo',
    displayName: 'กลุ่มลูกค้า',
    showDisplayName: true,
    type: 'option',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
];

export const INPUT_RENDER_PRODUCT = [
  {
    name: 'invoiceNo',
    displayName: 'invoiceNo',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'createDate',
    displayName: 'วันที่',
    showDisplayName: true,
    type: 'date',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'groupNo',
    displayName: 'กลุ่มลูกค้า',
    showDisplayName: true,
    type: 'option',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'pcName',
    displayName: 'ผู้ทำรายการ',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    widthCol: 4,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
];

export const INPUT_RENDER_ACTION_MODAL = [
  {
    name: 'saleStatus',
    displayName: 'สถานะการอนุมัติ',
    showDisplayName: true,
    type: 'option',
    value: '',
    option: [],
    required: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
  // {
  // 	name: 'saleStatusReason',
  // 	displayName: 'เหตุผล',
  // 	type: 'textarea',
  // 	value: '',
  // 	option: [],
  // 	required: true,
  // 	hidden: true,
  // 	widthCol: 12,
  // 	styleXs: {
  // 		label: 12,
  // 		input: 12,
  // 	},
  // 	styleSm: {
  // 		label: 3,
  // 		input: 9,
  // 	},
  // },
];

export const INPUT_RENDER_CONTENT = [
  {
    name: 'invoiceNo',
    displayName: 'Invoice No',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    disabled: true,
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'createDate',
    displayName: 'วันที่',
    showDisplayName: true,
    type: 'text',
    value: '',
    disabled: true,
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'groupNo',
    displayName: 'กลุ่มลูกค้า',
    showDisplayName: true,
    type: 'option',
    value: '',
    disabled: true,
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'pcName',
    displayName: 'ผู้ทำรายการ',
    showDisplayName: true,
    type: 'text',
    value: '',
    option: [],
    required: false,
    disabled: true,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  },
  {
    name: 'invoiceImage',
    displayName: 'รูปใบเสร็จ invoice',
    showDisplayName: true,
    type: 'upload',
    maxFile: 1,
    canUpload: false,
    value: [],
    option: [],
    required: false,
    widthCol: 12,
    styleXs: {
      label: 12,
      input: 12
    },
    styleSm: {
      label: 3,
      input: 9
    }
  }
];

export const TABLE_HEADER_TRADE = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'invoiceNo',
    label: 'invoiceNo'
  },
  {
    key: 'createDate',
    label: 'วันที่'
  },
  {
    key: 'pcNo',
    label: 'รหัสพีซี'
  },
  {
    key: 'pcName',
    label: 'ชื่อพีซี'
  },
  {
    key: 'groupNo',
    label: 'กลุ่มลูกค้า'
  },
  {
    key: 'action',
    label: 'จัดการอนุมัติ'
  }
];

export const TABLE_HEADER_PRODUCT = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'invoiceNo',
    label: 'invoiceNo'
  },
  {
    key: 'createDate',
    label: 'วันที่'
  },
  {
    key: 'groupNo',
    label: 'กลุ่มลูกค้า'
  },
  {
    key: 'pcName',
    label: 'ผู้ทำรายการ'
  },
  {
    key: 'action',
    label: 'จัดการอนุมัติ'
  }
];

export const TABLE_HEADER_MODAL = [
  {
    key: 'rowNo',
    label: 'ลำดับ'
  },
  {
    key: 'barcode',
    label: 'barcode'
  },
  {
    key: 'qty',
    label: 'จำนวน'
  }
];
